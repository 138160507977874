<script>
import Layout from "../../layouts/vertical.vue";
import PageHeader from "@/components/page-header";
import PreLoader from "@/components/pre-loader";
import Swal from "sweetalert2";
import _ from "lodash";
// import { tableData } from "./TaskListData";
import { mapGetters, mapState, mapMutations } from 'vuex';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export default {
  name: 'TaskList',
  components: { Layout, PageHeader, PreLoader },
  computed: {
    ...mapState(['getTaskUser', 'getPermissions', "getAssignedToUserId",'taskFilter']),
    ...mapGetters('tasks', ['getTaskData', "getAssignedToTaskData", "getalltasks", "GetAllTasksAssigned", "getAllStatuses",
      "getTaskData", 'getTaskAttachment',
      "getDeletedTask"]),
    ...mapGetters("errors", ["getErrors"]),
    ...mapGetters("userservice", ["getAllUsers"]),
    filteredTaskPermisions() {
      let permissions = this.getPermissions?.TaskRoles
      function hasPermission(permission) {
        return permissions.includes(permission);
      }
      return this.TaskPermisions?.filter(TaskPermisions => {
        return (
          (TaskPermisions.permission === "DeleteTask" && hasPermission("DeleteTask"))
        );
      });
    },
    hasData() {
      return this.getAssignedToTaskData && this.getAssignedToTaskData?.task?.length > 0;
    },
  },
  data() {
    return {
      isSearch: true,
      isError: false,
      isErrorResponse: null,
      isBusy: false,
      tableData: [],
      TaskList: null,
      downloading: false,
      rows: 0,
      perPage: 10,
      currentPage: 1,
      pageOptions: [10, 25, 50, 100],
      title: "",
      taskname: "",
      selectAll: false,
      HeirachyUsers: [],
      loading: true,
      filterVariable: [],
      modalVisible: false,
      currentDate: new Date().toISOString().split("T")[0],
      debouncedFiltered: _.debounce(this.onFiltered,),
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      TaskPermisions: [],
      selectedStatusOption:'Status',
      orderbylist:[],
      optionArray:[],
      filteredTableData:[],
      fields: [
        { key: "Selection", label: ' ' },
        { key: "Id", sortable: true, label: 'Task ID', class: ["text-truncate", "mw-150"] },
        { key: "TaskName", sortable: true, label: 'Task name', class: ["text-truncate", "mw-150"] },
        { key: "ProjectName", sortable: true, label: 'Project name', class: ["text-truncate", "mw-150"] },
        { key: "AssignedTo", sortable: true, label: 'Assigned to', class: ["text-truncate", "mw-150"] },
        { key: "CreatedDt", sortable: true, label: 'Created date', class: ["text-truncate", "mw-150"] },
        { key: "EndDate", sortable: true, label: 'End date', class: ["text-truncate", "mw-150"] },
        { key: "Comments", sortable: true, class: ["text-truncate", "mw-150"], label: 'Description' },
        { key: "Status", sortable: true, label: "Status" },
        { key: "Actions", sortable: true, label: "Action" },

      ],
      items: [],
    };
  },
  methods: {
    toggleSelectAll() {
      this.tableData.forEach((row) => {
        this.$set(row, 'selected', this.selectAll)
      });
    },
    selectAllToggle() {
      const allSelected = this.tableData.every(row => row.selected);
      this.selectAll = allSelected ? true : false;
    },
    initTaskPermisions() {
      this.TaskPermisions = [
        {
          key: 'deletetask',
          permission: 'DeleteTask',
        },
      ]
    },
    //checks the user has specific permission or not.
    hasPermission(permissionToCheck) {
      return this.filteredTaskPermisions.some(
        (permissionObj) => permissionObj.permission === permissionToCheck
      );
    },
    async GetAllTasks() {
      let param = await {
        "AssignedTo": !this.taskFilter ? this.getAssignedToUserId : '',
        "ProjectId": this.taskFilter ? this.taskFilter.ProjectId : '',
        "overdue": this.taskFilter && this.taskFilter?.filter == 'Overdue' ? 'Overdue' : ''
      }
      await this.$store.dispatch("tasks/GetAllViewTasksAssigned", param);
      //await this.$store.dispatch('tasks/Getalltasksbyid',Id);
      if (this.getAssignedToTaskData.task) {
        //this.tableData = await this.getTaskData != null ?  this.getTaskData : [];
        this.tableData = await this.getAssignedToTaskData.task != null ? this.getAssignedToTaskData.task.map(task => ({
          value: task?.Level1,
          _id: task?._id,
          Id: task?.Id,
          TaskName: task?.TaskName,
          AssignedById: task?.AssignedById,
          AssignedToId: task?.AssignedToId,
          Comments: task?.Comments,
          EndDate: task?.EndDate ? new Date(task.EndDate)?.toLocaleDateString('en-GB')?.replace(/\//g, '-') : '',
          StatusId: task?.StatusId,
          IsDeleted: task?.IsDeleted,
          CreatedBy: task?.CreatedBy,
          ModifiedName: task?.ModifiedName,
          AssignedTo: task?.AssignedTo,
          Status: task?.Status,
          AssignedBy: task?.AssignedBy,
          CreatedDt: task?.CreatedDt ? new Date(task.CreatedDt)?.toLocaleDateString('en-GB')?.replace(/\//g, '-') : '',
          ModifiedDt: task?.ModifiedDt,
          ProjectName: task?.ProjectName,

        }))
          : [];
          this.filteredTableData=this.tableData;
      }
      else {
        if (this.getErrors['critical_error']) {
          this.isError = true;
          this.errorResponse = this.getErrors['critical_error']
        }
      }

      if (this.tableData?.length == 0 || this.tableData == undefined) {
        this.isSearch = false;
      }
      else {
        this.isSearch = true;
      }
      this.loading = false;
      // Based on the table's length, the pagination count should be displayed
      this.rows = this.tableData?.length
      this.GetAllStatusList();
    },

    /**
    * Get All Statuses List
    */
    async GetAllStatusList() {
      await this.$store.dispatch("tasks/GetAllStatusesList");
      this.orderbylist = await this.getAllStatuses
        ?.filter((statusData) => statusData && statusData.Id != null && statusData.StatusName != 'In-active' && statusData.StatusName != 'Closed')
        .map((status) => status.StatusName);
        this.taskFilter && this.taskFilter?.filter != 'Overdue'?this.changeStatusOption(this.taskFilter?.filter):'';
    },
    changeStatusOption(option) {
      if (option == "clear") {
        this.optionArray = [];
        this.selectedStatusOption = "Status";
      } else {
        const index = this.optionArray.indexOf(option);
        if (index !== -1) {
          this.optionArray.splice(index, 1);
        } else {
          this.optionArray.push(option);
        }
        if (this.optionArray?.length) {
          this.selectedStatusOption =
            this.optionArray?.length > 1
              ? `${this.optionArray[0]} (+${this.optionArray?.length - 1})`
              : `${this.optionArray[0]}`;
        } else {
          this.selectedStatusOption = "Status";
        }
      }
      let filteredData = []
      this.tableData=this.filteredTableData
      if (this.optionArray?.length > 0) {
        filteredData = this.tableData?.filter((element) =>
          this.optionArray.includes(element.Status)
        );
      }
      else {
        filteredData = this.filteredTableData;
      }
      this.tableData = filteredData
      this.rows = this.tableData?.length
    },

    onFiltered() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      if (this.tableData?.length == 0) {
        this.rows = this.tableData?.length;
      }
      else if (this.$refs.myTable.filteredItems?.length != 0) {
        this.rows = this.$refs.myTable.filteredItems?.length
      }
      else {
        this.rows = this.$refs.myTable.filteredItems?.length;
      }
    },
    downloadRecords() {
      this.loading = true;
      this.downloading = true;
      const selectedRecords = this.tableData?.filter(
        (record) => record.selected
      );
      this.downloadZip(selectedRecords)

      const dataToDownload = selectedRecords.map((record) => ({
        ID: record.Id || "",
        "Task name": record.TaskName ? `"${record.TaskName.replace(/"/g, '""')}"` : "",
        "Created by": record.AssignedBy || "",
        "Assigned to": record.AssignedTo || "",
        "Created date": record.CreatedDt || "",
        "End date": record.EndDate || "",
        Description: record.Comments ? `"${record.Comments.replace(/"/g, '""')}"` : "",
        Status: record.Status || ""
      }));

      const headers = Object.keys(dataToDownload[0]);
      const headerRow = headers.join(",");
      const dataRows = dataToDownload.map((record) =>
        headers.map((header) => record[header]).join(",")
      );

      const csvData = [headerRow, ...dataRows].join("\n");
      const blob = new Blob(["\ufeff" + csvData], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, "Task_list.csv");
      } else {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Task_list.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      this.downloading = false;
    },
    downloadRecord(record) {
      const detailsToDownload = {
        ID: record.Id || "",
        "Task name": record.TaskName ? `"${record.TaskName.replace(/"/g, '""')}"` : "",
        "Created by": record.AssignedBy || "",
        "Assigned to": record.AssignedTo || "",
        "Created date": record.CreatedDt || "",
        "End date": record.EndDate || "",
        Description: record.Comments ? `"${record.Comments.replace(/"/g, '""')}"` : "",
        Status: record.Status || ""

      };

      const headings = Object.keys(detailsToDownload);
      const csvData = [
        headings.join(","),
        Object.values(detailsToDownload).join(","),
      ].join("\n");
      const blob = new Blob(["\ufeff" + csvData], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, "Task.csv");
      } else {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Task_Id-${record.Id}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    /**
     * download commented files into a zip
     */
    async downloadZip(data) {
      let selectedtasks = data.map(task => task.Id);
      const params = {
        taskIds: selectedtasks
      };
      await this.$store.dispatch('tasks/GetTaskAttachment', params);
      const arrayOfUrls = await this.getTaskAttachment?.data?.comments?.map((data) => data.ImageUrl);
      const fileURLs = [].concat(...arrayOfUrls);

      this.loading = false;

      if (fileURLs?.length > 0) {
        const zip = new JSZip();

        const getFileExtension = (url) => {
          const match = url.match(/\.([a-z0-9]+)(?:[?#]|$)/i);
          return match ? match[1] : "";
        };

        for (let i = 0; i < fileURLs?.length; i++) {
          const response = await fetch(fileURLs[i]);
          if (response.ok) {
            const blob = await response.blob();
            const fileExtension = getFileExtension(fileURLs[i]);
            const fileName = `file${i + 1}.${fileExtension || "txt"}`;
            zip.file(fileName, blob);
          }
        }

        const content = await zip.generateAsync({ type: "blob" });
        saveAs(content, `TaskAttachments_${selectedtasks}.zip`);
      }
    },
    /**
     * Single task download popup
     * @param {Recordlist} record
     */
    RecordDownload(record) {
      this.taskname = record.TaskName
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2",
        },
        buttonsStyling: false,
      });
      let truncatedTaskName = this.taskname?.length > 15 ? this.taskname.slice(0, 15) + "..." : this.taskname;
      let popUpText = this.downloadRecord?.length > 1 ? "You want to delete these tasks!" : `You want to download task <span style="color: black; font-weight: bold;">${truncatedTaskName}</span>?`;

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          html: '<div class="text-truncate">' + popUpText + '</div>',
          // icon: "warning",
          confirmButtonText: "Yes, download",
          cancelButtonText: "No, cancel",
          showCancelButton: true,
          allowOutsideClick: false,
          imageUrl: "/images/emailexpired_icon.png",
          imageHeight: 50, 
          imageWidth: 50,
        })
        .then((result) => {
          if (result.value) {
            this.loading = true;
            this.downloadRecord(record);
            this.downloadZip([record])
          }
        });
    },
    ChangePage() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        // this.loadInitData()
      }, 1000);
    },

    // User View Task list is redirect to view Task page. 
    ...mapMutations(['setTaskId']),
    async viewItem(Task) {
      let Id;
      if (Task.type == this.assignedByType || Task.type == this.assignedToType) {
        Id = Task.Id
        await this.setTaskId(Task.Id);
      }
      else {
        console.log(Task);
        Id = Task.TaskId
        await this.setTaskId(Task.TaskId);
      }
      await this.$router.push({ name: "ViewTask", params: { Id: Id } });
    },
    // search filter timeset.
    handleFilterInput() {
      if (this.filter !== null) {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.timer = null;
          this.filterVariable = this.filter;
        }, 800);
      }
    },
  },
  async created() {
    this.initTaskPermisions();
    // this.tableData = this.getAssignedToTaskData.task;
    //this.loading = false;
  },
  async mounted() {
    this.title = this.taskFilter ? this.taskFilter.ProjectName : this.getTaskUser;
    this.items = [{
      text: this.taskFilter ? "Projects" : "Users",
      href: this.taskFilter ? "/project-details/" + this.taskFilter?.ProjectGuid : null,
      routeName: this.taskFilter ? null : "UsersList"
    },
    {
      text: "View task",
      active: true,
    },]
    await this.GetAllTasks();
  },

}
</script>

<template>
  <Layout>
    <div v-if="loading">
      <PreLoader></PreLoader>
    </div>
    <PageHeader :title="title" :items="items" />
    <b-alert show variant="danger" v-if="isError == true">
      <i class="ri-close-circle-line mr-2"></i>{{ this.errorResponse }}
    </b-alert>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Filters -->
            <div class="d-flex flex-column flex-sm-row mt-1" :class="tableData?.length ? 'ytmFilterRow' : ''">
              <div class="col-sm-12 col-md-4">
                <div v-if="isSearch" id="tickets-table_length"
                  class="dataTables_length d-flex justify-content-center justify-content-md-start">
                  <label class="d-inline-flex align-items-center mb-2 mb-sm-0">
                    Show
                    <select size="sm" v-model="perPage" class="form-control align-self-start selction-padding"
                      style="width: 70px;" @change="ChangePage">
                      <option v-for="option of pageOptions" :key="option">{{ option }}</option>
                    </select>
                  </label>
                </div>
              </div>
             
              <!-- Search -->
              <div class="col-sm-12 col-md-8">
                <div class="d-flex justify-content-end align-items-center flex-column flex-sm-row">
                  <div class="ml-0 ml-sm-2 flex-shrink-0 mb-2 mb-sm-0 orderby-dropdown" v-if="isSearch">
                      <b-dropdown size="sm" @click="filterOption(option)" variant="outline-light"
                        class="outline-light w-100" ref="statusdropdown">
                        <template v-slot:button-content>
                          {{ selectedStatusOption }}
                          <i class="mdi mdi-chevron-down"></i>
                        </template>
                        <div class="assignedToDropdownData"
                          :class="[selectedStatusOption != 'Status' ? 'pb-40' : '']">
                          <b-dropdown-item-button v-for="option of orderbylist" :key="option"
                            @click="$refs.statusdropdown.show(true)">
                            <div class="custom-control custom-checkbox">
                              <input @click="changeStatusOption(option)" type="checkbox" :id="option"
                                :checked="optionArray?.includes(option)" class="custom-control-input" />
                              <label :for="option" class="custom-control-label ml-1 mb-0">{{ option }}</label>
                            </div>
                          </b-dropdown-item-button>
                        </div>
                        <button class="btn clear-btn text-primary font-weight-bold " @click="changeStatusOption('clear')"
                          v-if="selectedStatusOption != 'Status'">
                          x Clear
                        </button>
                      </b-dropdown>
                    </div>
                  <div v-if="isSearch != false && isError == false" id="tickets-table_filter"
                    class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center mb-2 mb-sm-0 position-relative">
                      <b-form-input v-model="filter" size="sm" type="search" placeholder="Search..."
                        @input="handleFilterInput" class="form-control ml-2 searchInput"></b-form-input>
                      <i class="ri-search-line search-icon"></i>
                    </label>
                  </div>
                  <!-- <div class="ml-2 flex-shrink-0 mb-2">
                    <b-button variant="primary" size="sm" @click="resetDataClick " v-b-modal.modal-center>
                      <i class="ri-add-line"></i> Add Task
                    </b-button>
                  </div> -->
                  <div class="ml-0 ml-sm-2 flex-shrink-0 mb-2 mb-sm-0" @click="downloadRecords" v-if="isSearch">
                    <b-button size="sm" variant="primary"
                      :disabled="(tableData?.filter(record => record.selected))?.length == 0">
                      <i class="ri-download-line align-bottom"></i> Download tasks
                      <div class="spinner-border" role="status" v-if="downloading">
                        <span class="sr-only"></span>
                      </div>
                    </b-button>
                  </div>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive">
              <div class="position-relative">
                <div class="round header-top" v-if="isSearch">
                  <input type="checkbox" :id="'checkbox'" v-model="selectAll" @change="toggleSelectAll" />
                  <label :for="'checkbox'"></label>
                </div>
              </div>
              <b-table class="tasksTable" ref="myTable" v-if="(tableData?.length > 0 || isSearch) && !loading "
                style="cursor: pointer ;border: 1px solid #ced4da;margin-top: 15px;" :items="tableData" responsive="sm"
                :fields="fields" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc" :filter="filterVariable" :filter-included-fields="filterOn"
                @filtered="debouncedFiltered" sticky-header>
                <!-- redirect for the view task page -->
                <template #cell(AssignedBy)="row">
                  <p class="mb-0 text-truncate mw-150">
                    <span :title="row.value">{{ row.value }}</span>
                  </p>
                </template>
                <template #cell(Id)="row">
                  <a v-if="row.value" @click="viewItem(row.item)" :title="row.value" class="task-link">
                    {{ row.value }}
                  </a>
                  <span v-else>{{ row.value }}</span>
                </template>
                <template #cell(TaskName)="row">
                  <a class="link font-weight-bold text-truncate mw-150"
                    @click="viewItem(row.item)" :title="row.value">{{ row.value
                    }}</a>
                </template>
                <template #cell(AssignedTo)="row">
                  <p class="mb-0 text-truncate mw-150">
                    <span :title="row.value">{{ row.value }}</span>
                  </p>
                </template>
                <template #cell(EndDate)="row">
                  <p class="mb-0">
                    <span :title="row.value">{{ row.value }}</span>
                  </p>
                </template>
                <template #cell(Comments)="row">
                  <p class="mb-0 text-truncate mw-150">
                    <span :title="row.value">{{ row.value }}</span>
                  </p>
                </template>
                <!-- <template #cell(Status)="row">
                    <p :title="row.value">{{ row.value }}</p> 
                </template> -->
                <!-- Slot for the actions column -->
                <template #cell(status)="row">
                  <div class="d-flex align-items-center" :title="row.value">
                            <span
                              :class="{
                                'color-active': row.value === 'New',
                                'bg-primary': row.value === 'Active',
                                'bg-success':
                                  row.value === 'Completed' ||
                                  row.value === 'Closed',
                                'bg-warning': row.value === 'In-progress',
                                'bg-secondary': row.value === 'In-active',
                              }"
                              class="ytmStatususerIndicator"
                            ></span>
                            {{ row.value }}
                          </div>
                </template>
                <!-- Select options -->
                <template #cell(Selection)="row">
                  <div class="round">
                    <input type="checkbox" :id="'checkbox-' + row.item.Id" v-model="row.item.selected"  @change="selectAllToggle()" />
                    <label :for="'checkbox-' + row.item.Id"></label>
                  </div>
                </template>
                <template #cell(actions)="row">
                  <i @click="RecordDownload(row.item)" class="ri-download-line text-primary cursor-pointer font-size-18"
                    size="sm" style="font-size: 1.5rem;" v-b-tooltip.hover="'Download task'"></i>
                </template>
                <!-- <template #cell(actions)="row">
                    <b-dropdown text="Actions" variant="outline-primary" size="sm">
                      <b-dropdown-item
                        variant="primary"
                        size="sm"
                        @click="RecordDownload(row.item)"
                        ><i class="ri-download-2-line mr-1 align-bottom"></i
                        >Download</b-dropdown-item
                      >
                      <b-dropdown-item 
                        variant="danger"
                        size="sm"
                        @click="cancel(row.item['Id'],row.item)" 
                        v-if="hasPermission('DeleteTask') && row.item['Status'] != 'Completed' &&
                          row.item['Status'] != 'In-progress' &&
                          tableData.filter((record) => record.selected).length < tableData.length"                        
                        ><i class="ri-delete-bin-line mr-1 align-bottom"></i
                        >Delete</b-dropdown-item
                      >
                    </b-dropdown>
                  </template> -->
              </b-table>
              <div class="nodata-ui" v-if="(tableData?.length <= 0 || tableData == undefined || isError == true || !rows) && !loading"
                v-cloak>
                <div class="fullscreen-height d-flex align-items-center justify-content-center">
                  <div class="py-5 text-muted">
                    <div class="d-flex flex-column justify-content-center gap-1 align-items-center fs-4">
                      <i class="ri-information-line font-size-36 text-muted"></i>
                      <h5 class="text-muted">No data found!</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- pagination -->
            <div class="row">
              <div class="col">
                <div class="paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-if="isSearch && rows != 0" v-model="currentPage" :total-rows="rows"
                      :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="myModal" id="modal-center" centered title="Add Task" title-class="font-18" hide-footer>
      <form @submit.prevent="onSubmit">
        <!-- <div class="text-left mb-2">-->

      </form>
    </b-modal>
  </Layout>
</template>

<style lang="scss" scoped>
// .table {
//   width: 100%;
//   margin-bottom: 1rem;
//   color: #505d69;

//   .font-size-14 {
//     font-size: 14px;
//     font-weight: normal;
//   }

//   .badge-soft-info {
//     color: #4aa3ff;
//     background-color: rgba(74, 163, 255, 0.18);
//   }

//   .badge-soft-primary {
//     color: #00435d;
//     background-color: rgba(0, 67, 93, 0.18);
//   }

//   .badge-soft-success {
//     color: #1cbb8c;
//     background-color: rgba(28, 187, 140, 0.18);
//   }

//   .badge-soft-warning {
//     color: #fcb92c;
//     background-color: rgba(252, 185, 44, 0.18);
//   }
// }

// .nodata-ui {
//   i {
//     font-size: 34px !important;
//   }

//   .fullscreen-height {
//     height: calc(100vh - 520px);
//   }

//   [v-cloak] {
//     display: none;
//   }
// }

.round {
  position: relative;
}

.ytmStatususerIndicator{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 3px;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;

}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 5px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 10px;
  right: 0;
  margin: auto;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
  background-color: #00435d;
  border-color: #00435d;
}

.round input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.task-link {
  cursor: pointer;
  color: #505d69;

  &:hover {
    color: #00435d;
  }

  &:active {
    color: #00435d;
  }
}

.header-top {
  position: absolute;
  top: 28px;
  left: 9px;
  z-index: 3;
}

.fullscreen-height {
  height: 340px;
}

.color-new {
  color: gray;
  background-color: lightgray;
}

.color-active {
  color: #007acc;
  background-color: rgba(0, 122, 204, 0.28);
}

.badge-soft-danger {
  color: #ff9d00;
  background-color: rgba(255, 157, 0, 0.28);
}

.link {
  color: #303030;

  &:hover {
    color: #00435d;
  }
}

.search-input {
  border-bottom: 1px solid #ddd !important;
  border: none;
  border-radius: 0;
}
.orderby-dropdown {
  @media screen and (max-width:444px) {
    width: 100%;
  }

  @media screen and (min-width:445px) and (max-width:518px) {
    margin-right: 0.5rem;
  }

  @media screen and (min-width:519px) {
    margin-left: 0.5rem;
  }
}
</style>
