import axios from 'axios';

const $taskApiService = axios.create({
  baseURL: process.env.VUE_APP_DEV_TASK_API_URL,
  timeout: 30000,
});

$taskApiService.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { $taskApiService as taskApiService};
