<script>
import Layout from "../../layouts/vertical.vue";
import PageHeader from "@/components/page-header";
import RecentActivity from "@/views/pages/tasks/timeline-activity";
import moment from 'moment-timezone';
//import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
// import PreLoader from "@/components/pre-loader";
import Swal from 'sweetalert2';

import { mapGetters, mapState, mapMutations } from 'vuex';
export default {
  components: { Layout, PageHeader, RecentActivity, Multiselect },
  computed: {
    ...mapState(["getUserguid", "getPermissions", 'getRepotingGuid', 'gettaskfilterstate']),
    ...mapGetters('tasks', ['getViewTask', 'getAllCommentsDataById', 'getAllStatuses', 'getupdateCommentsByTaskId', 'getDeteteTask']),
    ...mapGetters("userservice", [
      "getUserDetailsById", "getUserDefaults", "getUserHierarchy"
    ]),
    ...mapGetters("projects", ["getAllUsersByProject"]),
    ...mapGetters('auth', ['getProfileDetails', 'getAuthenticatedUser']),
    ...mapGetters("errors", ["getErrors"]),
    isFormInvalid() {
      return (
        !this.taskform.TaskName ||
        !this.taskform.AssignedBy ||
        !this.taskform.EndDate ||
        !this.taskform.Status ||
        !this.taskform.Comments
      );
    },
    isDirty() {
      if (this.taskData?.TaskName !== this.taskform?.TaskName
        || this.taskform?.AssignedToId !== this.taskData?.AssignedToId
        || this.taskform?.EndDate !== this.taskData?.EndDate
        || this.taskform?.Status !== this.taskData?.Status
        || this.taskform?.Comments !== this.taskData?.Comments) {
        return true
      }
      else {
        return false
      }
    },

    //  Max Date 2 years
    maxDate() {
      const maxDate = new Date();
      maxDate.setFullYear(maxDate.getFullYear() + 2);
      return maxDate.toISOString().split('T')[0];
    },
    //Check and filters the permissions user has for view tasks page.
    filteredTaskPermisions() {
      let permissions = this.getPermissions?.TaskRoles
      function hasPermission(permission) {
        return permissions?.includes(permission);
      }
      return this.TaskPermisions.filter(TaskPermisions => {
        return (
          (TaskPermisions.permission === 'EditTask' && hasPermission("EditTask")) ||
          (TaskPermisions.permission === 'DeleteTask' && hasPermission("DeleteTask")) ||
          (TaskPermisions.permission === 'CloseTask' && hasPermission("CloseTask"))
        );
      });
    },
  },
  data() {
    return {
      dismissCountDown: 0,
      showDismissibleAlert: false,
      // loading: true,
      showShimmers: true,
      isInprogress: 'In-progress',
      isCompleted: 'Completed',
      todayDate: new Date().toISOString().split("T")[0],
      taskform: {
        _id: "",
        Id: "",
        TaskName: "",
        AssignedById: '',
        AssignedToId: '',
        AssignedTo: "",
        AssignedBy: "",
        EndDate: "",
        Status: "",
        Comments: "",
        StatusId: ""
      },
      taskData: [],
      enableedit: true,
      title: "Task details",
      items: [
        {
          text: "Tasks",
          routeName: "TaskList"
        },
        {
          text: "View task",
          active: true,
        },
      ],
      taskId: null,
      additionalData: '',
      autoClose: "",
      assignedOption: [],
      StatusListOption: [],
      assignedTo: null,
      statusName: null,
      assignedBy: null,
      status: null,
      currentPage: 1,
      perPage: 200,
      Orderby: '',
      Sort: 'asc',
      filter: null,
      filterBy: null,
      searchQuery: '',
      isError: false,
      viewdeleted: "",
      TaskPermisions: [],
      assignedToname: null,
      StatusName: null,
      inputValue: [],
      isTaskEdited: 'TaskEdited',
      Timeline: "Timeline",
      isTimeline: false,
      isTaskDeleted: "TaskDeleted",
      date: '',
    };
  },
  watch: {
    assignedToname(newValue) {
      this.taskform.AssignedTo = newValue ? newValue.FullName : '';
      this.taskform.AssignedToId = newValue ? newValue.userId : '';
    },
    StatusName(newvalue) {
      this.taskform.Status = newvalue ? newvalue.name : '';
      this.taskform.StatusId = newvalue ? newvalue.Id : '';
    },
    assignedTo: {
      handler(newVal) {
        if (newVal) {
          // Access the selectedUser.id to get the corresponding additional data
          this.additionalData = this.getAdditionalData(newVal.Id)
        } else {
          this.additionalData = null;
        }
      },
      immediate: true,
    },

    statusName: {
      handler(newVal) {
        if (newVal) {
          // Access the selectedUser.id to get the corresponding additional data
          this.additionalData = this.getStatusData(newVal.Id)
        } else {
          this.additionalData = null;
        }
      },
      immediate: true,
    },
    'taskform.EndDate': function (newDate) {
      newDate ?
        this.date = moment(newDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : "";
    },
  },
  created() {
    this.initTaskPermisions();
  },
  methods: {
    containLink(text) {
      const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|(ftp:\/\/[^\s]+)/;
      return urlRegex.test(text);
    },
    //checks the user has specific permission or not.
    hasPermission(permissionToCheck) {
      return this.filteredTaskPermisions.some(permissionObj => permissionObj.permission === permissionToCheck);
    },
    getAdditionalData(selectedAssignedById) {
      //  array named 'this.getUserHierarchy' with objects containing 'id' and 'name' properties
      const additionalData = this.getUserHierarchy.find(item => item.UserId === selectedAssignedById);
      return additionalData ? additionalData : null;
    },

    //  array named 'getAllStatuses' with objects containing 'id' and 'name' properties
    getStatusData(selectedStatusId) {
      const additionalData = this.getAllStatuses.find(item => item.Id === selectedStatusId);
      return additionalData ? additionalData : null;
    },
    toggleMessage() {
      this.enableedit = !this.enableedit;
      this.taskData?.AssignedToId == 0 && this.enableedit
        ? this.taskform.AssignedToId=0
        : this.assignedToname = (this.assignedOption.filter(user => user.userId == this.taskData?.AssignedToId))[0]
      this.StatusName = (this.StatusListOption?.filter(element => element.Id == this.taskData?.StatusId))[0]
      this.enableedit != true ? this.title = `Edit task` : this.title = `View task`
    },

    async GetUsersList() {
      const params = {
        pagenumber: this.currentPage,
        rowcount: this.perPage,
        keyword: this.filter,
        sort: this.Sort,
        orderby: this.Orderby,
        ProjectId: this.getViewTask?.ProjectId,
      };
      await this.$store.dispatch("projects/GetUsersByProject", params);
      this.assignedOption = await this.getAllUsersByProject?.filter(element => element && element.userId != null)
        .map(user => (
          {
            userId: user.userId,
            FullName: user.FullName,
            Email: user.Email
          }
        ));
        this.assignedOption.unshift({ userId: 0, FullName: 'Unassigned', Email:'' });

      // this.loading = false;
      this.showShimmers = false;
    },
    /**
     * Get All Statuses List
     */
    async GetAllStstusesList() {
      await this.$store.dispatch('tasks/GetAllStatusesList');
      if (this.hasPermission("CloseTask")) {
        this.StatusListOption = await this.getAllStatuses?.filter(statusData => statusData && statusData.Id != null)
          .map(status => ({ Id: status.Id, name: status.StatusName }));
      } else {
        this.StatusListOption = await this.getAllStatuses?.filter(statusData => statusData && statusData.Id != null && statusData.StatusName != 'Closed')
          .map(status => ({ Id: status.Id, name: status.StatusName }));
      }
    },

    ...mapMutations(['setFilterData']),
    /**
     *  get TaskData Based on Id
     * @param {TaskId} Id 
     */
    async viewItem(Id) {
      await this.$store.dispatch('tasks/ViewTaskData', Id);
      this.taskData = []
      if (this.getViewTask) {
        await this.GetUsersList();
        this.taskData.push(await this.getViewTask)
        let task = this.taskData.map(task => ({
          AssignedBy: task.AssignedBy,
          AssignedById: task.AssignedById,
          AssignedTo: task.AssignedTo || "Unassigned",
          AssignedToId: task.AssignedToId,
          Comments: task.Comments,
          EndDate: task?.EndDate.split("T")[0],
          Id: task.Id,
          Status: task.Status,
          TaskName: task.TaskName,
          StatusId: task.StatusId,
          _id: task._id,
          IsDeleted: task.IsDeleted,
          date: moment(task?.EndDate.split("T")[0]).format('DD-MM-YYYY'),
          ProjectId: task?.ProjectId,
          ProjectName: task?.ProjectName
        }));
        this.taskData = task[0]
        this.taskform = {
          _id: this.taskData?._id,
          Id: this.taskData?.Id,
          TaskName: this.taskData?.TaskName,
          AssignedById: this.taskData?.AssignedById,
          AssignedBy: this.taskData?.AssignedBy,
          AssignedTo: this.taskData.AssignedTo,
          AssignedToId: this.taskData.AssignedToId,
          EndDate: this.taskData?.EndDate,
          Status: this.taskData?.Status,
          Comments: this.taskData?.Comments,
        };
        /**
       * find data based on assignedTo
       */
        const user = await this.getUserHierarchy?.find(element => element.userId == this.taskData?.AssignedToId)
        if (user) {
          this.assignedTo = {
            Id: user.UserId,
            name: user.FullName
          }
        }
        // this.loading = false;
        this.showShimmers = false;

      } else {
        if (this.getErrors['critical_error']) {
          this.isError = true;
          this.errorResponse = this.getErrors['critical_error']
        } else {
          this.isError = true;
          // this.loading = false;
          this.showShimmers = false;
          this.errorResponse = 'Something went wrong !'
          console.log("errors", this.errorResponse)
        }
      }
      const data = {
        statusFilter: this.gettaskfilterstate.statusFilter || [],
        assignedToFilter: this.gettaskfilterstate.assignedToFilter || [],
        projectFilter : this.gettaskfilterstate.projectFilter || [],
        dateFilter : this.gettaskfilterstate.dateFilter || [],
        fromViewTask: true,
        taskOrderBy: this.gettaskfilterstate.taskOrderBy || "Tasks"
      }
      this.setFilterData(data);
    },

    async CommentChecking() {
      // await this.$store.dispatch('tasks/GetAllCommentsById', this.taskId);
      this.isTimeline = this.getAllCommentsDataById?.some(item => item.CommentType === this.Timeline);
      if (this.isTimeline == false && this.taskform?.Status == this.isCompleted) {
        await Swal.fire("warning !", "Task cannot be completed without adding a comment.", "warning");
      }
      else {
        await this.EditTaskData();
      }
    },

    // Save Task Data 
    async EditTaskData() {
      // this.loading = true
      this.showShimmers = true
      const params = {
        Id: this.taskId,
        _id: this.taskData._id,
        TaskName: this.taskform.TaskName,
        EndDate: this.taskform.EndDate,
        StatusId: this.taskform.StatusId,
        Comments: this.taskform.Comments,
        AssignedToId: this.taskform.AssignedToId == 0 ? 0 :this.taskform.AssignedToId ,
        AssignedTo: this.taskform.AssignedTo == 'Unassigned' ? null : this.taskform.AssignedTo,
        Status: this.taskform.Status
      }
      await this.$store.dispatch('tasks/UpdateTaskData', params);
      // window.location.reload();
      const modifiedFields = {};
      for (const key in this.taskform) {
        if (this.taskform[key] !== this.taskData[key]) {
          modifiedFields[key] = this.taskform[key];
        }
      }
      if (Object.keys(modifiedFields).length > 0) {
        this.inputValue.push(modifiedFields);
      }
      this.inputValue.map(item => ({ AssignedTo: item.AssignedTo || '', Comments: item.Comments || '', EndDate: item.EndDate || '', Status: item.Status || '', TaskName: item.TaskName | '' }))

      const input = await {
        TaskId: this.taskId,
        Users: [],
        Comments: this.taskData.Comments,
        Status: this.taskData?.Status,
        TaskName: this.taskData?.TaskName,
        AssignedById: this.taskData?.AssignedById,
        AssignedToId: this.taskData.AssignedToId,
        EndDate: moment(this.taskData?.EndDate).format('yyyy-MM-DD'),
        AssignedTo: this.taskData?.AssignedTo,
        AssignedBy: this.taskData?.AssignedBy,
        Commentedby: this.getProfileDetails?.FirstName + ' ' + this.getProfileDetails?.LastName,
        CommentedbyId: this.getProfileDetails?.UserId || this.getProfileDetails.Id,
        currentComments: params.Comments == this.taskData.Comments ? '' : params.Comments,
        currentAssignedTo: params.AssignedTo == this.taskData.AssignedTo ? '' : params.AssignedTo,
        currentEndDate: params.EndDate == this.taskData.EndDate ? '' : params.EndDate,
        currentStatus: params.Status == this.taskData.Status ? '' : params.Status,
        currentTaskName: params.TaskName == this.taskData.TaskName ? '' : params.TaskName,
        CommentType: this.isTaskEdited,
        ProjectId: this.taskData?.ProjectId,
        ProjectName: this.taskData?.ProjectName,


      }
      await this.$store.dispatch('tasks/SaveCommentsByTaskId', input);
      await this.UpdateCommentsByTaskId();
      await this.viewItem(this.taskId)
      await this.$refs['RecentActivityRef'].getformViewTask();
      // const message = {
      //   variant: 'success',
      //   alertTitle: 'View task',
      //   alertDecription: 'Task saved sucessfully'
      // }
      // this.setAlertNotification(message)
      this.response = await this.getupdateCommentsByTaskId;
      const message = {
        variant: this.response.status === true ? 'success' : 'danger',
        alertTitle: this.response.status === true ? 'Updated' : 'Error',
        alertDecription: this.response.msg
      };
      this.setAlertNotification(message)
      await this.toggleMessage();
    },
    //Ueser task permissios data
    initTaskPermisions() {
      this.TaskPermisions = [
        {
          key: 'edittask',
          permission: 'EditTask',
        },
        {
          key: 'deletetask',
          permission: 'DeleteTask',
        },
        {
          key: 'closetask',
          permission: 'CloseTask',
        },
      ]
    },
    cancel(Id, Name) {
      this.taskname = Name
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-primary ml-2",
        },
        buttonsStyling: false,
      });
      let truncatedTaskName = this.taskname.length > 15 ? this.taskname.slice(0, 15) + "..." : this.taskname;
      let popUpText = `You want to delete the task <span style="color: black; font-weight: bold;">${truncatedTaskName}</span>? <br/> You can restore this task from the Deleted tasks tab.`
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          html: '<div class="text-truncate">' + popUpText + '</div>',
          // icon: "warning",
          confirmButtonText: "Yes, delete",
          cancelButtonText: "No, cancel",
          showCancelButton: true,
          allowOutsideClick: false,
          imageUrl: "/images/emailexpired_icon.png",
          imageHeight: 50, 
          imageWidth: 50,
        })
        .then((result) => {
          if (result.value) {
            this.deleteItem(Id);
            // this.loading = true;
            this.showShimmers = true;
          }
        });
    },
    async SaveDeletedTaskComment() {
      const input = {
        TaskId: this.taskData?.Id,
        Users: [],
        Comments: "Task has been deleted.",
        Status: "New",
        TaskName: this.taskData?.TaskName,
        AssignedById: this.taskData?.AssignedById,
        AssignedToId: this.taskData?.AssignedToId,
        EndDate: moment(this.taskData?.EndDate).format("yyyy-MM-DD"),
        AssignedTo: this.taskData?.AssignedTo,
        AssignedBy: this.taskData?.AssignedBy,
        Commentedby: `${this.getProfileDetails?.FirstName} ${this.getProfileDetails?.LastName}`,
        CommentedbyId: this.getProfileDetails?.UserId || this.getProfileDetails.Id,
        CommentType: this.isTaskDeleted,
        ProjectId: this.taskData?.ProjectId,
        ProjectName: this.taskData?.ProjectName
      };
      await this.$store.dispatch("tasks/SaveCommentsByTaskId", input);
    },
    async UpdateCommentsByTaskId() {
      const params = {
        TaskId: [this.taskData?.Id]
      }
      await this.$store.dispatch("tasks/UpdateCommentsByTaskId", params);
    },
    // Delete Task data based on Id
    ...mapMutations(['setTaskPopup', 'setAlertNotification']),
    async deleteItem(taskId) {
      const id = [taskId]
      const params = {
        taskIds: id,
        IsDeleted: true,
      };
      await this.$store.dispatch("tasks/DeleteTaskData", params);
      await this.SaveDeletedTaskComment();
      // this.loading = false;
      this.showShimmers = false;
      this.viewdeleted = await this.getDeteteTask
      setTimeout(() => {
        const message = {
          variant: this.viewdeleted?.data.status === false ? 'danger' : 'secondary',
          alertTitle: this.viewdeleted?.data.status === false ? 'Error' : 'Deleted',
          alertDecription: this.viewdeleted?.data.msg
        }
        this.setAlertNotification(message)
      }, 2000);
      await this.$router.push('/tasks-list');
      await this.setTaskPopup(this.isTaskDeleted)
      if (this.isTaskDeleted) {
        await this.UpdateCommentsByTaskId();
      }
    },

    // alert popup
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }

  },
  async mounted() {
    await this.GetAllStstusesList();
    this.taskId = await this.$route.params.Id;
    await this.viewItem(this.taskId);
  }
};
</script>
<template>
  <Layout>
    <!-- <div v-if="loading == true">
      <PreLoader></PreLoader>
    </div> -->
    <PageHeader :title="title" :items="items" />
    <b-alert show variant="danger" v-if="isError == true">
      <i class="ri-close-circle-line mr-2 align-middle"></i>{{ this.errorResponse }}
    </b-alert>
    <div class="row" v-if="showShimmers">
      <div class="col-lg-12">
        <b-card no-body>
          <b-card-body>
            <div class="row">
              <div class="col-lg-12 mb-4">
                <b-skeleton height="15px" width="150px"></b-skeleton>
              </div>
              <div class="col-lg-4 mb-4" v-for="(index ) in [].constructor(7)" :key="index">
                <b-skeleton height="10px" width="100px"></b-skeleton>
                <b-skeleton type="text" width="65%"></b-skeleton>
              </div>
            </div>
          </b-card-body>
          <div class="card-footer d-flex justify-content-end">
            <b-skeleton class="mr-2" type="button" height="40px" width="100px" border-radius="8px"></b-skeleton>
            <b-skeleton type="button" height="40px" width="100px" border-radius="8px"></b-skeleton>
          </div>
        </b-card>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-lg-12 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <h4 class="card-title mb-4">Project - ({{ taskData.ProjectName }})</h4>
            <template v-if="enableedit">
              <div class="row">
                <div class="col-lg-4">
                  <div class="mb-4">
                    <p class="text-muted mb-1">Task name</p>
                    <h5 class="font-size-15">
                      <span data-toggle="tooltip" data-placement="top" :title="taskform.TaskName"><code>{{ taskData.Id }}</code> - {{ taskform.TaskName
                      }}</span>
                    </h5>
                  </div>
                </div>
                <!-- <div class="col-lg-4">
                  <div class="mb-4">
                    <p class="text-muted mb-1">Task ID</p>
                    <h5>
                      <span class="font-size-15 text-truncate" :title="taskData.Id">{{ taskData.Id }}</span>
                    </h5>
                  </div>
                </div> -->
                <div class="col-lg-4">
                  <div class="mb-4">
                    <p class="text-muted mb-1">Assigned to</p>
                    <h5 class="font-size-15 text-truncate">
                      <span data-toggle="tooltip" data-placement="top" :title="taskform.AssignedTo">{{ taskform.AssignedTo
                      }}</span>
                    </h5>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="mb-4">
                    <p class="text-muted mb-1">Created by</p>
                    <h5 class="font-size-15 text-truncate">
                      <span data-toggle="tooltip" data-placement="top" :title="taskform.AssignedBy">{{ taskform.AssignedBy
                      }}</span>
                    </h5>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="mb-4">
                    <p class="text-muted mb-1">Due date</p>
                    <h5 class="font-size-15">
                      <span data-toggle="tooltip" data-placement="top" :title="taskData.date">{{ taskData.date }}</span>
                    </h5>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="mb-4">
                    <p class="text-muted mb-1">Status</p>
                    <h5 class="font-size-15">
                      <span data-toggle="tooltip" data-placement="top" :title="taskform.Status">{{ taskform.Status
                      }}</span>
                    </h5>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="mb-4">
                    <p class="text-muted mb-1">Description</p>
                    <h5 class="font-size-15" style="white-space: pre-wrap;">
                      <span>
                        <span v-for="(commentPart, index) in taskform.Comments.split(/(\s+)/)" :key="index">
                          <template v-if="containLink(commentPart)">
                            <a :href="commentPart" target="_blank">{{ commentPart }} </a>
                          </template>
                          <template v-else> {{ commentPart }}</template>
                        </span>
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <form>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="taskName">Task name<span :class="{ 'text-danger': !this.taskform.TaskName }">
                          *</span></label>
                      <input type="text" class="form-control" value="Yoda Task Management System"
                        :class="[this.taskform.TaskName?.length == 0 ? 'border border-danger' : '']"
                        v-model="taskform.TaskName" />
                      <p v-if="this.taskform.TaskName?.length == 0" class="text-danger">Task name is required!</p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="taskName">Task ID<span :class="{ 'text-danger': !this.taskform.Id }">
                          *</span></label>
                      <input type="text" class="form-control" value="Yoda Task Management System"
                        :class="[this.taskform.TaskName?.length == 0 ? 'border border-danger' : '']" v-model="taskform.Id"
                        disabled />
                      <p v-if="this.taskform.Id?.length == 0" class="text-danger">Task id is required!</p>
                    </div>
                  </div>
                  <!-- assigned to -->
                  <div class="col-lg-6">
                    <div class="form-group ">
                      <label class="" for="assignedTo">Assigned to</label>
                      <!-- <span :class="{ 'text-danger': taskform.AssignedTo == '' }"> *</span> 
                        :class="[this.taskform.AssignedTo?.length == 0 ? 'multiSelectError' : 'multi-selector-container']"-->
                      <div>
                        <multiselect v-model="assignedToname" :options="assignedOption" track-by="userId"
                          label="FullName" open-direction="bottom"></multiselect>
                      </div>
                      <!-- <p v-if="taskform.AssignedTo == ''" class="text-danger ">Assigned to is required!</p> --> 
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="" for="assignedBy">Assigned by<span :class="{ 'text-danger': !taskform.AssignedBy }">
                          *</span></label>
                      <!-- <div class="multi-selector-container" :class="[this.assignedBy == null ? 'border border-danger' : 'multi-selector-container' ]"> -->
                      <!-- <multiselect disabled v-model="assignedBy"  :options="assignedOption"  label="name" track-by="Id" ></multiselect> -->
                      <input type="text" class="form-control "
                        :class="[this.taskform.AssignedBy?.length == 0 ? 'border border-danger' : '']"
                        v-model="taskform.AssignedBy" disabled />
                      <!-- </div> -->
                      <p v-if="taskform.AssignedBy == null" class="text-danger">Assigned by is required!</p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="dueDate">Due date<span :class="{ 'text-danger ': !taskform.EndDate }"> *</span></label>
                      <!-- <date-picker v-model="date" :class="[ this.date==null ? 'due'  : 'due']" :first-day-of-week="1" lang="en"></date-picker> -->
                      <!-- <input type="date" class="form-control"
                        :class="[taskform.EndDate == '' ? 'border border-danger' : 'form-control']" v-bind:min="todayDate"
                        v-bind:max="maxDate" v-model="taskform.EndDate"> -->

                      <!-- <b-form-datepicker  v-model="taskform.EndDate" id="datepicker2"
                        selected-variant="success" today-variant="info" nav-button-variant="primary"
                        :date-format-options="{ day: 'numeric' }" :hide-header=true v-bind:min="todayDate"
                        placeholder="Select to date" locale="en" format="YYYY-MM-DD"
                        :class="[taskform.EndDate == '' ? state = false : 'form-control']"></b-form-datepicker> -->
                        <b-input-group>
            <b-form-input id="example-input" v-model="date" type="text" placeholder="Select to date" autocomplete="off"
              readonly
              v-bind:class="{ 'form-control': true, 'border border-danger': !taskform.EndDate}"></b-form-input>
            <b-input-group-append>
              <b-form-datepicker v-model="taskform.EndDate" button-only right locale="en-US" v-bind:max="maxDate"
                aria-controls="example-input" selected-variant="success" today-variant="info" nav-button-variant="primary"
                :hide-header=true v-bind:min="todayDate"></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
                      <p v-if="taskform.EndDate == ''" class="text-danger">Due date is required!</p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="status">Status<span :class="{ 'text-danger': taskform.Status == '' }"> *</span></label>
                      <div :class="[this.taskform.Status?.length == 0 ? 'multiSelectError' : 'multi-selector-container']">
                        <multiselect v-model="StatusName" track-by="Id" label="name" :options="StatusListOption">
                        </multiselect>
                      </div>
                      <p v-if="taskform.Status == ''" class="text-danger">Status is required!</p>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label for="comments">Description<span :class="{ 'text-danger': !this.taskform.Comments }">
                          *</span></label>
                      <textarea rows="5"
                        :class="[this.taskform.Comments?.length == 0 ? 'border border-danger' : 'dateInput']"
                        v-model="taskform.Comments" value="{taskform.Status}" class="form-control"></textarea>
                      <p v-if="this.taskform.Comments?.length == 0" class="text-danger">Description is required!</p>
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </div>
          <div
            v-if="this.taskData.IsDeleted == false && (hasPermission('DeleteTask') || hasPermission('EditTask')) && this.taskData.Status != 'Closed'"
            class="card-footer">
            <div class="col-lg-12 text-right">
              <b-button variant="outline-danger mr-2" @click="cancel(taskData.Id, taskform.TaskName)"
                v-if="enableedit && hasPermission('DeleteTask') && (taskData.Status != isInprogress && taskData.Status != isCompleted)">
                <i class="ri-delete-bin-line align-text-bottom"></i> Delete
              </b-button>
              <b-button variant="outline-primary mr-2" @click="toggleMessage" v-if="!enableedit">
                <i class="ri-close-line align-bottom"></i> Cancel
              </b-button>
              <span v-if="hasPermission('EditTask')">
                <b-button variant="primary" @click="toggleMessage" v-if="enableedit">
                  <i class="ri-pencil-line align-bottom"></i> Edit
                </b-button>
                <b-button :disabled="isFormInvalid || !isDirty" variant="primary" @click="CommentChecking" v-else>
                  <i class="ri-save-line align-bottom"></i> Update
                </b-button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <RecentActivity ref="RecentActivityRef" />
      </div>
    </div>
    <!-- <b-alert v-model="showDismissibleAlert" variant="success" dismissible class="edittask-alert">
      <p class="font-size-16 font-weight-bold mb-0"><i class="ri-checkbox-circle-line align-bottom"></i> Updated!</p>
      <p class="mb-0">Task has been updated successfully.</p>
    </b-alert> -->
    <!-- <b-button @click="showDismissibleAlert = true" variant="success" class="m-1">
      click
    </b-button> -->
  </Layout>
</template>

<style scoped>
/* .multi-selector-container{
  border: 1px  solid #d0d2d3;
  
  border-radius: 5px;
  padding: -20px;
  margin-top: -2px;
  
} */
.edittask-alert {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 10000;
}
code{
  font-size: 15px;
  font-weight: 600;
  color: #27ba8e;
}
</style>
