<template>
    <div id="preloader">
        <div id="status">
            <div class="spinner">
                <i class="ri-loader-line spin-icon"></i>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
};
</script>

<style lang="scss" scoped>
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0.65;
    z-index: 9999;

    #status {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%);
        margin: -20px 0 0 -20px;

        .spinner {
            .spin-icon {
                font-size: 56px;
                color: #00435d;
                position: relative;
                display: inline-block;
                animation: spin 1.6s infinite linear;
            }
        }
    }
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
  