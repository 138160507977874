import { axios } from "../../config/axios";
import { taskApiService } from "../../config/tasksaxios"
const initialState = () => {
    return {
      allProjects: [],
      AllUsersByProject : [],
      ProjectDetails : [],
      removeResponse:[],
      ReportingUsers :[],
      tasksCount : [],
      duplicateProject:[],
    };
  };
  const state = initialState();
  export const getters = {
    getAllProjectsForUser: (state) => state.allProjects,
    getAllUsersByProject: (state) => state.AllUsersByProject,
    getUsetProjectDetails:(state) =>state.ProjectDetails,
    getUsersFromProject:(state) =>state.AllUsersFromProject,
    getDeleteUserDetails:(state) =>state.removeResponse,
    getReportingUsers:(state) =>state.ReportingUsers,
    getTaskCount:(state) => state.tasksCount,
    getDuplicateProject:(state)=> state.duplicateProject
  };
  export const mutations = {
    setAllProjectsForUser(state, data) {
      state.allProjects = data;
    },
    setUsersByProjects(state, data) {
      state.AllUsersByProject = data;
    },
    setUsetProjectDetails(state,data){
      state.ProjectDetails = data;
    },
    setUsersFromProject(state,data){
      state.AllUsersFromProject = data;
    },
    setDeleteUserFromProject(state,data){
      state.removeResponse = data;
    },
    setReportingUsers(state,data){
      state.ReportingUsers = data;
    },
    setTaskCount(state,data){
      state.tasksCount = data;
    },
    setDuplicateProject(state,data){
      state.duplicateProject = data;
    },
    reset(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }
  };
  export const actions = {
    async UserProjects  ({ commit, dispatch }, params) {
      try {
        commit("setAllProjectsForUser", null);
        let response =await axios.post("/api/v1/User/GetUserProjects",params)
        commit("setAllProjectsForUser", response.data);
      } catch (err) {
        console.log(err)
        await dispatch("errors/errorResponse", err.response, { root: true });
        commit("setAllProjectsForUser", null);
      }
    },
    async GetUsersByProject  ({ commit, dispatch }, params) {
      try {
        commit("setUsersByProjects", null);
        let response =await axios.post("/api/v1/User/GetUsersByProject",params)
        commit("setUsersByProjects", response.data);
      } catch (err) {
        console.log(err)
        await dispatch("errors/errorResponse", err.response, { root: true });
        commit("setUsersByProjects", null);
      }
    },
    async SaveProjectDetails  ({ commit, dispatch }, params) {
      try {
        commit("setUsetProjectDetails", null);
        let response =await axios.post("/api/v1/User/SaveProject",params)
        commit("setUsetProjectDetails", response.data);
      } catch (err) {
        console.log(err)
        await dispatch("errors/errorResponse", err.response, { root: true });
        commit("setUsetProjectDetails", null);
      }
    },
    async DeleteUserFromProject  ({ commit, dispatch }, params) {
      try {
        commit("setDeleteUserFromProject", null);
        let response =await axios.post("/api/v1/User/DeleteUserFromProject",params)
        commit("setDeleteUserFromProject", response.data);
      } catch (err) {
        console.log(err)
        await dispatch("errors/errorResponse", err.response, { root: true });
        commit("setDeleteUserFromProject", null);
      }
    },

    async GetUsersFromProject  ({ commit, dispatch }, params) {
      try {
        commit("setUsersFromProject", null);
        let response =await axios.post("/api/v1/User/GetUsersFromProject",params)
        commit("setUsersFromProject", response.data);
      } catch (err) {
        console.log(err)
        await dispatch("errors/errorResponse", err.response, { root: true });
        commit("setUsersFromProject", null);
      }
    },
    async GetReportingUsers({commit, dispatch},params){
      try{
        commit("setReportingUsers", null);
        let response = await axios.get(`/api/v1/User/GetReportingUsers?userId=${params}`)
        commit("setReportingUsers", response.data);
      }
      catch(err){
        console.warn(err)
        commit("setReportingUsers", null);
        await dispatch("errors/errorResponse", err.response, { root: true });
      }
    },
    //Tasks count based on specific project
    async getTaskCount({commit, dispatch},params){
      try{
        commit("setTaskCount", null);
        let response = await taskApiService.post('Project/getTaskCountBasedonProject',params)
        commit("setTaskCount", response.data);
      }
      catch(err){
        console.warn(err)
        commit("setTaskCount", null);
        await dispatch("errors/errorResponse", err.response, { root: true });
      }
    },
    async getDuplicateProjectName({commit,dispatch},params){
      try{
        commit("setDuplicateProject",null);
        let response = await axios.get(`/api/v1/User/GetDublicateprojectscheck?projectname=${params}`);
        commit("setDuplicateProject",response.data);
      } catch(err){
        console.warn(err)
        commit("setDuplicateProject",null);
        await dispatch("errors/errorResponse", err.response, {root:true});
      }
    }
  };
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };