<script>
import Layout from "../../layouts/vertical.vue";
import PageHeader from "@/components/page-header";
import { mapGetters, mapMutations } from "vuex";
import moment from 'moment-timezone';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
    // name: 'Letter Requests',
    components: {
        Layout, PageHeader
    },

    data() {
        return {
            // loading: false,
            showShimmers: false,
            title: "Manager Appraisal",
            filterTableData: [],
            items: [
                {
                    text: "Dashboard",
                    routeName: "Dashboard",
                },
                {
                    text: "ManagerAppraisal",
                    active: true,
                },
            ],
            fields: [
                { key: "UserId", label: "Employee name", sortable: true },
                { key: "CreatedDt", label: "Appraisal due date", sortable: true },
                { key: "Status", label: "Status", sortable: true },
                { key: "Action", label: "Action" },
            ],
            employees: [],
            reportTypes: [],
            employeeReport: {
                employee: '',
                ReportType: '',
                FromDate: '',
                ToDate: ''

            },
            isUser: false,
            isValue: false,
            oneMonthBack: new Date(),
        }
    },
    computed: {
        ...mapGetters('auth', ['getProfileDetails']),
        ...mapGetters('userservice', ['getUserHierarchy']),
        ...mapGetters("attendance", ["getLeaveType", 'getAttendanceReport', 'getEmployee']),
        ...mapGetters("hrmanagement",["GetSaveAppraisalById"])
    },
    
    methods: {
        async data() {
            this.showShimmers = true;
            try {
                await this.$store.dispatch("hrmanagement/GetSaveAppraisalById", this.getProfileDetails.UserId || this.getProfileDetails.Id);
                const appraisalData = this.GetSaveAppraisalById?.appraisals;
                if (appraisalData) {
                    for (const appraisal of appraisalData) {
                        const newItem = {
                            UserId: this.getProfileDetails.FirstName,
                            CreatedDt: appraisal.CreatedDt ? new Date(appraisal.CreatedDt).toLocaleDateString('en-GB').replace(/\//g, '-') : '',
                            Status: appraisal.Status,
                            Action: "View",
                        };
                        this.filterTableData.push(newItem);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        },
        async oninit() {
            this.oneMonthBack.setMonth(new Date().getMonth() - 1);
            // this.loading = true

            const params = {
                UserId: this.getProfileDetails.UserGuid
            }
            await this.$store.dispatch('userservice/GetUserHierarchy', params)
            this.employees = this.getUserHierarchy?.map(user => ({
                name: user?.FirstName + ' ' + user?.LastName,
                id: user?.UserId, UserGuid: user?.UserGuid
            }));
            await this.$store.dispatch("attendance/fetchLeaveType");
            this.reportTypes = this.getLeaveType?.map(type => ({ type: type.TypeText, Id: type.Id }))
            this.reportTypes.push(
                { type: 'Attendance report', Id: 'AttendanceReport' }, { type: 'Remaining leave balance', Id: 'RemainingLeaveBalance' }
            )
            // this.loading = false;
            this.showShimmers = false;
        },
        ...mapMutations(['setAlertNotification']),
        async onSubmit(ReportType) {
            // this.loading = true
            this.showShimmers = true
            this.isUser = false;
            this.isValue = false;
            const input = {
                UserId: this.employeeReport.employee.id,
                FromDate: this.employeeReport.FromDate ? moment(this.employeeReport.FromDate).format('YYYY-MM-DD') : this.employeeReport.ToDate ? this.oneMonthBack : undefined,
                ToDate: this.employeeReport.ToDate ? moment(this.employeeReport.ToDate).format('YYYY-MM-DD') : this.employeeReport.FromDate ? new Date() : undefined,
                LeaveTypeId: typeof this.employeeReport?.ReportType?.Id === 'number' ? this.employeeReport.ReportType.Id : undefined
            }
            this.employeeReport?.ReportType?.Id == 'RemainingLeaveBalance' ?
                await this.$store.dispatch("attendance/getEmployee", this.employeeReport.employee.id) :
                await this.$store.dispatch("attendance/getattendanceReport", input);

            this.response = await this.getAttendanceReport;
            let response = this.employeeReport?.ReportType?.Id === 'RemainingLeaveBalance' ? [await this.getEmployee.employee] : await this.getAttendanceReport.getReport;
            // this.loading = false
            this.showShimmers = false
            response?.length > 0 ? this[ReportType](response) : ''
            const message = {
                variant: response?.length <= 0 ? 'warning' : this.response?.status == true ? 'success' : 'danger',
                alertTitle: 'Reports',
                alertDecription: response?.length > 0 ? this.response?.msg : 'No data found'
            };
            this.setAlertNotification(message)
        },
    
    },

    mounted() {
        this.oninit(),
            this.data()
    },
}
</script>


<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <!-- <div v-if="loading">
            <PreLoader />
        </div> -->
        <div v-if="showShimmers">
                <b-skeleton-table :rows="5" :columns="4"
                  :table-props="{ bordered: false, striped: false}"></b-skeleton-table>   
        </div>

        <div class="table-responsive card" v-else>
            <div class="card-body">
               <b-table hover v-if="this.filterTableData.length > 0" :items="filterTableData"  :fields="fields" style="border: 1px solid #ced4da; cursor: pointer;">
                <template #cell(UserId)="row">
                      <a href="/manager-appraisal-view" class="link font-weight-bold" @click="viewItem(row.item)"
                        :title="row.value">{{ row.value
                        }}</a>
              </template>
               </b-table>
             
            </div>
        </div>
    </Layout>
</template>
<style scoped lang="scss">
/* body {
    font-family: Arial, sans-serif;
}

.report {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.report-form {
    border: 1px solid #00435D;
    padding: 20px;
    border-radius: 5px;
} */

.form-group {
    margin-bottom: 10px;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

// input[type="text"],
// input[type="date"],
// select {
//     width: 100%;
//     padding: 8px;
//     border: 1px solid #00435D;
//     border-radius: 3px;
//     height: 100%;
// }

// button {
//     background-color: #00435D;
//     color: #fff;
//     border: none;
//     padding: 10px 20px;
//     border-radius: 5px;
//     cursor: pointer;
// }

// button:hover {
//     background-color: #00435D;
// }

.department,
.mx-input {
    height: 38px !important;
}

.card-footer {
    background-color: #e2eaed !important;
}
</style>