<script>
import { mapGetters, mapState,mapMutations } from 'vuex';
import PreLoader from "@/components/pre-loader";
export default {
  components: { PreLoader },
  data() {
    return {
      userGuid: '',
      verificationStatus: '', // Change this value based on your condition
      loading:false
    }
  },
  methods: {
    ...mapMutations(['setSharedData']),
    async ResendEmail(){
      this.loading=true
      const input={
        userGuid: this.getUserguid,
        email: this.getUserDeteils?.Email
      };
      await this.$store.dispatch('userservice/ResendEmail',input)
      if(this.getResendEmail){
        this.setSharedData('sent');
        this.verificationStatus = 'sent'
      }
      this.loading=false
    }
  },
  computed: {
    ...mapState(['sharedData','getUserguid']),
    ...mapGetters('userservice', ['getUserDeteils','getResendEmail']),
  },
  mounted(){
    this.verificationStatus=this.sharedData
  }
}

</script>

<template>
  <div>
    <div v-if="loading">
      <PreLoader></PreLoader>
    </div>
    <!-- <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div> -->
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div v-if="verificationStatus === 'success'">
                      <!-- Success Section -->
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/ytm-dark.svg" height="70" alt="logo" />
                          </a>
                        </div>
                        <div class="mt-5">
                          <img src="@/assets/images/verified_icon.png" alt="Verified" class="img-fluid" width="50" height="50">
                          <h2 class="mt-4 font-size-24 text-success">Verified sucessfully</h2>
                          <p class="mt-3 mb-0 font-size-18">Your email has been verified.</p>
                          <p class="mb-0 font-size-18">You can now log in to your new account.</p>
                          <div class="mt-5 text-center">
                            <a href="/login">
                              <button type="button" class="btn btn-primary">
                                <i class="ri-arrow-go-back-line align-middle"></i> Redirect to login page
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="verificationStatus === 'expired'">
                    <!-- Expired Section -->
                    <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/ytm-dark.svg" height="70" alt="logo" />
                          </a>
                        </div>
                        <div class="mt-5">
                          <img src="@/assets/images/emailExpired_icon.png" alt="Verified" class="img-fluid" width="50" height="50">
                          <h2 class="mt-4 font-size-24 text-warning">Email Expired</h2>
                          <p class="mt-3 mb-0 font-size-18">Sorry, but it seems like the link you were trying to access has expired. This means that the page
                            or resource that you were trying to reach is no longer available, and the link can no longer be used to access it.</p>
                          <div class="mt-5 text-center">
                            <button type="button" class="btn btn-primary" @click="ResendEmail">
                              <i class="ri-mail-line align-middle"></i> Resend
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="verificationStatus === 'sent'">
                    <!-- Email Sent Section -->
                    <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/ytm-dark.svg" height="70" alt="logo" />
                          </a>
                        </div>
                        <div class="mt-5">
                          <img src="@/assets/images/emailSent_icon.png" alt="Verified" class="img-fluid" width="50" height="50">
                          <h2 class="mt-4 font-size-24 text-warning">Email Sent</h2>
                          <p class="mt-3 mb-0 font-size-18">A password reset link has been sent to your registered email id. If you're not able to find it in the Inbox, please
              check in your spam folder.</p>
                          <div class="mt-5 text-center">
                            <a href="/login">
                              <button type="button" class="btn btn-primary">
                                <i class="ri-arrow-go-back-line align-middle"></i> Redirect to login page
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="verificationStatus === 'not-verified'">
                      <!-- Success Section -->
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/ytm-dark.svg" height="70" alt="logo" />
                          </a>
                        </div>
                        <div class="mt-5">
                          <img src="@/assets/images/emailNotVerified_icon.png" alt="Verified" class="img-fluid" width="50" height="50">
                          <h2 class="mt-4 font-size-24 text-warning">Not Verified</h2>
                          <p class="mt-3 mb-0 font-size-18">Your email has been not verified.</p>
                          <p class="mb-0 font-size-18">A verification mail has been sent to your registered email. Please verify your email to access your account.</p>
                          <div class="mt-5 text-center">
                            <a href="/login">
                              <button type="button" class="btn btn-primary">
                                <i class="ri-arrow-go-back-line align-middle"></i> Redirect to login page
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>