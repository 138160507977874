<script>
import Layout from "../../layouts/vertical.vue";
import PageHeader from "@/components/page-header";
// import PreLoader from "@/components/pre-loader";
import { mapGetters, mapState,mapMutations } from "vuex";
import _ from "lodash";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

export default {
    components: { Layout, PageHeader, Multiselect },
    data() {
        return {
            MatchinguserList: [],
            userType: "",
            AddUser: {
                selectedUsersByProject: []
            },
            title: "Project team",
            items: [
                {
                    text: "Projects",
                    routeName: "Projects",
                },
                {
                    text: "Project team",
                    active: true,
                },
            ],
            customers: {
                name: "",
                email: "",
                addedOn: "",
                userType: ""
            },
            currentPage: 1,
            selectedUserNames: [],

            showDismissible: false,
            perPage: 10,
            filter: null,
            value:'',
            saveMsg:"",
            Sort: "desc",
            Orderby: "",
            filterForUsers: null,
            SortForUsers: "desc",
            OrderbyForUsers: "",
            usersList: [],
            Users: [],
            // loading: false,
            showShimmers: false,
            SelectedId: null,
            isUser: false,
            Projects: [],
            selectedUsers: [],
            showDismissibleAlert: false,
            checked:false,
            ProjectPermisions: [],
            userData: {}
        };

    },
    validations: {
        AddUser: {
            selectedUsersByProject: { required }
        },
    },
    methods: {
        initProjectPermisions() {
          this.ProjectPermisions = [
            {
              key: "Add project user",
              permission: "AddProjectUser",
            },
            {
              key: "Delete project user",
              permission: "DeleteProjectUser",
            },
          ];
        },
        hasPermission(permissionToCheck) {
          return this.filteredProjectPermisions.some(
            (permissionObj) => permissionObj.permission === permissionToCheck
          );
        },
        async GetUsersByProject() {
            // this.loading = true;
            this.showShimmers = true;
            const params = {
                pagenumber: this.currentPage,
                rowcount: this.perPage,
                keyword: this.filter,
                sort: this.Sort,
                orderby: this.Orderby,
                ProjectId: this.$route.params.Id,
            };
            await this.$store.dispatch("projects/GetUsersByProject", params);
            if (this.getAllUsersByProject?.length > 0) {
                this.usersList = this.getAllUsersByProject
            } else {
                this.usersList = [];
            }
            await this.GetAllUsers()
        },
        async GetAllUsers() {
      const params = {
        pagenumber: -1,
        rowcount: -1,
        keyword: this.filter,
        sort: this.Sort,
        orderby: this.Orderby,
        userGuid: this.getProfileDetails.UserGuid,
      };
      await this.$store.dispatch("userservice/fetchUsers", params);
      if (this.getAllUsers?.length > 0) {
        const Alluserslist=this.getAllUsers.filter(user =>user.Id !=this.getUserId)
        this.MatchinguserList= Alluserslist.map(user =>user.Id);
      } else {
        this.MatchinguserList = [];
      }
    //   this.loading = false;
    this.showShimmers = false;
    },
        async GetUserDefaults() {
            await this.$store.dispatch('userservice/GetUserDefaults', this.getUserguid);
            setTimeout(() => {
            }, 1000);
            this.Projects = this.getUserDefaults.ProjectResponse;
              const Project = this.Projects.filter(item => item.ProjectId === parseInt(this.$route.params.Id))[0]
            this.title="Project team( "+`${Project.ProjectName}`+ " )"
                                },
        /**
         * Remove user/users in the project
         */
         ...mapMutations(['setAlertNotification']),
        async RemoveUser() {
            const Project = this.Projects.filter(item => item.ProjectId === parseInt(this.$route.params.Id))[0]
            const SaveObject = {
                ProjectGuid: Project.ProjectGuid,
                UserIds: this.selectedUsers.map(user => user.userId).join(','),
            }
            await this.$store.dispatch('projects/DeleteUserFromProject', SaveObject);
            await this.assignTasksToNewReportingManager()
            this.selectedUsers = []
            this.$refs.deleteModal?.hide()
            await this.GetUsersByProject()
            await this.loadInitData()
              this.value=this.getDeleteUserDetails?.Response
             const message = {
                                  variant: 'secondary',
                                  alertTitle: 'Project team updated!',
                                  alertDecription: this.value
                                  }
            this.setAlertNotification(message)
        },
        SelectUsers(user) {
            this.userData = user
            const index = this.selectedUsers.indexOf(user);
            if (index === -1) {
                this.selectedUsers.push(user);
            } else {
                this.selectedUsers.splice(index, 1);
            }
             this.selectedUserNames = this.selectedUsers.map(user => user.FullName);
            this.checked = this.selectedUsers?.length == this.usersList.filter(user => this.MatchinguserList.includes(user.userId))?.length ? true:false;
        },
        selectAllUsers() {
            if (this.selectedUsers?.length == this.usersList.filter(user => this.MatchinguserList.includes(user.userId))?.length) {
                this.selectedUsers = []
                this.checked=false
            }
            else {
                this.selectedUsers = []
                this.selectedUsers = this.usersList.filter(user => this.MatchinguserList.includes(user.userId));
                this.checked=true
            }
        },
        /**
 * Get all users api call
 */
        async loadInitData() {
           
            const params = {
                ProjectId: parseInt(this.$route.params.Id)
            };
            await this.$store.dispatch("projects/GetUsersFromProject", params);
            this.Users = this.getUsersFromProject;
            // this.loading = false;
            this.showShimmers = false;
        },
                  async SaveUser() {
            const Project = this.Projects.filter(item => item.ProjectId === parseInt(this.$route.params.Id))[0]
            const SaveObject = {
                ProjectGuid: Project.ProjectGuid,
                UserIds: this.AddUser.selectedUsersByProject.map(user => user.Id).join(','),
            }
            await this.$store.dispatch("userservice/SaveUserByProject", SaveObject)
            await this.resetDataClick()
            await this.GetUsersByProject()
            await this.loadInitData()
             this.saveMsg=this.getUserDetailsByProject?.Response
           const messag = {
                                  variant: 'success',
                                  alertTitle: 'Project team updated!',
                                  alertDecription: this.saveMsg
                                  }
            this.setAlertNotification(messag)
                    },
        resetDataClick() {
            this.$refs["myModal"]?.hide();
            this.isUser = false;
            this.AddUser.selectedUsersByProject = [];
        },
        isFormInvalid() {
            return !this.AddUser.selectedUsersByProject?.length;
        },
        ChangePage() {
            this.checked = false;
            this.selectedUsers = []
            this.loadInitData();
            this.GetUsersByProject()
        },
        /**
* search functionality for users
*/
        changesearch: _.debounce(function () {
            this.currentPage = 1;
            this.GetUsersByProject();
        }, 800),
        async assignTasksToNewReportingManager() {
          let params = {
          ProjectId: parseInt(this.$route.params.Id),
          DeletedId: this.userData?.userId,
          ReportingId:this.userData?.ReportingId,
          ReportingName:this.userData?.ReportingName,
          };
          await this.$store.dispatch("tasks/ReportingTasks", params);
        },
    },
    watch: {

    },
    created() {
        this.initProjectPermisions();
    },
    computed: {
        ...mapState(["getUserguid","getUserId" ,  "getPermissions"]),
        ...mapGetters("projects", ["getAllUsersByProject", 'getDeleteUserDetails', "getUsersFromProject"]),
        ...mapGetters("userservice", ["getAllUsers", "getUserDefaults",'getUserDetailsByProject']),
        ...mapGetters("auth", ["getProfileDetails"]),
         rows() {
      return this.usersList[0]?.TotalCount;
    },
      message() {
      if (this.selectedUserNames.length === 1) {
        const userName = `<b>${this.selectedUserNames[0]}</b>`;
        return `Are you sure you want to remove  ${userName} in this project?`;
      } else {
        return "Are you sure you want to remove these users?";
      }
    },
      filteredProjectPermisions() {
        let permissions = this.getPermissions?.ProjectRoles
        function hasPermission(permission) {
          return permissions?.includes(permission);
        }
        return this.ProjectPermisions.filter((ProjectPermisions) => {
          return (
            (ProjectPermisions.permission === "AddProjectUser" &&
              hasPermission("AddProjectUser")) ||
            (ProjectPermisions.permission === "DeleteProjectUser" &&
              hasPermission("DeleteProjectUser"))
          );
        });
      },
    },
    async mounted() {
        // this.loading=true
        this.showShimmers = true;
         setTimeout(() => {
            // this.loading=false
            this.showShimmers = false;
            }, 3000);
         await this.GetUserDefaults()
        await this.GetUsersByProject()
        await this.loadInitData()
       
    }
};

</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <!-- <div v-if="loading">
            <PreLoader></PreLoader>
        </div> -->
        <div v-if="showShimmers" class="mt-2 justify-content-end">
                            <div class="d-flex justify-content-end">
                                <b-skeleton class="mr-2" type="input" width="180px" height="30px"></b-skeleton>
                                <b-skeleton type="button" width="100px" height="30px"></b-skeleton>
                            </div>
                             <b-skeleton-table :rows="5" :columns="5"
                                :table-props="{ bordered: false, striped: false}"></b-skeleton-table>
                        </div>
                        <div v-else>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                   
                    <div class="card-body">
                     
                        <div class="d-flex justify-content-end ytmFilterRow">
                            <div class="d-flex">
                                <div class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center position-relative mb-0">
                                        <b-form-input size="sm" v-model="filter" type="search" placeholder="Search..."
                                            @input="changesearch" class="form-control ml-2 searchInput"></b-form-input>
                                        <i class="ri-search-line search-icon"></i>
                                    </label>
                                                                    </div>
                                <div class="ml-2 flex-shrink-0" v-if="hasPermission('AddProjectUser')">
                                    <b-button size="sm" variant="primary" @click="resetDataClick()" v-b-modal.modal-center>
                                        <i class="ri-add-line align-middle"></i> Add user
                                    </b-button>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive mt-3">
                            <table class="table table-centered datatable dt-responsive nowrap"
                                style="width: 100%;border: 1px solid #ced4da;">
                                <thead class="thead-light sticky">
                                    <tr>
                                        <th style="width: 20px;" v-if="usersList">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="customercheck"  
                                                    :checked="this.usersList.length > 0 && checked"
                                                    @click="selectAllUsers()" />
                                                <label class="custom-control-label" for="customercheck">&nbsp;</label>
                                            </div>
                                        </th>
                                        <th>User</th>
                                        <th>Email</th>
                                        <th>User type</th>
                                        <th style="width: 120px;" class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="user in usersList" :key="user.userId">
                                        <td>
                                            <div class="custom-control custom-checkbox" v-if="MatchinguserList.includes(user.userId) && hasPermission('DeleteProjectUser')">
                                                <input type="checkbox" class="custom-control-input"
                                                    :id="`customercheck${user.userId}`"
                                                    :checked="selectedUsers.includes(user)" @click="SelectUsers(user)" />
                                                <label class="custom-control-label" :for="`customercheck${user.userId}`"
                                                    aria-checked=""></label>
                                            </div>
                                        </td>
                                        <td class="mw-200 text-truncate">
                                            <div class="d-flex align-items-center">
                                                <img   :src="user.image"  alt=""
                                                            class="avatar-sm align-self-start rounded-circle mr-2"
                                                            style="width: 30px; height: 30px;" v-if="user.image">
                                                <img src="@/assets/images/users/user.png" alt=""
                                                            class="avatar-sm align-self-start rounded-circle mr-2"
                                                            style="width: 30px; height: 30px;"  v-else>
                                                <span :title="user.FullName" class="font-weight-bold text-dark">{{
                                                    user.FullName }}</span>
                                            </div>
                                        </td>
                                        <td class="text-truncate mw-200"><span :title="user.Email">{{ user.Email }}</span>
                                        </td>
                                        <td class="text-truncate mw-200"><span :title="user.userType">Member</span></td>
                                        <td class="text-center">
                                           <a v-if="hasPermission('DeleteProjectUser')" :class="MatchinguserList.includes(user.userId) ? 'text-danger' : 'text-black'" v-b-tooltip.hover title="Delete" v-b-modal.modal-backdrop
                                              @click="!selectedUsers.includes(user) ? SelectUsers(user) : ''" :disabled="!MatchinguserList.includes(user.userId)">
                                              <i v-if="MatchinguserList.includes(user.userId)" class="mdi mdi-trash-can font-size-18"></i>
                                              <span v-else :disabled="!MatchinguserList.includes(user.userId)">-</span>
                                            </a>
                                            <span v-else>-</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                           
                            <div class="nodata-ui">
                                <div class="fullscreen-height d-flex align-items-center justify-content-center"  v-if="!usersList?.length && !showShimmers" >
                                    <div class="py-5 text-muted">
                                        <div
                                            class="d-flex flex-column justify-content-center gap-1 align-items-center fs-4">
                                            <i class="ri-information-line font-size-36 text-muted"></i>
                                            <h5 class="text-muted">
                                                No data found!
                                            </h5>
                                            <p class="font-size-16 text-center">Get started by adding a new user.</p>
                                            <b-button v-if="hasPermission('AddProjectUser')" size="sm" variant="outline-primary" @click="resetDataClick()"
                                                v-b-modal.modal-center>
                                                <i class="ri-add-line align-middle"></i> Add User
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2" v-if="rows">
                          <div class="col">
                             <div class="paging_simple_numbers float-right">
                                <ul class="pagination pagination-rounded mb-0">
                                   <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                                   @input="ChangePage()"></b-pagination>
                                </ul>
                             </div>
                          </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <b-modal ref="myModal" id="modal-center" centered title="Add user" title-class="font-18" hide-footer
            no-close-on-backdrop>
            <div class="form-group">
                <label for="taskName">User
                    <span :class="{
                        'text-danger': !AddUser.selectedUsersByProject?.length
                    }">*</span></label>
                      <div :class="[!AddUser.selectedUsersByProject?.length && (isUser) ? 'multiSelectError' : 'multi-selector-container',]">
                        <multiselect v-model="AddUser.selectedUsersByProject" :options="Users"
                        v-bind:class="{ 'is-invalid': !AddUser.selectedUsersByProject?.length && (isUser) }" label="FullName"
                        track-by="Id" placeholder="Select users" :multiple="true" @close="isUser = true" :close-on-select="false">
                        <template slot="option" slot-scope="{ option }">
                                                        {{ option ? option.FullName + ' ( ' +
                                                            option.Email
                                                            + ' )' : 'Select User'
                                                        }}
                                                    </template>
                                                    <template slot="selected-option" slot-scope="{ option }">
                                                        {{ option.FullName }} {{ option.Email }}
                                                    </template>
                    </multiselect>
                <span class="text-danger" v-if="!AddUser.selectedUsersByProject?.length && (isUser)">User name is
                    required!</span>
                    </div>
            </div>
            <div class="text-right mt-4">
                <b-button class="ml-1 mr-2" type="button" variant="outline-primary mr-2" @click="resetDataClick()"><i
                        class="ri-close-line align-bottom"></i> Cancel</b-button>
                <button type="submit" class="btn btn-primary" :disabled="isFormInvalid()" @click="SaveUser()">
                    <i class="ri-save-line align-bottom"></i> Save
                </button>
            </div>
        </b-modal>

        <!-- Delete Use Modal -->
        <b-modal id="modal-backdrop" no-close-on-backdrop title="Delete user" ref="deleteModal"
            title-class="text-dark font-18" hide-footer>
            <h5 class="font-size-16" v-html="message"></h5>
            <div class="text-right mt-4">
                <b-button class="ml-1 mr-2" type="button" variant="outline-primary mr-2"
                    @click="$refs.deleteModal?.hide()"><i class="ri-close-line align-bottom"></i> Cancel</b-button>
                <button type="submit" class="btn btn-danger" @click="RemoveUser()">
                    <i class="ri-delete-bin-line align-bottom"></i> Delete
                </button>
            </div>
        </b-modal>
        <!-- Delete Use Modal End -->
        
    </Layout>
</template>

<style lang="scss" scoped>
.table td {
    padding: 0.45rem 0.5rem;
}
.edittask-alert{
  position: fixed;
  top:10px;
  right: 10px;
  z-index: 10000;
}
.custom-control {
    margin-left: 1rem;
}
// .fullscreen-height{
//     min-height: 240px;
// }
</style>