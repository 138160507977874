 const TaskFields = [
    { "key": "Selection", "label": " " },
    {
      "key": "TaskIdNumber",
      "label": "ID",
      "sortable": true,
      "class": ["text-truncate", "mw-150"],
    },
    {
      "key": "TaskName",
      "label": "Task name",
      "sortable": true,
      "class": ["text-truncate", "mw-150"],
    },
    {
      "key": "ProjectName",
      "label": "Project name",
      "sortable": true,
      "class": ["text-truncate", "mw-150"],
    },
    {
      "key": "AssignedTo",
      "label": "Assigned to",
      "sortable": true,
      "class": ["text-truncate", "mw-150"],
    },        
    
    {
        "key": "CreatedDt",
      "label": "Created date",
      "sortable": true,
      "class": ["text-truncate", "mw-150"],
    },
    {
        "key": "EndDate",
      "label": "End date ",
      "sortable": true,
      "class": ["text-truncate", "mw-150"],
    },
    {
        "key": "Comments",
      "label": "Comments" ,
      "sortable": true,
      "class": ["text-truncate", "mw-150"],
    },
    { "key": "Status", "label": "Status", "sortable": true },
    { "key": "Actions", "label": "Action" },
  ]

  export { TaskFields };