<script>
// import Layout from "../../layouts/vertical.vue";
// import PageHeader from "@/components/page-header";
// import PreLoader from "@/components/pre-loader";
import Multiselect from "vue-multiselect";
import { mapGetters, mapMutations } from 'vuex';
import { required } from "vuelidate/lib/validators";
// import PreLoader from "@/components/pre-loader";
import _ from "lodash";
import Swal from "sweetalert2";
import moment from 'moment-timezone';

export default {
  name: 'LeaveApproval',
  components: { Multiselect },
  computed: {
    ...mapGetters("userservice", ["getUserDetailsById", 'getUserHierarchy']),
    ...mapGetters("attendance", ["getLeaveType", 'getuserLeaveApplication', 'getLeaveApplication', "getleaveStatus", "getdeleteLeave", "getEmployee"]),
    ...mapGetters("errors", ["getErrors"]),
    ...mapGetters("auth", ["getProfileDetails", "getAuthenticatedUser"]),
    ...mapGetters("holiday", ['getAllHolidays']),
    // hasData() {
    //   return this.getalltasks && this.getalltasks.length > 0;
    // },
    isFormInvalid() {
      return (
        !this.leaveTypeValue ||
        !this.leaveDataBind.fromDate ||
        !this.leaveDataBind.toDate ||
        !this.leaveDataBind.duration ||
        !this.leaveDataBind.reason || this.View?.msg
      );
    },
    isDirty() {
      return (
        this.leaveTypeValue?.TypeText != undefined ||
        this.data.FromDate.split('-').reverse().join('-') != this.leaveDataBind.fromDate ||
        this.data.ToDate.split('-').reverse().join('-') != this.leaveDataBind.toDate ||
        this.data?.Reason != this.leaveDataBind.reason)
    }
  },
  data() {
    return {
      isleaveType: false,
      isfromDate: false,
      istoDate: false,
      isduration: false,
      isreason: false,
      CompensatoryErrorMsg: "",
      leaveDataBind: {
        Id: null,
        leaveType: "",
        fromDate: '',
        toDate: '',
        duration: "",
        reason: ''
      },
      isBusy: false,
      LeaveApproval: null,
      rows: 1,
      perPage: 10,
      currentPage: 1,
      pageOptions: [10, 25, 50, 100],
      title: "Leaves",
      isDisable: false,
      formChanged: true,
      debouncedFiltered: _.debounce(this.onFiltered,),
      // loading: true,
      showShimmers: true,
      leaveData: [],
      hasData: false,
      modalVisible: false,
      timer: null,
      currentDate: new Date().toISOString().split("T")[0],
      filter: null,
      filterOn: [],
      sortBy: "age",
      filterVariable: [],
      sortDesc: false,
      saveDismissibleAlert: false,
      ToDate: '',
      FromDate: '',
      errorDismissibleAlert: false,
      isError: false,
      userId: '',
      option: "",
      options: [],
      fields: [
        { key: "EmployeeName", label: "Employee", sortable: true, class: ["text-truncate", "mw-200"] },
        { key: "AppliedOn", label: "Applied on", sortable: true, class: ["text-truncate", "mw-200"] },
        { key: "TypeofLeave", label: "Type of leave", sortable: true },
        { key: "FromDate", label: "From date", sortable: true, class: ["text-truncate", "mw-200"] },
        { key: "ToDate", label: "To date", sortable: true, class: ["text-truncate", "mw-200"] },
        { key: "Duration", label: "Duration", sortable: true, class: ["text-truncate", "mw-200"] },
        { key: "Reason", label: "Reason", sortable: true, class: ["text-truncate", "mw-200"] },
        { key: "Status", label: "Status", sortable: true },
        { key: "Actions", label: "Action" },
      ],
      items: [
        {
          text: "Dashboard",
          href: "/dashboard",
        },
        {
          text: "Tasks",
          active: true,
        },
      ],
      leaveTypeValue: null,
      checked: false,
      editLeave: false,
      data: null,
      View: null,
      disabledDates: [],
      holidayLists: [],
      maternityLeave: false
    };
  },
  validations: {
    leaveDataBind: {
      leaveType: { required },
      fromDate: { required },
      toDate: { required },
      duration: { required },
      reason: { required }
    },
  },
  watch: {
    leaveTypeValue() {
      this.maternityLeave = false;
      this.View = null
    },
    'leaveDataBind.fromDate': function (newDate) {
      newDate ?
        this.FromDate = moment(newDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : "";
    },
    'leaveDataBind.toDate': function (newDate) {
      newDate ?
        this.ToDate = moment(newDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : "";
    },
  },
  methods: {
    /**
     * disable dates function
     * @param {*} ymd 
     * @param {*} date 
     */
    dateDisabled(ymd, date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const weekday = date.getDay();

      const formattedDay = day.toString().padStart(2, '0');
      const formattedMonth = month.toString().padStart(2, '0');

      let disabledates = this.disabledDates.reduce((accumulator, currentArray) => {
        return accumulator.concat(currentArray);
      }, [])
      return disabledates.includes(`${year}-${formattedMonth}-${formattedDay}`) || weekday === 0 || weekday === 6;
    },
    async UserHierarchy() {
      const params = {
        UserId: this.getUserDetailsById?.UserGuid
      }
      await this.$store.dispatch(
        "userservice/GetUserHierarchy", params
      );
      await this.getLeaveApplications();
    },
    ...mapMutations(['setAlertNotification']),
    deleteLeave(Leave) {
      // console.log("dgidsgih")
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          cancelButton: "btn btn-danger ml-2",
          confirmButton: "btn btn-success",
        },
        buttonsStyling: false,
      });
      let popUpText = `You want to delete the <span style="color: black; font-weight: bold;"> ${Leave?.TypeofLeave} <br class="d-none d-sm-block d-md-block d-lg-block" /> from ${Leave?.FromDate} to ${Leave?.ToDate} </span>?`;
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          html: popUpText,
          // icon: "warning",
          confirmButtonText: "Yes, delete",
          cancelButtonText: "No, cancel",
          showCancelButton: true,
          allowOutsideClick: false,
          imageUrl: "/images/emailexpired_icon.png",
          imageHeight: 50, 
          imageWidth: 50,
        })
        .then((result) => {
          if (result.value) {
            this.delete(Leave.Id)
          }
        });
    },
    //delete leave
    async delete(Id) {
      const params = {
        Id: Id
      }
      await this.$store.dispatch("attendance/deleteLeave", params);
      await this.getLeaveApplications();
      let Alert = await this.getdeleteLeave?.data
      setTimeout(() => {
        const message = {
          variant: Alert.status === true ? 'secondary' : 'danger',
          alertTitle: Alert.status === true ? 'Deleted' : 'Not deleted',
          alertDecription: Alert.msg
        }
        this.setAlertNotification(message)
      }, 1000);
    },
    isToDateCrossed(EndDate) {
      const currentDate = new Date();
      const endDateParts = EndDate.split('-');
      const endDate = new Date(
        endDateParts[2],
        endDateParts[1] - 1,
        endDateParts[0]
      );
      return endDate < currentDate && EndDate !== currentDate?.toLocaleDateString('en-GB')?.replace(/\//g, '-');
    },
    EditLeave(data) {
      this.data = data
      this.leaveDataBind.Id = data.Id
      this.leaveTypeValue = this.options.filter(option => option.TypeText === data.TypeofLeave),
        this.leaveDataBind.fromDate = data.FromDate.split('-').reverse().join('-'),
        this.leaveDataBind.toDate = data.ToDate.split('-').reverse().join('-'),
        this.leaveDataBind.duration = data.Duration,
        this.leaveDataBind.reason = data.Reason,
        this.$refs.myModal.show(),
        this.editLeave = true,
        this.calculateDuration()
    },
    async getLeaveApplications() {
      // this.loading=true
      this.showShimmers = true;
      this.userId = this.getProfileDetails.Id||this.getProfileDetails.UserId
      try {
        let users = this.getUserHierarchy ? this.getUserHierarchy?.map((x) => x.UserId) : null;
        users = this.checked == false ? users : [Number(this.getUserDetailsById.UserId || this.userId)]
        let input = {
          UsersIds: [...users]
        }
        await this.$store.dispatch("attendance/getLeaveApplications", input);
        this.leaveTypes = this.getuserLeaveApplication;
        this.hasData = true;
        this.leaveData = this.leaveTypes.getAttenance.map((leave) => ({
          UserId: leave?.UserId,
          Id: leave?.Id,
          EmployeeName: leave?.EmployeeName,
          FromDate: leave?.FromDate ? moment(leave.FromDate).format('DD-MM-YYYY') : '',
          ToDate: leave?.EndDate ? moment(leave.EndDate).format('DD-MM-YYYY') : '',
          Duration: leave?.Duration ?
            (Math.floor(leave?.Duration / 30) > 0 ? `${Math.floor(leave?.Duration / 30)} month${Math.floor(leave?.Duration / 30) > 1 ? 's' : ''} ` : '') +
            (leave?.Duration % 30 > 0 ? `${leave?.Duration % 30} day${leave?.Duration % 30 > 1 ? 's' : ''}` : '') : '',
          AppliedOn: leave?.CreatedDt ? moment(leave.CreatedDt).format('DD-MM-YYYY') : '',
          TypeofLeave: leave?.LeaveType,
          Reason: leave?.Reason,
          Status: !leave?.IsApproved && !leave?.IsCancelled ? this.isToDateCrossed(leave?.EndDate ? moment(leave.EndDate).format('DD-MM-YYYY') : '') ? "Expired" : "Pending" : leave?.IsApproved ? "Approved" : "Rejected",
          dateDifference: this.getDatesInRange(leave?.FromDate ? moment(leave.FromDate).format('YYYY-MM-DD') : '',
            leave?.EndDate ? moment(leave.EndDate).format('YYYY-MM-DD') : '')
        }));
        this.disabledDates.push(this.leaveData.filter(user => user.UserId == Number(this.userId)).map(data => data.dateDifference))


        await this.$store.dispatch('holiday/GetHolidays');
        this.holidayLists = await this.getAllHolidays.holidayList.map(holiday => ({
          HolidayDate: holiday?.HolidayDate ? moment(holiday?.HolidayDate).format('YYYY-MM-DD') : '',
        }))
        this.disabledDates.push(this.holidayLists.map(date => date.HolidayDate));
        this.rows = this.leaveData?.length;
        // this.loading = false;
        this.showShimmers = false;
      } catch (error) {
        this.errorDismissibleAlert = true;
      }
    },

    getDatesInRange(startDate, endDate) {
      const dateRange = [];
      const currentDate = new Date(startDate); // Make sure startDate is in "yyyy-MM-dd" format
      const endDateTime = new Date(endDate); // Make sure endDate is in "yyyy-MM-dd" format
      while (currentDate <= endDateTime) {
        dateRange.push(currentDate.toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return dateRange;
    },

    resetDataClick() {
      this.leaveTypeValue = "";
      this.leaveDataBind.fromDate = "";
      this.leaveDataBind.toDate = "";
      this.leaveDataBind.duration = "";
      this.leaveDataBind.reason = "";
      this.leaveDataBind.Id = null
      this.editLeave = false,
        this.View = null,
        this.isleaveType = false;
      this.isfromDate = false;
      this.istoDate = false;
      this.isduration = false;
      this.isreason = false;
      this.formChanged = true;
      this.maternityLeave = false;
    },
    ResetData() {
      this.$refs['myModal'].hide();
      this.leaveTypeValue = "";
      this.leaveDataBind.fromDate = "";
      this.leaveDataBind.toDate = "";
      this.leaveDataBind.duration = "";
      this.leaveDataBind.reason = "";
      this.leaveDataBind.Id = null;
      this.editLeave = false,
      this.FromDate='';
      this.ToDate='';
        this.View = null,
        this.isleaveType = false;
      this.isfromDate = false;
      this.istoDate = false;
      this.isduration = false;
      this.isreason = false;
      this.formChanged = true;
      this.maternityLeave = false;
    },
    ...mapMutations(['setAlertNotification']),
    //Check for maternity leave application
    async checkMaternityLeave() {
      if (this.leaveTypeValue?.TypeText != "Maternity leave") {
        this.onSubmit()
      }
      else {
        await this.$store.dispatch('userservice/GetUserDetailsByGuid', this.getProfileDetails.UserGuid)
        if (this.getUserDetailsById?.Gender == "Female" && this.getUserDetailsById?.Maritalstatus == "Married") {
          this.onSubmit()
        }
        else {
          this.maternityLeave = true
        }
      }
    },
    async onSubmit() {
      const params = {
        Id: this.leaveDataBind.Id,
        UserId: this.getProfileDetails.UserId|| this.getProfileDetails.Id,
        EmployeeName: this.getProfileDetails.FirstName + " " + this.getProfileDetails.LastName,
        Leave: true,
        LeaveType: this.leaveTypeValue?.TypeText,
        LeaveTypeId: this.leaveTypeValue?.Id,
        leaveTypetext:this.leaveTypeValue?.Type,
        FromDate: this.leaveDataBind.fromDate,
        EndDate: this.leaveDataBind.toDate,
        Duration: Number(this.leaveDataBind.duration.split(' ')[0]),
        Reason: this.leaveDataBind.reason
      };
      //console.log(params)
      await this.$store.dispatch("attendance/saveLeaveApplication", params)
      if (this.getLeaveApplication?.status == true) {
        this.$refs.myModal.hide()
        this.fetchLeaveTypes();
        this.getLeaveApplications()
      }
      this.View = await this.getLeaveApplication
      setTimeout(() => {
        const message = {
          variant: this.View.status === false ? 'danger' : 'success',
          alertTitle: this.View.status === false ? 'Error' : 'Saved',
          alertDecription: this.View.status === false ? null : this.View?.msg
        }
        this.setAlertNotification(message)
      }, 1000);
    },
    async getEmployeeDetails() {
      await this.$store.dispatch("attendance/getEmployee", Number(this.getProfileDetails?.UserId))
      const userData = this.getEmployee?.employee;
      let duration=Number(this.leaveDataBind.duration.split(' ')[0])
      if (this.leaveTypeValue?.TypeText == "Compensatory leave" || this.leaveTypeValue?.Id == 7) {
        if (userData?.CompensatoryLeave != 0 && duration <= userData?.CompensatoryLeave) {
          await this.checkMaternityLeave();
        } else {
          this.CompensatoryErrorMsg = userData?.CompensatoryLeave == 0 ? "You don't have Compensatory leave" : `you have a ${userData?.CompensatoryLeave} Compensatory leave's only`
        }
      }
      else {
        await this.checkMaternityLeave();
      }

    },
    calculateDuration() {
      this.View = null;
      const selectedDays = [];
      const fromDate = new Date(this.leaveDataBind.fromDate);
      const toDate = new Date(this.leaveDataBind.toDate);
      // const durationInMilliseconds = toDate - fromDate;
      // let durationInDays = durationInMilliseconds / (1000 * 60 * 60 * 24);
      // this.duration = durationInDays + 1;
      // //console.log(this.duration)

      // const months = Math.floor(durationInDays / 30);
      // const remainingDays = durationInDays % 30 + 1;
      // const monthString = months > 0 ? `${months} month${months > 1 ? 's' : ''} ` : '';
      // const dayString = remainingDays > 0 ? `${remainingDays} day${remainingDays > 1 ? 's' : ''}` : '';
      // this.leaveDataBind.duration = monthString + dayString;

      const holiday = this.holidayLists.map((H) => H.HolidayDate);
      while (fromDate <= toDate) {
        selectedDays.push(fromDate.toISOString().split('T')[0]);
        fromDate.setDate(fromDate.getDate() + 1);
      }
      const filteredSatSun = selectedDays.filter((leaves) => {
        const date = new Date(leaves);
        const dayOfWeek = date.getDay();
        const dateString = leaves;
        return dayOfWeek !== 6 && dayOfWeek !== 0 && !holiday.includes(dateString);
      });
      this.leaveDataBind.duration = filteredSatSun?.length > 0 ? filteredSatSun?.length > 1 ? filteredSatSun?.length + ' ' + 'days' : filteredSatSun?.length + ' ' + 'day' : '';
            return selectedDays;
//console.log(this.leaveDataBind.duration)
    },
    ChangePage() {
      // this.loading = true;
      this.showShimmers = true;
      setTimeout(() => {
        // this.loading = false;
        this.showShimmers = false;
      }, 1000);
    },
    onFiltered() {
      //  this.loading = true;
      this.showShimmers = true;
      setTimeout(() => {
        // this.loading = false;
        this.showShimmers = false;
      }, 1000);
      if (this.leaveData.length == 0) {
        this.rows = this.leaveData.length;
      } else if (this.$refs.myTable.filteredItems.length != 0) {
        this.rows = this.$refs.myTable.filteredItems.length;
      } else {
        this.rows = this.$refs.myTable.filteredItems.length;
      }

    },
    async fetchLeaveTypes() {
      await this.$store.dispatch("attendance/fetchLeaveType");
      this.options = this.getLeaveType;
      // console.log('wweewe',data)
    },
    //Changing leave status 
    async leaveStatus(row, Type) {
      // console.log(row)
      const days = row.Duration.split(" ")[0];
      if (row.TypeofLeave == "Compensatory leave") {
        const params = {
          UserId: row.UserId,
          leaveCount: days
        }
        await this.$store.dispatch("attendance/UpdateCompansetoryLeave", params)
        this.CompensatoryErrorMsg = "";
      }
      const params = {
        Id: row.Id,
        Duration: row.Duration,
        isValid: true,
        Type: Type
      }
      await this.$store.dispatch("attendance/saveleaveStatus", params)
      this.response = await this.getleaveStatus;
      const message = {
        variant: this.response?.status == true ? 'success' : 'danger',
        alertTitle: this.response?.status == true ? 'Updated' : 'Error',
        alertDecription: this.response?.msg
      };
      this.setAlertNotification(message)
      this.getLeaveApplications()
    },
    handleFilterInput() {
      if (this.filter !== null) {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.timer = null;
          this.filterVariable = this.filter;
        }, 800);
      }
    }
  },
  async mounted() {
    this.UserHierarchy()
    await this.fetchLeaveTypes();
  }

}
</script>

<template>
  <div class="home">
    <!-- <div v-if="loading">
      <PreLoader></PreLoader>
    </div>  -->
    <div v-if="showShimmers">
      <div class="d-flex justify-content-between mt-2">
        <div>
          <b-skeleton type="input" width="100px" height="30px"></b-skeleton>
        </div>
        <div class="d-flex justify-content-end">
          <b-skeleton class="mr-2" type="input" width="100px" height="30px"></b-skeleton>
          <b-skeleton class="mr-2" type="input" width="100px" height="30px"></b-skeleton>
          <b-skeleton type="button" width="100px" height="30px"></b-skeleton>
        </div>
      </div>
      <div class="mt-2">
        <b-skeleton-table :rows="5" :columns="6"></b-skeleton-table>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-12">
        <div class="d-flex justify-content-between ytmFilterRow my-2 flex-column flex-sm-row">
          <div class="">
            <div id="tickets-table_length" class="dataTables_length text-center text-sm-left">
              <label class="d-inline-flex align-items-center mb-2 mb-sm-0">
                Show
                <select size="sm" v-model="perPage" @change="ChangePage"
                  class="form-control align-self-start selction-padding" style="width: 70px;">
                  <option v-for="option of pageOptions" :key="option">{{ option }}</option>
                </select>
              </label>
            </div>
          </div>

          <!-- Search -->
          <div class="dataTables_filter text-md-right">
            <div class="d-flex justify-content-end align-items-center flex-column flex-sm-row">
              <div class="text-md-right">
                <b-form-checkbox class="mb-2 mb-sm-0 mr-2" v-model="checked" switch @change="getLeaveApplications()">
                  <label class="mb-0">My leaves</label>
                </b-form-checkbox>
              </div>
              <div id="tickets-table_filter" class="dataTables_filter text-md-right mb-2 mb-sm-0">
                <label class="d-inline-flex align-items-center position-relative w-100 mb-0">
                  <b-form-input size="sm" v-model="filter" type="search" placeholder="Search..."
                    @input="handleFilterInput" class="form-control searchInput"></b-form-input>
                  <i class="ri-search-line search-icon"></i>
                </label>
              </div>
              <div class="ml-2 flex-shrink-0 mb-2 mb-sm-0">
                <b-button size="sm" variant="primary" @click="ResetData(), CompensatoryErrorMsg = ''" v-b-modal.modal-center>
                  <i class="ri-add-line align-bottom"></i> Apply leave
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <!-- End search -->
        <div>
          <!-- Table -->
          <div class="table-responsive">
            <b-table ref="myTable" v-if="this.leaveData.length > 0" :items="leaveData" :fields="fields" responsive="sm"
              style="border: 1px solid #ced4da;border-top: 0;" :per-page="perPage" :current-page="currentPage"
              :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filterVariable"
              :filter-included-fields="filterOn" @filtered="debouncedFiltered" class="mb-0" sticky-header>
              <!-- Slot for the actions column -->
              <template #cell(EmployeeName)="row">
                <a v-if="row.item.Status == 'Pending' && row.item.UserId == getProfileDetails.UserId"
                  href="javascript:void(0);" class="link font-weight-bold" @click="EditLeave(row.item)"
                  :title="row.value">{{ row.value
                  }}</a>
                <span v-else>{{ row.value }}</span>
              </template>
              <template #cell(status)="row">
                <div class="d-flex align-items-center" :title="row.value" >
                            <span
                              :class="{
                                'bg-primary': row.value === 'Active',
                                'bg-success': row.value === 'Approved',
                                'bg-warning': row.value === 'Pending',
                                'bg-secondary': row.value === 'Expired',
                                'bg-danger': row.value === 'Rejected',
                              }"
                              class="ytmStatusIndicator"
                            ></span>
                            {{ row.value }}
                          </div>
              </template>
              <template #cell(actions)="row">
                <span v-if="row.item.Status != 'Pending' || isToDateCrossed(row.item.ToDate)" style="margin-left: 20px;">
                  - </span>
                <b-dropdown text="Actions" variant="outline-primary" size="sm" v-else>
                  <b-dropdown-item v-if="row.item.UserId != userId" @click="leaveStatus(row.item, 'IsApproved')"
                  variant="success" size="sm"><i class="ri-check-line align-bottom"></i>
                    Approve</b-dropdown-item>
                  <b-dropdown-item v-if="row.item.UserId != userId" @click="leaveStatus(row.item, 'IsCancelled')"
                    variant="danger" size="sm"><i class="ri-close-line align-bottom"></i>
                    Reject</b-dropdown-item>

                  <b-dropdown-item v-if="row.item.UserId == userId" @click="EditLeave(row.item)" variant="secondary"
                    size="sm"><i class="ri-pencil-line align-bottom"></i>
                    Edit leave</b-dropdown-item>
                    <b-dropdown-item v-if="row.item.UserId == userId" @click="deleteLeave(row.item)" variant="danger"
                    size="sm"><i class="ri-delete-bin-line align-bottom"></i>
                    Cancel leave</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </div>
          <!-- No data found message-->
          <div class="nodata-ui" v-if="this.leaveData?.length == 0 || !rows">
            <div class="fullscreen-height d-flex align-items-center justify-content-center">
              <div class="py-5 text-muted">
                <div class="d-flex flex-column justify-content-center gap-1 align-items-center fs-4">
                  <i class="ri-information-line font-size-36"></i>
                  <h5>No data found!</h5>
                </div>
              </div>
              <!-- No data found message end-->
            </div>
          </div>
          <!-- pagination -->
          <div class="row pagination" v-if="!showShimmers || !rows">
            <div class="col">
              <div class="paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination> -->
                  <b-pagination v-model="currentPage" v-if="rows != 0" pills aria-controls="my-table" show-total
                    :total-rows="rows" :per-page="perPage" @input="ChangePage()"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal ref="myModal" id="modal-center" centered :title="editLeave ? 'Edit leave' : 'Leave application'"
      title-class="font-18" @click="ResetData" hide-footer no-close-on-backdrop>
      <form @submit.prevent="getEmployeeDetails">
        <div class="form-group">
          <label for="leaveType"> Leave type<span :class="{ 'text-danger': !this.leaveTypeValue }">
              *</span></label>
          <div :class="[!this.leaveTypeValue && isleaveType ? 'multiSelectError' : 'multi-selector-container',]">
            <multiselect v-model="leaveTypeValue" @close="isleaveType = true" :options="options" label="TypeText">
            </multiselect>
          </div>
          <p class="text-danger" v-if="!this.leaveTypeValue && isleaveType">Leave type is required!</p>
          <p class="text-danger" v-if="this.CompensatoryErrorMsg && leaveTypeValue.Id == 7">{{ CompensatoryErrorMsg }}
          </p>
          <p class="text-danger" v-if="this.maternityLeave">You cannot apply maternity leave!</p>
        </div>
        <div class="form-group">
          <label for="fromDate">From date <span :class="{ 'text-danger ': !this.leaveDataBind.fromDate }">
              *</span></label>
          <b-input-group @click="isfromDate = true">
            <b-form-input id="datepicker2" v-model="FromDate" type="text" placeholder="Select from date"
              autocomplete="off" readonly
              v-bind:class="{ 'form-control': true, 'border border-danger': !leaveDataBind.fromDate && isfromDate }"></b-form-input>
            <b-input-group-append @click="isfromDate = true">
              <b-form-datepicker v-model="leaveDataBind.fromDate" button-only right locale="en-US"
                :date-disabled-fn="dateDisabled" aria-controls="datepicker2" selected-variant="success"
                today-variant="info" nav-button-variant="primary" :hide-header=true v-bind:min="currentDate"
                @input="calculateDuration, leaveDataBind.toDate = ''"></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
          <span class="text-danger" v-if="!this.leaveDataBind.fromDate && isfromDate">From date is required!</span>
        </div>
        <div class="form-group">
          <label for="toDate">To date <span :class="{ 'text-danger ': !this.leaveDataBind.toDate }"> *</span></label>
          <b-input-group @click="istoDate = true">
            <b-form-input id="datepicker1" v-model="ToDate" type="text" placeholder="Select to date" autocomplete="off"
              readonly
              v-bind:class="{ 'form-control': true, 'border border-danger': !leaveDataBind.toDate && istoDate }"></b-form-input>
            <b-input-group-append @click="istoDate = true">
              <b-form-datepicker v-model="leaveDataBind.toDate" button-only right locale="en-US"
                :date-disabled-fn="dateDisabled" aria-controls="datepicker1" selected-variant="success"
                today-variant="info" nav-button-variant="primary" :hide-header=true v-bind:min="leaveDataBind.fromDate"
                @input="calculateDuration"></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
          <span class="text-danger" v-if="!this.leaveDataBind.toDate && istoDate">To date is required!</span>
        </div>
        <div class="form-group">
          <label for="duration">Duration<span :class="{ 'text-danger': !this.leaveDataBind.duration }">
              *</span></label>
          <input type="text" @blur="isduration = true" class="form-control" placeholder="Duration in days"
            :class="[!this.leaveDataBind.duration && isduration ? 'is-invalid' : 'form-control']"
            v-model="leaveDataBind.duration" disabled />
          <span class="text-danger" v-if="!this.leaveDataBind.duration && isduration">Duration is required!</span>
        </div>
        <div class="form-group">
          <label for="reason">Reason<span :class="{ 'text-danger': !leaveDataBind.reason }"> *</span></label>
          <textarea @blur="isreason = true" rows="3" v-model="leaveDataBind.reason"
            :class="[!this.leaveDataBind.reason && isreason ? 'is-invalid' : 'form-control']" placeholder="Enter reason"
            class="form-control"></textarea>
          <span class="text-danger" v-if="!this.leaveDataBind.reason && isreason">Reason is required!</span>
        </div>
        <div v-if="View?.msg && View?.status == false" class="text-danger">
          <p>{{ View?.msg }}</p>
        </div>
        <div class="text-right mt-4">
          <b-button class="ml-1" :disabled="!formChanged" type="button" variant="outline-primary" @click="ResetData"><i
              class="ri-close-line align-bottom"></i> Cancel</b-button>
          &nbsp;
          <button type="submit" :disabled="isFormInvalid || !isDirty" class="btn btn-primary">
            <i class="ri-save-line align-bottom"></i> {{ editLeave ? "Update" : "Save" }}
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
.fullscreen-height {
  height: 340px;
}

.color-new {
  background-color: lightgray;
  color: gray;
}

.color-active {
  color: #007ACC;
  background-color: rgba(0, 122, 204, 0.28);
}

.badge-soft-danger {
  color: #ff3d60;
  background-color: rgba(255, 61, 96, 0.28);
}

.edittask-alert {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 10000;
}
.ytmStatusIndicator{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 3px;
}
</style>
