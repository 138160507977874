<script>
import { required, email } from "vuelidate/lib/validators";
import { mapGetters, mapActions, mapMutations } from "vuex";
import PreLoader from "@/components/pre-loader";
import AlertMessages from '@/components/alerts/alert'
export default {
  components: { PreLoader, AlertMessages },
  data() {
    return {
      notification: null,
      submitted: false,
      emailBlured: false,
      PasswordBlured: false,
      loginForm: {
        Email: "",
        password: "",
      },
      loading: false,
      showPassword: false,
      ShowAlert: false,
      response: ''
    };
  },
  computed: {
    ...mapGetters('auth', ['getAuthenticatedUser', 'getProfileDetails']),
    // ...mapGetters("userservice", ['getUserDetailsById']),
    ...mapGetters("errors", ["getErrors"]),
    ...mapGetters("attendance", ["getEmployee"]),
    /**
     * Email validation
     */
    isValidEmail() {
      // Regular expression for email validation
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(this.loginForm.Email);
    },
  },
  validations: {
    loginForm: {
      Email: { required, email },
      password: { required }
    }
  },
  /**
 * Checking initially if user already logged in then redirect to dashboard
 */
  ...mapMutations(['setUserId', 'setUserGuid']),
  mounted() {
    if (this.getAuthenticatedUser?.EmailConfirmed == "True") {
      this.setUserId(this.getProfileDetails?.UserId);
      this.setUserGuid(this.getProfileDetails?.UserGuid);
      this.$router.push({ name: 'Dashboard' });
    }
  },

  methods: {
    // async status() {
    //   const params = {
    //     UserId: this.getProfileDetails.UserId || this.getProfileDetails.Id
    //   }
    //   await this.$store.dispatch('attendance/getAttendanceState', params);
    // },
    /**
     * Check the email and password is valid
     */
    ...mapMutations(['setLocation']),
    tryToLogIn() {
      if (this.validateForm()) {
        // console.log('Form is valid, proceed with login logic')
        this.loading = true;
        this.setLocation('');
        this.signIn();

      } else {
        this.emailBlured = true;
        this.PasswordBlured = true;
        // console.log('Form is not valid, handle errors or display messages')
      }
    },
    /**
     * validating form
     */
    validateForm() {
      const form = this.loginForm;
      let isValid = true;
      if (form.Email == '' || form.password == '' || !this.isValidEmail) {
        isValid = false;
      }
      return isValid;
    },
    /**
     * Remove space
     */
    handleEmailInput(event) {
      const cleanedValue = event.target.value.replace(/\s/g, '');
      event.target.value = cleanedValue;
      this.loginForm.Email = cleanedValue;

      this.ShowAlert = false;
    },
    handlePasswordInput(event) {
      const cleanedValue = event.target.value.replace(/\s/g, '');
      event.target.value = cleanedValue;
      this.loginForm.password = cleanedValue;
      this.ShowAlert = false;
    },
    /**
     * Logging in the user
     */
    ...mapMutations(['setUserId', 'setUserGuid', 'setSharedData', 'setusername']),
    async signIn() {
      try {
        const form = this.loginForm;
        const params = {
          auth_type: "password",
          client_id: "ro.client",
          client_secret: "secret",
          username: form.Email,
          grant_type: "password",
          password: form.password,
          scope: "api1 offline_access",
          user_type_id: 1,
          social_token: undefined,
          verification_code: undefined
        };
        await this.$store.dispatch('auth/login', params);
        if (this.getAuthenticatedUser?.EmailConfirmed == "True") {
          // await this.status()
          this.updateLeaves();
          // await this.$store.dispatch('userservice/GetUserDetailsByGuid', this.getProfileDetails.UserGuid);
          this.setusername(this.getProfileDetails?.FirstName + ' ' + this.getProfileDetails?.LastName)
          this.setUserId(this.getProfileDetails?.UserId);
          this.setUserGuid(this.getProfileDetails?.UserGuid);
          this.$router.push({ name: 'Dashboard' });
          this.loading = false;
        }
        else if (this.getAuthenticatedUser?.EmailConfirmed == "False") {
          this.setSharedData('not-verified');
          this.$router.push({ name: 'NotVerified' });
        }
        else {
          this.loading = false;
          this.ShowAlert = true;
          this.response = this.getErrors.critical_error || 'Something went wrong'
        }
        // this.$store.dispatch('user',decodedPayload);
        if (this.getAuthenticationStatus) {
          console.log("accessed")
        } else if (!this.getError) {
          return true;
        }
      } catch (err) {
        this.loading = false;
      }
    },
    async updateLeaves() {
      await this.$store.dispatch("attendance/getEmployee", this.getProfileDetails?.UserId);
      const timestamp = new Date(this.getEmployee.employee.ModifiedDt);
      const timeDifferenceMilliseconds = new Date() - timestamp;
      const millisecondsInADay = 24 * 60 * 60 * 1000;

      if (timeDifferenceMilliseconds > 30 * millisecondsInADay) {
        const params = {
          "UserId": Number(this.getProfileDetails?.UserId || this.getProfileDetails?.Id),
          "SickLeave": 1.5,
          "PersonalLeave": 2,
          "WorkFromHome": 0.5
        }
        await this.$store.dispatch('auth/updateMonthlyLeave', params);
      }

    },
    /**
     * reset the userdetails when email is not confirmed
     */
    ...mapActions('auth', ['logout']),
    async Logout() {
      await this.$store.dispatch("globalReset");
      await this.logout();
    },
    /**
     * Blured the fields
     */
    blurFields(field) {
      this[field + 'Blured'] = true;
    }
  },
};
</script>

<template>
  <div>
    <div v-if="loading">
      <PreLoader></PreLoader>
    </div>
    <AlertMessages />
    <!-- <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div> -->
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/ytm-dark.svg" height="70" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-5">Log in to your account</h4>

                      </div>
                      <div class="p-2 mt-2">
                        <b-alert show variant="danger" v-if="ShowAlert">
                          <i class="ri-close-circle-line mr-2 align-bottom"></i>{{ this.response }}
                        </b-alert>
                        <!-- ref="loginForm" -->
                        <form class="form-horizontal" @submit.prevent="tryToLogIn()" ref="loginForm">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="email">Email <span :class="{ 'text-danger': !loginForm.Email }">*</span></label>
                            <input type="email" class="form-control" v-model="loginForm.Email"
                              @input="handleEmailInput($event)" v-on:blur="blurFields('email')" id="email"
                              placeholder="Enter email"
                              v-bind:class="{ 'form-control': true, 'is-invalid': (!loginForm.Email || !isValidEmail) && emailBlured }" />
                            <span class="invalid-feedback  position-absolute" v-if="!loginForm.Email && emailBlured">Email
                              is
                              required!</span>
                            <span class="invalid-feedback  position-absolute"
                              v-if="loginForm.Email && !isValidEmail">Please enter valid
                              email!</span>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4 mt-35">
                            <div class="password-visibility-block position-relative">
                              <i class="ri-lock-2-line auti-custom-input-icon"></i>
                              <label for="userpassword">Password <span
                                  :class="{ 'text-danger': !loginForm.password }">*</span></label>
                              <input :type="showPassword ? 'text' : 'password'" v-model="loginForm.password"
                                v-on:blur="blurFields('Password')"
                                v-bind:class="{ 'form-control': true, 'is-invalid': (!loginForm.password) && PasswordBlured }"
                                class="form-control" id="userpassword" placeholder="Enter password"
                                @input="handlePasswordInput($event)" />
                              <div v-if="PasswordBlured == true && !loginForm.password"
                                class="invalid-feedback  position-absolute">Password
                                is
                                required!</div>
                              <button class="btn password-visibility-icon" type="button"
                                @click="showPassword = !showPassword">
                                <template v-if="showPassword">
                                  <i class="ri-eye-line font-size-20" variant="primary"></i>
                                </template>
                                <template v-else>
                                  <i class="ri-eye-off-line font-size-20" variant="primary"></i>
                                </template>
                              </button>
                            </div>
                          </div>

                          <!-- <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customControlInline" />
                            <label class="custom-control-label" for="customControlInline">Remember me</label>
                          </div> -->
                          <div class="mt-3 text-center"><button class="btn btn-primary w-md" type="submit">Log In</button>
                          </div>
                          <div class="mt-4 text-center">
                            <a href="/forgot-password" class="font-weight-bold">
                              <i class="ri-lock-2-line mr-1 align-middle"></i> Forgot password?
                            </a>
                          </div>

                        </form>
                      </div>

                      <div class="mt-4 text-center">
                        <!-- <p>Don't have an account ? <a href="/register">Register</a></p> -->
                        <p>
                          © 2023 Yoda Task Management
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
input::-ms-reveal {
  display: none;
}

.password-visibility-block {
  .password-visibility-icon {
    position: absolute;
    top: 14px;
    right: 10px;
    width: 25px;
    padding: 0;
    box-shadow: none;
  }
}

.mt-35 {
  margin-top: 35px
}
</style>