import { taskApiService } from "../../config/tasksaxios"

const initialState=()=>{
    return{
        AllAppraisal:null
    };
};
const state=initialState();

export const getters={
    GetManagerQuestionById:(state)=>{
    return state.AllAppraisal
},


};


export const mutations={
    SetManagerQuestionById:(state,data)=>{
        return state.AllAppraisal=data
    },
  
    
};


export const actions={
async GetManagerQuestionById({commit,dispatch}){
    try{
        commit("SetManagerQuestionById",null);
        let response = await taskApiService.get(`/appraisal/GetManagerQuestionById`);
        commit("SetManagerQuestionById", response.data)
    }
    catch(err){
        console.warn(err)
        commit("SetManagerQuestionById", null);
        await dispatch("errors/errorResponse", err.response, { root: true });
    }
},



};
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
