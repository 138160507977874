<script>
import Layout from "../../layouts/vertical.vue";
import PageHeader from "@/components/page-header";
// import PreLoader from "@/components/pre-loader";
// import DatePicker from "vue2-datepicker";
// import Multiselect from "vue-multiselect";
import { mapGetters, mapMutations } from "vuex";
import moment from 'moment-timezone';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
    name: 'LetterRequests',
    components: {
        Layout, PageHeader
        // DatePicker
    },

    data() {
        return {
            // loading: false,
            questions: [], 
            showShimmers: false,
            title: "Self Appraisal",
            items: [
                {
                    text: "Dashboard",
                    routeName: "Dashboard",
                },
                {
                    text: "Self Appraisal",
                    active: true,
                },
            ],
            employees: [],
            reportTypes: [],
            employeeReport: {
                employee: '',
                ReportType: '',
                FromDate: '',
                ToDate: ''

            },
            isUser: false,
            isValue: false,
            oneMonthBack: new Date()
        }
    },
    computed: {
        ...mapGetters('auth', ['getProfileDetails']),
        ...mapGetters('userservice', ['getUserHierarchy']),
        ...mapGetters('hrmanagement', ['GetQuestionById']),
        ...mapGetters("attendance", ["getLeaveType", 'getAttendanceReport', 'getEmployee']),
    },
    methods: {
        async oninit() {
            this.oneMonthBack.setMonth(new Date().getMonth() - 1);
            // this.loading = true
            this.showShimmers = true
            const params = {
                UserId: this.getProfileDetails.UserGuid
            }
            await this.$store.dispatch('userservice/GetUserHierarchy', params)
            this.employees = this.getUserHierarchy?.map(user => ({
                name: user?.FirstName + ' ' + user?.LastName,
                id: user?.UserId, UserGuid: user?.UserGuid
            }));
            await this.$store.dispatch("attendance/fetchLeaveType");
            this.reportTypes = this.getLeaveType?.map(type => ({ type: type.TypeText, Id: type.Id }))
            await this.$store.dispatch("hrmanagement/GetQuestionById");
            this.questions = this.GetQuestionById?.questionResponse?.map(data => ({
                QuestionCategory: data?.CategoryDetails?.QuestionCategory,
                Questions: data?.Questions.map(questionData => questionData.Question)
            }));
            // console.log(this.GetQuestionById, this.questions)
            this.reportTypes.push(
                { type: 'Attendance report', Id: 'AttendanceReport' }, { type: 'Remaining leave balance', Id: 'RemainingLeaveBalance' }
            )
            // this.loading = false;
            this.showShimmers = false;
        },
        ...mapMutations(['setAlertNotification']),
        async onSubmit(ReportType) {
            // this.loading = true
            this.showShimmers = true
            this.isUser = false;
            this.isValue = false;
            const input = {
                UserId: this.employeeReport.employee.id,
                FromDate: this.employeeReport.FromDate ? moment(this.employeeReport.FromDate).format('YYYY-MM-DD') : this.employeeReport.ToDate ? this.oneMonthBack : undefined,
                ToDate: this.employeeReport.ToDate ? moment(this.employeeReport.ToDate).format('YYYY-MM-DD') : this.employeeReport.FromDate ? new Date() : undefined,
                LeaveTypeId: typeof this.employeeReport?.ReportType?.Id === 'number' ? this.employeeReport.ReportType.Id : undefined
            }
            this.employeeReport?.ReportType?.Id == 'RemainingLeaveBalance' ?
                await this.$store.dispatch("attendance/getEmployee", this.employeeReport.employee.id) :
                await this.$store.dispatch("attendance/getattendanceReport", input);

            this.response = await this.getAttendanceReport;
            let response = this.employeeReport?.ReportType?.Id === 'RemainingLeaveBalance' ? [await this.getEmployee.employee] : await this.getAttendanceReport.getReport;
            // this.loading = false
            this.showShimmers = false
            response?.length > 0 ? this[ReportType](response) : ''
            const message = {
                variant: response?.length <= 0 ? 'warning' : this.response?.status == true ? 'success' : 'danger',
                alertTitle: 'Reports',
                alertDecription: response?.length > 0 ? this.response?.msg : 'No data found'
            };
            this.setAlertNotification(message)
        },
       
    },

    mounted() {
        this.oninit()
    },
}
</script>


<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <!-- <div v-if="loading">
            <PreLoader />
        </div> -->
        <div v-if="showShimmers">
            <b-card>
                <b-card-body>
                    <div class="row">
                        <div class="col-12">
                            <b-skeleton height="10px" width="20%"></b-skeleton>
                            <b-skeleton class="mt-4" height="10px" width="50%"></b-skeleton>
                            <b-skeleton class="mt-2" type="input" height="60px"></b-skeleton>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <b-skeleton class="mt-4" height="10px" width="50%"></b-skeleton>
                            <b-skeleton class="mt-2" type="input" height="60px"></b-skeleton>
                        </div>
                    </div>
                </b-card-body>
                <div class="row d-flex justify-content-end"> 
                        <b-skeleton class="mr-2" type="button" width="100px" border-radius="8px"></b-skeleton>
                        <b-skeleton type="button"  width="100px" border-radius="8px"></b-skeleton>
                </div>
            </b-card>   
        </div>

        <div class="home" v-else>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <form>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="col-lg-12">
                                            <ul>
                                                <li v-for="(category, index) in questions" :key="index">
                                                    <h6>{{ index + 1 }}. {{ category.QuestionCategory }}</h6>
                                                    <div class="form-group"
                                                        v-for="(question, questionIndex) in category.Questions"
                                                        :key="questionIndex">
                                                        <label>{{ questionIndex + 1 }}. {{ question }}</label>
                                                        <textarea type="text" class="form-control" rows="3"></textarea>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>


                                        <div class="col-12">
                                            <div class="col-lg-12">
                                                <div class="text-right">
                                                    <button class="btn btn-outline-primary ml-2 mb-2" type="button">
                                                        Save as draft</button>
                                                    <button class="btn btn-primary ml-2 mb-2" type="button">
                                                        Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<style scoped lang="scss">
/* body {
    font-family: Arial, sans-serif;
}

.report {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.report-form {
    border: 1px solid #00435D;
    padding: 20px;
    border-radius: 5px;
} */

.form-group {
    margin-bottom: 10px;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

// input[type="text"],
// input[type="date"],
// select {
//     width: 100%;
//     padding: 8px;
//     border: 1px solid #00435D;
//     border-radius: 3px;
//     height: 100%;
// }

// button {
//     background-color: #00435D;
//     color: #fff;
//     border: none;
//     padding: 10px 20px;
//     border-radius: 5px;
//     cursor: pointer;
// }

// button:hover {
//     background-color: #00435D;
// }

.department,
.mx-input {
    height: 38px !important;
}

.card-footer {
    background-color: #e2eaed !important;
}
</style>