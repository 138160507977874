<script>
  import { processTreeData } from "./tree-data-operations";
  import { mapGetters, mapMutations, mapState } from "vuex";  
  import Treeselect from '@riophae/vue-treeselect'
  // import the styles
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  
  const dataItemKey = "id";
  const subItemsField = "children";
  const expandField = "expanded";
  const selectField = "selected";
  const textField = "label";
  
  const fields = {
    dataItemKey,
    expandField,
    selectField,
    subItemsField,
  };
  
  export default {
    components: {
      Treeselect 
    },
    data: function () {
      return {
        fields,
        textField,
        dataItemKey,
        subItemsField,
        expandField,
        selectField,
        value: null,
        expanded: [],
        richMediaData:[],
        userIdData: []
      };
    },
    async mounted() {
        await this.oninit();
      },
      watch: {
        UpdateHierarchy: {
            deep: true,
            handler() {   
              if(this.UpdateHierarchy){
                this.oninit();
              }       
            },
        },
    },
    computed: {
      treeData () {
        return processTreeData(this.richMediaData, { expanded: this.expanded, value: this.value }, fields);
      },      
      ...mapState(['getUserId','UpdateHierarchy']),
      ...mapGetters('auth', ['getProfileDetails']),
      ...mapGetters('userservice',['getUserHierarchy']),
    },
    methods: {
     ...mapMutations(['setUserId','setUserGuid','setusername','setUserIds','setUserReportingGuid']),
      onChange(event) {
        if(event.id!=this.getUserId){
          window.location.reload();
          this.setUserId(event.id);
          this.setUserGuid(event?.UserGuid);
          this.setUserReportingGuid(event?.ReportingGuid)
          this.setusername(event?.label.split('(')[0]);
        }
      },
      async oninit() {
        const params={
          UserId:this.getProfileDetails.UserGuid
        }
        await this.$store.dispatch('userservice/GetUserHierarchy',params)
        let getUserHierarchy = await this.getUserHierarchy?.map(user => ({
          value: user?.Level, label: user?.FirstName + ' ' + user?.LastName+'('+ user?.DesignationName +')', ReportingId: user?.ReportingId, id: user?.UserId,
          UserGuid:user?.UserGuid,ReportingGuid:user?.ReportingGuid
        }));
        this.userIdData =  this.getUserHierarchy.map(item => item.UserId)
        this.setUserIds(this.userIdData)
      /**
       * Management array for Hierarachy  
       */
      const Level0 = getUserHierarchy?.find(item => item.value === 0);
      this.richMediaData=[]
      if (Level0) {
        this.richMediaData.push({
          ...Level0,
          children: this.buildHierarchy(getUserHierarchy, Level0.id, 1)
        });
      }
      
      this.value= (getUserHierarchy?.find(item => item.id == Number(this.getUserId)))?.id;
    },
    buildHierarchy(data, parentId, depth) {
      const items = data
        .filter(item => item.value === depth && item.ReportingId === parentId)
        .map(item => ({
          ...item,
          children: this.buildHierarchy(data, item.id, depth + 1)
        }));

      return items.length > 0 ? items : [];
    },
    }
  };
  </script>
<template>
    <div>
        <treeselect 
        :style="{ 'min-width': '200px', 'max-width': '300px' }"
          v-model="value"
          @select="onChange($event)"
          :options="treeData"
          :searchable="false"
          :clearable="false"
          :placeholder="'Organization Hierarchy ...'"
          :close-on-select="false"
          ></treeselect>
      </div>
  </template>

<style scoped>

</style>
  
  