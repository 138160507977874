<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
            variant: 'danger',
            alertText: 'success',
            dismissible: true,
            showAlert: false,
            alertData:[]
        }
    },
    methods: {
        toggleAlert() {
            this.alertData = this.getAlertNotification;
            this.showAlert = true;
            setTimeout(() => {
                this.showAlert = false;
            }, 5000);
        }
    },
    computed: {
        ...mapState(['getAlertNotification'])
    },
    watch: {
        getAlertNotification: {
            deep: true,
            handler() {
                this.toggleAlert();
            },
        },
    },
}

</script>

<template>
    <div>
        <div>
            <b-alert :variant="alertData?.variant" dismissible class="custom-alert" v-model="showAlert">
                <div class="d-flex align-items-baseline">
                    <div class="alert-icon font-size-16 mr-2">
                        <i v-if="alertData?.variant === 'danger'" class="ri-close-circle-line align-bottom"></i>
                        <i v-else-if="alertData?.variant === 'success' || 'secondary'" class="ri-checkbox-circle-line align-bottom"></i>   
                    </div>
                    <div class="alert-text">
                        <p class="font-size-16 font-weight-bold mb-0">
                            {{ alertData?.alertTitle }}</p>
                        <p class="mb-0">{{ alertData?.alertDecription }}</p>
                    </div>
                </div>
            </b-alert>
        </div>
        <!-- <div v-if="variant === 'danger'">
            <b-alert variant="danger" dismissible class="custom-alert" v-model="showDismissibleAlert">
                <p class="font-size-16 font-weight-bold mb-0"><i class="ri-checkbox-circle-line align-bottom"></i>
                    {{ alertText }}</p>
                <p class="mb-0">{{alertDecription}}</p>
            </b-alert>
        </div>
        <div v-if="variant === 'warning'">
            <b-alert variant="warning" dismissible class="custom-alert" v-model="showDismissibleAlert">
                <p class="font-size-16 font-weight-bold mb-0"><i class="ri-checkbox-circle-line align-bottom"></i>
                    {{ alertText }}</p>
                <p class="mb-0">{{alertDecription}}</p>
            </b-alert>
        </div>
        <div v-if="variant === 'info'">
            <b-alert variant="info" dismissible class="custom-alert" v-model="showDismissibleAlert">
                <p class="font-size-16 font-weight-bold mb-0"><i class="ri-checkbox-circle-line align-bottom"></i>
                    {{ alertText }}</p>
                <p class="mb-0">{{alertDecription}}</p>
            </b-alert>
        </div> -->
    </div>
</template>


<style scoped lang="scss">
.custom-alert {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 10000;
}
</style>