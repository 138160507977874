//import axios from "axios";
import { taskApiService } from "../../config/tasksaxios"
const initialState=()=>{
    return{
        leaveApplication:[],
        leaveType:null,
        userleaveApplication:null,
        getLeaveType:null,
        getuserLeaveApplication:null,
        leaveTypes:null,
        leaveStatus:null,
        AttendanceReport:null,
        EmployeeAttendance:null,
        Employee:null,
        MarkAttendance:null,
        AttendanceState:'',
        AllEmployeesAttendances:null,
        deleteLeave:'',
        Days :[],
        saveCompansetoryLeave:null,
        UpdateCompansetoryLeave : null
    };
};

const state=initialState();

export const getters={
    getLeaveApplication:(state)=>{
        return state.leaveApplication;
    },
    getLeaveType:(state)=>{
       return state.leaveTypes;
    },
    getuserLeaveApplication:(state)=>{
        return state.userleaveApplication;
    },
    getleaveStatus:(state)=>{
        return state.leaveStatus
    },
    getAttendanceReport:(state)=>{
        return state.AttendanceReport
    },
    getEmployeeAttendance:(state)=>{
        return state.EmployeeAttendance
    },
    getEmployee:(state)=>{
        return state.Employee
    },
    getMarkAttendance:(state)=>{
        return state.MarkAttendance
    },
    getAttendanceState:(state)=>{
        return state.AttendanceState?.data
    },
    getAllEmployeesAttendance:(state)=>{
        return state.AllEmployeesAttendances?.data
    },
    getdeleteLeave:(state)=>{
        return state.deleteLeave
    },
    getDays:(state)=>{
        return state.Days
    },
    getSaveCompansetoryLeave:(state)=>{
        return state.saveCompansetoryLeave;
    },
    getUpdateCompansetoryLeave:(state)=>{
        return state.UpdateCompansetoryLeave;
    },
};

export const mutations={
    saveCompansetoryLeave:(state,data)=>{
        return state.saveCompansetoryLeave = data;
    },
    UpdateCompansetoryLeave:(state,data)=>{
        return state.UpdateCompansetoryLeave = data;
    },
    setLeaveApplication:(state,data)=>{
        return state.leaveApplication=data;
    },
    setLeaveType:(state,data)=>{
        return state.leaveTypes=data;
    },
    setuserLeaveApplication:(state,data)=>{
        return state.userleaveApplication=data;
    },
    setleaveStatus:(state,data)=>{
        return state.leaveStatus=data;
    },    
    setAttendanceReport:(state,data)=>{
        return state.AttendanceReport=data;
    },
    setEmployeeAttendance:(state,data)=>{
        return state.EmployeeAttendance=data;
    },
    setEmployee:(state,data)=>{
        return state.Employee=data;
    },
    setMarkAttendance:(state,data)=>{
        return state.MarkAttendance=data;
    },
    setAttendanceState:(state,data)=>{
        return state.AttendanceState=data
    },
    setAllEmployeesAttendances:(state,data)=>{
        return state.AllEmployeesAttendances=data
    },
    setdeleteLeave:(state,data)=>{
        return state.deleteLeave=data
    },
    setDays:(state,data)=>{
        return state.Days=data
    },
    reset: (state) => {
        const initial = initialState();
        Object.keys(initial).forEach((key) => {
          state[key] = initial[key];
        });
      }
};


export const actions={
    async saveLeaveApplication({commit,dispatch},params){
        try{
            commit("setLeaveApplication",null);
            let response= await taskApiService.post("/attendance/SaveEmployeeHistory",params)
            commit("setLeaveApplication",response.data);
        } catch(err){
            console.log(err)
            await dispatch("errors/errorResponse", err.response, { root: true });
        }
    },
    async fetchLeaveType({commit,dispatch}){
        try{
            commit("setLeaveType",null);
            let response = await taskApiService.get("/attendance/getLeaveTypes");
            commit("setLeaveType", response.data.leaveTypes)
            // console.log(response.data.leaveTypes,this.setLeaveType)

        } catch (err) {
            await dispatch("errors/errorResponse", err.response, {root:true});
        }
    },
    async getLeaveApplications({commit,dispatch},params){
        try{
            commit("setuserLeaveApplication",null);
            let response= await taskApiService.post('/attendance/getEmployeeLeavesHistory',params)
            
            commit("setuserLeaveApplication",response.data);
        } catch(err){
            console.log(err)
            await dispatch("errors/errorResponse", err.response, { root: true });
        }
    },
    
    /**
     *  get Employee Attandance
     * @param {*} param0 
     * @param {*} params 
     */
    async getEmployeeAttendance({commit,dispatch},params){
        try{
            commit("setEmployeeAttendance",null);
            let response= await taskApiService.get(`/attendance/getEmployeeAttendance/${params}`)
            
            commit("setEmployeeAttendance",response.data);
        } catch(err){
            console.log(err)
            await dispatch("errors/errorResponse", err.response, { root: true });
        }
    },
    /**
     *  get Employee NODE JS
     * @param {*} param0 
     * @param {*} params 
     */
    async getEmployee({commit,dispatch},params){
        try{
            commit("setEmployee",null);
            let response= await taskApiService.get(`/employee/getEmployee/${params}`)
            
            commit("setEmployee",response.data);
        } catch(err){
            console.log(err)
            await dispatch("errors/errorResponse", err.response, { root: true });
        }
    },
    async saveleaveStatus({commit,dispatch},params){
        try{
            commit("setleaveStatus",null);
            let response = await taskApiService.post("/attendance/UpdateLeaveStatus",params);
            commit("setleaveStatus", response.data)
            // console.log(response.data)
        }
        catch(err){
            console.warn(err)
            commit("setleaveStatus", null);
            await dispatch("errors/errorResponse", err.response, { root: true });
        }
    },
    /**
 * Get attendance report by UserId
 * @param {*} param0 
 * @param {*} params 
 */
    async getattendanceReport({ commit, dispatch }, params = {}) {
        try {
            commit("setAttendanceReport", null);
            let response = await taskApiService.post("/attendance/getAttendanceReport", params);
            commit("setAttendanceReport", response?.data)

        } catch (err) {
            await dispatch("errors/errorResponse", err.response, { root: true });
        }
    },
    /**
     * Save attendance
     * @param {*} param0 
     * @param {*} params 
     */
    async savemarkAttendance({ commit, dispatch }, params) {
        try {
            commit("setMarkAttendance", null);
            let response = await taskApiService.post("/attendance/markAttendance", params);
            commit("setMarkAttendance", response?.data)

        } catch (err) {
            console.log(err)
            await dispatch("errors/errorResponse", err.response, { root: true });
        }
    },
    /**
     * Get attendance status UserId
     * @param {*} param0 
     * @param {*} params 
     */
    async getAttendanceState({ commit, dispatch }, params) {
        try {
            commit("setAttendanceState", null);
            let response = await taskApiService.post("/attendance/attendanceStatus", params);
            commit("setAttendanceState", response)

        } catch (err) {
            console.warn(err)
            await dispatch("errors/errorResponse", err.response, { root: true });
        }
    },
       /**
       * Get attendance report by UserId
       * @param {*} param0
       * @param {*} params
       */
    async getAllEmployeesAttendances({ commit, dispatch }, params = {}) {
        try {
            commit("setAllEmployeesAttendances", null);
            let response = await taskApiService.post("/attendance/getAllEmployeesAttendances", params);
            commit("setAllEmployeesAttendances", response)
           
        } catch (err) {
            await dispatch("errors/errorResponse", err.response, { root: true });
        }
    },
    /**
     * Delete leave
     * @param {*} params
     */
    async deleteLeave({ commit , dispatch }, params){
        try{
            commit("setdeleteLeave", null);
            let response = await taskApiService.post("attendance/deleteLeave", params);
            commit("setdeleteLeave", response)
        }
        catch(err){
            console.warn(err)
            await dispatch("errors/errorResponse", err.response, { root: true });
        }
    },
    async getDays({commit,dispatch},params){
        try{
            commit("setDays",null);
            let response = await taskApiService.post("/attendance/getDays",params);
            commit("setDays", response.data)
        }
        catch(err){
            console.warn(err)
            commit("setDays", null);
            await dispatch("errors/errorResponse", err.response, { root: true });
        }
    },

    async addCompansetoryLeave({commit,dispatch},params){
        try{
            commit('saveCompansetoryLeave' , null)
            const response = await taskApiService.post('attendance/SaveCompansetoryLeave' , params)
            commit('saveCompansetoryLeave' , response.data);

        }catch(err){
            console.warn(err)
            commit('saveCompansetoryLeave' , null)
            await dispatch('error/errorResponce')
        }
    },

    async UpdateCompansetoryLeave({commit,dispatch},params){
        try{
            commit('UpdateCompansetoryLeave' , null)
            const response = await taskApiService.post('attendance/updateCompansetoryLeave' , params)
            commit('UpdateCompansetoryLeave' , response.data);

        }catch(err){
            console.warn(err)
            commit('UpdateCompansetoryLeave' , null)
            await dispatch('error/errorResponce')
        }
    },
};


export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
