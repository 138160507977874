import Vue from 'vue'
import Vuex from 'vuex'
import userservice from '../store/module/userservice'
import auth from '../store/module/auth'
import errors from '../store/module/errors'
import createPersistedState from "vuex-persistedstate";
import tasks from '../store/module/tasks'
import layout from '../state/modules/layout'
import config from "../config/app";
import projects from '../store/module/projects'
import holiday from './module/holiday'
import attendance from '../store/module/attendance'
import hrmanagement from '../store/module/hrmanagement'
import managerappraisalquestion from '../store/module/managerappraisalquestion'
Vue.use(Vuex)
const moduleNames = ["auth", "userservice", "tasks","projects","attendance","hrmanagement", "managerappraisalquestion"];

export default new Vuex.Store({
  state: {
    taskFilter:null,
    Username: null,
    sharedData:null,
    getUserId : null,
    getUserguid:null,
    getTaskId : null,
    getTaskUser:null,
    getPermissions:[],
    getUserIds:[],
    getAssignedToUserId: null,
    getUserHierarchyValue:null,
    getRepotingGuid:'',
    getTaskPopup:'',
    UpdateHierarchy:'',
    fullLocation:'',
    gettaskfilterstate:[],
    getAlertNotification:[],
    getUserReportingGuid:[],
  },
  getters: {
  },
  mutations: {
    setNew(state, data){
      state.taskFilter = data;
    },
    setusername(state, data){
      state.Username = data;
    },
    setUserId(state , data){
      state.getUserId = data;
    },
    setSharedData(state, data) {
      state.sharedData = data;
    },
    setUserGuid(state,data){
      state.getUserguid=data
    },
    setTaskId(state,data){
      state.getTaskId=data
    },
    setTaskuser(state,data){
      state.getTaskUser=data
    },
    setAssignedToUserId(state,data){
      state.getAssignedToUserId=data
    },
    setUserpermissions(state,data){
      state.getPermissions=data
    },
      setUserIds(state,data){
        state.getUserIds=data
      },
    setUserHierarchyValue(state,data){
      state.getUserHierarchyValue=data
    },
    setRepotingGuid(state,data){
      state.getRepotingGuid=data
    },
    setTaskPopup(state,data){
      state.getTaskPopup=data
    },
    setUpdateHierarchy(state,data){
      state.UpdateHierarchy=data
    },
    setFilterData(state, data) {
      state.gettaskfilterstate=data
    },
    setAlertNotification(state, data) {
      state.getAlertNotification = data
    },
    setUserReportingGuid(state, data){
      state.getUserReportingGuid=data
    },
    setLocation(state, data){
      state.fullLocation=data
    }
  },
  actions: {
    globalReset({ commit }) {
      moduleNames.forEach((module) => {
        commit(`${module}/reset`);
      }); 
    },
  },
  plugins: [
    createPersistedState({
      key: config.APP_NAME + "_" + config.APP_VERSION,
      paths: ["auth", "tasks", 'userservice', 'sharedData', 'Username', 'fullLocation', 'getAlertNotification', "attendance",
        'getUserId', 'getUserguid', 'getTaskId', 'getPermissions', 'getRepotingGuid', 'getUserReportingGuid',
        'getUserIds', 'getAssignedToUserId', 'getTaskUser', 'getUserHierarchyValue', 'getTaskPopup', 'projects',
        'gettaskfilterstate', 'taskFilter'],
    }),
  ],
  modules: {
    userservice:userservice,
    auth:auth,
    errors:errors,
    tasks:tasks,
    layout:layout,
    projects : projects,
    holiday: holiday,
    attendance:attendance,
    hrmanagement:hrmanagement,
    managerappraisalquestion : managerappraisalquestion

  }
})
