<script>
import Layout from "../../layouts/vertical.vue";
import PageHeader from "@/components/page-header";
// import DatePicker from "vue2-datepicker";
// import PreLoader from "@/components/pre-loader";
import { mapGetters, mapState, mapMutations } from "vuex";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
  components: { Layout, PageHeader ,  Multiselect},
  data() {
    return {
      UserPermisions: [],
      Specificuser: [],
      departmentOptions: [],
      department: [],
      designationOptions: [],
      designation: [],
      Roles: [],
      RoleType: [],
      ApprovalType: [],
      Permissions: [],
      Profile: "",
      ReportingManagers: [],
      previewUrl: null,
      // loading: false,
      showUDShimmers: true,
      delete: "",
      TaskRoles: [],
      UserRoles: [],
      ProjectRoles: [],
      title: "",
      NewRepotingManager: null,
      listusers: [],
      childUsers: [],
      SpecificUserProjects: [],
      Projects: [],
      selectfile: [],
      documents: [
        {
          document: "Aadhar",
          documentNumber: "644018992390",
          action: "Download",
        },
        { document: "PAN", documentNumber: "BOBPP2279N", action: "Download" },
        {
          document: "Bank Account",
          documentNumber: "BOBPP2279N",
          action: "Download",
        },
      ],
      fields: ["document", "documentNumber", "action"],
      items: [
        {
          text: "Users",
          routeName: "UsersList",
        },
        {
          text: "User profile",
          active: true,
        },
      ],
      isTabActive: "",
    };
  },
  created() {
    this.initUserPermisions();
  },
  methods: {
    permissionsMethod() {
      let selectedApprovalTypes = JSON.parse(
        this.getUserDetailsById?.Lstpermissionstext
      )?.permissions;
      let UserRoles = selectedApprovalTypes
        ?.filter((item) => item.TypeFor === "Users")
        .map((types) => types?.TypeText);
      let TaskRoles = selectedApprovalTypes
        ?.filter((item) => item.TypeFor === "Tasks")
        .map((types) => types?.TypeText);
      let ProjectRoles = selectedApprovalTypes
        ?.filter((item) => item.TypeFor === "Projects")
        .map((types) => types?.TypeText);
      (this.UserRoles = UserRoles[0]), (this.TaskRoles = TaskRoles[0]);
      this.ProjectRoles = ProjectRoles[0];
    },
    //checks the user has specific permission or not.
    hasPermission(permissionToCheck) {
      return this.filteredUserPermisions.some(
        (permissionObj) => permissionObj.permission === permissionToCheck
      );
    },
    //Ueser permissios data
    initUserPermisions() {
      this.UserPermisions = [
        {
          key: "deleteuser",
          permission: "DeleteUser",
        },
        {
          key: "edituser",
          permission: "EditUser",
        },
      ];
    },
    EditUser() {
      this.$router.push({
        name: "AddUser",
        params: {
          guid: this.Profile || "00000000-0000-0000-0000-000000000000",
        },
      });
    },
    async GetUserDetailsByUserId() {
      this.showUDShimmers = true;
      this.Profile = this.$route.params.guid;
      await this.$store.dispatch(
        "userservice/GetUserDetailsByGuid",
        this.Profile
      );
      await this.permissionsMethod();
      this.Specificuser =
        this.$store.getters["userservice/getUserDetailsById"] ||
        this.getUserDetailsById;
      this.department = this.departmentOptions.filter(
        (item) => item.DepartmentGuid === this.Specificuser.DepartmentGuid
      )[0];
      this.title =
        this.Specificuser.FirstName + " " + this.Specificuser.LastName;
      this.designation = this.designationOptions.filter(
        (item) => item.DesignationGuid === this.Specificuser.DesignationGuid
      )[0];
      this.RoleType = this.Roles.filter(
        (item) => item.RoleGuid === this.Specificuser.RoleGuid
      )[0];
      const Projects = this.Specificuser.Projects;
      const ProjectId = Projects.split(",").map((Id) => Id);
      this.SpecificUserProjects = ProjectId.map((Id) =>
        this.Projects.find((item) => item.ProjectId === parseInt(Id))
      ).filter(Boolean);
      await this.getusers();
      // this.loading = false;
      this.showUDShimmers = false;

    },
    /**
     *
     * @param {*} user
     */
    async cancel() {
      if (this.Specificuser.TeamCount > 0) {
        this.NewRepotingManager = null;
        this.$refs["myModal"]?.show();
      } else {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-primary ml-2",
          },
          buttonsStyling: false,
        });
        const text = `You want to delete the <b style="color: black;">${this.Specificuser.FirstName} ${this.Specificuser.LastName}</b>?`;

        await swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            html: text,
            // icon: "warning",
            confirmButtonText: "Yes, delete",
            cancelButtonText: "No, cancel",
            position: "center",
            showCancelButton: true,
            allowOutsideClick: false,
            imageUrl: "/images/emailexpired_icon.png",
          imageHeight: 50, 
          imageWidth: 50,
          })
          .then((result) => {
            if (result.value) {
              this.deleteUser(this.Specificuser);
            }
          });
      }
    },

    async deleteUser(user) {
      try {
        //console.log('Deleting user...', user);
        const params = {
          guid: user.UserGuid,
          IsDeleted: true,
        };
        await this.$store.dispatch("userservice/DeleteUser", params);
        const param = {
          UserId: this.Specificuser.UserId,
        };
        this.delete = await this.getDeleteUser;
        const Response = {
          variant: this.delete.status === false ? "danger" : "secondary",
          alertTitle: this.delete.status === false ? "Error" : "Deleted",
          alertDecription: this.getDeleteUser.data.message,
        };
        this.setAlertNotification(Response);
        await this.$store.dispatch("userservice/DeleteEmployee", param);
        await this.asignNewRepotingManager();
        if (this.getDeleteUser) {
          await this.ReportingComments();
        }

        //console.log('User deleted successfully');
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    },
    async GetUserDefaults() {
      // this.loading = true;
      await this.$store.dispatch(
        "userservice/GetUserDefaults",
        this.getUserguid
      );
      setTimeout(() => {}, 1000);
      this.UserDefaultData = this.getUserDefaults;
      this.departmentOptions = this.UserDefaultData.DepartmentType;
      this.designationOptions = this.UserDefaultData.DesignationType;
      this.Permissions = this.UserDefaultData.AppRightType;
      this.ApprovalType = this.UserDefaultData.ApprovalType;
      this.Roles = this.UserDefaultData.RoleType;
      this.Projects = this.UserDefaultData.ProjectResponse;
      this.GetUserDetailsByUserId();
    },
    async getusers() {
      let users = this.getUserHierarchy;
      this.childUsers = users
        ?.filter((item) => item.ReportingId === this.Specificuser.UserId)
        .map((item) => ({
          ...item,
        }));
      let restictedusers = [];
      this.childUsers?.forEach((element) => {
        restictedusers.push(element.UserId);
      });
      restictedusers.push(this.Specificuser.UserId);
      this.listusers = this.getUserHierarchy?.filter((user) => !restictedusers.includes(user.UserId))
        .map((user) => ({
          ...user,
          Fullname: user.FirstName + " " + user.LastName,
        }));
    },
    async assignNewReportingManager() {
      this.$refs["myModal"]?.hide();
      this.deleteUser(this.Specificuser);
    },

    async ReportingComments() {
      let params = await {
        AssignedId: this.Specificuser.UserId,
        ReportingId:this.NewRepotingManager != null ?
          this.NewRepotingManager?.UserId : this.Specificuser.ReportingId,
        ReportingName:
        this.NewRepotingManager != null ? this.NewRepotingManager?.Fullname :
          this.Specificuser?.ReportingManger,
        CommentedType: "UserDeleted",
        CommentedBy:
          this.getProfileDetails?.FirstName +
          " " +
          this.getProfileDetails?.LastName,
        CommentedById: Number(this.getProfileDetails.UserId || this.getProfileDetails.Id),
      };
      await this.$store.dispatch("tasks/ReportingComments", params);
      setTimeout(() => {
        this.assignTasksToNewReportingManager();
      }, 1500);
    },

    async assignTasksToNewReportingManager() {
      let params = {
        DeletedId: this.Specificuser.UserId,
        ReportingId:this.NewRepotingManager != null ?
          this.NewRepotingManager?.UserId : this.Specificuser.ReportingId,
        ReportingName:this.NewRepotingManager != null ?
          this.NewRepotingManager?.Fullname :
          this.Specificuser?.ReportingManger ,
      };
      await this.$store.dispatch("tasks/ReportingTasks", params);
    },
    ...mapMutations(["setUpdateHierarchy", "setAlertNotification"]),
    async asignNewRepotingManager() {
      let restictedusers = [];
      this.childUsers.forEach((element) => {
        restictedusers.push({ userGuid: element.UserGuid });
      });
      //  this.NewRepotingManager?.UserGuid
      if (this.getDeleteUser) {
        const params = {
          repotingManagerGuid: this.NewRepotingManager?.UserGuid,
          lstUsers: [...restictedusers],
          UserGuid: this.Specificuser.UserGuid,
        };
        await this.$store.dispatch("userservice/UpdateRepotingManager", params);
        this.setUpdateHierarchy(this.Specificuser);
        this.$router.push("/users-list");
      }
    },
    closemodel() {
      this.$refs["myModal"]?.hide();
    },
  },
  computed: {
    ...mapState(["getPermissions", "getUserguid"]),
    ...mapGetters("userservice", [
      "getUserDetailsById",
      "getUserDefaults",
      "getAllUsers",
      "getUserHierarchy",
      "getDeleteUser",
    ]),
    ...mapGetters("auth", ["getProfileDetails"]),
    ...mapGetters("tasks", ["getReportingComments"]),
    filteredUserPermisions() {
      let permissions = this.getPermissions?.UserRoles;
      function hasPermission(permission) {
        return permissions?.includes(permission);
      }
      return this.UserPermisions.filter((UserPermisions) => {
        return (
          (UserPermisions.permission === "DeleteUser" &&
            hasPermission("DeleteUser")) ||
          (UserPermisions.permission === "EditUser" &&
            hasPermission("EditUser"))
        );
      });
    },
  },
  async mounted() {
    this.GetUserDefaults();
  },
};
</script>

<template>
  <Layout>
    <!-- <div v-if="loading == true">
      <PreLoader></PreLoader>
    </div> -->
    <PageHeader :title="title" :items="items" />
    <div v-if="showUDShimmers">
          <b-card no-body img-left height="100px">
                <b-card-body class="d-flex justify-content-between flex-column flex-sm-row align-items-center">
                  <div class="d-flex flex-column flex-sm-row align-items-center">
                    <b-skeleton class="mb-2" type="avatar" width="40px" height="40px"></b-skeleton>
                    <div class="ml-3 flex-column flex-sm-row align-self-end">
                    <b-skeleton width="140px"></b-skeleton>
                    <b-skeleton width="60px"></b-skeleton>
                  </div>
                  </div>
                  
                  <div>
                    <b-skeleton type="button"></b-skeleton>
                  </div>
                </b-card-body>
          </b-card>  
    </div>
        <div>


    <!-- <div class="row">
      <div class="col-lg-4 mb-3">
        <div class="card">
          <div class="card-body">
            <h6 class="font-size-16 mb-3">User info</h6>
            <div class="grid-container">
              <div class="grid-item">
                <div class="user-avatar"><img src="/img/user.960d9c1c.png" class="rounded-0 card-img"></div>
              </div>
              <div class="grid-item ">
                <h4 class="card-title font-weight-bold text-truncate">{{ Specificuser.FirstName }} {{
                  Specificuser.LastName }}</h4>
                <p class="text-muted mb-0">
                  <i class="ri-mail-line mr-1 align-text-top"></i>{{ Specificuser.Email }}
                </p>
                <p class="text-muted mb-0">
                  <i class="ri-smartphone-line mr-1 align-text-top"></i>{{ Specificuser.CountryCode }} {{
                    Specificuser.PhoneNumber }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 mb-3">
        <div class="card">
          <div class="card-body">
            <h6 class="font-size-16 mb-3">Employee details</h6>
            <div class="grid-container emp-details">
              <div class="grid-item ">
                <p class="text-muted mb-0">Employee ID</p>
                <h6 class="font-size-14">{{ Specificuser.EmployeeCode }}</h6>
              </div>
              <div class="grid-item ">
                <p class="text-muted mb-0">Department</p>
                <h6 class="font-size-14">{{ department?.DepartmentName }}</h6>
              </div>
              <div class="grid-item ">
                <p class="text-muted mb-0">Designation</p>
                <h6 class="font-size-14">{{ designation?.DesignationName }}</h6>
              </div>
              <div class="grid-item ">
                <p class="text-muted mb-0">Reporting manager</p>
                <h6 class="font-size-14">{{ ReportingManagers?.FirstName ? ReportingManagers?.FirstName + ' ' +
                  ReportingManagers?.LastName : '' }}</h6>
              </div>
              <div class="grid-item ">
                <p class="text-muted mb-0">Role</p>
                <h6 class="font-size-14">{{ RoleType?.Role }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 mb-3">
        <div class="card">
          <div class="card-body">
            <h6 class="font-size-16 mb-3">Permissions</h6>
            <div class="grid-container user-permission">
              <div class="grid-item ">
                <p class="text-muted mb-0">Tasks</p>
                <span v-if="TaskRoles?.length > 0">
                  <span v-for="role in this.TaskRoles" :key="role" style=" display: inline-block;  margin-right: 10px; ">
                    <h5 class="btn btn-sm btn-light rounded-pill">{{ role }}</h5>
                  </span>
                </span>
                <span v-else>
                  <h5 class="font-size-16" v-if="!loading">N/A</h5>
                </span>
              </div>
              <div class="grid-item ">
                <p class="text-muted mb-0">Users</p>
                <span v-if="UserRoles?.length > 0">
                  <span v-for="role in this.UserRoles" :key="role" style=" display: inline-block;  margin-right: 10px; ">
                    <h5 class="btn btn-sm btn-light rounded-pill">{{ role }}</h5>
                  </span>
                </span>
                <span v-else>
                  <h5 class="font-size-16" v-if="!loading">N/A</h5>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div>
      <div class="card h-100" v-if="!showUDShimmers">
        <div class="card-body">
          <div class="col-sm-12">
            <div class="d-flex align-items-center flex-column flex-sm-row">
              <img
                class="avatar-sm profile-pic"
                :src="Specificuser.ImgUrl"
                alt=""
                v-if="Specificuser.ImgUrl"
              />
              <img
                class="avatar-sm profile-pic mb-2"
                v-else
                src="@/assets/images/users/user.png"
                alt="Default Profile Picture"
              />
              <div class="media-body mb-2 ml-3">
                <!-- <p class="text-truncate mb-0 font-weight-bold">
                {{ Specificuser.FirstName }} {{ Specificuser.LastName }}
              </p> -->
                <h6 class="mb-0 text-center text-sm-left">
                  {{
                    Specificuser.FirstName
                      ? Specificuser.FirstName + " " + Specificuser.LastName
                      : ""
                  }}
                </h6>
                <p class="text-muted mb-0 mt-1">
                  <i class="ri-mail-line mr-1 align-text-top"></i
                  >{{ Specificuser.Email }}
                </p>
                <p class="text-muted mb-0 text-center text-sm-left">
                  <i class="ri-phone-line mr-1 align-text-top"></i
                  >{{ Specificuser.CountryCode }} {{ Specificuser.PhoneNumber }}
                </p>
              </div>
              <div class="d-flex justify-content-end">
                <span v-if="hasPermission('EditUser')">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="EditUser"
                  >
                    <i class="ri-pencil-line align-bottom"></i> Edit profile
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <!-- Tabs-->
          <div class="tasklist-tabs d-md-block d-lg-block">
            <b-tabs nav-class="nav-tabs-custom" content-class="text-muted">
              <b-tab
                :active="isTabActive == 'Tasks'"
              >
                <template v-slot:title>
                  <span class="d-inline-block">
                    <i class="ri-user-line align-text-bottom"></i>
                  </span>
                  <span class="d-inline-block ml-2">Profile details</span>
                </template>
                <!--Employee Details-->
                <!-- <hr /> -->
                <b-card v-if="showUDShimmers">
          
          <div class="col-sm-12 mt-4">
            <div class="row">
              <div class="col-sm-4">
                 <b-skeleton height="10px" width="100px"></b-skeleton>
                 <b-skeleton type="input" height="10px"></b-skeleton>
              </div>
              <div class="col-sm-4">
                 <b-skeleton  class="mt-2" height="10px" width="100px"></b-skeleton>
                 <b-skeleton type="input" height="10px"></b-skeleton>
              </div>
              <div class="col-sm-4">
                 <b-skeleton  class="mt-2" height="10px" width="100px"></b-skeleton>
                 <b-skeleton type="input" height="10px"></b-skeleton>
              </div>
              <div class="col-sm-4">
                 <b-skeleton class="mt-2" height="10px" width="100px"></b-skeleton>
                 <b-skeleton type="input" height="10px"></b-skeleton>
              </div>
              <div class="col-sm-4">
                 <b-skeleton class="mt-2" height="10px" width="100px"></b-skeleton>
                 <b-skeleton type="input" height="10px"></b-skeleton>
              </div>
            </div>
          </div>
          <div class="col-sm-4 mt-4">
            <b-skeleton type="input" height="10px" width="100px"></b-skeleton> 
          </div>
       
         </b-card>
                <div class="col-sm-12 mt-4" v-if="!showUDShimmers">
                  <div class="row">
                    <!-- <div class="col-sm-4">
                      <p class="text-muted mb-0">First Name</p>
                      <p class="font-size-15 text-dark font-weight-bold">
                        {{ this.Specificuser.FirstName }}
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <p class="text-muted mb-0">Last Name</p>
                      <p class="font-size-15 text-dark font-weight-bold">
                        {{ this.Specificuser.FirstName }}
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <p class="text-muted mb-0">Blood Group</p>
                      <p class="font-size-15 text-dark font-weight-bold">
                        {{ this.Specificuser?.BloodGroup }}
                      </p>
                    </div> -->
                    <div class="col-sm-4">
                      <p class="text-muted mb-0">Employee ID</p>
                      <p class="font-size-15 text-dark font-weight-bold">
                        {{ Specificuser.EmployeeCode }}
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <p class="text-muted mb-0">Department</p>
                      <p class="font-size-15 text-dark font-weight-bold">
                        {{ department?.DepartmentName }}
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <p class="text-muted mb-0">Designation</p>
                      <p class="font-size-15 text-dark font-weight-bold">
                        {{ designation?.DesignationName }}
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <p class="text-muted mb-0">Reporting manager</p>
                      <p class="font-size-15 text-dark font-weight-bold">
                        {{
                          Specificuser?.ReportingManger
                            ? Specificuser?.ReportingManger
                            : ""
                        }}
                      </p>
                    </div>
                    <!-- <div class="col-sm-4">
                      <p class="text-muted mb-0">Official Email</p>
                      <p class="font-size-15 text-dark font-weight-bold">
                        rajyalakshmi.chava@tekreant.com
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <p class="text-muted mb-0">Personal Email</p>
                      <p class="font-size-15 text-dark font-weight-bold">
                        rajichava25@gmail.com
                      </p>
                    </div> -->
                    <div class="col-sm-4">
                      <p class="text-muted mb-0">Role</p>
                      <p class="font-size-15 text-dark font-weight-bold">
                        {{ RoleType?.Role }}
                      </p>
                    </div>
                    <!-- <div class="col-sm-4">
                      <p class="text-muted mb-0">Personal Phone Number</p>
                      <p class="font-size-15 text-dark font-weight-bold">
                        8888888888
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <p class="text-muted mb-0">Emergency Contact Number</p>
                      <p class="font-size-15 text-dark font-weight-bold">
                        8888855555
                      </p>
                    </div> -->
                  </div>
                </div>
                <!--Table-->
                <div class="table-responsive mt-4">
                  <!-- <b-table
                    style="border: 1px solid #ced4da;"
                    hover
                    :items="documents"
                    :fields="fields"
                  >
                    <template #cell(action)="data">
                      <i class="ri-download-line align-middle"></i>
                      {{ data.item.action }}
                    </template>
                  </b-table> -->

                  <span
                    v-if="hasPermission('DeleteUser') && !showUDShimmers"  
                    @click="cancel()"
                    class="clickable-text"
                  >
                    <i class="ri-delete-bin-6-line align-bottom"></i> Delete
                    profile
                  </span>
                </div>
              </b-tab>
              <!-- <b-tab
                :active="isTabActive == 'Assigned to'"
              >
                <template v-slot:title>
                  <span class="d-inline-block">
                    <i class="ri-user-follow-line"></i>
                  </span>
                  <span class="d-inline-block ml-2">Professional Docs</span>
                </template>
                <div
                  class="d-flex justify-content-between my-2 flex-column flex-sm-row"
                >
                  <div class="col-sm-12 col-md-4 mt-1">
                    <div id="tickets-table_length" class="dataTables_length">
                      <h5 class="d-inline-flex align-items-center">
                        Educational Documents
                      </h5>
                    </div>
                  </div>
                  Search
                  <div class="col-sm-12 col-md-8">
                    <div
                      class="d-flex justify-content-end align-items-sm-center flex-column flex-sm-row"
                    >
                      <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-right"
                      >
                        <label
                          class="d-inline-flex align-items-center position-relative w-100"
                        >
                          <b-form-input
                            size="sm"
                            type="search"
                            placeholder="search..."
                            class="form-control ml-0 ml-sm-2"
                          ></b-form-input>
                          <i class="ri-search-line search-icon"></i>
                        </label>
                      </div>
                      <div class="ml-2 mb-2">
                        <button
                          class="btn btn-primary btn-sm w-100"
                          variant="primary"
                          v-b-modal.modal-backdrop
                        >
                          <i class="ri-upload-line align-bottom"></i> Upload
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="dropdown-divider"></div>
                <h6>Certificates and MarkShets</h6>
                <div
                  class="d-flex justify-content-between my-2 flex-column flex-sm-row border py-2 mb-3"
                >
                  <div class="col-sm-12 col-md-4 mt-1">
                    <div id="tickets-table_length" class="dataTables_length">
                      <h6 class="d-inline-flex align-items-center mb-0">
                        <i class="fas fa-graduation-cap mr-2"></i>
                        10th Grade Certificate
                      </h6>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-8">
                    <div
                      class="d-flex justify-content-end align-items-sm-center flex-column flex-sm-row mt-1"
                    >
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-primary">
                          <i class="ri-zoom-in-line align-bottom"></i>
                          Preview
                        </div>
                      </div>
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-danger">
                          <i class="ri-delete-bin-line align-bottom"></i>
                          Delete
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex justify-content-between my-2 flex-column flex-sm-row border py-2"
                >
                  <div class="col-sm-12 col-md-4 mt-1">
                    <div id="tickets-table_length" class="dataTables_length">
                      <h6 class="d-inline-flex align-items-center mb-0">
                        <i class="fas fa-graduation-cap mr-2"></i>
                        12th Grade Certificate
                      </h6>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-8">
                    <div
                      class="d-flex justify-content-end align-items-sm-center flex-column flex-sm-row mt-1"
                    >
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-primary">
                          <i class="ri-zoom-in-line align-bottom"></i>
                          Preview
                        </div>
                      </div>
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-danger">
                          <i class="ri-delete-bin-line align-bottom"></i>
                          Delete
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex justify-content-between my-2 flex-column flex-sm-row border py-2"
                >
                  <div class="col-sm-12 col-md-4 mt-1">
                    <div id="tickets-table_length" class="dataTables_length">
                      <h6 class="d-inline-flex align-items-center mb-0">
                        <i class="fas fa-graduation-cap mr-2"></i>
                        Graduation Certificate and Mark Sheets
                      </h6>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-8">
                    <div
                      class="d-flex justify-content-end align-items-sm-center flex-column flex-sm-row mt-1"
                    >
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-primary">
                          <i class="ri-zoom-in-line align-bottom"></i>
                          Preview
                        </div>
                      </div>
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-danger">
                          <i class="ri-delete-bin-line align-bottom"></i>
                          Delete
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex justify-content-between my-2 flex-column flex-sm-row border py-2"
                >
                  <div class="col-sm-12 col-md-4 mt-1">
                    <div id="tickets-table_length" class="dataTables_length">
                      <h6 class="d-inline-flex align-items-center mb-0">
                        <i class="fas fa-graduation-cap mr-2"></i>
                        Post Graduation Certificate and Mark Sheets
                      </h6>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-8">
                    <div
                      class="d-flex justify-content-end align-items-sm-center flex-column flex-sm-row mt-1"
                    >
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-primary">
                          <i class="ri-zoom-in-line align-bottom"></i>
                          Preview
                        </div>
                      </div>
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-danger">
                          <i class="ri-delete-bin-line align-bottom"></i>
                          Delete
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex justify-content-between my-2 flex-column flex-sm-row border py-2"
                >
                  <div class="col-sm-12 col-md-4 mt-1">
                    <div id="tickets-table_length" class="dataTables_length">
                      <h6 class="d-inline-flex align-items-center mb-0">
                        <i class="fas fa-graduation-cap mr-2"></i>
                        Other Qualification Certificates
                      </h6>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-8">
                    <div
                      class="d-flex justify-content-end align-items-sm-center flex-column flex-sm-row mt-1"
                    >
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-primary">
                          <i class="ri-zoom-in-line align-bottom"></i>
                          Preview
                        </div>
                      </div>
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-danger">
                          <i class="ri-delete-bin-line align-bottom"></i>
                          Delete
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                Work Experience Documents
                <h6>Work Experience Documents</h6>
                <div
                  class="d-flex justify-content-between my-2 flex-column flex-sm-row border py-2 mb-3"
                >
                  <div class="col-sm-12 col-md-4 mt-1">
                    <div id="tickets-table_length" class="dataTables_length">
                      <h6 class="d-inline-flex align-items-center mb-0">
                        <i class="ri-award-fill mr-2"></i>
                        Experience Certificates
                      </h6>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-8">
                    <div
                      class="d-flex justify-content-end align-items-sm-center flex-column flex-sm-row mt-1"
                    >
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-primary">
                          <i class="ri-zoom-in-line align-bottom"></i>
                          Preview
                        </div>
                      </div>
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-danger">
                          <i class="ri-delete-bin-line align-bottom"></i>
                          Delete
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex justify-content-between my-2 flex-column flex-sm-row border py-2"
                >
                  <div class="col-sm-12 col-md-4 mt-1">
                    <div id="tickets-table_length" class="dataTables_length">
                      <h6 class="d-inline-flex align-items-center mb-0">
                        <i class="ri-file-list-2-fill mr-2"></i>
                        Relieving Letters
                      </h6>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-8">
                    <div
                      class="d-flex justify-content-end align-items-sm-center flex-column flex-sm-row mt-1"
                    >
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-primary">
                          <i class="ri-zoom-in-line align-bottom"></i>
                          Preview
                        </div>
                      </div>
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-danger">
                          <i class="ri-delete-bin-line align-bottom"></i>
                          Delete
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex justify-content-between my-2 flex-column flex-sm-row border py-2"
                >
                  <div class="col-sm-12 col-md-4 mt-1">
                    <div id="tickets-table_length" class="dataTables_length">
                      <h6 class="d-inline-flex align-items-center mb-0">
                        <i class="ri-money-dollar-circle-fill mr-2"></i>
                        Salary Slips
                      </h6>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-8">
                    <div
                      class="d-flex justify-content-end align-items-sm-center flex-column flex-sm-row mt-1"
                    >
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-primary">
                          <i class="ri-zoom-in-line align-bottom"></i>
                          Preview
                        </div>
                      </div>
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-danger">
                          <i class="ri-delete-bin-line align-bottom"></i>
                          Delete
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                ID Proofs
                <h6>ID Proofs (Aadhar, Pan, UAN)</h6>
                <div
                  class="d-flex justify-content-between my-2 flex-column flex-sm-row border py-2 mb-3"
                >
                  <div class="col-sm-12 col-md-4 mt-1">
                    <div id="tickets-table_length" class="dataTables_length">
                      <h6 class="d-inline-flex align-items-center mb-0">
                        <i class="ri-profile-fill mr-2"></i>
                        Aadhar Card
                      </h6>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-8">
                    <div
                      class="d-flex justify-content-end align-items-sm-center flex-column flex-sm-row mt-1"
                    >
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-primary">
                          <i class="ri-zoom-in-line align-bottom"></i>
                          Preview
                        </div>
                      </div>
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-danger">
                          <i class="ri-delete-bin-line align-bottom"></i>
                          Delete
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex justify-content-between my-2 flex-column flex-sm-row border py-2"
                >
                  <div class="col-sm-12 col-md-4 mt-1">
                    <div id="tickets-table_length" class="dataTables_length">
                      <h6 class="d-inline-flex align-items-center mb-0">
                        <i class="ri-profile-fill mr-2"></i>
                        PAN Card
                      </h6>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-8">
                    <div
                      class="d-flex justify-content-end align-items-sm-center flex-column flex-sm-row mt-1"
                    >
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-primary">
                          <i class="ri-zoom-in-line align-bottom"></i>
                          Preview
                        </div>
                      </div>
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-danger">
                          <i class="ri-delete-bin-line align-bottom"></i>
                          Delete
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex justify-content-between my-2 flex-column flex-sm-row border py-2"
                >
                  <div class="col-sm-12 col-md-4 mt-1">
                    <div id="tickets-table_length" class="dataTables_length">
                      <h6 class="d-inline-flex align-items-center mb-0">
                        <i class="ri-profile-fill mr-2"></i>
                        UAN (Universal Account Number)
                      </h6>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-8">
                    <div
                      class="d-flex justify-content-end align-items-sm-center flex-column flex-sm-row mt-1"
                    >
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-primary">
                          <i class="ri-zoom-in-line align-bottom"></i>
                          Preview
                        </div>
                      </div>
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-danger">
                          <i class="ri-delete-bin-line align-bottom"></i>
                          Delete
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                Bank Account Details
                <h6>Bank Account Details</h6>
                <div
                  class="d-flex justify-content-between my-2 flex-column flex-sm-row border py-2 mb-3"
                >
                  <div class="col-sm-12 col-md-4 mt-1">
                    <div id="tickets-table_length" class="dataTables_length">
                      <h6 class="d-inline-flex align-items-center mb-0">
                        <i class="ri-book-2-fill mr-2"></i>
                        Passbook
                      </h6>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-8">
                    <div
                      class="d-flex justify-content-end align-items-sm-center flex-column flex-sm-row mt-1"
                    >
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-primary">
                          <i class="ri-zoom-in-line align-bottom"></i>
                          Preview
                        </div>
                      </div>
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-danger">
                          <i class="ri-delete-bin-line align-bottom"></i>
                          Delete
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex justify-content-between my-2 flex-column flex-sm-row border py-2"
                >
                  <div class="col-sm-12 col-md-4 mt-1">
                    <div id="tickets-table_length" class="dataTables_length">
                      <h6 class="d-inline-flex align-items-center mb-0">
                        <i class="ri-file-list-fill mr-2"></i>
                        Cheque Leaf
                      </h6>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-8">
                    <div
                      class="d-flex justify-content-end align-items-sm-center flex-column flex-sm-row mt-1"
                    >
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-primary">
                          <i class="ri-zoom-in-line align-bottom"></i>
                          Preview
                        </div>
                      </div>
                      <div class="ml-2">
                        <div style="cursor: pointer;" class="ml-2 text-danger">
                          <i class="ri-delete-bin-line align-bottom"></i>
                          Delete
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab> -->
              <b-tab
                :active="isTabActive == 'Assigned to'"
              >
                <template v-slot:title>
                  <span class="d-inline-block">
                    <i class="ri-user-follow-line align-text-bottom"></i>
                  </span>
                  <span class="d-inline-block ml-2">Permissions</span>
                </template>

                <!-- <b-card v-if="ProjectRoles?.length === 0">
          <b-card-body height="250px">
          <div class="mt-4">
            <div class="row">
              <div class="col-sm-12">
                 <b-skeleton height="10px" width="100px"></b-skeleton>
                 <div class="d-flex">
                  <b-skeleton class="mb-4" type="input" height="10px" width="100px"></b-skeleton>
                 </div>
              </div>
              <div class="col-sm-12 mb-4">
                 <b-skeleton height="10px" width="100px"></b-skeleton>
                 <div class="d-flex mt-2">
                  <b-skeleton class="mr-4" type="button" height="10px" width="100px"></b-skeleton>
                  <b-skeleton class="mr-4" type="button" height="10px" width="100px"></b-skeleton>
                  <b-skeleton type="button" height="10px" width="100px"></b-skeleton>
                 </div>
              </div>
              <div class="col-sm-12">
                 <b-skeleton height="10px" width="100px"></b-skeleton>
                 <div class="d-flex mt-2">
                   <b-skeleton class="mr-4" type="button" height="10px" width="100px"></b-skeleton>
                  <b-skeleton class="mr-4" type="button" height="10px" width="100px"></b-skeleton>
                  <b-skeleton type="button" height="10px" width="100px"></b-skeleton>
                 </div>
              </div>
            </div>
          </div>
        </b-card-body>
         </b-card> -->

                <div class="col-sm-12">
                  <div class="mt-4">
                    <p class="text-muted mb-0">Projects</p>
                    <span v-if="ProjectRoles?.length > 0">
                      <span
                        v-for="role in this.ProjectRoles"
                        :key="role"
                        style="display: inline-block; margin-right: 10px;"
                      >
                        <p class="btn btn-sm btn-light rounded-pill">
                          {{ role }}
                        </p>
                      </span>
                    </span>
                    <span v-else>
                      <p class="font-size-16">N/A</p>
                    </span>
                  </div>
                  <div class="mt-4">
                    <p class="text-muted mb-0">Tasks</p>
                    <span v-if="TaskRoles?.length > 0">
                      <span
                        v-for="role in this.TaskRoles"
                        :key="role"
                        style="display: inline-block; margin-right: 10px;"
                      >
                        <p class="btn btn-sm btn-light rounded-pill">
                          {{ role }}
                        </p>
                      </span>
                    </span>
                    <span v-else>
                      <p class="font-size-16">N/A</p>
                    </span>
                  </div>
                  <div class="mt-4">
                    <p class="text-muted mb-0">Users</p>
                    <span v-if="UserRoles?.length > 0">
                      <span
                        v-for="role in this.UserRoles"
                        :key="role"
                        style="display: inline-block; margin-right: 10px;"
                      >
                        <p class="btn btn-sm btn-light rounded-pill">
                          {{ role }}
                        </p>
                      </span>
                    </span>
                    <span v-else>
                      <p class="font-size-16">N/A</p>
                    </span>
                  </div>
                </div>
              </b-tab>
              <b-tab
                :active="isTabActive == 'Mentioned me'"
              >
                <template v-slot:title>
                  <span class="d-inline-block">
                    <i class="ri-file-list-2-line align-text-bottom"></i>
                  </span>
                  <span class="d-inline-block ml-2">Projects</span>
                </template>


                <!-- <div class="row mt-4" v-if="showUDShimmers">
                <div class="col-12 col-sm-6 col-md-6 col-xl-4" v-for="(user, index ) in [].constructor(3)" :key="index">
                      <b-card>
                           <b-card-body class="grid-container no-gutters">
                               <div class="grid-item">
                                 <b-skeleton type="avatar" width="40px" height="40px"></b-skeleton>
                               </div>
                              <div class="">
                                 <b-skeleton width="60px"></b-skeleton>
                                 <b-skeleton width="140px"></b-skeleton>
                               </div>
                         </b-card-body>
                      </b-card>
                </div>
            </div> -->

                <div class="col-sm-12" v-if="!showUDShimmers">
                  <div class="mt-4">
                    <div class="row">
                      <div
                        class="col-sm-4"
                        v-for="user in SpecificUserProjects"
                        :key="user.ProjectId"
                      >
                        <div class="card min-h-110">
                          <div class="card-body">
                            <div class="grid-container text-truncate">
                              <div class="grid-item">
                                <!-- <img src="../../../assets/images/authentication-bg.jpg" class="rounded-0 card-img"> -->
                                <div class="project-logo">
                                  {{ user.ProjectName.charAt(0) }}
                                </div>
                              </div>
                              <div class="grid-item">
                                <h4
                                  class="card-title font-weight-bold text-truncate"
                                  :title="user.ProjectName"
                                >
                                  {{ user.ProjectName }}
                                </h4>
                                <p
                                  class="text-muted truncate-overflow mb-0"
                                  :title="user.ProjectDescription"
                                >
                                  {{ user.ProjectDescription || "-" }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </b-tab>
              <b-tab
                :active="isTabActive == 'OverDue'"
                @click="GetTaskListToggle(iSOverDue)"
              >
                <!-- <template v-slot:title>
                  <span class="d-inline-block">
                    <i class="ri-refund-2-line align-bottom"></i>
                  </span>
                  <span class="d-inline-block ml-2">Payroll</span>
                </template> -->
                <div class="col-sm-12">
                  <div class="mt-4">
                    <div class="row">
                      <div class="col-sm-4">
                        <p class="text-muted mb-0">Total Leaves</p>
                        <p class="font-size-15 text-dark font-weight-bold">
                          12
                        </p>
                      </div>
                      <div class="col-sm-4">
                        <p class="text-muted mb-0">Sick Leave</p>
                        <p class="font-size-15 text-dark font-weight-bold">6</p>
                      </div>
                      <div class="col-sm-4">
                        <p class="text-muted mb-0">Earned Leave</p>
                        <p class="font-size-15 text-dark font-weight-bold">2</p>
                      </div>
                      <div class="col-sm-4">
                        <p class="text-muted mb-0">Bereavement Leave</p>
                        <p class="font-size-15 text-dark font-weight-bold">6</p>
                      </div>
                      <div class="col-sm-4">
                        <p class="text-muted mb-0">Compensatory Leave</p>
                        <p class="font-size-15 text-dark font-weight-bold">0</p>
                      </div>
                      <div class="col-sm-4">
                        <p class="text-muted mb-0">Optional Leave</p>
                        <p class="font-size-15 text-dark font-weight-bold">1</p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <div style="cursor: pointer;">
                  <div class="d-flex align-items-center">
                    <i class="ri-external-link-line"></i>
                    <span class="d-inline-block ml-2">Export Attendance</span>
                  </div>
                  <div class="d-flex align-items-center">
                    <i class="ri-money-dollar-box-line"></i>
                    <span class="d-inline-block ml-2"
                      >Download CTC Structure</span
                    >
                  </div>
                  <div class="d-flex align-items-center">
                    <i class="ri-money-dollar-box-line"></i>
                    <span class="d-inline-block ml-2" v-b-modal.modal-payslip
                      >Download Payslip</span
                    >
                  </div>
                  <div class="d-flex align-items-center">
                    <i class="ri-calendar-todo-line align-text-bottom"></i>
                    <span class="d-inline-block ml-2">Leave Requests</span>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    </div>
    <!-- Modal1 -->
    <!-- <div class="col-sm-6 col-md-4 col-xl-3">
      <div class="my-4 text-center">
        <b-modal
          no-close-on-backdrop
          id="modal-backdrop"
          ref="myModal"
          title="Upload"
          title-class="font-18"
          hide-footer
        >
          <div class="form-group">
            <label for="">Select Document</label>
            <select class="form-control">
              <option disabled selected>Select</option>
              <optgroup label="Educational Documents">
                <option>10th Certificate</option>
                <option>12th Certificate</option>
                <option>Graduation Certificate and Mark Sheets</option>
                <option>Post-Graduation Certificate and Mark Sheets</option>
                <option>Other Qualification Certificates</option>
              </optgroup>
              <optgroup label="Work Experience Documents">
                <option>Experience Certificates</option>
                <option>Relieving Letters</option>
                <option>Salary Slips</option>
              </optgroup>
              <optgroup label="ID Proofs">
                <option>Aadhar Card</option>
                <option>PAN Card</option>
                <option>UAN (Universal Account Number)</option>
              </optgroup>
              <optgroup label="Bank Account Details">
                <option>Passbook</option>
                <option>Cheque Leaf</option>
              </optgroup>
            </select>
          </div>
                <div class="form-group">
                    <label for="">Select File</label>
                     <input type="file"  class="form-control" />
                </div>
          <div class="pt-4 text-right">
            <button type="button" class="btn btn-outline-danger mr-2" @click="closemodel">
              <i class="ri-close-line align-middle"></i> Cancel
            </button>
            <button type="button" class="btn btn-primary" @click="assignNewReportingManager()"
              :disabled="!NewRepotingManager">
            <i class="ri-upload-line align-bottom"></i> Upload
            </button>
          </div>
        </b-modal>
      </div>
    </div> -->
    <!-- Modal1 -->
    <!-- Modal -->
    <!-- <div class="col-sm-6 col-md-4 col-xl-3">
      <div class="my-4 text-center">
        <b-modal  no-close-on-backdrop hide-footer id="modal-payslip"  ref="myModal" title="Download Payslip">
          <div class="form-group">
            <label for="">Select Year</label>
            <date-picker v-model="demoYear" type="year" lang="en" confirm placeholder="Select Year"></date-picker>
          </div>
          <div class="form-group">
            <label for="">Select Month</label>
            <date-picker v-model="demoMonth" type="month" lang="en" confirm placeholder="Select Month"></date-picker>
          </div>
          <div>
            <b-form-checkbox class="mb-2" v-model="checked" switch>
              <label class="mb-0">Consolidated Payslip</label>
            </b-form-checkbox>
          </div>
          <div class="card-footer text-right">
            <button type="button" class="btn btn-outline-danger mr-2">
              <i class="ri-close-line align-middle"></i> Cancel
            </button>
            <button type="button" class="btn btn-primary">
            <i class="ri-arrow-down-line align-bottom"></i> Download
            </button>
          </div>
        </b-modal>
      </div> 
    </div> -->
    <div class="col-sm-6 col-md-4 col-xl-3">
      <div class="my-4 text-center">
        <b-modal
          no-close-on-backdrop
          id="modal-backdrop"
          ref="myModal"
          title=" Reassign"
          title-class="font-18"
          hide-footer
        >
          <div>
            <p>
              Deleted user can be reassigned to the below reporting manager.
            </p>
            <multiselect
              v-model="NewRepotingManager"
              :options="listusers"
              label="Fullname"
              track-by="UserGuid"
            >
            </multiselect>
          </div>
          <div class="pt-4 text-right">
            <button
              type="button"
              class="btn btn-outline-primary mr-2"
              @click="closemodel"
            >
              <i class="ri-close-line align-middle"></i> Cancel
            </button>
            <button
              type="button"
              class="btn btn-outline-danger"
              @click="assignNewReportingManager()"
              :disabled="!NewRepotingManager"
            >
              <i class="ri-delete-bin-line align-middle"></i> Reassign & Delete
            </button>
          </div>
        </b-modal>
      </div>
    </div>
    <!-- Modal -->
  </Layout>
</template>

<style lang="scss" scoped>
.profilepic-upload {
  position: relative;
  margin: auto;
}

.profile-pic {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  -o-object-fit: contain;
  object-fit: contain;
  border: 2px solid #00435d;
  background-color: #00435d;
}

.ytm-file-input {
  position: absolute;
  border-radius: 50% !important;
  left: 75px;
  top: 50px;
  width: 40px;
  height: 40px;
  z-index: 1;
}

.ytm-file-input input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
}

.minheight-460 {
  min-height: 460px;
}
.min-h-110 {
  min-height: 110px;
}
.grid-container {
  display: grid;
  grid-template-columns: 55px auto;
  row-gap: 10px;
  column-gap: 10px;
}

.emp-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.user-permission {
  display: grid;
  grid-template-columns: 1fr;
}

.grid-container {
  display: grid;
  grid-template-columns: 55px auto;
  row-gap: 10px;
  column-gap: 10px;
}

.project-logo {
  background: #00435d;
  color: #fff;
  text-align: center;
  width: 50px;
  height: 50px;
  border-radius: 6px;
  line-height: 50px;
  font-size: 30px;
  font-weight: bold;
}

.truncate-overflow {
  --max-lines: 3;
  position: relative;
  max-height: 42px;
  overflow: hidden;
  padding-right: 0.5rem;

  &::before {
    position: absolute;
    content: "...";
    bottom: 0;
    right: 0;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    width: 1rem;
    height: 1rem;
    background: white;
  }
}

.clickable-text {
  cursor: pointer;
  color: #ff3d60;
}
</style>
