<script>
import Layout from "../../layouts/vertical.vue";
import PageHeader from "@/components/page-header";
import { mapGetters, mapMutations, mapState } from "vuex";
import _ from "lodash";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
  components: { Layout, PageHeader,Multiselect },
  data() {
    return {
      userList: [],
      userType: "",
      title: "Users list",
      items: [
        {
          text: "Dashboard",
          routeName: "Dashboard",
        },
        {
          text: "Users",
          active: true,
        },
      ],
      UserPermisions: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 40, 80, 120,],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      option: "",
      autoClose: "",
      // loading: true,
      showShimmers: true,
      selectedOption: "Order by",
      selecteddepartment: "Department",
      selecteddesignation: "Designation",
      Orderby: "",
      Sort: "desc",
      sortclickcount: 0,
      orderbylist: ["Name", "Email", "Designation"],
      designationlist: [],
      departmentlist : [],
      HeirachyUsers: [],
      isDeleted: false,
      downloading: false,
      departments : [],
      Departmentdata : [],
      designations : [],
      Designationdata : [],
      listusers :[],
      Specificuser : '',
      NewRepotingManager: null,
      childUsers : []
    };
  },

  // watch: {
  //   selectedRoute() {
  //     this.$router.push({ path: "/users-list" });
  //   },
  // },
  computed: {
    ...mapState(["getUserguid", "getUserId", "getPermissions"]),
    ...mapGetters("auth", ["getProfileDetails", "getAuthenticatedUser"]),
    ...mapGetters("userservice", [
      "getUserHierarchy",
      "getAllUsers",
      "getDeleteUser", "getUserDetailsById", "getUserDefaults" , "getDesignationAndDepartment" , "getActiveUser"
    ]),
          /**
     * Total no. of records
     */
    rows() {
      return this.userList[0]?.TotalCount;
    },
    //Check and filters the permissions user has.
    filteredUserPermisions() {
      let permissions = this.getPermissions?.UserRoles
      function hasPermission(permission) {
        return permissions?.includes(permission);
      }
      return this.UserPermisions.filter((UserPermisions) => {
        return (
          (UserPermisions.permission === "AddUser" &&
            hasPermission("AddUser")) ||
          (UserPermisions.permission === "EditUser" &&
            hasPermission("EditUser"))
        );
      });
    },
    uniquedesignation() {
      const uniqueIds = new Set();
      return this.getDesignationAndDepartment.filter((task) => {
        if (!uniqueIds.has(task.DesignationId)) {
          uniqueIds.add(task.DesignationId);
          return true;
        }
        return false;
      });
    },
    uniquedepartment() {
      const uniqueIds = new Set();
      return this.getDesignationAndDepartment.filter((task) => {
        if (!uniqueIds.has(task.DepartmentId)) {
          uniqueIds.add(task.DepartmentId);
          return true;
        }
        return false;
      });
    },
    sortedDesignationList() {
      return this.designationlist
        .filter(item => item !== null)
        .slice()
        .sort((a, b) => {
          return a.DesignationName && b.DesignationName
            ? a.DesignationName.localeCompare(b.DesignationName)
            : 0;
        });
    },
    sortedDepartmentlist() {
      return this.departmentlist
        .filter(item => item !== null)
        .slice()
        .sort((a, b) => {
          return a.DepartmentName && b.DepartmentName
            ? a.DepartmentName.localeCompare(b.DepartmentName)
            : 0;
        });
    }
  },

  async mounted() {
    this.getDepartmentAndDesignation()
    this.loadInitData();
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  created() {
    this.initUserPermisions();
  },
  methods: {
    //checks the user has specific permission or not.
    hasPermission(permissionToCheck) {
      return this.filteredUserPermisions.some(
        (permissionObj) => permissionObj.permission === permissionToCheck
      );
    },
    /**
     * search functionality for users
     */
    changesearch: _.debounce(function () {
      this.currentPage = 1;
      this.loadInitData();
    }, 800),
    /**
     * Change page number
     */
    ChangePage() {
      this.loadInitData();
    },
    /**
     * Order by functionality
     * @param {*} value
     */
    ChangeOrderby(value) {
      this.selectedOption = value;
      if (this.Orderby == this.selectedOption) {
        if (this.sortclickcount % 2 == 0) {
          this.Sort = "asc";
          this.sortclickcount = this.sortclickcount + 1;
        } else {
          this.Sort = "desc";
          this.sortclickcount = this.sortclickcount + 1;
        }
      } else {
        this.sortclickcount = 0;
        this.Sort = "asc";
        this.sortclickcount = this.sortclickcount + 1;
        this.Orderby = this.selectedOption;
      }
      this.loadInitData();
    },
    /**
     * Get all users api call
     */
    async loadInitData() {
      // this.loading = true;
      this.showShimmers = true;
      const params = {
        pagenumber: this.currentPage,
        rowcount: this.perPage,
        keyword: this.filter,
        sort: this.Sort,
        orderby: this.Orderby,
        userGuid: this.getUserguid,
        designationID :this.designations,
        departmentID : this.departments
      };
      await this.$store.dispatch("userservice/fetchUsers", params);
      if (this.getAllUsers?.length > 0) {
        this.userList = this.getAllUsers;
      } else {
        //Emptying the userlist when there are no results for searched user.
        this.userList = [];
      }
      // this.loading = false;
      this.showShimmers = false;
    },
    async getDepartmentAndDesignation() {
  const UserGuid = this.getUserguid;
  await this.$store.dispatch("userservice/GetDesignationAndDepartment", UserGuid);
  if (this.getDesignationAndDepartment?.length > 0) {
    this.designationlist = this.uniquedesignation;
    this.departmentlist =this.uniquedepartment
  } else {
    this.designationdepartmentlist = [];
  }
},

    /**
     * redirecting to Add user page
     * @param {*} data
     */
    EditUser(data, user) {
      this.$router.push({
        name: data,
        params: {
          guid: user?.UserGuid || "00000000-0000-0000-0000-000000000000"
        },
      });
    },

    async cancel(user) {
      this.Specificuser=user
     await this.$store.dispatch(
        "userservice/GetUserDetailsByGuid",
        this.Specificuser.UserGuid
      );
      if (this.getUserDetailsById.TeamCount > 0) {
        this.getusers()
        this.NewRepotingManager = null;
        this.$refs["myModal"]?.show();
      } else {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-primary ml-2",
          },
          buttonsStyling: false,
        });
        const text = `You want to  ${ user.IsActive ? 'deactivate' : 'activate'} <b style="color: black;">${this.Specificuser.FirstName} ${this.Specificuser.LastName}</b>?`;
        await swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            html: text,
            // icon: "warning",
            confirmButtonText: "Yes",
            cancelButtonText: "No, cancel",
            position: "center",
            showCancelButton: true,
            allowOutsideClick: false,
            imageUrl: "/images/emailexpired_icon.png",
          imageHeight: 50, 
          imageWidth: 50,
          })
          .then((result) => {
            if (result.value) {
              this.assignNewReportingManager();
            }
          });
      }
    },

    async getusers() {
      let users = this.getUserHierarchy;
      this.childUsers = users
        ?.filter((item) => item.ReportingId === this.Specificuser.Id)
        .map((item) => ({
          ...item,
        }));
      let restictedusers = [];
      this.childUsers?.forEach((element) => {
        restictedusers.push(element.UserId);
      });
      restictedusers.push(this.Specificuser.Id);
      this.listusers = this.getUserHierarchy?.filter((user) => !restictedusers.includes(user.UserId))
        .map((user) => ({
          ...user,
          Fullname: user.FirstName + " " + user.LastName,
        }));
    },
    async assignNewReportingManager() {
      this.$refs["myModal"]?.hide();
      this.ActiveUser(this.Specificuser);
    },
    ...mapMutations(["setUpdateHierarchy"]),
    async asignNewRepotingManager() {
      let restictedusers = [];
      this.childUsers.forEach((element) => {
        restictedusers.push({ userGuid: element.UserGuid });
      });
      if (this.getUserDetailsById.TeamCount > 0) {
        const params = {
          repotingManagerGuid: this.NewRepotingManager?.UserGuid,
          lstUsers: [...restictedusers],
          UserGuid: this.Specificuser.UserGuid,
        };
        await this.$store.dispatch("userservice/UpdateRepotingManager", params);
        this.setUpdateHierarchy(this.Specificuser);
      }
    },
    /**
     * Getting User tasks
     * @param {*} id
     */
    ...mapMutations(["setTaskuser", "setAssignedToUserId",'setNew']),

    async getUserViewTasks(user) {
      this.setNew(null)
      const parms = {
        UserId: user.UserGuid
      }
      await this.$store.dispatch("userservice/GetUserHierarchy", parms);
      this.HeirachyUsers = this.getUserHierarchy
        ? this.getUserHierarchy?.map((x) => x.UserId)
        : null;
      // this.loading = true;
      this.showShimmers = true;
      let name = user.FirstName + " " + user.LastName;
      this.setTaskuser(name);
      this.setAssignedToUserId(user.Id);
      let body = {};
      body.assignedByIdValues = this.HeirachyUsers;
      body.assignedToIdValues = this.HeirachyUsers;
      body.isDeleted = this.isDeleted;
      body.CommentedUserId = Number(user.Id);
      let param = {
        "AssignedTo": user.Id
      }
      await this.$store.dispatch("tasks/GetAllViewTasksAssigned", param);
      await this.$router.push({ name: "UserView" });
    },

    ...mapMutations(["setAlertNotification"]),
       async ActiveUser(user) {
      this.setNew(null)
      const parms = {
        UserGuid: user.UserGuid,
        IsActive : user.IsActive == true || null?false : true
      }
    await this.$store.dispatch("userservice/UpdateActiveUser", parms);
    const message = {
        variant:  'success',
        alertTitle: 'Updated',
        alertDecription: this.getActiveUser.Response
      };
      this.setAlertNotification(message);
      await this.asignNewRepotingManager();
      await this.loadInitData()
    },

    //Ueser permissios data
    initUserPermisions() {
      this.UserPermisions = [
        {
          key: "adduser",
          permission: "AddUser",
        },
        {
          key: "edituser",
          permission: "EditUser",
        },
      ];
    },
    /**
     * hide mondal on close
     */
    // eslint-disable-next-line no-unused-vars
    // hideModal() {
    //   this.$refs['myModal'].hide()
    // },
    // addedituser(type) {
    //   this.userType = type;
    // },
    async downloadRecords() {
      const params = {
        UserId: this.getUserDetailsById?.UserGuid
      }
      await this.$store.dispatch(
        "userservice/GetUserHierarchy", params
      );
      this.downloading = true;
      const selectedRecords = this?.getUserHierarchy
      const dataToDownload = selectedRecords.map((record) => ({
        "First name": record.FirstName || "",
        "Last name": record.LastName || "",
        "Designation": record.DesignationName || "",
        "Email": record.Email || "",
        "Department name": record.DepartmentName || "",
        "Reporting manager": record.ReportingTo || "",
      }));

      const headers = Object.keys(dataToDownload[0]);
      const headerRow = headers.join(",");
      const dataRows = dataToDownload.map((record) =>
        headers.map((header) => record[header]).join(",")
      );

      const csvData = [headerRow, ...dataRows].join("\n");
      const blob = new Blob(["\ufeff" + csvData], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, "User_list.csv");
      } else {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "User_list.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      this.downloading = false;
    },
    async Changefilter(option, type) {
         if(type=='Department') {
          const index = this.Departmentdata.indexOf(option);
          if (index !== -1) {
            this.departments.splice(index, 1);
            this.Departmentdata.splice(index, 1);
          } else {
            this.Departmentdata.push(option)
          }
          if (this.Departmentdata.length) {
            this.departments=this.Departmentdata.map(x=>x.DepartmentId);
            this.selecteddepartment =
              this.departments.length > 1
                ? `${this.Departmentdata[0].DepartmentName} (+${this.departments.length - 1})`
                : `${this.Departmentdata[0].DepartmentName}`;
          } else {
            this.selecteddepartment = "Department";
          }
          await this.loadInitData()
        }
        else if (type=='Designation'){
          const index = this.Designationdata.indexOf(option);
          if (index !== -1) {
            this.designations.splice(index, 1);
            this.Designationdata.splice(index, 1);
          } else {
            this.Designationdata.push(option)
          }
          if (this.Designationdata.length) {
            this.designations=this.Designationdata.map(x=>x.DesignationId);
            this.selecteddesignation =
              this.designations.length > 1
                ? `${this.Designationdata[0].DesignationName} (+${this.designations.length - 1})`
                : `${this.Designationdata[0].DesignationName}`;
          } else {
            this.selecteddesignation = "Designation";
          }
          await this.loadInitData()
      } 
      else if (type == 'Clear') {
        if(this.departments.length>0){
          this.selecteddepartment = "Department"
          this.departments =[] 
          this.Departmentdata=[]
        }
        else{
          this.selecteddesignation = "Designation";
          this.designations=[]
          this.Designationdata=[]
        }
        this.loadInitData();
      }
    },
    /**
     * Display label AssignedTo filer
     * @param {*} name
     */
     displayLabel(name) {
      // truncated version
      return name?.length <= 8 ? name : name?.slice(0, 15) + "...";
    },
    closemodel() {
      this.$refs["myModal"]?.hide();
    },



  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- <div v-if="loading">
      <PreLoader></PreLoader>
    </div> -->
    
          <div v-if="showShimmers">
            <div  class="d-flex justify-content-between flex-column flex-sm-row align-items-center mb-2">
            <div>
              <b-skeleton class="mr-2 mb-2" type="input" width="150px" height="30px"></b-skeleton>
            </div>
               <div class="d-flex justify-content-end flex-column flex-sm-row">
                <b-skeleton class="mr-2 mb-2" type="input" width="150px" height="30px"></b-skeleton>
                   <b-skeleton class="mr-2 mb-2" type="input" width="150px" height="30px"></b-skeleton>
                   <b-skeleton class="mr-2 mb-2" type="input" width="150px" height="30px"></b-skeleton>
                   <b-skeleton type="button"  height="30px"></b-skeleton>
                   <b-skeleton class="ml-0 ml-sm-2 mt-2 mt-sm-0" type="button"  height="30px"></b-skeleton>
                </div>
              </div>
                <div class="row">
              <div class="col-12 col-sm-6 col-md-6 col-xl-3" v-for="(user, index ) in [].constructor(4)" :key="index">
              <b-card no-body img-left>
                <b-card-body class="d-flex">
                  <div>
                    <b-skeleton type="avatar" width="40px" height="40px"></b-skeleton>
                  </div>
                  <div class="ml-3 align-self-end">
                    <b-skeleton width="140px"></b-skeleton>
                    <b-skeleton width="60px"></b-skeleton>
                  </div>
                </b-card-body>
              </b-card>
            </div>
          </div>
          </div>
          <div v-else >
    <b-alert show variant="danger" v-if="this.getAllUsers == null && !showShimmers">
      <i class="ri-close-circle-line mr-2 align-middle"></i>something went wrong
    </b-alert>
    <div>
      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-between ytmFilterRow my-2 flex-column flex-lg-row px-0">
            <div id="tickets-table_filter" class="dataTables_filter text-md-right mb-2">
                <label class="d-inline-flex align-items-center position-relative mb-0" v-if="userList.length || filter ">
                  <b-form-input size="sm" v-model="filter" type="search" placeholder="Search..." @input="changesearch" style="width: 160px;"
                    class="form-control ml-0  searchInput"></b-form-input>
                  <i class="ri-search-line search-icon"></i>
                </label>
              </div>
            <div class="d-flex flex-column flex-sm-row gap-1 flex-wrap">
              <div class="d-flex text-md-right">
                <div class="ml-sm-2 orderby-dropdown orderby w-180 mb-2 mb-md-0" v-if="userList.length || filter">
                  <b-dropdown size="sm" v-model="selecteddesignation" variant="outline-light" class="w-100" ref="designationdropdown">
                  <template v-slot:button-content>
                  <div class="text-truncate mw-150">
                    {{ selecteddesignation }}
                    <i class="mdi mdi-chevron-down"></i>
                  </div>
                </template>
                      <div class="designationdropdown" style="max-height: 320px; overflow: auto;">
                   <b-dropdown-item-button v-for="(option, index) in sortedDesignationList" :key="index" class="text-truncate" @click="$refs.designationdropdown.show(true)">
                 <div class="custom-control custom-checkbox">
                <input type="checkbox" :id="option.DesignationName" @click="Changefilter(option, 'Designation')" :checked="designations?.includes(option.DesignationId)" class="custom-control-input" />
                 <label class="custom-control-label ml-1 mb-0" :for="option.DesignationName">{{ displayLabel(option.DesignationName) }}</label>
               </div>
             </b-dropdown-item-button>
         </div>
          <button class="btn clear-btn text-primary font-weight-bold" @click="Changefilter(option, 'Clear')" v-if="selecteddesignation !== 'Designation'">
          x Clear
        </button>
         </b-dropdown>
                </div>
              </div>
              <div class="d-flex text-md-right">
                <!-- <label class="col-form-label flex-shrink-0 pt-1">Sort</label> -->
                <div class="ml-sm-2 orderby-dropdown orderby w-150 mb-2 mb-sm-0" v-if="userList.length || filter">
                  <b-dropdown size="sm" v-model="selecteddepartment" variant="d-flex outline-light" class="w-100" ref="departmentdropdown">
                    <template v-slot:button-content>
                      <span class="text-truncate dropdown-value">{{ selecteddepartment }}</span>
                      <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <div class="departmentdropdown" style="max-height: 320px; overflow: auto;">
                      <b-dropdown-item-button v-for="(option , index) in sortedDepartmentlist" :key="index"  
                       @click="$refs.departmentdropdown.show(true)">
                       <div class="custom-control custom-checkbox">
                                <input type="checkbox" :id="option.DepartmentName" @click="Changefilter(option,'Department')"
                                  :checked="departments?.includes(option.DepartmentId)" class="custom-control-input" />
                                <label class="custom-control-label ml-1 mb-0" :for="option.DepartmentName">{{
                                displayLabel(option.DepartmentName)}}</label>
                        </div>
                      </b-dropdown-item-button>
                    </div>                    
                      <button class="btn clear-btn text-primary font-weight-bold " @click="Changefilter(option,'Clear')" v-if="selecteddepartment!='Department'">
                          x Clear
                      </button>
                  </b-dropdown>
                </div>
              </div>
              <div class="d-flex text-md-right">
                <!-- <label class="col-form-label flex-shrink-0 pt-1">Sort</label> -->
                <div class="ml-sm-2 orderby-dropdown orderby w-100 mb-2 mb-sm-0" v-if="userList.length || filter">
                  <b-dropdown size="sm" v-model="selectedOption" variant="outline-light" class="w-100">
                    <template v-slot:button-content>
                      {{ selectedOption }}
                      <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item-button v-for="option of orderbylist" :key="option" @click="ChangeOrderby(option)">{{
                      option }}</b-dropdown-item-button>
                  </b-dropdown>
                </div>
              </div>
              <div class="ml-sm-2 flex-shrink-0 mt-md-0" v-if="userList.length || filter">
                <button class="btn btn-outline-primary btn-sm w-100"  @click="downloadRecords()" variant="primary">
                  <i class="ri-arrow-up-line align-middle"></i> Export all
                  <div class="spinner-border" v-if="downloading">
                          <span class="sr-only"></span>
                        </div>
                </button>
              </div>
              <div class="ml-sm-2 flex-shrink-0 mt-md-0 mobile-btn" v-if="hasPermission('AddUser')">
                <button class="btn btn-primary btn-sm w-100" variant="primary" @click="EditUser('AddUser')">
                  <i class="mdi mdi-plus"></i> Add user
                </button>
              </div>
            </div>
          </div>
          <hr v-if="userList.length" />
          <!-- No data found message-->
          <div class="nodata-ui card" v-if="!userList.length && !showShimmers">
            <div class="fullscreen-height d-flex align-items-center justify-content-center">
              <div class="py-5 text-muted">
                <div class="d-flex flex-column justify-content-center gap-1 align-items-center fs-4">
                  <i class="ri-information-line font-size-36 text-muted"></i>
                  <h5 class="text-muted">No data found!</h5>
                </div>
              </div>
            </div>
          </div>
          <!-- No data found message end-->

          
          <div class="row CustomePagination">            
            <div class="col-12 col-sm-6 col-md-6 col-xl-3" v-for="user in userList" :key="user.id">
              <div class="card align-self-start">
                <!-- User List Code -->
                <div class="card-body" style="cursor: pointer;">
                  <b-dropdown right class="float-right dots-dropdown" variant="white">
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-vertical m-0 text-muted font-size-20"></i>
                    </template>
                    <!-- <b-dropdown-item><i class="ri-mail-fill align-bottom mr-2 icon-color"></i>Send Email</b-dropdown-item>
                    <b-dropdown-item><i class="ri-cellphone-fill align-bottom mr-2 icon-color"></i>Contact</b-dropdown-item> -->
                    <b-dropdown-item @click="getUserViewTasks(user)"><i
                        class="ri-eye-fill align-bottom mr-2 icon-color"></i>View task</b-dropdown-item>
                                            <b-dropdown-item @click="cancel(user)"><i
                        class="ri-close-fill align-bottom mr-2 icon-color"></i>{{ user.IsActive ? 'Deactivate user' : 'Activate user' }}</b-dropdown-item>
                    <b-dropdown-item v-if="hasPermission('EditUser') && user.IsActive" @click="EditUser('AddUser', user)"><i
                        class="ri-pencil-fill align-bottom mr-2 icon-color"></i>Edit user</b-dropdown-item>
                    <!-- <b-dropdown-item variant="danger"><i class="ri-delete-bin-fill align-bottom mr-2 icon-color"></i>Delete User</b-dropdown-item> -->
                  </b-dropdown>
                  <div class="d-flex" @click="EditUser('User', user)">
                    <div class="user-avatar mr-2">
                      <img class="avatar-sm align-self-start rounded-circle" :src="user.image" alt=""
                        style="width: 40px; height: 40px; object-fit: cover;" v-if="user.image" />
                      <img class="avatar-sm align-self-start rounded-circle" v-else src="@/assets/images/users/user.png"
                        alt="" style="width: 40px; height: 40px;" />
                    </div>
                    <div class="user-details overflow-hidden w-100">
                      <h5 class="mt-0 font-size-16 text-truncate mb-0 mw-80">
                        <span class="user-name" :title="user.FirstName + ' ' + user.LastName">{{ user.FirstName }} {{ user.LastName
                        }}</span>
                      </h5>
                      <p class="mb-0 text-truncate">
                        <span :title="user.DesignationName">
                          <i class="ri-briefcase-3-line align-bottom"></i>
                          {{ user.DesignationName }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <!-- User List Code -->
              </div>
            </div>            
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="d-flex justify-content-between w-100 flex-wrap">
              <div class="d-flex"  v-if="rows >= 20">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show
                    <select size="sm" v-model="perPage" class="form-control align-self-start selction-padding"
                      @change="ChangePage">
                      <option v-for="option of pageOptions" :key="option">{{
                        option
                      }}</option>
                    </select>
                  </label>
                </div>
              </div>
              <div class="paging_simple_numbers float-right" v-if="rows > perPage ">
                <ul class="pagination pagination-rounded ms-auto">
                  <!-- pagination -->
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    @input="ChangePage()"></b-pagination>
                </ul>
              </div>
            </div>
          </div>            
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6 col-md-4 col-xl-3">
      <div class="my-4 text-center">
        <b-modal
          no-close-on-backdrop
          id="modal-backdrop"
          ref="myModal"
          title=" Reassign"
          title-class="font-18"
          hide-footer
        >
          <div>
            <p>
              Deactivate user can be reassigned to the below reporting manager.
            </p>
            <multiselect
              v-model="NewRepotingManager"
              :options="listusers"
              label="Fullname"
              track-by="UserGuid"
            >
            </multiselect>
          </div>
          <div class="pt-4 text-right">
            <button
              type="button"
              class="btn btn-outline-primary mr-2"
              @click="closemodel"
            >
              <i class="ri-close-line align-middle"></i> Cancel
            </button>
            <button
              type="button"
              class="btn btn-outline-danger"
              @click="assignNewReportingManager()"
              :disabled="!NewRepotingManager"
            >
              <i class="ri-delete-bin-line align-middle"></i> Reassign & Deactive
            </button>
          </div>
        </b-modal>
      </div>
    </div>
  </Layout>
</template>

<style scoped lang="scss">
.CustomePagination{
  height: calc(100vh - 400px);
  overflow: auto;
  align-content: flex-start;
}
.mw-200 {
  width: 200px;
}

.card-footer {
  background-color: #e2eaed;
  border-top: 1px solid #e9e9e9;
}

.fullscreen-height {
  height: 340px;
}

// .outline-light {
//   border: 1px solid #ced4da;
//   border-radius: 0.25rem;
// }
.w148px {
  max-width: 148px;
  width: 148px;

  .mdi-chevron-down::before {
    position: absolute;
    right: 6px;
  }
}

.search-input {
  border-bottom: 1px solid #ddd !important;
  border: none;
  border-radius: 0;
}

// .orderby{
//   background-color: #fff;
//   height: 28px;
//   border: none;
//   &:hover{
//     background-color: #fff !important;
//   }
// }

.p-20 {
  padding: 12px 20px
}

.vertical-divider {
  position: absolute;
  border-left: 1px solid #e9e9e9;
  height: 46px;
  left: 50%;
  top: 0;
}

.dots-dropdown {
  position: absolute;
  right: 0;
  top: 4px
}

.icon-color {
  color: #bcc1cc
}

.mw-80 {
  max-width: 80%;
}
.spinner-border {
  width: 1rem;
  /* Adjust the width to your desired size */
  height: 1rem;
  /* Adjust the height to your desired size */
}

.clear-btn {
  position: absolute;
  // bottom: 0;
  background: #fff;
  width: 100%;
  text-align: right;
  left: 0;
  border-top: 1px solid #ddd;
  border-radius: 0;
  z-index: 1;
}


.mobile-btn {
    @media screen and (max-width: 575px){
      margin-top: 0.5rem;
    }

    
}
.w-180{
  width: 180px;
}
.user-details .user-name:hover {
    color: #00435d;
    text-decoration: underline;
  }

  .dropdown-value{
    max-width: 90%;
    display: inline-block;
  }
</style>
