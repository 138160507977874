export const menuItems = [
    {
        key: 'dashboard',
        label: 'Dashboard',
        //permission: null,
        link: '/dashboard',
        icon:"ri-dashboard-line mr-1",
    },
    {
        key: 'tasks',
        label: 'Tasks',
        //permission: 'TaskRoles',
        link: '/tasks-list',
        icon:"ri-pencil-ruler-2-line mr-1"
    },
    {
        key: 'users',
        label: 'Users',
        //permission: 'UserRoles',
        link: '/users-list',
        icon:"ri-user-line mr-1"
    },                
    // {
    //   key: "leaves",
    //   label: "Leaves",
    //   permission: null,
    //   link: "/leave-approval",
    //   icon: "ri-file-list-3-line mr-2 align-bottom"
    // },
    // {
    //   key: "calendar",
    //   label: "Holiday Calendar",
    //   permission: null,
    //   link: "/calender",
    //   icon: "ri-file-list-3-line mr-2 align-bottom"
    // },
    // {
    //   key: 'Reports',
    //   label: 'Reports',
    //   submenu: [
    //     {
    //       key: 'Absence',
    //       label: 'Absence',
    //       permission: null,
    //       link: '/absence',
    //       icon: 'ri-file-list-3-line mr-2 align-bottom',
    //     },
    //     {
    //       key: 'Group',
    //       label: 'Group',
    //       permission: null,
    //       link: '/group',
    //       icon: 'ri-file-list-3-line mr-2 align-bottom',
    //     },
    //     {
    //       key: 'Holiday',
    //       label: 'Holiday',
    //       permission: null,
    //       link: '/holiday',
    //       icon: 'ri-file-list-3-line mr-2 align-bottom',
    //     },
    //     {
    //       key: 'Individual',
    //       label: 'Individual',
    //       permission: null,
    //       link: '/Individual',
    //       icon: 'ri-file-list-3-line mr-2 align-bottom',
    //     },
    //     {
    //       key: 'Late Early',
    //       label: 'Late Early',
    //       permission: null,
    //       link: '/late-early',
    //       icon: 'ri-file-list-3-line mr-2 align-bottom',
    //     },
    //   ],
    // },                
    {
      key: "management tree",
      label: "Organization",
      //permission: null,
      link: "/management-tree",
      icon: "ri-team-line mr-1"
    },
    {
      key: "projects",
      label: "Projects",
      //permission: null,
      link: "/projects-list",
      icon: "ri-todo-line mr-1"
    },
    {
      key: 'attendance',
      label: 'Attendance',
      permission: null,
      link: '/attendance-record',
      icon: 'ri-file-history-line mr-1',
    },
    // {
    //   key: 'letter-requests',
    //   label: 'LetterRequests',
    //   //permission: null,
    //   link: '/letter-requests',
    //   icon:"ri-file-list-line"
    // },
    // {
    //   key: 'performance-matrix',
    //   label: 'PreformanceMatrix',
    //   //permission: null,
    //   link: '/performance-matrix',
    //   icon:"ri-file-list-line"
    // },
    {
      key: "reports",
      label: "Reports",
      permission: null,
      link: "/Individual",
      icon: "ri-pie-chart-line mr-1"
    },
    {
      label: 'HR Management',
      icon: 'ri-file-list-line',
      subItems: [
          {
              label: 'Letter Requests',
              link: '/letter-requests'
          },
          {
              label: 'Performance Matrix',
              link: '/self-appraisal',

              subItems: [
                {
                    label: 'Self Appraisal',
                    link: '/self-appraisal'
                },
                {
                    label: 'Manager Appraisal',
                    link: '/manager-appraisal'
                },
            ]
          },
          {
            label: 'Investment Declarations',
            link: '/investment-declaration'
          },
          {
            label: 'IT Proof Submission',
            link: '/it-proof'
          },
          
      ]
  },
   
    //We can add more items here according to the roles we have.
];