// initial state
const initialState = () => {
    return {
      errors: [],
      error: null,
    };
  };
  const state = initialState();
  
  const getters = {
    getErrors: (state) => state.errors,
    getError: (state) => state.error,
  };
  
  const mutations = {
    setErrors: (state, errors) => {
      state.errors = errors;
    },
    setError: (state, error) => {
      state.error = error;
    },
    clear: (state) => {
      state.errors = [];
      state.error = null;
    },
  
    reset: (state) => {
      const initial = initialState();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    },
  };
  const actions = {
    clear: ({ commit }) => {
      commit("clear", null);
    },
    // error response
    errorResponse: async ({ commit }, errorResponse) => {
      let errorData = {};
      if (errorResponse && errorResponse.data) {
        let errorStatusCode = errorResponse.status;
        
        switch (errorStatusCode) {
          case 422:
            var errors = errorResponse.data.errors;
            if (errors && errors.details && errors.details.length) {
              errors.details.forEach((detail) => {
               {
                  if (!errorData[detail.path]) {
                    errorData[detail.path] = detail.message;
                  }
                }  if (!errorData[detail.key]) {
                  errorData[detail.key] = detail.message;
                }
              });
            } else {
              errorData["critical_error"] = errorResponse.data.message || errorResponse.data.error_description;
            }
            break;
          case 401:
          case 403:
            errorData["critical_error"] = errorResponse.data.message || errorResponse.data.error_description;
            break;
  
          default:
            errorData["critical_error"] = errorResponse.data.message || errorResponse.data.error_description;
            break;
        }
      }
      commit("setErrors", errorData);
      if (errorData["critical_error"]) {
        commit("setError", errorData["critical_error"]);
      }
  
      return errorData;
    },
  };
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
  