<script>
import { mapGetters, mapMutations } from 'vuex';
import axios from 'axios';
// import moment from 'moment';
export default {
    data() {
        return {
            isCameraOpen: false,
            isPhotoTaken: false,
            isShotPhoto: false,
            link: '#',
            image: '',
            latitude: '',
            longitude: '',
            imageUrl: '',
            errMessage: '',
            textMessage:'',
            message:'',
            currentPage:1,
            filter: null,
            sortBy: "age",
            findPublicHoliday:null

        }
    },
   async mounted() {
    //    this.init()
       this.status()
    //    await this.getEmployeeData();
    },
    computed: {
        ...mapGetters('auth', ['getUpload', 'getProfileDetails']),
        ...mapGetters("holiday", ["getAllHolidays"]),
        ...mapGetters("attendance", ["getAttendanceState" , "getEmployee" , "getUpdateCompansetoryLeave"]),
    },
    methods: {
        init() {
            // console.log(this.getAttendanceState)
            if (this.getAttendanceState?.message === 'Pending') {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.latitude = position?.coords?.latitude;
                        this.longitude = position?.coords?.longitude;
                    },
                    (error) => {
                        console.error("Error getting location:", error);
                    }
                );
            } else {
                this.latitude = this.getAttendanceState?.attendanceRecords?this.getAttendanceState?.attendanceRecords[0]?.CheckInLocation?.latitude:''
                this.longitude = this.getAttendanceState?.attendanceRecords?this.getAttendanceState?.attendanceRecords[0]?.CheckInLocation?.longitude:''
                this.updateLocationInfo()
            } 
        },
        async status() {
            const params = {
                UserId: this.getProfileDetails.UserId || this.getProfileDetails.Id
            }
            await this.$store.dispatch('attendance/getAttendanceState', params);
                this.init()
        },
        async punchOut() {
            const params = {
                UserId: this.getProfileDetails.UserId || this.getProfileDetails.Id,
                CheckOutLocation: {
                    longitude: this.longitude,
                    latitude: this.latitude
                },
                CheckOut: new Date(),
                CheckIn: null
            }
            await this.$store.dispatch('attendance/savemarkAttendance', params);
            this.status()
        },
        checkPermissions(){
            if (this.latitude !== "" && this.longitude !== "") {
                this.toggleCamera()
            }else{
                this.$refs["centerModal"]?.show();
                this.isCameraOpen = false;
                this.message ='Location permission denied'
                this.textMessage = "Please allow your location for Marking attendance"

            }
        },
        toggleCamera() {
            this.isCameraOpen = true;
            this.createCameraElement();
        },

        createCameraElement() {
            this.isLoading = true;
            const constraints = (window.constraints = {
                audio: false,
                video: true
            });
            navigator.mediaDevices
            .getUserMedia(constraints)
            .then(stream => {
                this.$refs.camera.srcObject = stream;
                this.message = 'Smile Please'
                })
                .catch((err) => {
                    this.message ="No camera device detected"
                    this.textMessage = "It seems like your device does not have an active camera. Please make sure your camera is properly connected or enabled."
                    this.isCameraOpen = err.length
                    this.errMessage = err
                    //alert("May the browser didn't support or there is some errors.");
                });
        },
        stopCameraStream() {
            let tracks = this.$refs.camera.srcObject.getTracks();
            tracks.forEach(track => {
                track.stop();
            })
            if (this.latitude != '' && this.longitude != '') {
                this.updateLocationInfo()
            }
        },

        ...mapMutations(['setLocation']),
        updateLocationInfo() {
            if (this.latitude !== "" && this.longitude !== "") {
                const apiKey = 'AIzaSyBUGP1VB9_djVu3uNuFgFRm7E508gGcUXo';
                const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.latitude},${this.longitude}&key=${apiKey}`;
                axios.get(apiUrl)
                    .then(response => {
                        if (response.data.status === 'OK') {
                            const formattedAddress = response.data.results[0].formatted_address;
                            this.fullLocation = formattedAddress;
                            this.setLocation(this.fullLocation);
                        } else {
                            console.error("No detailed address information found in the response.");
                            this.setLocation('');
                        }
                    })
                    .catch(error => {
                        console.error("Error fetching data from Google Maps API:", error);
                        this.setLocation('');
                    });
            }
        },


        async takePhoto() {
            if (!this.isPhotoTaken) {
                this.isShotPhoto = true;

                const FLASH_TIMEOUT = 50;

                setTimeout(() => {
                    this.isShotPhoto = false;
                }, FLASH_TIMEOUT);
            }

            this.isPhotoTaken = !this.isPhotoTaken;

            const canvas = this.$refs.canvas;
            const context = canvas.getContext('2d');
            context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
            const imageBlob = await new Promise((resolve) => {
                canvas.toBlob((blob) => {
                    resolve(blob);
                }, 'image/jpeg');
            });
            this.image = imageBlob
            setTimeout(() => {
                this.$bvModal.hide('center');
            }, 2000);
            const today = new Date();
            const dateStr = `${String(today.getDate()).padStart(2, '0')}${String(today.getMonth() + 1).padStart(2, '0')}${String(today.getFullYear()).slice(2, 4)}`;
            const params = {
                File: imageBlob,
                FileName: `image${this.getProfileDetails.UserId || this.getProfileDetails.Id}-${dateStr}.jpeg`
            };
            await this.$store.dispatch('auth/fileUpload', params);
            this.ImageUrl = this.getUpload?.result;
            this.stopCameraStream()
            this.markAttendance()
            console.log(this.ImageUrl)
        },
        async markAttendance() {
            const params = {
                UserId: this.getProfileDetails.UserId || this.getProfileDetails.Id,
                UserName: `${this.getProfileDetails?.FirstName} ${this.getProfileDetails?.LastName}`,
                CheckInLocation: {
                    longitude: this.longitude,
                    latitude: this.latitude
                },
                CheckInPic: this.getUpload?.result,
                CheckIn: new Date(),
                CheckOut: null
            }
            await this.$store.dispatch('attendance/savemarkAttendance', params);
            this.status()

            await this.UpdateCompansetoryLeave();
        },

        async UpdateCompansetoryLeave(){
            const params = {
                UserId: this.getProfileDetails.UserId || this.getProfileDetails.Id,
            }
            await this.$store.dispatch('attendance/UpdateCompansetoryLeave' , params)
            console.log(this.getUpdateCompansetoryLeave.ResetCompensatoryLeave)
            this.getEmployeeData()
            await this.addCompansetoryLeave();
        },

        async addCompansetoryLeave(){
            const params = {
                UserId: this.getProfileDetails.UserId|| this.getProfileDetails.Id,
            }
            const employeeData = this.getEmployee?.employee;
            const ExpiarCompensatoryLeave = employeeData?.ExpiarCompensatoryLeave
            const updateCompensatory = this.getUpdateCompansetoryLeave?.ResetCompensatoryLeave;

            if(ExpiarCompensatoryLeave == null ||   updateCompensatory ){
                await this.$store.dispatch('attendance/addCompansetoryLeave' , params);
            }
        },

        async getEmployeeData(){
            await this.$store.dispatch('attendance/getEmployee' , Number(this.getProfileDetails.UserId || this.getProfileDetails.Id));
        },
        closemodel() {
            this.$refs["centerModal"]?.hide();
        },
        // downloadImage() {
        //     const download = document.getElementById("downloadPhoto");
        //     const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
        //         .replace("image/jpeg", "image/octet-stream");
        //     download.setAttribute("href", canvas);
        // }
    }
};
</script>


<template>
    <div class="d-none d-md-none d-lg-block">
        <div class="camera-button" v-if="this.getAttendanceState?.message != 'Done'">
            <b-button variant="success" v-b-modal.center @click="checkPermissions" v-if="this.getAttendanceState?.message == 'Pending'">
                <span class="flex-shrink-0">Mark attendance</span>
            </b-button>
            <b-button variant="success" @click="punchOut" v-if="this.getAttendanceState?.message == 'CheckedIn'">
                <span>Punch out</span>
            </b-button>
        </div>
        <b-modal id="center" hide-footer no-close-on-backdrop ref="centerModal">
            <div>
                <div id="app" class="web-camera-container" v-if="isCameraOpen">
                    <div class="camera-box" :class="{ 'flash': isShotPhoto }" v-if="isCameraOpen">
                        <div class="camera-shutter" :class="{ 'flash': isShotPhoto }"></div>
                        <video v-show="!isPhotoTaken" ref="camera" :width="460" :height="340" autoplay></video>
                        <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="460" :height="340"></canvas>
                    </div>
                    <div v-if="isCameraOpen" class="camera-shoot">
                        <button type="button" class="button" @click="takePhoto">
                            <i class="ri-camera-line font-size-36"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" v-if="!isCameraOpen || (!this.latitude&& !this.longitude)">
                    <div class="col-sm-12">
                        <p>{{textMessage}}</p>
                    </div>
                </div>    
                <div class="text-right mt-4" v-if="!isCameraOpen || (!this.latitude&& !this.longitude)">
                    <b-button class="ml-1 mr-2" type="button" variant="outline-primary" @click="closemodel"> Ok</b-button>
                </div>
            <template #modal-header>
                <div>
                    <h5 class="modal-title">{{ message }}</h5>
                </div>
            </template>
        </b-modal>
        <!-- Locatin enable pop modal end -->
    </div>
</template>

<style scoped lang="scss">
.web-camera-container {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;


    .camera-button {
        margin-bottom: 2rem;
    }

    h3 {
        font-size: 1rem;
    }

    .camera-box {

        .camera-shutter {
            opacity: 0;
            width: 260px;
            height: 100px;
            background-color: #fff;
            position: absolute;

            &.flash {
                opacity: 1;
            }
        }
    }

    .camera-shoot {
        margin: 1rem 0;

        button {
            height: 60px;
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;

            img {
                height: 35px;
                object-fit: cover;
            }
        }
    }

    .camera-loading {
        overflow: hidden;
        height: 100%;
        position: absolute;
        width: 100%;
        min-height: 150px;
        margin: 3rem 0 0 -1.2rem;

        ul {
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: 999999;
            margin: 0;
        }

        .loader-circle {
            display: block;
            height: 14px;
            margin: 0 auto;
            top: 50%;
            left: 100%;
            transform: translateY(-50%);
            transform: translateX(-50%);
            position: absolute;
            width: 100%;
            padding: 0;

            li {
                display: block;
                float: left;
                width: 10px;
                height: 10px;
                line-height: 10px;
                padding: 0;
                position: relative;
                margin: 0 0 0 4px;
                background: #999;
                animation: preload 1s infinite;
                top: -50%;
                border-radius: 100%;

                &:nth-child(2) {
                    animation-delay: .2s;
                }

                &:nth-child(3) {
                    animation-delay: .4s;
                }
            }
        }
    }

    @keyframes preload {
        0% {
            opacity: 1
        }

        50% {
            opacity: .4
        }

        100% {
            opacity: 1
        }
    }
}

.camera-button {
    button {
        display: flex;
        align-items: center;
        height: 36px;
    }
}
</style>