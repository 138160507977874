<script>
import Layout from "../../layouts/vertical.vue";
import PageHeader from "@/components/page-header";
// import PreLoader from "@/components/pre-loader";
import router from "../../../router/index";
import Multiselect from "vue-multiselect";
import { countrycode } from "../../../countrycode";
import { mapGetters, mapState, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
    name: 'AddUser',
    components: { Layout, PageHeader, Multiselect },

    data() {
        return {
            countrycode: countrycode,
            title: " ",
            Profile: {
                UserGuid: null,
                FirstName: "",
                LastName: "",
                Email: "",
                CountryCode: "",
                PhoneNumber: "",
                Gender : "",
                Maritalstatus : "",
                EmployeeCode: "",
                DepartmentGuid: "",
                DesignationGuid: "",
                ReportingGuid: "",
                RoleGuid: "",
                LstPermission: [],
                selectedApprovalTypes: {},
                SelectedProjects: [],
                ReportingId:null,
                UserId:null,
                ReportingManger:null,
            },
            AddDesignation: {
                CustomDesignation: ""
            },
            firstNameBlured: false,
            lastNameBlured: false,
            emailBlured: false,
            countrycodeBlured: false,
            phoneNumberBlured: false,
            employeeIdBlured: false,
            departmentBlured: false,
            designationBlured: false,
            reportingManagerBlured: false,
            roleBlured: false,
            Projectblured: false,
            userNameBlured: false,
            passwordBlured: false,
            confirmPasswordBlured: false,
            samePassword: true,
            items: [
                {
                    text: "Users",
                    routeName: "UsersList",
                },
                {
                    text: " ",
                    active: true,
                },
            ],
            designation: "",
            departmentOption: "",
            department: "",
            View:"",
            departmentOptions: [],
            designationOption: "",
            designationOptions: [],
            reportingManager: null,
            option: "",
            ReportingManagers: [
            ],
            UserDefaultData: {},
            Email: "",
            selectedApprovalTypes: {},
            Permissions: [],
            ApprovalType: [],
            PhonehasCharacters: false,
            employeeIdhasCharacters: false,
            // loading: false,
            showShimmers: false,
            // isloadingDesignation :false,
            emailNotValid: false,
            isPasswordValid: true,
            LastNamehasSpecialCharacters: false,
            FirstNamehasSpecialCharacters: false,
            Role: "",
            Roles: [
            ],
            SaveObject: {},
            submit: false,
            ListPermissions: {},
            ListProjects: {},
            isEditMode: false,
            designationvalue: null,
            repotingvalue: null,
            departmentGuidvalue: null,
            ProjectGuidvalue: null,
            error: "",
            DuplicateCheck: false,
            SpinnerCheck: false,
            isLowercaseValid: false,
            isUppercaseValid: false,
            isNumberValid: false,
            isSpecialCharacterValid: false,
            showpopover: false,
            showPassword: false,
            showconfirmPassword: false,
            formChanged: true,
            count: 0,
            reportingCount: 0,
            designationCount: 0,
            departmentCount: 0,
            permissionCount: 0,
            ProjectsCount: 0,
            UserPermisions: [],
            Projects: [],
            isDesignation: false,
            designationExists: '',
            SelectReportingGuid: null,
            ModalTitle:'',
            fieldTitle:'',
            Placeholder:'',
            showAllProjects: false,
            RoleGuidvalue : null,
            rolecount : 0,
            rolename : '',
            userProjects:[],
        }
    },
    validations: {
        AddDesignation: {
            CustomDesignation: { required }
        },
    },
    computed: {
        ...mapState(["getUserguid"]),
        ...mapGetters("auth", ["getAuthenticatedUser", 'getProfileDetails']),
        ...mapGetters('userservice', ['getUserDefaults', 'getUserHierarchy', 'getUserDetails', 'getUserDetailsById', 'getSignupUser', 'getEmailCheck', 'DeleteUser', 'getDesignationValue']),
        ...mapGetters("errors", ["getError", "getErrors"]),
        filteredUserPermisions() {
            const UserRoles = this.getAuthenticatedUser || {};
            const userRoles = UserRoles.UserRoles
                ? UserRoles.UserRoles.split(",")
                : [];
            function hasPermission(permission) {
                return userRoles.includes(permission);
            }
            return this.UserPermisions.filter((UserPermisions) => {
                return (
                    (UserPermisions.permission === "DeleteUser" &&
                        hasPermission("DeleteUser"))
                );
            });
        },
        selectedGuids() {
            const allSelectedGuids = [];
            for (const rightType in this.selectedApprovalTypes) {
                const selectedApprovalTypesForTypeFor = this.selectedApprovalTypes[rightType];
                if (selectedApprovalTypesForTypeFor && selectedApprovalTypesForTypeFor?.length > 0) {
                    selectedApprovalTypesForTypeFor.forEach((type) => {
                        allSelectedGuids.push(type.Guid);
                    });
                }
            }
            return allSelectedGuids;
        },
        selectedprojectsGuids() {
            const allProjectGuids = [];
            const selectedProjects = this.Profile.SelectedProjects;
            if (selectedProjects && selectedProjects?.length > 0) {
                selectedProjects.forEach((type) => {
                    allProjectGuids.push(type.ProjectId);
                });
            }
            return allProjectGuids;
        },
        isDropdownDisabled() {
            return !this.Profile?.ReportingGuid;
        },
    },

    async mounted() {
        this.setTitleBasedOnGuid()
        this.editUser()   
    },
    methods: {
        // Tilte name's
        setTitleBasedOnGuid() {
            if (this.$route.params.guid !== '00000000-0000-0000-0000-000000000000') {
                this.title = "Edit user";
                this.items[1].text = "Edit user";
            } else {
                this.title = "Add user";
                this.items[1].text = "Add user";
            }
        },
        //checks the user has specific permission or not.
        hasPermission(permissionToCheck) {
            return this.filteredUserPermisions.some(
                (permissionObj) => permissionObj.permission === permissionToCheck
            );
        },
        async editUser() {
            // this.loading = true;
            this.showShimmers = true;
            this.isEditMode = false;
            await this.GetReportingUsers();
            this.ReportingManagers = this.getUserHierarchy
            this.ReportingManagers = this.ReportingManagers.map((user) => ({
                ...user,
                FullName: `${user.FirstName} ${user.LastName}`,
            }));
            this.ReportingManagers.sort((a, b) => {
                const fullNameA = `${a.FirstName} ${a.LastName}`;
                const fullNameB = `${b.FirstName} ${b.LastName}`;
                return fullNameA.localeCompare(fullNameB);
            });
            if (this.$route.params.guid && this.$route.params.guid !== '00000000-0000-0000-0000-000000000000') {
                this.ReportingManagers = this.ReportingManagers.filter(data => data?.UserGuid != this.$route.params.guid)
                this.isEditMode = true;
                this.formChanged = true;
                this.count = 0;
                this.reportingCount = 0;
                this.designationCount = 0;
                this.departmentCount = 0;
                this.rolecount =0;
                this.permissionCount = 0;
                this.ProjectsCount = 0;
                this.formChanged = false;
                await this.GetUserDetailsByUserId();
                this.title = `Edit user (${this.Profile.FirstName} ${this.Profile.LastName})`
            }
            else {
                await this.GetUserDefaults();
                // this.loading = false
                this.showShimmers = false
            }
        },
        async GetUserDefaults() {
            await this.$store.dispatch('userservice/GetUserDefaults',this.SelectReportingGuid? this.SelectReportingGuid: this.getUserguid  )  
            this.UserDefaultData = this.getUserDefaults;
            this.departmentOptions = this.UserDefaultData?.DepartmentType
            this.designationOptions = this.UserDefaultData?.DesignationType;
            this.Permissions = this.UserDefaultData?.AppRightType;
            this.ApprovalType = this.UserDefaultData?.ApprovalType;
            this.Projects = this.UserDefaultData?.ProjectResponse;
            this.Roles = this.UserDefaultData?.RoleType;
            if (this.$route.params.guid === '00000000-0000-0000-0000-000000000000') {
                this.Roles = this.Roles.filter(data => data?.Role != 'Admin' && data?.Role != 'Super admin')
            }
        },
        async GetReportingUsers() {
            const params = {
                UserId: this.getProfileDetails.UserGuid
            }
            await this.$store.dispatch(
                "userservice/GetUserHierarchy", params
            );
        },
        getApprovalTypesForTypeFor(typeFor) {
            const approvalTypes = this.ApprovalType.filter(item => item?.TypeFor === typeFor);
            approvalTypes.sort((a, b) => {
                const typeTextA = a?.TypeText.toLowerCase();
                const typeTextB = b?.TypeText.toLowerCase();
                return typeTextA.localeCompare(typeTextB);
            });
            return approvalTypes.map(item => ({ Type: item?.Type, Guid: item?.Guid, TypeText: item?.TypeText }));
        },
        //Validations for first and last name fields
        validateName(fieldName) {
            this[fieldName + 'hasSpecialCharacters'] = false;
            const specialCharPattern = /[@#$%^&*()_+=<>?!,\-/'[\]{};]/g;
            const validCharPattern = /[^a-zA-Z0-9. ]/g;
            this[fieldName + 'hasSpecialCharacters'] = specialCharPattern.test(this.Profile[fieldName]);
            this.Profile[fieldName] = this.Profile[fieldName]?.replace(validCharPattern, '');
        },
        validateEmail() {
            this.fieldTitle='email'
            this.DuplicateCheck = false;
            this.emailNotValid = !(/^[^\s@,]+@[^\s@]+\.(com|in)$/.test(this.Email));
        },
        //Phone number should be a number
        validatePhoneNumber() {
            this.PhonehasCharacters = false;
            this.PhonehasCharacters = (/[^0-9]/.test(this.Profile.PhoneNumber));
            this.Profile.PhoneNumber = this.Profile.PhoneNumber?.replace(/[^0-9.]/g, '');
        },
        validateEmployeeId(fieldName) {
            this.designationExists = ''
            const specialCharPattern = /[^\w\s]/;
            this.employeeIdhasCharacters = false;
            this[fieldName + 'hasSpecialCharacters'] = specialCharPattern.test(this.Profile[fieldName]);
            this.employeeIdhasCharacters = /[^a-zA-Z0-9]/.test(this.Profile.EmployeeCode);
            this.Profile.EmployeeCode = this.Profile.EmployeeCode?.replace(/[^a-zA-Z0-9]/g, '');
        },
        blurFields(field) {
            this[field + 'Blured'] = true;
            if (field == 'email') {
                this.emailCheck()
            }
        },
        validatePassword() {
            // password and confirm password should be same
            this.samePassword = false;
            if (this.Profile?.Password === this.Profile.confirmpassword) {
                this.samePassword = true;
            }
            // Password must be greater than 8 characters
            if (this.Profile?.Password?.length < 8) {
                this.isPasswordValid = false;
                this.showpopover = true;
                this.isUppercaseValid = /[A-Z]/.test(this.Profile?.Password);
                this.isLowercaseValid = /[a-z]/.test(this.Profile?.Password);
                this.isNumberValid = /[0-9]/.test(this.Profile?.Password);
                this.isSpecialCharacterValid = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/\-=]/.test(this.Profile?.Password);
            } else {
                // Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.
                const uppercaseRegex = /[A-Z]/;
                const lowercaseRegex = /[a-z]/;
                const digitRegex = /\d/;
                const specialCharRegex = /[!@#$%^&*()_+[\]{};':"\\|,.<>/?]+/;
                this.showpopover = true;
                this.isPasswordValid =
                    uppercaseRegex.test(this.Profile?.Password) &&
                    lowercaseRegex.test(this.Profile?.Password) &&
                    digitRegex.test(this.Profile?.Password) &&
                    specialCharRegex.test(this.Profile?.Password);
                if (this.isPasswordValid) {
                    this.showpopover = false;
                }
            }
        },
        // password and confirm password should be same
        validateConfirmpassword() {
            this.samePassword = false;
            if (this.Profile?.Password === this.Profile.confirmpassword) {
                this.samePassword = true;
            }
        },
        //Check for email alredy exist or not.
        async emailCheck() {
            if (this.Email && !this.emailNotValid) {
                this.SpinnerCheck = true
                const params = {
                    email: this.Email,
                }
                await this.$store.dispatch('userservice/EmailCheck', params);
                this.DuplicateCheck = true;
                this.SpinnerCheck = false
            }
        },
        //Checks whether all the mandatory fields are given or not
        isValidForm() {
            return (!this.Profile.FirstName || !this.Profile.LastName || !this.Email || !this.Profile.PhoneNumber || this.Profile.PhoneNumber?.length < 10 || !this.Profile.CountryCode ||
                !this.Profile.DepartmentGuid || !this.Profile.DesignationGuid || !this.Profile.ReportingGuid  || !this.Profile.Gender || !this.Profile.Maritalstatus 
                ||!this.Profile.RoleGuid || !this.Profile.EmployeeCode || !this.formChanged || !this.Profile.SelectedProjects?.length || this.emailNotValid
            );
        },
        ...mapMutations(['setUpdateHierarchy', 'setAlertNotification']),
        async SaveUserDetails() {
            // this.loading = true;
            this.showShimmers = true;
            if (this.Profile.UserGuid == null) {
                const UserObject = {
                    Email: this.Profile.Email ,
                    Password: 'Welcome@123',
                    ConfirmPassword: 'Welcome@123',
                    FirstName: this.Profile.FirstName,
                    LastName: this.Profile.LastName,
                    PhoneNumber: this.Profile.PhoneNumber,
                }
                await this.$store.dispatch('userservice/signup', UserObject)
                //  console.log("this.getSignupUser", this.getSignupUser, this.getErrors)
            }
            if (this.getSignupUser || this.Profile.UserGuid !== null) {
                const SaveObject = {
                    UserGuid: this.Profile.UserGuid,
                    FirstName: this.Profile.FirstName,
                    LastName: this.Profile.LastName,
                    Email: this.Profile.Email || this.Email,
                    CountryCode: this.Profile.CountryCode,
                    PhoneNumber: this.Profile.PhoneNumber,
                    Gender: this.Profile.Gender,
                    Maritalstatus: this.Profile.Maritalstatus,
                    employeeCode: this.Profile.EmployeeCode,
                    DepartmentGuid: this.Profile.DepartmentGuid.DepartmentGuid || '00000000-0000-0000-0000-000000000000',
                    DesignationGuid: this.Profile.DesignationGuid || '00000000-0000-0000-0000-000000000000',
                    ReportingGuid: this.Profile.ReportingGuid.UserGuid || '00000000-0000-0000-0000-000000000000',
                    UserName: this.Email,
                    RoleGuid: this.Profile?.RoleGuid?.RoleGuid || '00000000-0000-0000-0000-000000000000',
                    LstPermission: this.selectedGuids.map(guid => ({ PermissionGuid: guid })),
                    Projects: this.selectedprojectsGuids.join(',')
                }

                await this.$store.dispatch("userservice/SaveUserDetails", SaveObject)
                await this.assignTasksToNewReportingManager()
                this.View=await this.getUserDetails
                setTimeout(() => {
                const message = {
                    variant:  'success',
                    alertTitle:this.isEditMode === true?"Updated" :"Saved",
                    alertDecription: this.View.Response
                }
         
                this.setAlertNotification(message)
            }, 1000);
                // this.loading = false;
                this.showShimmers = false;
                if (this.getUserDetails.UserId > 0) {
                router.push('/users-list');
        
                     
                    const input = {
                        // UserId:this.getProfileDetails?.UserId || this.getProfileDetails?.Id,
                        UserId: this.getUserDetailsById.UserId,
                        UserName: this.Profile.FirstName + ' ' + this.Profile.LastName
                    }
                    if (this.isEditMode) {
                        await this.$store.dispatch('userservice/UpdateAssignedNames', input);
                    }
                    else{
                      let MaternityLeave = this.Profile.Gender == "Female" && this.Profile.Maritalstatus == "Married" ? 180 : 0;
                      const params = {
                        UserId: this.getUserDetails.UserId,
                        UserName: this.Profile.FirstName + ' ' + this.Profile.LastName,
                        SickLeave: 6,
                        PersonalLeave: 6,
                        WorkFromHome: 12,
                        OptionalHoliday: 3,
                        PublicHoliday: 7,
                        MaternityLeave: MaternityLeave,
                        PaternityLeave: 15,
                        CompensatoryLeave: 0,
                        BereavementLeave: 7,
                    }
                    await this.$store.dispatch('userservice/SaveUserLeaves', params);
                    }
                    this.setUpdateHierarchy(SaveObject)
                } else {
                    alert("Something went wrong")
                }
            } else {
                if (this.getError) {
                    this.error = this.getError
                }
                else {
                    this.error = "Some thing went wrong please try again"
                }
            }

        },
        async SaveDesignation(data,type) {
            this.fieldTitle=type
            this.SpinnerCheck = true
            const trimmedDesignation = data.trim();
            const DesignationObject = {
                Type: type,
                Name: trimmedDesignation,
            };
            await this.$store.dispatch('userservice/SaveDesignation', DesignationObject);
            this.designationExists = this.getDesignationValue.Response
            const findInOptions = (options, guid, propertyName) => options.find(opt => opt[propertyName] === guid) || null;
            await this.GetUserDefaults();
            this.fieldTitle == 'Designation' ?
                this.designationvalue = findInOptions(this.designationOptions, this.getDesignationValue.DesignationGuid, 'DesignationGuid')
                :this.fieldTitle == 'Department'? this.departmentGuidvalue = findInOptions(this.departmentOptions, this.getDesignationValue.DepartmentGuid, 'DepartmentGuid')
                :this.fieldTitle=='Role' ?  this.RoleGuidvalue = findInOptions(this.Roles, this.getDesignationValue.RoleGuid, 'RoleGuid') : '';
            if(!this.designationExists){
                await this.resetDataClick();
            }
            // this.loading = false;
            this.SpinnerCheck = false
        },

        resetDataClick(data) {
            this.$refs["myModal"]?.hide();
            this.isDesignation = false;
            this.AddDesignation.CustomDesignation = ""
            this.designationExists = false;
            if (data) {
                const lowerCaseData = data.charAt(0).toLowerCase() + data.slice(1); 
                this.ModalTitle = 'Add custom ' + lowerCaseData;
                this.fieldTitle = data;
                this.Placeholder = 'Enter ' + lowerCaseData;
            }
        },
        isFormInvalid() {
            return (
                !this.AddDesignation.CustomDesignation || this.designationExists
            );
        },
        async GetUserDetailsByUserId() {
            await this.$store.dispatch('userservice/GetUserDetailsByGuid', this.$route.params.guid);
            this.SelectReportingGuid = await this.getUserDetailsById.ReportingGuid
             await this.GetUserDefaults();

            const { FirstName, LastName, UserName, Email, Password, confirmpassword, ReportingGuid, DesignationGuid, DepartmentGuid, RoleGuid, ProjectGuid, CountryCode, PhoneNumber, EmployeeCode, LstPermission, UserGuid, Projects , Maritalstatus , Gender ,ReportingId,UserId ,ReportingManger} = this.getUserDetailsById;
            this.Profile = {
                FirstName,
                LastName,
                UserName,
                Email,
                Password: "123456789101112131415",
                confirmpassword: "123456789101112131415" || confirmpassword || Password,
                CountryCode: CountryCode || '',
                PhoneNumber,
                Maritalstatus,
                Gender,
                EmployeeCode,
                UserGuid,
                ReportingId,
                UserId,
                ReportingManger,

            };
            // this.loading = false
            this.showShimmers = false
            this.Email = this.getUserDetailsById.Email;
            const reporting = this.ReportingManagers.find(opt => opt.UserGuid === ReportingGuid);
            this.ReportingManagers.sort((a, b) => {
                const fullNameA = `${a.FirstName} ${a.LastName}`;
                const fullNameB = `${b.FirstName} ${b.LastName}`;
                return fullNameA.localeCompare(fullNameB);
            });
            this.repotingvalue = reporting ? { UserGuid: ReportingGuid, FullName: `${reporting.FirstName} ${reporting.LastName}` } : { UserGuid: this.getProfileDetails.UserGuid, FullName: `${this.getProfileDetails?.FirstName} ${this.getProfileDetails?.LastName}` };
            this.SelectReportingGuid = this.repotingvalue.UserGuid
            //this.Profile.ReportingGuid = reporting ? { UserGuid: ReportingGuid, FullName: `${reporting.FirstName} ${reporting.LastName}` } : null;
            const findInOptions = (options, guid, propertyName) => options.find(opt => opt[propertyName] === guid) || null;
            //   this.Profile.DesignationGuid= findInOptions(this.designationOptions, DesignationGuid, 'DesignationGuid');
            this.designationvalue = findInOptions(this.designationOptions, DesignationGuid, 'DesignationGuid');
            //this.Profile.DepartmentGuid = findInOptions(this.departmentOptions, DepartmentGuid, 'DepartmentGuid');
            this.departmentGuidvalue = findInOptions(this.departmentOptions, DepartmentGuid, 'DepartmentGuid');
            this.RoleGuidvalue = findInOptions(this.Roles, RoleGuid, 'RoleGuid');
            this.rolename=this.Roles.filter(item=>item.RoleGuid==this.RoleGuidvalue.RoleGuid)[0].Role
            this.rolename ==="Admin" ?  this.Roles = this.Roles.filter(role => role.Role !== 'Super admin') : this.Roles = this.Roles.filter(role => role.Role !== 'Super admin' && role.Role !=='Admin'); 
            this.ProjectGuidvalue = findInOptions(this.Projects, ProjectGuid, 'ProjectGuid');
            if (this.getUserDetailsById.LstPermission) {
                this.ListPermissions = LstPermission.split(',').map(guid => guid.toLowerCase());
                const selectedApprovalTypes = this.ListPermissions.map(guid => this.ApprovalType.find(item => item.Guid === guid)).filter(Boolean);
                const groupedData = selectedApprovalTypes.reduce((acc, { Type, Guid, TypeFor, TypeText }) => {
                    acc[TypeFor] = acc[TypeFor] || [];
                    acc[TypeFor].push({ Type, Guid, TypeText });
                    return acc;
                }, {});
                this.Profile.selectedApprovalTypes = groupedData;
                this.selectedApprovalTypes = groupedData
            } else {
                this.selectedApprovalTypes = {};
            }
            if (this.getUserDetailsById.Projects) {
                this.ListProjects = Projects.split(',').map(Id => Id);
                const selectedProjects = this.ListProjects.map(Id => this.Projects.find(item => item.ProjectId === parseInt(Id))).filter(Boolean);
                this.Profile.SelectedProjects = selectedProjects;
                this.ProjectGuidvalue = selectedProjects
                this.userProjects = selectedProjects
            }
            this.samePassword = true;
        },
        handleSelect(selectedOption) {
            this.SelectReportingGuid = selectedOption
            this.selectedApprovalTypes = [];
            this.ProjectGuidvalue = ''
            this.GetUserDefaults()
        },
        async assignTasksToNewReportingManager() {
            const filteredProjects = this.userProjects.filter(project =>
                !this.Profile.SelectedProjects.some(filterProject => filterProject.ProjectId == project.ProjectId)
            );
            const projectIds = filteredProjects.map(project => project.ProjectId);
            let params = {
                ProjectId: projectIds,
                DeletedId: this.Profile.UserId,
                ReportingId: this.Profile.ReportingId,
                ReportingName: this.Profile.ReportingManger,
            };
            if(this.$route.params.guid !== '00000000-0000-0000-0000-000000000000'){ 
                await this.$store.dispatch("tasks/ReportingTasks", params);
        }
        },
    },
    watch: {
        designationvalue(newValue) {
            // This handler will be triggered when the selected value changes
            // Update other parts of your data or do necessary operations here
            // For example, you can update this.Profile.DesignationGuid with newValue.DesignationGuid
            this.Profile.DesignationGuid = newValue ? newValue.DesignationGuid : '';
            this.designationCount += 1;
            if (this.isEditMode && this.designationCount == 2) {
                this.formChanged = true;
            }
        },
        // 'Profile.DesignationGuid': {
        // handler(newValue) {
        //   // Your watch logic here
        //   console.log('New DesignationGuid:', newValue);
        // },
        // immediate: false,
        // },
        repotingvalue(newValue) {
            this.Profile.ReportingGuid = newValue ? newValue : '';
            this.reportingCount += 1;
            if (this.isEditMode && this.reportingCount == 2) {
                this.formChanged = true;
            }
        },
        departmentGuidvalue(newValue) {
            this.Profile.DepartmentGuid = newValue ? newValue : '';
            this.departmentCount += 1;
            if (this.isEditMode && this.departmentCount == 2) {
                this.formChanged = true;
            }
        },
        RoleGuidvalue(newValue) {
            this.Profile.RoleGuid = newValue ? newValue : '';
            this.rolecount += 1;
            if (this.isEditMode && this.rolecount == 2) {
                this.formChanged = true;
            }
        },
        ProjectGuidvalue(newValue) {
            this.Profile.SelectedProjects = newValue ? newValue : '';
            this.ProjectsCount += 1;
            if (this.isEditMode && this.ProjectsCount == 2) {
                this.formChanged = true;
            }
        },
        Email(newValue) {
            this.Profile.Email = newValue ? newValue : '';
            this.Profile.UserName = newValue ? newValue : '';
        },
        //Checks for the change in form values
        Profile: {
            deep: true, // Watch nested properties
            handler() {
                this.count += 1;
                if (this.isEditMode && this.count == 2) {
                    this.formChanged = true;
                }
            },
        },
        //Checks for change in permissions section in edit page
        selectedApprovalTypes: {
            deep: true,
            handler() {
                this.permissionCount += 1;
                if (this.isEditMode && this.permissionCount == 2) {
                    this.formChanged = true;
                }
            }
        }
    }
}
</script>
     
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <!-- <PreLoader /> -->
        <!-- <div v-if="loading">
            <PreLoader></PreLoader>
        </div> -->
        <div class="card col-12 mx-6" v-if="error?.length">
            <div class="card-body">
                <b-alert show variant="danger">
                    <i class="ri-close-circle-line mr-2"></i>{{ error }}
                </b-alert>
            </div>
        </div>
        <div v-if="showShimmers">
            <div class="card">
                <div class="card-body">
                   <div class="row">
                       <div class="col-md-4 col-sm-12 mb-4">
                            <div>
                               <b-skeleton width="40%" height="20px"></b-skeleton>
                               <b-skeleton type="input"></b-skeleton>
                            </div>
                       </div>
                       <div class="col-md-4 col-sm-12  mb-4">
                           <div>
                               <b-skeleton width="40%" height="20px"></b-skeleton>
                               <b-skeleton type="input"></b-skeleton>
                           </div>
                        </div>
                       <div class="col-md-4 col-sm-12  mb-4">
                           <div>
                               <b-skeleton width="40%" height="20px"></b-skeleton>
                               <b-skeleton type="input"></b-skeleton>
                           </div>
                       </div>
                       <div class="col-md-4 col-sm-12  mb-4">
                              <b-skeleton width="30%"></b-skeleton>
                                    <div class="d-flex justify-content-between">
                                        <b-skeleton width="34%" type="input"></b-skeleton>
                                        <b-skeleton  width="65%" type="input"></b-skeleton>
                                    </div>
                        </div>
                    </div>
                </div>               
            </div>
        </div>
        
        <div class="row" v-else>
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mb-3">Personal details</h4>
                            <!-- <b-button size="sm" variant="danger" v-if="isEditMode && hasPermission('DeleteUser')" @click="cancel()" :disabled="!isValidForm()">
                                <i class="ri-delete-bin-6-line align-bottom"></i> Delete User
                            </b-button> -->
                        </div>
                        <form>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                    <div class="form-group">
                                        <label for="firstName">First name<span
                                                :class="{ 'text-danger': !Profile.FirstName }"> *</span></label>
                                        <input type="text" v-model="Profile.FirstName" :maxlength="50"
                                            v-bind:class="{ 'form-control': true, 'is-invalid': !Profile.FirstName && (firstNameBlured) || FirstNamehasSpecialCharacters }"
                                            @blur="firstNameBlured = true" @input="validateName('FirstName')"
                                            placeholder="Please enter first name" class="custom-placeholder" />
                                        <div class="invalid-feedback"
                                            v-if="!Profile.FirstName && (firstNameBlured) && !FirstNamehasSpecialCharacters">
                                            First name is required!</div>
                                        <div class="invalid-feedback" v-if="FirstNamehasSpecialCharacters">Special
                                            characters are not allowed!</div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                    <div class="form-group">
                                        <label for="LastName">Last name<span :class="{ 'text-danger': !Profile.LastName }">
                                                *</span></label>
                                        <input type="text" v-model="Profile.LastName" :maxlength="50"
                                            v-bind:class="{ 'form-control': true, 'is-invalid': !Profile.LastName && (lastNameBlured) || LastNamehasSpecialCharacters }"
                                            @blur="lastNameBlured = true" @input="validateName('LastName')"
                                            placeholder="Please enter last name" class="custom-placeholder" />
                                        <div class="invalid-feedback"
                                            v-if="!Profile.LastName && (lastNameBlured) && !LastNamehasSpecialCharacters">
                                            Last name is required!</div>
                                        <div class="invalid-feedback" v-if="LastNamehasSpecialCharacters">Special characters
                                            are not allowed!</div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                    <div class="form-group">
                                        <label for="email">Email<span :class="{ 'text-danger': !Email }"> *</span></label>
                                        <input :disabled="isEditMode ? isEditMode : null" type="email" v-model="Email"
                                            v-on:blur="blurFields('email')" @input="validateEmail()"
                                            placeholder="Please enter email" maxlength="64" class="custom-placeholder"
                                            v-bind:class="{ 'form-control': true, 'is-invalid': !Email && emailBlured || emailNotValid }" />
                                        <div v-if="SpinnerCheck && fieldTitle == 'email'" class="spinLoader">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                                height="24">
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path
                                                    d="M12 2a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1zm0 15a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm10-5a1 1 0 0 1-1 1h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 1 1zM7 12a1 1 0 0 1-1 1H3a1 1 0 0 1 0-2h3a1 1 0 0 1 1 1zm12.071 7.071a1 1 0 0 1-1.414 0l-2.121-2.121a1 1 0 0 1 1.414-1.414l2.121 2.12a1 1 0 0 1 0 1.415zM8.464 8.464a1 1 0 0 1-1.414 0L4.93 6.344a1 1 0 0 1 1.414-1.415L8.464 7.05a1 1 0 0 1 0 1.414zM4.93 19.071a1 1 0 0 1 0-1.414l2.121-2.121a1 1 0 1 1 1.414 1.414l-2.12 2.121a1 1 0 0 1-1.415 0zM15.536 8.464a1 1 0 0 1 0-1.414l2.12-2.121a1 1 0 0 1 1.415 1.414L16.95 8.464a1 1 0 0 1-1.414 0z" />
                                            </svg>
                                        </div>
                                        <div class="invalid-feedback" v-if="!Email && (emailBlured) && !emailNotValid">Email
                                            is required!
                                        </div>
                                        <div class="invalid-feedback" v-if="emailNotValid">Enter a valid email!
                                        </div>
                                        <div class="position-absolute font-size-14"
                                            v-bind:class="(getEmailCheck?.result ? 'text-success' : 'text-danger')"
                                            v-if="!emailNotValid && DuplicateCheck && !getEmailCheck?.result">
                                            {{ getEmailCheck?.message }}
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="form-group">
                                     <label class="label-title">CountryCode<span :class="{'text-danger': !Profile.CountryCode }">*</span></label>   
                                     <div class="phoneWrapper"></div>
                                     <div class="countryCodeWrapper"></div>
                                     <select v-model="Profile.CountryCode" id="countrycode" name="countrycode"  v-bind:class="{ 'is-invalid': !Profile.CountryCode && (countrycodeBlured) }"
                                     class="form-control h-40px auth-input flex-grow-0 countryCodeBox phone-dropdown pl-1" v-on:blur="countrycodeBlured = true" @change="Profile.PhoneNumber=''">
                                     <option value="" disabled selected>Country code</option>
                                     <option v-for="(option, index) in countrycode" :key="index" :value="option.dialCode">
                                     {{ option.dialCode }} - ({{ option.isoCode }})
                                     </option>
                                     </select>
                                     </div> -->
                                <!-- <div class="invalid-feedback d-block" v-if="countrycodeBlured && !Profile.CountryCode">country code is required!</div>
                                     </div> -->

                                <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                    <div class="form-group">
                                        <label class="label-title">Contact number <span
                                                :class="{ 'text-danger': Profile.PhoneNumber?.length < 10 || !Profile.CountryCode || !Profile.PhoneNumber }">*</span></label>
                                        <div class="phoneWrapper">
                                            <div class="countryCodeWrapper">
                                                <select v-model="Profile.CountryCode" id="countrycode" name="countrycode"
                                                    v-bind:class="{ 'is-invalid': !Profile.CountryCode && (countrycodeBlured) }"
                                                    class="form-control h-40px auth-input flex-grow-0 countryCodeBox phone-dropdown pl-1"
                                                    v-on:blur="countrycodeBlured = true" @change="Profile.PhoneNumber = ''">
                                                    <option value="" disabled selected>Country code</option>

                                                    <option v-for="(option, index) in countrycode" :key="index"
                                                        :value="option.dialCode">{{ option.dialCode }} - ({{ option.isoCode
                                                        }})</option>
                                                </select>
                                                <div class="invalid-feedback d-block"
                                                    v-if="countrycodeBlured && !Profile.CountryCode">Country code is
                                                    required!</div>
                                            </div>
                                            <div class="w-100 phone-input">
                                                <input type="tel" placeholder="Please enter mobile number"
                                                    class="form-control auth-input custom-placeholder" pattern="[0-9]+"
                                                    v-model="Profile.PhoneNumber" id="contactNumber" :maxlength="10"
                                                    :minlength="10" @input="validatePhoneNumber"
                                                    v-bind:class="{ 'form-control': true, 'is-invalid': (Profile.PhoneNumber?.length < 10 || !Profile.PhoneNumber) && (phoneNumberBlured) || PhonehasCharacters }"
                                                    v-on:blur="phoneNumberBlured = true; PhonehasCharacters = false" />
                                                <span class="invalid-feedback d-block"
                                                    v-if="Profile.PhoneNumber?.length < 10 && !PhonehasCharacters && Profile.PhoneNumber">Phone
                                                    number must be 10 digits!</span>
                                                <div class="invalid-feedback"
                                                    v-if="!Profile.PhoneNumber && (phoneNumberBlured) && !PhonehasCharacters">
                                                    Phone number is required!</div>
                                            </div>

                                        </div>
                                        <div class="invalid-feedback d-block" v-if="PhonehasCharacters">Special characters
                                            and characters are not allowed!</div>
                                            

                                    </div>
                                    
                                    <!-- <div class="form-group">
                                             <label for="phone">Phone<span
                                                     :class="{ 'text-danger': !Profile.PhoneNumber }">*</span></label>
                                             <input type="tel" v-model="Profile.PhoneNumber" :maxlength="10"
                                                 @blur="phoneNumberBlured = true" @input="validatePhoneNumber"
                                                 v-bind:class="{ 'form-control': true, 'is-invalid': !Profile.PhoneNumber && (phoneNumberBlured) || phonehasCharacters }"
                                                 placeholder="Please enter phone number" />
                                             <div class="invalid-feedback"
                                                 v-if="!Profile.PhoneNumber && (phoneNumberBlured) && !phonehasCharacters">Phone
                                                 number is required!</div>
                                             <div class="invalid-feedback" v-if="phonehasCharacters">Special characters and
                                                 characters are not allowed!</div>
                                         </div> -->
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                    <div class="form-group">
                                        <label class="mt-1" for="gender">Gender<span :class="{ 'text-danger': !Profile.Gender }"> *</span></label><br>
                                            <div class="custom-radio form-check-inline mt-2 mr-4">
                                                <input class="custom-control-input" type="radio" name="gender" id="Male" value="Male"  v-model="Profile.Gender"/>
                                                   <label class="custom-control-label ml-4" for="Male">Male</label>
                                            </div>
                                            <div class="custom-radio form-check-inline ml-3">
                                                <input class="custom-control-input" type="radio" name="gender" id="Female" value="Female"  v-model="Profile.Gender"/>
                                                    <label class="custom-control-label ml-2" for="Female">Female</label>
                                            </div>
                                            <div class="custom-radio form-check-inline ml-3">
                                                <input class="custom-control-input" type="radio" name="gender" id="Others" value="Others"  v-model="Profile.Gender"/>
                                                    <label class="custom-control-label ml-4" for="Others">Others</label>
                                            </div>
                                    </div>
                                </div>

                                <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                    <div class="form-group">
                                        <label class="mt-1" for="maritalstatus">Marital status<span :class="{ 'text-danger': !Profile.Maritalstatus }"> *</span></label><br>
                                            <div class="custom-radio form-check-inline mt-2 mr-4">
                                                <input class="custom-control-input" type="radio" name="maritalstatus" id="Married" value="Married" v-model="Profile.Maritalstatus"/>
                                                    <label class="custom-control-label ml-4" for="Married">Married</label>
                                            </div>
                                            <div class="custom-radio form-check-inline ml-3">
                                               <input class="custom-control-input" type="radio" name="maritalstatus" id="Unmarried" value="Unmarried" v-model="Profile.Maritalstatus"/>
                                                    <label class="custom-control-label ml-2" for="Unmarried">Unmarried</label>
                                            </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <hr>
                                </div>
                                <h4 class="col-sm-12 card-title mb-3">Employee details</h4>
                                <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                    <div class="form-group">
                                        <label for="employeeId">Employee ID<span
                                                :class="{ 'text-danger': !Profile.EmployeeCode }"> *</span></label>
                                        <input :disabled="isEditMode ? isEditMode : null" type="text"
                                            v-model="Profile.EmployeeCode" :maxlength="30" @blur="employeeIdBlured = true" 
                                            @input="validateEmployeeId" @focusout="SaveDesignation(Profile.EmployeeCode , 'EmployeeCode')"
                                            v-bind:class="{ 'form-control': true, 'is-invalid': !Profile.EmployeeCode && (employeeIdBlured) || employeeIdhasCharacters }"
                                            placeholder="Enter employee Id " class="custom-placeholder" />
                                        <div class="invalid-feedback" v-if="!Profile.EmployeeCode && (employeeIdBlured)">
                                            Employee ID is required!</div>
                                        <div class="invalid-feedback" v-if="employeeIdhasCharacters">
                                            Special characters are not allowed!
                                        </div>
                                        <div v-if="SpinnerCheck && Profile.EmployeeCode && fieldTitle=='EmployeeCode'" class="spinLoader">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                                height="24">
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path
                                                    d="M12 2a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1zm0 15a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm10-5a1 1 0 0 1-1 1h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 1 1zM7 12a1 1 0 0 1-1 1H3a1 1 0 0 1 0-2h3a1 1 0 0 1 1 1zm12.071 7.071a1 1 0 0 1-1.414 0l-2.121-2.121a1 1 0 0 1 1.414-1.414l2.121 2.12a1 1 0 0 1 0 1.415zM8.464 8.464a1 1 0 0 1-1.414 0L4.93 6.344a1 1 0 0 1 1.414-1.415L8.464 7.05a1 1 0 0 1 0 1.414zM4.93 19.071a1 1 0 0 1 0-1.414l2.121-2.121a1 1 0 1 1 1.414 1.414l-2.12 2.121a1 1 0 0 1-1.415 0zM15.536 8.464a1 1 0 0 1 0-1.414l2.12-2.121a1 1 0 0 1 1.415 1.414L16.95 8.464a1 1 0 0 1-1.414 0z" />
                                            </svg>
                                        </div>
                                        <span v-bind:class="{ 'text-danger': designationExists, 'text-success': !designationExists }" v-if="designationExists && this.fieldTitle == 'EmployeeCode'">{{ designationExists}}</span>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                    <div class="form-group position-relative">
                                        <label for="department">Department <span
                                                :class="{ 'text-danger': !Profile.DepartmentGuid }"> *</span>
                                                <span class="custom-add text-primary" v-b-modal.modal-center 
                                                @click="resetDataClick('Department')"><i class="ri-add-line align-bottom"></i>
                                                Add</span>
                                            </label>
                                        <div
                                            :class="[!Profile.DepartmentGuid && (departmentBlured) ? 'multiSelectError' : 'multi-selector-container',]">
                                            <multiselect v-model="departmentGuidvalue" label="DepartmentName"
                                                :placeholder="'Select department'"  :options="departmentOptions"
                                                v-bind:class="{ 'is-invalid': !Profile.DepartmentGuid && (departmentBlured) }"
                                                @close="departmentBlured = true">
                                                <template slot="option" slot-scope="{ option }">
                                                    {{ option ? option.DepartmentName : 'Select Department' }}
                                                </template>
                                                <template slot="selected-option" slot-scope="{ option }">
                                                    {{ option.DepartmentName }}
                                                </template>
                                            </multiselect>
                                            <div class="invalid-feedback"
                                                v-if="!Profile.DepartmentGuid && (departmentBlured)">Department is
                                                required!</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                    <div class="form-group position-relative">
                                        <label for="designation">Designation <span
                                                :class="{ 'text-danger': !Profile.DesignationGuid }"> *</span>
                                            <span class="custom-add text-primary" v-b-modal.modal-center
                                                @click="resetDataClick('Designation')"><i class="ri-add-line align-bottom"></i>
                                                Add</span>
                                        </label>
                                        <div
                                            :class="[!Profile.DesignationGuid && (designationBlured) ? 'multiSelectError' : 'multi-selector-container',]">
                                            <multiselect v-model="designationvalue" label="DesignationName"
                                                :placeholder="'Select designation'" :options="designationOptions"
                                                v-bind:class="{ 'is-invalid': !Profile.DesignationGuid && (designationBlured) }"
                                                @close="designationBlured = true">
                                                <template slot="option" slot-scope="{ option }">
                                                    {{ option ? option.DesignationName : 'Select Designation' }}
                                                </template>
                                                <template slot="selected-option" slot-scope="{ option }">
                                                    {{ option.DesignationName }}
                                                </template>
                                            </multiselect>
                                            <div class="invalid-feedback"
                                                v-if="!Profile.DesignationGuid && (designationBlured)">Designation is
                                                required!</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                    <div class="form-group">
                                        <label for="assignedTo">Reporting manager <span
                                                :class="{ 'text-danger': !Profile.ReportingGuid }"> *</span></label>
                                        <div
                                            :class="[!Profile.ReportingGuid && (reportingManagerBlured) ? 'multiSelectError' : 'multi-selector-container',]">
                                            <multiselect v-model="repotingvalue" :options="ReportingManagers"
                                                :placeholder="'Select reporting manager'" label="FullName"
                                                :disabled="Profile.RoleGuid?.Role == 'Admin'" track-by="UserGuid"
                                                v-bind:class="{ 'is-invalid': !Profile.ReportingGuid && (reportingManagerBlured) }"
                                                @close="reportingManagerBlured = true"
                                                @select="handleSelect(repotingvalue.UserGuid)">
                                                <template slot="option" slot-scope="{ option }">
                                                    {{ option ? option.FirstName + ' ' + option.LastName + ' ( ' +
                                                        option.Email
                                                        + ' )' : 'Select User'
                                                    }}
                                                </template>
                                                <template slot="selected-option" slot-scope="{ option }">
                                                    {{ option.FirstName }} {{ option.LastName }} {{ option.Email }}
                                                </template>
                                            </multiselect>
                                            <div class="invalid-feedback"
                                                v-if="!Profile.ReportingGuid && (reportingManagerBlured)">Reporting
                                                manager
                                                is required!</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                    <div class="form-group position-relative">
                                        <label for="assignedTo">Roles<span :class="{ 'text-danger': !Profile.RoleGuid }">
                                                *</span>
                                                <span class="custom-add text-primary" v-b-modal.modal-center
                                                @click="resetDataClick('Role')"><i class="ri-add-line align-bottom"></i>
                                                Add</span>
                                        </label>
                                        <div
                                            :class="[!Profile.RoleGuid && (roleBlured) ? 'multiSelectError' : 'multi-selector-container',]">
                                            <multiselect  :disabled="rolename === 'Admin' || rolename === 'Super admin' ? isEditMode : null"
                                                v-model="RoleGuidvalue" :options="Roles" :placeholder="'Select role'"
                                                label="Role" track-by="RoleGuid"
                                                v-bind:class="{ 'is-invalid': !Profile.RoleGuid && (roleBlured), }"
                                                @close="roleBlured = true">
                                                <template slot="option" slot-scope="{ option }">
                                                    {{ option ? option?.Role : 'Select Role' }}
                                                </template>
                                            </multiselect>
                                            <div class="invalid-feedback" v-if="!Profile.RoleGuid && (roleBlured)">Role
                                                is
                                                required!</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                    <div class="form-group">
                                        <label for="department">Projects<span
                                                :class="{ 'text-danger': !Profile.SelectedProjects?.length }">
                                                *</span></label>
                                        <div
                                            :class="[!Profile.SelectedProjects?.length && (Projectblured) ? 'multiSelectError' : 'multi-selector-container',]">
                                            <multiselect v-model="ProjectGuidvalue" :options="Projects"
                                                v-bind:class="{ 'is-invalid': !Profile.SelectedProjects?.length && (Projectblured), }"
                                                label="ProjectName" track-by="ProjectGuid" placeholder="Select projects"
                                                :multiple="true" @close="Projectblured = true"
                                                :disabled="isDropdownDisabled" :close-on-select="false" :limit="showAllProjects ? Profile.SelectedProjects?.length : 2">
                                            </multiselect>
                                            <div class="invalid-feedback"
                                                v-if="!Profile.SelectedProjects?.length && (Projectblured)">Project is
                                                required!</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-sm-12" v-if="!isEditMode">
                                    <hr>
                                </div> -->
                                <!-- <h4 class="col-sm-12 card-title mb-3" v-if="!isEditMode">User login details</h4>
                                <div class="col-12 col-sm-6 col-md-6 col-xl-4" v-if="!isEditMode">
                                    <div class="form-group">
                                        <label for="UserName">User name<span :class="{ 'text-danger': !Profile.UserName }">
                                                *</span></label>
                                        <input disabled type="text" v-model="Profile.UserName" @blur="userNameBlured = true"
                                            v-bind:class="{ 'form-control': true, 'is-invalid': !Profile.UserName && (userNameBlured) }"
                                            placeholder="Enter user name" />
                                        <div class="invalid-feedback" v-if="!Profile.UserName && (userNameBlured)">User
                                            name
                                            is required!</div>
                                    </div>
                                </div> -->
                                <!-- <div class="col-12 col-sm-6 col-md-6 col-xl-4" v-if="!isEditMode">
                                    <div class="password-visibility-block position-relative">
                                        <div class="form-group">
                                            <label for="password">Password<span
                                                    :class="{ 'text-danger': !Profile?.Password }"> *</span></label>
                                            <input :type="showPassword ? 'text' : 'password'" v-model="Profile.Password"
                                                @blur="passwordBlured = true; showpopover = false" @input="validatePassword"
                                                v-bind:class="{ 'form-control': true, 'is-invalid': (!Profile?.Password && passwordBlured) || !isPasswordValid }"
                                                placeholder="Enter password" class="custom-placeholder"
                                                autocomplete="new-password" />
                                            <div class="invalid-feedback" v-if="!Profile?.Password && (passwordBlured)">
                                                Password is required!</div>
                                            <div class="invalid-feedback"
                                                v-if="Profile?.Password?.length < 8 && Profile?.Password">Password must
                                                be at least 8 characters!</div>
                                            <div class="invalid-feedback"
                                                v-if="!isPasswordValid && Profile?.Password && Profile?.Password?.length >= 8">
                                                Password is invalid!</div>
                                        </div>
                                        <div>
                                            <button class="btn password-visibility-icon" type="button"
                                                @click="showPassword = !showPassword">
                                                <template v-if="showPassword">
                                                    <i class="ri-eye-line font-size-20" variant="primary"></i>
                                                </template>
                                                <template v-else>
                                                    <i class="ri-eye-off-line font-size-20" variant="primary"></i>
                                                </template>
                                            </button>
                                        </div>
                                        <div v-if="showpopover" class="password-popup">
                                            <div class="password-popup_Header">Passwords must be at least eight characters
                                                long and contain at least one lowercase letter, one uppercase letter, one
                                                number, and one special character.</div>
                                            <ul class="mt-3 align-center">
                                                <li :class="{ 'active': isLowercaseValid }"><span></span> Lowercase letter
                                                </li>
                                                <li :class="{ 'active': isUppercaseValid }"><span></span> Uppercase letter
                                                </li>
                                                <li :class="{ 'active': isNumberValid }"><span></span> Number</li>
                                                <li :class="{ 'active': isSpecialCharacterValid }"><span></span> Special
                                                    character</li>
                                                <li :class="{ 'active': this.Profile?.Password?.length >= 8 }"><span></span>
                                                    # of characters</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> -->

                                <!-- <div class="col-12 col-sm-6 col-md-6 col-xl-4" v-if="!isEditMode">
                                    <div class="password-visibility-block position-relative">
                                        <div class="form-group">
                                            <label for="confirmPassword">Confirm password<span
                                                    :class="{ 'text-danger': !Profile.confirmpassword }"> *</span></label>
                                            <input :type="showconfirmPassword ? 'text' : 'password'" onpaste="return false;"
                                                v-model="Profile.confirmpassword" @blur="confirmPasswordBlured = true"
                                                @input="validateConfirmpassword"
                                                v-bind:class="{ 'form-control': true, 'is-invalid': !Profile.confirmpassword && (confirmPasswordBlured) || (!samePassword && Profile.confirmpassword) }"
                                                placeholder="Enter confirm password" class="custom-placeholder" />
                                            <div class="invalid-feedback"
                                                v-if="!Profile.confirmpassword && (confirmPasswordBlured)">Confirm password
                                                is
                                                required!</div>
                                            <div class="invalid-feedback" v-if="(!samePassword && Profile.confirmpassword)">
                                                Password and confirm password must match!</div>
                                        </div>
                                        <div v-if="samePassword && Profile.confirmpassword">
                                            <i class="ri-check-fill tick-icon" style="color: #28a945; font-size: 24px;"></i>
                                        </div>
                                        <div>
                                            <button class="btn password-visibility-icon" type="button"
                                                @click="showconfirmPassword = !showconfirmPassword">
                                                <template v-if="showconfirmPassword">
                                                    <i class="ri-eye-line font-size-20" variant="primary"></i>
                                                </template>
                                                <template v-else>
                                                    <i class="ri-eye-off-line font-size-20" variant="primary"></i>
                                                </template>
                                            </button>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-sm-12" v-if="Permissions[0]?.TypeFor!==null">
                                    <hr>
                                </div>
                                <h4 class="col-sm-12 card-title mb-3" v-if="Permissions[0]?.TypeFor!==null">Permissions</h4>
                                <div class="col-12 col-sm-6 col-md-6 col-xl-4" v-for="rightType in Permissions" 
                                    :key="rightType?.TypeFor">
                                    <div class="form-group" v-if="Profile && rightType">
                                        <label for="assignedTo">{{ rightType?.TypeFor }}</label>
                                        <multiselect v-model="selectedApprovalTypes[rightType?.TypeFor]" v-if="Permissions[0].TypeFor!==null"
                                            :options="getApprovalTypesForTypeFor(rightType?.TypeFor)" label="TypeText"
                                            track-by="Guid" placeholder="Select approval type" :multiple="true"
                                            :disabled="isDropdownDisabled" :close-on-select="false">
                                        </multiselect>
                                    </div>
                                </div>
                                <!-- <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                         <div class="form-group">
                                             <label for="assignedTo">Adding Employee</label>
                                             <multiselect v-model="addingEmployee" :options="addingEmployeeOptions"
                                                 :multiple="true"></multiselect>
                                         </div>
                                     </div> -->
                            </div>
                        </form>
                    </div>
                    <div class="card-footer text-right">
                        <b-button @click="$router.push('/users-list')" class="ml-1" variant="outline-primary"
                            v-if="!isEditMode">
                            <i class="ri-close-line align-bottom"></i> Cancel
                        </b-button>&nbsp;
                        <b-button @click="editUser()" class="ml-1" variant="outline-primary" :disabled="!this.formChanged"
                            v-if="isEditMode">
                            <i class="ri-refresh-line align-bottom"></i> Reset
                        </b-button>&nbsp;
                        <button type="button" class="btn btn-primary" @click="SaveUserDetails()"
                            :disabled="(getEmailCheck?.message == 'User already exists!') || isValidForm() || (designationExists && this.fieldTitle == 'EmployeeCode')"
                            v-if="!isEditMode">
                            <i class="ri-save-line align-bottom"></i> Save
                        </button>
                        <button type="button" class="btn btn-primary" @click="SaveUserDetails()" :disabled="isValidForm()"
                            v-if="isEditMode">
                            <i class="ri-check-double-line align-bottom"></i> Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <!-- <div v-if="isloadingDesignation">
            <PreLoader></PreLoader>
        </div> -->
        <b-modal ref="myModal" id="modal-center" centered :title="ModalTitle" title-class="font-18"
            no-close-on-backdrop hide-footer>
            <form>
                <div class="form-group">
                    <label for="taskName">{{ fieldTitle }}
                        <span
                            :class="{ 'text-danger': this.AddDesignation.CustomDesignation?.length == 0 }">*</span></label>
                    <input type="text" maxlength="50" :placeholder="Placeholder" class="form-control"
                        @blur="isDesignation = true" @input="designationExists = false"
                        :class="[
                            designationExists || (!this.AddDesignation.CustomDesignation && isDesignation) ? 'border border-danger' : 'form-control',]"
                        v-model="AddDesignation.CustomDesignation">
                        <span class="text-danger" v-if="this.AddDesignation.CustomDesignation && designationExists">{{ designationExists }}</span>
                    <span class="text-danger" v-if="!this.AddDesignation.CustomDesignation && isDesignation">{{ fieldTitle }} is
                        required!</span>
                </div>
                <div class="text-right mt-4">
                    <b-button class="ml-1" type="button" variant="outline-primary mr-2" @click="resetDataClick()"><i
                            class="ri-close-line align-bottom"></i> Cancel</b-button>
                    <button type="button" class="btn btn-primary" :disabled="isFormInvalid()" @click="SaveDesignation(AddDesignation.CustomDesignation,fieldTitle)">
                        <i class="ri-save-line align-bottom"></i> Save
                    </button>
                </div>
            </form>
        </b-modal>
        <!-- end modal -->
    </Layout>
</template>
     
     
<style lang="scss" scoped>
.color {
    border: 1px solid #ff0000;
    border-radius: 5px;
    padding: -20px;
    margin-top: -2px;
}

.custom-placeholder::placeholder {
    color: #ced4da;
}

.phoneWrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 480px) {
        flex-direction: row;
    }
}

.phone-dropdown {
    /* margin-right: 0.5rem; */
    width: 127px;
    height: 38px;
    padding-left: 0.25rem;
    padding-right: 0.25rem;

    @media screen and (max-width: 479px) {
        width: 100%;
        margin-bottom: 0.5rem;
    }
}

.phone-input {
    margin-left: 0.2rem;

    @media screen and (max-width: 479px) {
        margin-left: 0rem;
    }

    input {
        height: 38px;
        padding: .47rem 0.45rem;
    }
}

.spinLoader {
    position: absolute;
    top: 35px;
    right: 30px;
    width: 25px;

    svg {
        animation: rotate 2.5s linear infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
}

.password-popup {
    background: #fff;
    position: absolute;
    bottom: 75px;
    right: 0;
    z-index: 10;
    height: 260px;
    width: 300px;
    border-radius: 11px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);

    &:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #00435d;
        border-width: 10px;
        margin-left: -10px;

        @media screen and (max-width:320px) {}
    }

    &_Header {
        background-color: #00435d;
        color: #fff;
        width: 100%;
        padding: 15px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        line-height: 18px;
        letter-spacing: 0.1px;
    }

    ul {
        list-style-type: none;
        padding: auto;
    }

    li {
        display: block;
        margin-bottom: 4px;

        span {
            display: inline-block;
            width: 15px;
            height: 15px;
            border: 2px solid #ccc;
            background-color: #fff;
            border-radius: 10px;
            vertical-align: middle;
        }

        &.active {
            span {
                border: 2px solid #28a745;
                background-color: #28a745;
            }
        }
    }
}

.password-visibility-block {
    .password-visibility-icon {
        position: absolute;
        top: 32px;
        right: 10px;
        width: 25px;
        padding: 0;
        box-shadow: none;
    }
}

.tick-icon {
    position: absolute;
    top: 32px;
    right: 35px;
    width: 25px;
    padding: 0;
    box-shadow: none;
}

input::-ms-reveal {
    display: none;
}

.custom-add {
    position: absolute;
    right: 0;
}

.form-control{
    height: 38px;
}
.multiselect{
    height: 38px;
}
</style>