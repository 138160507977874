<script>
import { mapActions, mapGetters,mapMutations } from "vuex";
import PreLoader from "@/components/pre-loader";
export default {
    components: { PreLoader },
    data() {
        return {            
            Account: {
                CurrentPassword: "",
                NewPassword: "",
                ConfirmPassword: "",
            },
            CurrentPasswordBlured: false,
            newpasswordBlured: false,
            ConfirmPasswordBlured: false,
            isPasswordValid: true,
            isPasswordMatch: true,
            showDismissibleAlert: false,
            showPassword: false,
            showconfirmPassword: false,
            isLowercaseValid: false,
            isUppercaseValid: false,
            isNumberValid: false,
            isSpecialCharacterValid: false,
            PasswordSame: false,
            formChanged: false,
            showpopover:false,
            loading:false,
            ShowAlert:false,
            response:''
        };
    },
    computed: {
        ...mapGetters('auth',['getAuthenticatedUser','getProfileDetails']),
        ...mapGetters('userservice',['getUpdatePassword']),
        ...mapGetters("errors", ["getErrors"]),
        isSaveEnabled() {
            return (
                this.Account.NewPassword &&
                this.isPasswordValid &&
                this.isPasswordMatch &&
                this.Account.CurrentPassword &&
                !this.PasswordSame
            );
        },
    },
    watch: {
        Account: {
            deep: true,
            handler() {
                this.formChanged = true;
            },
        },
    },
    methods: {
        validatePassword() {
            this.response='';
            this.invalidform = false
            this.isPasswordValid = false;
            this.showpopover = true;
            this.isUppercaseValid = /[A-Z]/.test(this.Account.NewPassword);
            this.isLowercaseValid = /[a-z]/.test(this.Account.NewPassword);
            this.isNumberValid = /[0-9]/.test(this.Account.NewPassword);
            this.isSpecialCharacterValid = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/\-=]/.test(this.Account.NewPassword);
            const isLengthValid = this.Account.NewPassword?.length >= 8;

            if (this.isUppercaseValid && this.isLowercaseValid && this.isSpecialCharacterValid && this.isNumberValid && isLengthValid) {
                this.showpopover = false;
                this.isPasswordValid = true;
                this.PasswordSame=this.Account.CurrentPassword=== this.Account.NewPassword
            }
            this.validateConfirmPassword();
        },
        validateConfirmPassword(){
            this.response='';
            this.isPasswordMatch = this.Account.NewPassword === this.Account.ConfirmPassword;
                    },
        validateCurrentPassword(){
            this.response='';
            this.PasswordSame=this.Account.CurrentPassword=== this.Account.NewPassword
           if (this.PasswordSame) {
             this.ShowAlert = true;
             } else {
             this.ShowAlert = false;
              }

        },
        ...mapActions('auth', ['logout']),
         ...mapMutations(['setAlertNotification']),
        async Submit() {
            this.response = ''
            this.loading = true;
            const params = {
                auth_type: "password",
                client_id: "ro.client",
                client_secret: "secret",
                username: this.getProfileDetails.Email,
                grant_type: "password",
                password: this.Account.CurrentPassword,
                scope: "api1 offline_access",
                user_type_id: 1,
                social_token: undefined,
                verification_code: undefined
            };

            await this.$store.dispatch('auth/login', params);
            this.response = this.getAuthenticatedUser?.message || this.getErrors?.critical_error
            if (this.getAuthenticatedUser?.EmailConfirmed == "True") {
                const params = {
                    userId: this.getProfileDetails.UserGuid,
                    password: this.Account.NewPassword,
                    confirmPassword: this.Account.ConfirmPassword,
                }
                await this.$store.dispatch('userservice/UpdatePassword', params);

                this.response = this.getUpdatePassword?.message || this.getErrors?.critical_error
                if (this.getUpdatePassword?.status == 'success') {
                    let value = await this.getUpdatePassword
                     setTimeout(() => {
                        
                        const message = {
                            variant:value?.status === 'success' ? 'success' : 'danger',
                            alertTitle: 'Account settings',
                            alertDecription: this.response
                        }
                        this.setAlertNotification(message)
                    }, 1000);
                    await this.$store.dispatch("globalReset");
                    await this.logout();
                    // Call the logout action
                    await this.$router.push({ name: 'Login' });
                }
                
                this.loading = false;
            }
            else {
                this.loading = false;
                this.ShowAlert = true;
                            }

        },
        initform() {
            this.Account = {
                CurrentPassword: "",
                NewPassword: "",
                ConfirmPassword: "",
            }
            this.response = ""
            this.CurrentPasswordBlured = false;
            this.newpasswordBlured = false;
            this.ConfirmPasswordBlured = false;
            this.showPassword = false;
            this.showconfirmPassword = false;
            setTimeout(() => {
                this.formChanged = false;
            });
        }
    }
};
</script>
<template>
    <div>
        <div v-if="loading">
          <PreLoader></PreLoader>
        </div>
        
    <form @submit.prevent="Submit">
        <b-alert show variant="danger" v-if="this.response">
            <i class="align-bottom ri-close-circle-line mr-2"></i>{{this.response}}
          </b-alert>
        <div class="row">
            <div class="col-lg-12">
                <div class="form-group">
                <label for="CurrentPassword">Current password <span :class="{'text-danger': !Account.CurrentPassword}">*</span></label>
                <input type="password" class="form-control custom-placeholder" :maxlength="100" v-model="Account.CurrentPassword" placeholder="Enter current password"  @input="validateCurrentPassword"
                v-bind:class="{ 'form-control': true, 'is-invalid':!Account.CurrentPassword && (CurrentPasswordBlured)}" v-on:blur="CurrentPasswordBlured = true"/>
                <div class="invalid-feedback" v-if="!Account.CurrentPassword && (CurrentPasswordBlured)">Current password is required!</div>
                <span v-if="ShowAlert" class="error-message">Please enter correct current password!</span>
                 
                </div>
            </div>
                <div class="col-lg-12">
                    <div class="password-visibility-block position-relative">
                        <div class="form-group">
                            <label for="NewPassword">New password <span :class="{ 'text-danger': !Account.NewPassword }">*</span></label>
                            <input :type="showPassword ? 'text' : 'password'" :maxlength="100" v-model="Account.NewPassword" placeholder="Enter new password" class="custom-placeholder"
                            v-bind:class="{ 'form-control': true, 'is-invalid': (!Account.NewPassword && newpasswordBlured) || (Account.NewPassword && (!isPasswordValid || PasswordSame)) }" 
                            v-on:blur="newpasswordBlured = true;showpopover=false" @input="validatePassword" @click="validatePassword"/>
                            <div class="invalid-feedback" v-if="!Account.NewPassword && (newpasswordBlured)">New password is required!</div>
                            <div class="invalid-feedback" v-if="Account.NewPassword.length < 8 && Account.NewPassword && !PasswordSame">Password must be at least 8 characters!</div>
                            <div class="invalid-feedback" v-if="!isPasswordValid && Account.NewPassword && Account.NewPassword.length >= 8">Password is invalid!</div>
                            <div class="invalid-feedback" v-if="PasswordSame && Account.NewPassword">New password and current password should not be same</div>
                            <button class="btn password-visibility-icon" type="button" @click="showPassword = !showPassword">
                                <template v-if="showPassword">
                                    <i class="ri-eye-line font-size-20" variant="primary"></i>
                                </template>
                                <template v-else>
                                    <i class="ri-eye-off-line font-size-20" variant="primary"></i>
                                </template>
                            </button>
                        </div>
                    </div>
                    <div v-if="showpopover" class="password-popup">
                        <div class="password-popup_Header">Passwords must be at least eight characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.</div>
                        <ul class="mt-3 align-center">
                            <li :class="{ 'active': isLowercaseValid }"><span></span> Lowercase letter</li>
                            <li :class="{ 'active': isUppercaseValid }"><span></span> Uppercase letter</li>
                            <li :class="{ 'active': isNumberValid }"><span></span> Number</li>
                            <li :class="{ 'active': isSpecialCharacterValid }"><span></span> Special character</li>
                            <li :class="{ 'active': this.Account.NewPassword?.length >= 8 }"><span></span> # of characters</li>
                        </ul>
                    </div>
                </div>
            <div class="col-lg-12">
                <div class="password-visibility-block position-relative">
                    <div class="form-group">
                        <label for="ConfirmPassword">Confirm password <span :class="{ 'text-danger': !Account.ConfirmPassword }">*</span></label>
                        <input :type="showconfirmPassword ? 'text' : 'password'"   onpaste="return false;"  class="form-control custom-placeholder" :maxlength="100" v-model="Account.ConfirmPassword" placeholder="Enter confirm password" 
                        v-bind:class="{ 'form-control': true, 'is-invalid': (!Account.ConfirmPassword && ConfirmPasswordBlured) || (Account.ConfirmPassword && !isPasswordMatch) }" 
                        v-on:blur="ConfirmPasswordBlured = true" @input="validateConfirmPassword"/>
                        <div class="invalid-feedback d-block" v-if="!Account.ConfirmPassword && (ConfirmPasswordBlured)">Confirm password is required!</div>
                        <div class="invalid-feedback d-block" v-if="!isPasswordMatch && Account.ConfirmPassword">New password and confirm password must match!</div>
                        <div v-if="isPasswordMatch && Account.ConfirmPassword">
                                        <i class="ri-check-fill tick-icon" style="color: #28a945; font-size: 24px;"></i>
                                    </div>  
                        <button class="btn password-visibility-icon" type="button" @click="showconfirmPassword = !showconfirmPassword">
                            <template v-if="showconfirmPassword">
                                <i class="ri-eye-line font-size-20" variant="primary"></i>
                            </template>
                            <template v-else>
                                <i class="ri-eye-off-line font-size-20" variant="primary"></i>
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right mt-4">
            <button type="button" :class="{'btn btn-outline-primary':!formChanged,'btn btn-primary':formChanged}" :disabled="!formChanged" @click="initform">
                <i class="ri-refresh-line align-middle"></i> Reset
            </button>
            <b-button class="ml-2" variant="primary" type="submit" :disabled="!isSaveEnabled">
                <i class="ri-check-double-line align-middle"></i> Update
            </b-button>                            
        </div>
    </form>
</div>
</template>

<style lang="scss" scoped>
input::-ms-reveal{
        display: none;
      }
.password-visibility-block {
  .password-visibility-icon {
    position: absolute;
    top: 32px;
    right: 10px;
    width: 25px;
    padding: 0;
    box-shadow: none;
  }
}
.error-message {
  color: red;
}
  .password-popup {
    background: #fff;
    position: absolute;
    top:75px;
    right:0;
    z-index: 1;
    height: 260px;
    width: 300px;
    border-radius: 11px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);

    &:before{
      bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #00435d;
        border-width: 10px;
        margin-left: -10px;
    }
    &_Header {
        background-color: #00435d;
        color: #fff;
        width: 100%;
        padding: 15px;
        border-top-left-radius: 10px;
        border-top-right-radius:10px;
        line-height: 18px;
        letter-spacing: 0.1px;
      }
    ul {
      list-style-type: none;
      padding: auto;
    }
    li {
      display: block;
      margin-bottom: 4px;
      span {
        display: inline-block;
        width: 15px;
        height: 15px;
        border: 2px solid #ccc;
        background-color: #fff;
        border-radius: 10px;
        vertical-align: middle;
      }
      
      &.active {
        span {
          border: 2px solid #28a745;
          background-color: #28a745;
        }
      }
    }
  }
  .tick-icon{
    position: absolute;
    top: 32px;
    right: 35px;
    width: 25px;
    padding: 0;
    box-shadow: none;
}
.custom-placeholder::placeholder {
    color: #ced4da;
}
</style>