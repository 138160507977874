<script>
import Layout from "../../layouts/vertical.vue";
import PageHeader from "@/components/page-header";
// import ManagementTree from "@/components/management-tree";
import OrganizationChart from "@/components/organization-chart";
export default {
  components: { Layout, PageHeader, OrganizationChart },
  data() {
    return {      
    //   loading:true,
    title: "Organization structure",
    items: [
                {
                    text: "Dashboard",
                    routeName: "Dashboard",
                },
                {
                    text: "Organization",
                    active: true,
                },
            ],
    };
  },
  methods: {
    
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div>
      <div>
        <!-- <ManagementTree/> -->
        <OrganizationChart/>
      </div>
    </div>
  </Layout>
</template>

<style scoped lang="scss">
</style>
