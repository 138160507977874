<script>
import Layout from "../../layouts/vertical.vue";
import PageHeader from "@/components/page-header";
// import PreLoader from "@/components/pre-loader";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapState, mapMutations } from "vuex";
import _ from "lodash";

export default {
    components: { Layout, PageHeader, Multiselect },
    data() {
        return {
            userList: [],
            previewUrl: null,
            userType: "",
            title: "Projects",
            items: [
                {
                    text: "Dashboard",
                    routeName: "Dashboard",
                },
                {
                    text: "Projects",
                    active: true,
                },
            ],
            Users: [],
            projectDescription: '',
            isDescriptionValid: true,
            showDismissibleAlert: false,
            currentPage: 1,
            perPage: 12,
            filter: null,
            Sort: "desc",
            Orderby: "",
            ProjectsList: [],
            showShimmers: true,
            value: '',
            selectedUsers: [],
            isUserValid: true,
            AddUser: {
                selectedUsersByProject: { required }
            },
            users: null,
            isUser: false,
            isProjectName: false,
            isShortname: false,
            isDescription: false,
            formChanged: false,
            userSelected: false,
            isError: false,
            errorResponse: null,
            error: "",
            UserList: [],
            userIds: "",
            Projectdetails: {
                ProjectName: "",
                ShortName: "",
                description: "",
                users: null,
            },
            showAllUsers: false,
            duplicateCount: 0,
            ProjectPermisions: [],
            saveDisable: false,

        };
    },
    computed: {
        ...mapState(["getUserguid", "getPermissions", "getUserId"]),
        ...mapGetters("projects", ["getAllProjectsForUser", 'getUsetProjectDetails', 'getReportingUsers', 'getDuplicateProject']),
        ...mapGetters('auth', ['getProfileDetails', 'getUpload']),
        ...mapGetters("errors", ["getError", "getErrors"]),
        ...mapGetters("userservice", [
            "getUserHierarchy",
            "getAllUsers"]),
        sortedUsers() {
            return this.Users.map(a => ({ fullname: a.FirstName + ' ' + a.LastName, Id: a.Id, Email: a.Email })).sort((a, b) => a.fullname.localeCompare(b.fullname));
        },
        isFormInvalid() {
            return (
                !this.Projectdetails.ProjectName || this.duplicateCount > 0 ||
                !this.projectDescription || !this.AddUser.selectedUsersByProject?.length

            );
        },
        rows() {
            return this.ProjectsList[0]?.TotalCount;
        },
        filteredProjectPermisions() {
            let permissions = this.getPermissions?.ProjectRoles
            function hasPermission(permission) {
                return permissions?.includes(permission);
            }
            return this.ProjectPermisions.filter((ProjectPermisions) => {
                return (
                    (ProjectPermisions.permission === "CreateProject" &&
                        hasPermission("CreateProject")) ||
                    (ProjectPermisions.permission === "EditProject" &&
                        hasPermission("EditProject")) ||
                    (ProjectPermisions.permission === "DeleteProject" &&
                        hasPermission("DeleteProject"))
                );
            });
        },
    },
    userProjects() {
        return this.$store.state.projects.ProjectDetails;
    },
    async mounted() {
        this.value = this.getUsetProjectDetails?.Response
        console.log(this.value)
        this.GetProjects();
        this.loadInitData();
    },
    created() {
        this.initProjectPermisions();
    },
    methods: {
        initProjectPermisions() {
            this.ProjectPermisions = [
                {
                    key: "createproject",
                    permission: "CreateProject",
                },
                {
                    key: "editproject",
                    permission: "EditProject",
                },
                {
                    key: "deleteproject",
                    permission: "DeleteProject",
                },
            ];
        },
        hasPermission(permissionToCheck) {
            return this.filteredProjectPermisions.some(
                (permissionObj) => permissionObj.permission === permissionToCheck
            );
        },
        /**
         * Get All projects for user
         */
        async GetProjects() {
            this.showShimmers = true;
            const params = {
                pagenumber: this.currentPage,
                rowcount: this.perPage,
                keyword: this.filter,
                sort: this.Sort,
                orderby: this.Orderby,
                userGuid: this.getUserguid,
            };
            await this.$store.dispatch("projects/UserProjects", params);
            if (this.getAllProjectsForUser?.length > 0) {
                this.ProjectsList = this.getAllProjectsForUser;
            } else if (this.getAllProjectsForUser?.length === 0) {
                this.ProjectsList = [];
            } else {
                this.isError = true;
                this.errorResponse = this.getErrors["critical_error"] || "Something went wrong!";
            }
            this.showShimmers = false;
        },
        ChangePage() {
            this.loadInitData();
            this.GetProjects()
        },

        async loadInitData() {
            this.showShimmers = true;
            const params = {
                pagenumber: -1,
                rowcount: -1,
                keyword: this.filter,
                sort: this.Sort,
                orderby: this.Orderby
            };
            await this.$store.dispatch("userservice/fetchUsers", params);
            this.Users = this.getAllUsers;
            this.showShimmers = false;
        },
        validateUserSelection() {
            this.isUserValid = this.selectedUsers.length > 0;
        },
        validateDescription() {
            this.isDescriptionValid = this.projectDescription.trim() !== '';
        },
        ResetData() {
            this.$refs["myModal"]?.hide();
            this.Projectdetails.ProjectName = "";
            this.previewUrl = "";
            this.projectDescription = "",
                this.Projectdetails.description = "";
            this.duplicateCount = ''
            this.AddUser.selectedUsersByProject = [];
            this.isProjectName = false;
            this.isShortname = false;
            this.isDescription = false;
            this.formChanged = true;
            this.isUser = false;
            this.saveDisable = false
        },
        async previewImage(event) {
            this.showShimmers = true;
            this.formChanged = true
            const allowed_types = ['image/jpeg', 'image/jpg', 'image/png'];
            if (event?.target?.files[0]) {
                const selectedFile = event?.target?.files[0];
                this.message = '';
                if (!_.includes(allowed_types, selectedFile.type)) {
                    this.message = "Please upload jpeg, jpg, png file only!";
                } else if (selectedFile.size > 5242880) {
                    this.message = "File size should not be greater than 5Mb.";
                } else {
                    try {
                        const params = {
                            File: selectedFile,
                            FileName: selectedFile.name
                        };
                        await this.$store.dispatch('auth/fileUpload', params);
                        this.previewUrl = this.getUpload?.result;
                    } catch (error) {
                        console.error("File upload error:", error);
                        this.message = "File upload failed.";
                    }
                }
            }
            this.showShimmers = false;
        },
        ...mapMutations(['setAlertNotification']),
        async SaveProject() {
            this.saveDisable = true;
            await this.$store.dispatch('projects/GetReportingUsers', this.AddUser.selectedUsersByProject.map(user => user.Id).join(','));
            this.userIds = await this.getReportingUsers.map(user => user.UserId);
            const newUserIds = this.AddUser.selectedUsersByProject.map(user => user.Id);
            const combinedUserIds = [...new Set([...this.userIds, ...newUserIds, Number(this.getProfileDetails.UserId)])].filter(Boolean).join(',');
            const SaveObject = {
                ProjectName: this.Projectdetails.ProjectName,
                ProjectDescription: this.projectDescription,
                UserIds: combinedUserIds,
                ProjectThumbnail: this.previewUrl,
                ProjectCreatedId: this.getProfileDetails.UserId || this.getProfileDetails.Id
            }
            await this.$store.dispatch("projects/SaveProjectDetails", SaveObject)
            await this.GetProjects();
            const message = {
                variant: 'success',
                alertTitle: 'Project list updated!',
                alertDecription: this.value
            }
            this.setAlertNotification(message)
            await this.ResetData()
        },

        async getDuplicateProjectName() {
            this.isProjectName = true;
            await this.$store.dispatch('projects/getDuplicateProjectName', this.Projectdetails?.ProjectName);
            this.duplicateCount = this.getDuplicateProject?.DuplicateCount
        },
        removeImage() {
            this.formChanged = true;
            this.previewUrl = '';
            this.message = '';
        },
        uploadImage() {
            this.$refs.fileInput.click();
        },
        /**
 * search functionality for users
 */
        changesearch: _.debounce(function () {
            this.currentPage = 1;
            this.GetProjects();
        }, 800),
        /**
   * redirecting to Add user page
   * @param {*} data
   */
        ProjectClick(data, user) {
            this.$router.push({
                name: data,
                params: {
                    guid: user?.ProjectGuid
                },
            });
        }
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <!-- <div v-if="loading">
            <PreLoader></PreLoader>
        </div> -->

        <div v-if="showShimmers">
            <div class="d-flex justify-content-end">
                <b-skeleton class="mr-2" type="input" width="180px" height="30px"></b-skeleton>
                <b-skeleton type="button" width="100px" height="30px"></b-skeleton>
            </div>
            <div class="row mt-4">
                <div class="col-12 col-sm-6 col-md-6 col-xl-4" v-for="(user, index ) in [].constructor(3)" :key="index">
                    <b-card no-body img-left>
                        <b-card-body class="grid-container no-gutters">
                            <div class="grid-item">
                                <b-skeleton type="avatar" width="40px" height="40px"></b-skeleton>
                            </div>
                            <div class="">
                                <b-skeleton width="60px"></b-skeleton>
                                <b-skeleton width="140px"></b-skeleton>
                            </div>
                        </b-card-body>
                    </b-card>
                </div>
            </div>
        </div>
        <div v-else>
            <b-alert show variant="danger" v-if="isError == true">
                <i class="ri-close-circle-line mr-2 align-bottom"></i>{{ this.errorResponse }}
            </b-alert>
            <div>
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-end">
                            <div class="d-flex">
                                <div class="dataTables_filter text-md-right mb-2">
                                    <label class="d-inline-flex align-items-center position-relative mb-0">
                                        <b-form-input size="sm" v-model="filter" type="search" placeholder="Search..."
                                            @input="changesearch" class="form-control  searchInput"></b-form-input>
                                        <i class="ri-search-line search-icon"></i>
                                    </label>
                                </div>
                                <div class="ml-2 flex-shrink-0" v-if="hasPermission('CreateProject')">
                                    <b-button size="sm" variant="primary" @click="ResetData" v-b-modal.modal-center>
                                        <i class="ri-add-line align-bottom"></i> Add project
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-4 mb-4" v-for="user in ProjectsList" :key="user.Id">
                        <div class="card h-100 c-pointer" @click="ProjectClick('ProjectDetails', user)">
                            <div class="card-body">
                                <div class="grid-container no-gutters">
                                    <div class="grid-item">
                                        <div class="project-logo">
                                            <img v-if="user.ProjectThumbnail" :src="user.ProjectThumbnail"
                                                alt="Project thumbnail">
                                            <div v-else>{{ user.ProjectName.charAt(0) }}</div>
                                        </div>

                                    </div>
                                    <div class="grid-item ">
                                        <h4 class="card-title font-weight-bold text-truncate" :title="user.ProjectName">{{
                                            user.ProjectName }}</h4>
                                        <p class="text-muted truncate-overflow" :title="user.ProjectDescription">
                                            {{ user.ProjectDescription || '-' }}
                                        </p>
                                    </div>
                                    <div class="grid-item positioin-relative">
                                        <div class="members">
                                            <p class="card-text mb-0">Members </p>
                                            <span><img src="../../../assets/images/users/user.png" alt="avatar"
                                                    class="member-avatar" /></span>
                                            <span><img src="../../../assets/images/users/user.png" alt="avatar"
                                                    class="member-avatar" style="margin-left:-6px" /></span>
                                            <span><img src="../../../assets/images/users/user.png" alt="avatar"
                                                    class="member-avatar" style="margin-left:-6px" /></span>
                                            <span v-if="user.UsersCount > 3" class="badge badge-soft-primary ml-2">+{{
                                                user.UsersCount - 3 }}</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" v-if="!ProjectsList.length && !showShimmers">
                        <!-- No data found message-->
                        <div class="card">
                            <div class="nodata-ui">
                                <div class="fullscreen-height d-flex align-items-center justify-content-center">
                                    <div class="py-5 text-muted">
                                        <div
                                            class="d-flex flex-column justify-content-center gap-1 align-items-center fs-4">
                                            <i class="ri-information-line font-size-36 text-muted"></i>
                                            <h5 class="text-muted">No projects found!</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- No data found message end-->
                    </div>
                </div>
            </div>
            <div class="row mt-2" v-if="rows">
                <div class="col">
                    <div class="paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                                @input="ChangePage()"></b-pagination>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <b-modal ref="myModal" id="modal-center" centered title="Add project" @click="ResetData" title-class="font-18"
            hide-footer no-close-on-backdrop>
            <form>
                <label for="">Project thumbnail
                    <!-- <span :class="{ 'text-danger': !this.previewUrl }">*</span> --></label>
                <div class="profilepic-upload mb-3">
                    <button class="btn btn-primary ytm-file-input" v-if="!previewUrl">
                        <input type="file" ref="fileInput" @change="previewImage" accept=".jpg,.png,.jpeg,.img" title="" />
                        <i class="ri-camera-3-line"></i>
                    </button>
                    <div class="profile-pic-container">
                        <div class="image-container">
                            <div class="profile-pic">
                                <img src="@/assets/images/project-thumbnail.png" alt="Default Profile Picture"
                                    v-if="!previewUrl" />
                                <img :src="previewUrl" alt="Preview" v-if="previewUrl" />
                            </div>
                            <span class="remove-text font-size-16 font-weight-bold" v-if="previewUrl"
                                @click="removeImage">Remove</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="form-group">
                    <label for="taskName">Project thumbnail
                        <span :class="{ 'text-danger': '' }">*</span></label>
                    <input type="text" class="form-control" placeholder="Enter project thumbnail" />
                    <span class="text-danger">Project thumbnail is required!</span>
                </div> -->
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label for="">Project name <span
                                    :class="{ 'text-danger': !this.Projectdetails.ProjectName }">*</span></label>
                            <input type="text" @blur="getDuplicateProjectName" class="form-control" @keydown.enter.prevent
                                placeholder="Enter project name" :class="[
                                    !this.Projectdetails.ProjectName && isProjectName
                                        ? 'border border-danger'
                                        : 'form-control',
                                ]" v-model="Projectdetails.ProjectName" />
                            <span class="text-danger" v-if="!this.Projectdetails.ProjectName && isProjectName">Project name
                                is required!</span>
                            <span class="text-danger" v-else-if="this.duplicateCount > 0">Project name is already
                                exists!</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="form-group position-relative">
                            <label for="">User <span
                                    :class="{ 'text-danger': !AddUser.selectedUsersByProject?.length }">*</span></label>
                            <div
                                :class="[!AddUser.selectedUsersByProject?.length && isUser ? 'multiSelectError' : 'multi-selector-container',]">
                                <multiselect v-model="AddUser.selectedUsersByProject" :options="sortedUsers"
                                    v-bind:class="{ 'is-invalid': !AddUser.selectedUsersByProject?.length && isUser }"
                                    label="fullname" track-by="Id" placeholder="Select users" :multiple="true"
                                    @close="isUser = true"
                                    :limit="showAllUsers ? AddUser.selectedUsersByProject?.length : 3"
                                    :close-on-select="false">
                                    <template slot="option" slot-scope="{ option }">
                                        {{ option ? option.fullname + ' ( ' +
                                            option.Email
                                            + ' )' : 'Select User'
                                        }}
                                    </template>
                                    <template slot="selected-option" slot-scope="{ option }">
                                        {{ option.fullname }} {{ option.Email }}
                                    </template>
                                </multiselect>
                            </div>
                            <span class="text-danger" v-if="!AddUser.selectedUsersByProject?.length && isUser">Project user
                                is required!</span>
                        </div>
                    </div>
                    <!--<div class="col-sm-6">
                        <div class="form-group">
                            <label for="">Project short name <span :class="{ 'text-danger': !this.Projectdetails.ShortName }" >*</span></label>
                              <input type="text" @blur="isShortname = true" class="form-control" placeholder="Enter project short name" :class="[
                                 !this.Projectdetails.ShortName && isShortname
                                ? 'border border-danger'
                               : 'form-control',
                            ]" v-model="Projectdetails.ShortName" />
                            <span class="text-danger" v-if="!this.Projectdetails.ShortName && isShortname">project name is required!</span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="">Project lead <span :class="{ 'text-danger': '' }">*</span></label>
                            <multiselect :options="ProjectLead" v-model="projectLead" placeholder="Select project lead">
                            </multiselect>
                            <span class="text-danger">Project lead is required!</span>
                        </div>
                    </div>-->
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label for="">Project description
                                <span :class="{ 'text-danger': !projectDescription }">*</span></label>
                            <textarea rows="3" placeholder="Enter project description" :maxlength="500"
                                class="form-control 'border border-danger'" @blur="isDescription = true"
                                v-model="projectDescription" @input="validateDescription" :class="[
                                    !this.projectDescription && isDescription
                                        ? 'border border-danger'
                                        : 'form-control',
                                ]"></textarea>
                            <span class="text-danger" v-if="!projectDescription && isDescription">Project description is
                                required!</span>
                        </div>
                    </div>
                </div>
                <div class="text-right mt-4">
                    <b-button class="ml-1 mr-2" type="button" variant="outline-primary" @click="ResetData">
                        <i class="ri-close-line align-bottom"></i> Cancel</b-button>
                    <button type="button" class="btn btn-primary" @click="SaveProject"
                        :disabled="isFormInvalid || saveDisable">
                        <i class="ri-save-line align-bottom"></i> Save
                    </button>
                </div>

            </form>
        </b-modal>
        <!-- end modal -->
    </Layout>
</template>

<style scoped lang="scss">
.c-pointer {
    cursor: pointer;
}

.member-avatar {
    width: 20px;
    height: 20px;
    box-shadow: 2px 2px 2px 2px #ddd;
    border-radius: 30px;
}

.grid-container {
    display: grid;
    grid-template-columns: 55px auto 100px;
    row-gap: 10px;
    column-gap: 10px;

}

.project-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00435d;
    color: #fff;
    text-align: center;
    width: 50px;
    height: 50px;
    border-radius: 6px;
    overflow: hidden;
    font-size: 30px;
    font-weight: bold;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.members {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.fullscreen-height {
    height: 480px
}

.dataTables_filter {
    .search-icon {
        position: absolute;
        right: 6px;
    }

    .ri-search-line:before {
        content: "\f06f";
        color: #00435d;
        vertical-align: middle;
    }
}

.profilepic-upload {
    position: relative;
    margin: auto;
}

.profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    // border: 2px solid #00435d;
    // background-color: #00435d;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.ytm-file-input {
    position: absolute;
    border-radius: 50% !important;
    left: 75px;
    top: 50px;
    width: 40px;
    height: 40px;
    z-index: 1;
}

.ytm-file-input input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    cursor: pointer;
}

.profile-pic-container {
    position: relative;
    display: inline-block;
}

.image-container {
    position: relative;
    display: inline-block;
}

.remove-text {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px;
    cursor: pointer;
    width: 100%;
    top: 0;
    color: #00435d;
}

.image-container:hover .remove-text {
    display: flex;
    justify-content: center;
    align-items: center;

}

/* Additional styling for the remove button */
.remove-text:hover {
    background-color: rgba(255, 255, 255, 0.8);
}

.remove-btn {
    position: absolute;
    bottom: 0;
    border-radius: 30px;
    left: 75px;
    top: 50px;
    width: 40px;
    height: 40px;
    z-index: 2;
    display: flex;
    justify-content: center;
}

.edittask-alert {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 10000;
}

.truncate-overflow {
    --max-lines: 3;
    position: relative;
    max-height: 42px;
    overflow: hidden;
    padding-right: 0.5rem;

    &::before {
        position: absolute;
        content: "...";
        bottom: 0;
        right: 0;
    }

    &::after {
        content: "";
        position: absolute;
        right: 0;
        width: 1rem;
        height: 1rem;
        background: white;
    }
}

.show-users {
    position: absolute;
    right: 0;
}
</style>
