<script>
import Layout from "../../layouts/vertical.vue";
import PageHeader from "@/components/page-header";
// import PreLoader from "@/components/pre-loader";
import { mapGetters, mapState, mapMutations } from "vuex";

export default {
    components: { Layout, PageHeader},
    data() {
        return {
            usersList: [],
            ProjectDetails : [],
            userType: "",
            ProjectGuid : '',
            currentPage: -1,
            perPage: -1,
            filter: null,
            Sort: "desc",
            Orderby: "",
            isDeleted  : false,
            title: "Project details",
            ProjectId : '',
            items: [
                {
                    text: "Projects",
                    routeName: "Projects",
                },
                {
                    text: "Project details",
                    active: true,
                },
            ],
            // loading: false,
            showShimmers: false,
        };
    },
    computed: {
        ...mapState(['getUserId',"getUserguid"]),
        ...mapGetters("projects", ["getAllProjectsForUser","getAllUsersByProject","getTaskCount"]),
        ...mapGetters('auth', ['getAuthenticatedUser' , 'getProfileDetails']),
        ...mapGetters('tasks', ['getpercentageCount' ]),
    },
    async mounted() {
        //  this.loading=true
        this.showShimmers = true;
         setTimeout(() => {
            // this.loading=false
            this.showShimmers = false;
            }, 3000);
       await this.GetProjects();
    },
    methods: {
        async GetProjects() {
            this.ProjectGuid = this.$route.params.guid
           // this.loading = true;
            const params = {
                pagenumber: this.currentPage,
                rowcount: this.perPage,
                keyword: this.filter,
                sort: this.Sort,
                orderby: this.Orderby,
                userGuid: this.getUserguid,
            };
            await this.$store.dispatch("projects/UserProjects", params);
            if (this.getAllProjectsForUser?.length > 0) {
                const projects=this.getAllProjectsForUser
                this.ProjectDetails =projects.filter(item=>item.ProjectGuid == this.ProjectGuid)[0];
                this.ProjectId=this.ProjectDetails.Id
            } else {
                this.ProjectDetails = [];
            }
            this.GetUsersByProject()
          //  this.loading = false;
            const param = {
                ProjectId : this.ProjectId
            }
            await this.$store.dispatch('projects/getTaskCount',param);
        },
        async GetUsersByProject() {
            // this.loading = true;
            this.showShimmers = true;
           setTimeout(() => {
        //    this.loading = false;
        this.showShimmers = false;
        
          }, 2000);
            const params = {
                pagenumber: this.currentPage,
                rowcount: this.perPage,
                keyword: this.filter,
                sort: this.Sort,
                orderby: this.Orderby,
                ProjectId: this.ProjectDetails.Id,
            };
            await this.$store.dispatch("projects/GetUsersByProject", params);
            if (this.getAllUsersByProject?.length > 0) {
                this.usersList=this.getAllUsersByProject
            } else {
                this.usersList = [];
            }
           // this.loading = false;
        },
        MembersClick(data, user) {
            this.$router.push({
                name: data,
                params: {
                    Id: user
                },
            });
        },
        ...mapMutations(['setNew']),
        projectTasks(data) {
            let projectData={
                filter:data,
                ProjectId:this.ProjectId,
                ProjectName:this.ProjectDetails.ProjectName,
                ProjectGuid: this.ProjectGuid
            }
            this.$router.push({ name: 'UserView' }) 
            this.setNew(projectData)
        }

    }
};

</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <!-- <div v-if="loading">
            <PreLoader></PreLoader>
        </div> -->
        <div v-if="showShimmers">
           <div class="row">
              <b-col cols="12" class="mt-3">
                <b-skeleton-img no-aspect height="100px"></b-skeleton-img>
              </b-col>
           </div>
            <div class="row flex-column flex-sm-row" cols="12">
              <b-col cols="8" class="mt-3 mw-100">
                 <b-skeleton-img no-aspect height="100px"></b-skeleton-img>
              </b-col>
              <b-col cols="4" class="mt-3 mw-100">
                 <b-skeleton-img no-aspect height="300px"></b-skeleton-img>
                 <b-skeleton-img class="mt-3" no-aspect height="100px"></b-skeleton-img>
              </b-col>
           </div>
        </div>
        <div v-else>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="grid-container">
                            <div class="grid-item">
                                <div class="project-logo" >
                                    <img v-if="ProjectDetails.ProjectThumbnail" :src="ProjectDetails.ProjectThumbnail" alt="Project thumbnail">
                                    <div v-else>{{ ProjectDetails.ProjectName?.charAt(0) }}</div>
                                </div>
                            </div>
                            <div class="align-self-center grid-item">
                                <h1 class="project-title text-truncate mb-0" :title="ProjectDetails.ProjectName">{{ProjectDetails.ProjectName}}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="card">
                    <div class="card-body">
                        <div class="grid-container">
                            <div class="grid-item ">
                                <h4 class="card-title font-size-16 text-dark" title="Project details">Project details</h4>
                                <p class="text-muted mb-0" :title="ProjectDetails.ProjectDescription">
                                  {{ProjectDetails.ProjectDescription}}
                                </p>
                                <p class="text-muted mb-0 font-weight-bold" :title="ProjectDetails.ProjectCreatedUser">
                                    Created by : 
                                    <span v-if="ProjectDetails.ProjectCreatedUser === 'sa' || !ProjectDetails.ProjectCreatedUser">N/A</span>
                                    <span v-else>{{ ProjectDetails.ProjectCreatedUser }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">

                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title font-size-16 text-dark mb-4" title="Tasks status">Tasks status</h4>
                        <div class="grid-container project-stats">
                            <div class="grid-item" @click="projectTasks('New')">
                                <div class="project-stats-icon">
                                    <i class="ri-history-line font-size-16 text-primary"></i>
                                </div>
                                <div class="stats-details ml-3 c-pointer" >
                                    <p class="font-weight-bold text-truncate mb-0 text-dark" :title="getTaskCount?.newCount">{{getTaskCount?.newCount}}</p>
                                    <p class="text-muted mb-0" title="New tasks">New tasks</p>
                                </div>
                            </div>
                            <div class="grid-item" @click="projectTasks('Active')">
                                <div class="project-stats-icon ">
                                    <i class="ri-timer-flash-line font-size-16 text-primary"></i>
                                </div>
                                <div class="stats-details ml-3 c-pointer">
                                    <p class="font-weight-bold text-truncate mb-0 text-dark" :title="getTaskCount?.activeCount">{{getTaskCount?.activeCount}}</p>
                                    <p class="text-muted mb-0" title="Active tasks">Active tasks</p>
                                </div>
                            </div>
                            <div class="grid-item" @click="projectTasks('Completed')">
                                <div class="project-stats-icon">
                                    <i class="ri-check-double-line font-size-16 text-primary"></i>
                                </div>
                                <div class="stats-details ml-3 c-pointer">
                                    <p class="font-weight-bold text-truncate mb-0 text-dark" :title="getTaskCount?.completedCount">{{getTaskCount?.completedCount}}</p>
                                    <p class="text-muted mb-0" title="Completed tasks">Completed tasks</p>
                                </div>
                            </div>
                            <div class="grid-item" @click="projectTasks('In-progress')">
                                <div class="project-stats-icon">
                                    <i class="ri-database-line font-size-16 text-primary"></i>
                                </div>
                                <div class="stats-details ml-3 c-pointer">
                                    <p class="font-weight-bold text-truncate mb-0 text-dark" :title="getTaskCount?.inProgressCount">{{getTaskCount?.inProgressCount}}</p>
                                    <p class="text-muted mb-0" title="In-Progress tasks">In-Progress tasks</p>
                                </div>
                            </div>
                            <div class="grid-item" @click="projectTasks('In-active')">
                                <div class="project-stats-icon">
                                    <i class="ri-database-line font-size-16 text-primary"></i>
                                </div>
                                <div class="stats-details ml-3 c-pointer">
                                    <p class="font-weight-bold text-truncate mb-0 text-dark" :title="getTaskCount?.InactiveCount">{{getTaskCount?.InactiveCount}}</p>
                                    <p class="text-muted mb-0" title="In-active tasks">In-active tasks</p>
                                </div>
                            </div>
                            <div class="grid-item" @click="projectTasks('Overdue')">
                                <div class="project-stats-icon">
                                    <i class="ri-file-list-3-line font-size-16 text-primary"></i>
                                </div>
                                <div class="stats-details ml-3 c-pointer">
                                    <p class="font-weight-bold text-truncate mb-0 text-dark" :title="getTaskCount?.overdueCount">{{getTaskCount?.overdueCount}}</p>
                                    <p class="text-muted mb-0" title="Overdue tasks">Overdue tasks</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" >
                    <div class="card-body">
                        <div class="grid-container">
                            <div class="grid-item positioin-relative">
                                <div class="members"  @click="MembersClick('ProjectTeam',ProjectId)">
                                    <h4 class="card-title font-size-16 text-dark mb-4" title="Members">Members <span
                                            class="badge badge-soft-primary ml-2 font-weight-normal" :title="ProjectDetails.UsersCount">+{{ProjectDetails.UsersCount}}</span>
                                    </h4>
                                    <span  v-for="user in usersList" :key="user.userId">
                                        <a href="javascript: void(0);" class="d-inline-block mr-2 mb-2" v-b-tooltip.hover :title="user.FullName">
                                            <img  :src="user.image" alt="avatar"
                                            class="member-avatar" title=""  v-if="user.image"/>
                                            <img   src="@/assets/images/users/user.png" alt="avatar"  v-else
                                            class="member-avatar" title=""/>
                                        </a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </Layout>
</template>

<style lang="scss" scoped>
.grid-container {
    display: grid;
    grid-template-columns: auto 1fr;
    row-gap: 10px;
    column-gap: 10px;

    .project-title {
        font-weight: bold;
        font-size: 24px;
    }

    .members{
        height: 80px;
        overflow: hidden;
    }
}

.project-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00435d;
    color: #fff;
    text-align: center;
    width: 50px;
    height: 50px;
    border-radius: 6px;
    overflow: hidden;
    font-size: 30px;
    font-weight: bold;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.member-avatar {
    width: 35px;
    height: 35px;
    border-radius: 30px;
    object-fit: cover;
    
}

.project-stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 50px;
    column-gap: 10px;

    .grid-item {
        display: flex;
    }

    .project-stats-icon {
        background: rgb(0 67 93 / 28%);
        width: 35px;
        height: 40px;
        border-radius: 4px;
        display: grid;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .project-title {
        font-weight: bold;
        font-size: 24px;
    }
}
.c-pointer {
    cursor: pointer;
}
</style>