<script>
import Layout from "../../layouts/vertical.vue";
import PageHeader from "@/components/page-header";
// import PreLoader from "@/components/pre-loader";
import { mapGetters, mapState, mapMutations } from "vuex";
import Swal from "sweetalert2";
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    // PreLoader
  },

  data() {
    return {
      cardsData: [],
      dashboardItems: [],
      filterTableData: [],
      // loading: true,
      showShimmers: true,
      isShow: true,
      HeirachyUsers: [],
      TaskPermisions: [],
      taskname: "",
      title: "",
      DashboardPercentage: null,
      zerovalue: "",
      errorResponse: null,
      items: [
        {
          text: "",
        },

        // {
        //   text: "Dashboard",
        //   active: true,
        // },
      ],
      rows: null,
      series: [],
      chartOptions: {
        chart: {
          height: 230,
          width: "100%",
          type: "donut",
        },
        labels: [
          "New tasks",
          "Completed tasks",
          "In-Progress tasks",
          "Overdue  tasks",
          "Active tasks",
          "No data found!",
        ],
        plotOptions: {
          pie: {
            size: 200,
            donut: {
              size: "75%",
            },
            expandOnClick: false
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        states: {
          active: {
            filter: {
              type: 'none',
              value: 0,
            }
          },
        },
        colors: ["#00435d", "#1cbb8c", "#eeb902", "#FF3D60", "#007acc", "#74788d"],
      },
      transactionData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      isDeleted: false,
      isError: false,
      fields: [
        { key: "Id", sortable: true, label: "ID", class: ["text-truncate", "mw-150"] },
        { key: "TaskName", label: "Task name", sortable: true, class: ["text-truncate", "mw-150"] },
        { key: "ProjectName", sortable: true, label: "Project name", class: ["text-truncate", "mw-150"] },
        { key: "AssignedBy", sortable: true, label: "Assigned by", class: ["text-truncate", "mw-150"] },
        { key: "CreatedDt", sortable: true, label: "Created date", class: ["text-truncate", "mw-150"] },
        { key: "Status", sortable: true, label: "Status", class: ["text-truncate", "mw-150"] },

      ],
      activityData: [
        {
          icon: "ri-edit-2-fill",
          date: "28 Aug, 2023 ",
          time: "12:07 am",
          title: "Created",
        },
        {
          icon: "ri-user-2-fill",
          date: "21 Aug, 2023 ",
          time: "08:01 pm",
          title: "New",
        },
        {
          icon: "ri-bar-chart-fill",
          date: "17 Aug, 2023 ",
          time: "05:10 pm",
          title: "In-Progress",
        },
        {
          icon: "ri-calendar-2-fill",
          date: "07 Aug, 2023",
          time: "12:47 pm",
          title: "Created”",
        },
        {
          icon: "ri-edit-2-fill",
          date: "05 Aug, 2023 ",
          time: "03:09 pm",
          title: "Completed",
        },
        {
          icon: "ri-user-2-fill",
          date: "02 Aug, 2023 ",
          time: "12:07 am",
          title: "In-Progress",
        },
      ],
    };

  },
  computed: {
    ...mapState(['getUserId', 'Username', 'getPermissions', 'getUserIds', 'getUserguid']),
    ...mapGetters("userservice", ['getUserDetailsById']),
    ...mapGetters('tasks', ['gettaskscounts', 'getViewAllTaskstoId', 'getpercentageCount', 'getTaskData']),
    ...mapGetters('auth', ['getProfileDetails', 'getAuthenticatedUser']),
    ...mapGetters("errors", ["getErrors"]),
    // userHierarchy() {
    //   const allUsers = this.getUserHierarchy || [];
    //   const filteredUsers = allUsers.filter(user => user.Level !== 0);
    //   return filteredUsers;
    // },
    filteredTaskPermisions() {
      let permissions = this.getPermissions?.TaskRoles
      function hasPermission(permission) {
        return permissions.includes(permission);
      }
      return this.TaskPermisions.filter(TaskPermisions => {
        return (
          (TaskPermisions.permission === "DeleteTask" && hasPermission("DeleteTask")) ||
          (TaskPermisions.permission === "EditTask" && hasPermission("EditTask"))
        );
      });
    },
  },
  /**
   * Total no. of records
   */
  rows() {
    return this.transactionData.length;
  },


  // 
  async mounted() {
    await this.UserHierarchy();
    //this.ifActions();
  },
  created() {
    this.pageHeaderTitle()
    this.initTaskPermisions();
  },
  methods: {

    async getDashboardCardsData() {
      this.cardsData = await [
        {
          title: "New tasks",
          icon: "ri-history-line",
          value: this.getpercentageCount?.recentCount || 0,
          filter: "New"
          //subvalue:recentCountPercentage?.toFixed(2)  + " %"
        },
        {
          title: "Active tasks",
          icon: "ri-timer-flash-line",
          value: this.getpercentageCount?.activecount || 0,
          filter: "Active"
          //subvalue:overduePercentage?.toFixed(2) +  " %"
        },
        {
          title: "In-Progress tasks",
          icon: "ri-database-line",
          value: this.getpercentageCount?.inProgressCount || 0,
          filter: "In-progress"
          //subvalue:inProgressPercentage?.toFixed(2) + " %"
        },
        {
          title: "Completed tasks",
          icon: "ri-check-double-line",
          value: this.getpercentageCount?.completedCount || 0,
          filter: "Completed"
          //subvalue: compltetedPercentage?.toFixed(2) + " %"
        },
        {
          title: "Overdue tasks",
          icon: "ri-file-list-3-line",
          value: this.getpercentageCount?.overdueCount || 0,
          filter: "Over due"
          //subvalue:overduePercentage?.toFixed(2) +  " %"
        }
      ]
      // this.loading = false,
      this.showShimmers = false
    },
    ...mapMutations(['setNew']),
    async taskdata(data) {
      this.setNew(data.filter)
      await this.$router.push({ name: 'TaskList' });
    },

    //checks the user has specific permission or not.
    hasPermission(permissionToCheck) {
      return this.filteredTaskPermisions.some(
        (permissionObj) => permissionObj.permission === permissionToCheck
      );
    },
    cancel(Id, value) {
      this.taskname = value.TaskName
      const idArrayString = JSON.stringify([Id]);
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2"
        },
        buttonsStyling: false
      });

      let truncatedTaskName = this.taskname.length > 15 ? this.taskname.slice(0, 15) + "..." : this.taskname;
      let popUpText = this.deleteItem.length > 1 ? "You want to delete these tasks!" : `You want to delete task <span style="color: black; font-weight: bold;">${truncatedTaskName}</span>?`;

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          html: '<div class="text-truncate">' + popUpText + '</div>',
          // icon: "warning",
          confirmButtonText: "Yes, delete",
          cancelButtonText: "No, cancel",
          showCancelButton: true,
          allowOutsideClick: false,
          imageUrl: "/images/emailexpired_icon.png",
          imageHeight: 50, 
          imageWidth: 50,
        })
        .then(result => {
          if (result.value) {
            this.deleteItem(idArrayString)
            // this.loading = true,
            this.showShimmers = true
          }
        });
    },
    async deleteItem(Id) {
      const ids = JSON.parse(Id)
      const params = {
        taskIds: ids,
        IsDeleted: true
      }
      await this.$store.dispatch('tasks/DeleteTaskData', params);
      this.UserHierarchy()
      // await this.GetAllStatusList();
      // this.loading = false,
      this.showShimmers = false
      Swal.fire({
        icon: "success",
        title: "Deleted!",
        text: "Task has been deleted successfully.",
        showConfirmButton: false,
        timer: 2000,        
      });

    },

    ChangePage() {
      // this.loading = true;
      this.showShimmers = true;
      setTimeout(() => {
        // this.loading = false;
        this.showShimmers = false;
        this.loadInitData()
      }, 1000);
    },
    changesearch() {
      // this.loading = true;
      this.showShimmers = true;
      setTimeout(() => {
        // this.loading = false;
        this.showShimmers = false;
      }, 1000);
    },
    ...mapMutations(['setUserId', 'setUserGuid']),
    async pageHeaderTitle() {
      this.title = 'Welcome' + ' ' + (this.Username);
    },
    //  get a taskscount data
    async UserHierarchy() {
      // const params = {
      //   UserId: this.getUserguid
      // }
      // await this.$store.dispatch('userservice/GetUserHierarchy', params);
      // this.HeirachyUsers = await this.getUserHierarchy ? this.getUserHierarchy?.map(x => x.UserId) : null
      const body = {
        assignedToIdValues: Number(this.getUserId || this.getProfileDetails?.UserId || this.getProfileDetails?.Id),
        isDeleted: this.isDeleted,
        CommentedUserId: Number(this.getUserId || this.getUserDetailsById?.UserId || this.getUserDetailsById?.Id)
      }

      // await this.$store.dispatch('tasks/GetAllTasksAssigned', body);
      await this.$store.dispatch('tasks/GetDashboardDataCountList', body)
      await this.getAllTaskListData()
    },

    async getAllTaskListData() {
      if (this.getpercentageCount) {
        this.DashboardPercentage = await this.getpercentageCount;
        const RecentTask = await this.DashboardPercentage?.recentTasksData;
        this.filterTableData = await RecentTask?.filter(element => element.AssignedToId >= 0).map(task => {
          return {
            value: task?.Level1,
            _id: task?._id,
            Id: task?.Id,
            TaskName: task?.TaskName,
            AssignedById: task?.AssignedById,
            AssignedToId: task?.AssignedToId,
            Comments: task?.Comments,
            EndDate: task?.EndDate ? new Date(task.EndDate)?.toLocaleDateString('en-GB')?.replace(/\//g, '-') : '',
            StatusId: task?.StatusId,
            IsDeleted: task?.IsDeleted,
            CreatedBy: task?.CreatedBy,
            ModifiedName: task?.ModifiedName,
            AssignedTo: task?.AssignedTo || "-",
            Status: task?.Status,
            AssignedBy: task?.AssignedBy,
            CreatedDt: task?.CreatedDt ? new Date(task.CreatedDt)?.toLocaleDateString('en-GB')?.replace(/\//g, '-') : '',
            ModifiedDt: task?.ModifiedDt,
            type: task?.type,
            TaskId: task?.TaskId,
            ProjectName: task?.ProjectName
          };

        }) || [];
      } else {
        if (this.getErrors["critical_error"]) {
          this.isError = true;
          this.errorResponse = this.getErrors["critical_error"];
        } else {
          this.isError = true;
          this.errorResponse = "Something went wrong !";
        }
      }
      await this.getDashboardCardsData();
      await this.getDashboardCounts();
      // this.loading = false;
      this.showShimmers = false;
      this.rows = this.getpercentageCount?.recentTasksData.length;
    },

    async getDashboardCounts() {
      //await this.UserHierarchy();
      ///const totalTasks = await (this.getTaskData?.filter(element => element.AssignedToId).length || 0);
      const DashboardPercentageCount = this.getpercentageCount;
      const recentCount = await DashboardPercentageCount?.recentCount || 0;
      const completedCount = await DashboardPercentageCount?.completedCount || 0;
      const inProgressCount = await DashboardPercentageCount?.inProgressCount || 0;
      const overdueCount = await DashboardPercentageCount?.overdueCount || 0;
      const activecount = await DashboardPercentageCount?.activecount || 0;

      // Number of fields: Recent, Completed, In-Progress, Overdue,active
      const totalCount = await (recentCount + completedCount + inProgressCount + overdueCount + activecount);
      this.zerovalue = totalCount
      const adjustedPercentages = {
        recentPercentage: totalCount > 0 ? ((recentCount / totalCount) * 100).toFixed(1) : 0,
        completedPercentage: totalCount > 0 ? ((completedCount / totalCount) * 100).toFixed(1) : 0,
        inProgressPercentage: totalCount > 0 ? ((inProgressCount / totalCount) * 100).toFixed(1) : 0,
        overduePercentage: totalCount > 0 ? ((overdueCount / totalCount) * 100).toFixed(1) : 0,
        activePercentage: totalCount > 0 ? ((activecount / totalCount) * 100).toFixed(1) : 0,
      };

      const sumOfPercentages =
        parseFloat(adjustedPercentages.recentPercentage) +
        parseFloat(adjustedPercentages.completedPercentage) +
        parseFloat(adjustedPercentages.inProgressPercentage) +
        parseFloat(adjustedPercentages.overduePercentage) +
        parseFloat(adjustedPercentages.activePercentage);

      let adjustment = 0;

      if (sumOfPercentages !== 100 && sumOfPercentages > 0) {
        adjustment = 100 - sumOfPercentages;
      }

      if (adjustment !== 0) {
        if (adjustedPercentages.recentPercentage !== "0.0") {
          adjustedPercentages.recentPercentage = (parseFloat(adjustedPercentages.recentPercentage) + adjustment).toFixed(1);
        } else if (adjustedPercentages.completedPercentage !== "0.0") {
          adjustedPercentages.completedPercentage = (parseFloat(adjustedPercentages.completedPercentage) + adjustment).toFixed(1);
        } else if (adjustedPercentages.inProgressPercentage !== "0.0") {
          adjustedPercentages.inProgressPercentage = (parseFloat(adjustedPercentages.inProgressPercentage) + adjustment).toFixed(1);
        } else if (adjustedPercentages.overduePercentage !== "0.0") {
          adjustedPercentages.overduePercentage = (parseFloat(adjustedPercentages.overduePercentage) + adjustment).toFixed(1);
        } else if (adjustedPercentages.activePercentage !== "0.0") {
          adjustedPercentages.activePercentage = (parseFloat(adjustedPercentages.activePercentage) + adjustment).toFixed(1);
        }
      }


      // let totalzeroPercentages = await totalCount === 0 ? 100 : 0;
      this.dashboardItems = [
        {
          TasksOverview: `${adjustedPercentages.recentPercentage} %`
        },
        {
          TasksOverview: `${adjustedPercentages.completedPercentage} %`
        },
        {
          TasksOverview: `${adjustedPercentages.inProgressPercentage} %`
        },
        {
          TasksOverview: `${adjustedPercentages.overduePercentage} %`
        },
        {
          TasksOverview: `${adjustedPercentages.activePercentage} %`
        },
        // {
        //   TasksOverview: `${totalzeroPercentages} %`
        // },
      ]
      const series = this.dashboardItems.map(item => parseFloat(item.TasksOverview));
      this.series = series;
      // this.loading = false
      this.showShimmers = false;
    },
    ...mapMutations(['setTaskId']),
    async viewItem(Task) {
      let Id;
      if (Task.type == this.assignedByType || Task.type == this.assignedToType) {
        Id = Task?.Id;
        await this.setTaskId(Task.Id);
      }
      else {
        Id = Task?.TaskId;
        await this.setTaskId(Task.TaskId);
      }

      await this.$router.push({ name: "ViewTask", params: { Id: Id } });
    },
    initTaskPermisions() {
      this.TaskPermisions = [
        {
          key: 'deletetask',
          permission: 'DeleteTask',
        },
        {
          key: 'edittask',
          permission: 'EditTask',
        },
      ]
    },
    // ifActions() {
    //   if (this.hasPermission('DeleteTask') || this.hasPermission('EditTask')) {
    //     this.fields.push({ key: "action" });
    //   }
    // },
    // onFiltered(filteredItems) {
    //   this.totalRows = filteredItems.length;
    //   this.currentPage = 1;
    // },
  },
};
</script>

<template>
  <Layout>
    <div>
      <PageHeader :title="title" :items="items" />
      <!-- <div v-if="loading">
        <PreLoader></PreLoader>
      </div> -->
      <b-alert show variant="danger" v-if="isError == true">
        <i class="ri-close-circle-line mr-2 align-bottom"></i>{{ this.errorResponse }}
      </b-alert>
      <div v-if="showShimmers">
        <b-row class="flex-column flex-sm-row">
          <b-col class="mb-2">
            <b-skeleton-img aspect="3:1"></b-skeleton-img>
          </b-col>
          <b-col class="mb-2">
            <b-skeleton-img aspect="3:1"></b-skeleton-img>
          </b-col>
          <b-col class="mb-2">
            <b-skeleton-img aspect="3:1"></b-skeleton-img>
          </b-col>
          <b-col class="mb-2">
            <b-skeleton-img aspect="3:1"></b-skeleton-img>
          </b-col>
          <b-col class="mb-2">
            <b-skeleton-img aspect="3:1"></b-skeleton-img>
          </b-col>
        </b-row>
        <b-row class="flex-column flex-sm-row">
          <b-col cols="9" class="mt-3 mw-100">
            <b-skeleton-img no-aspect height="300px"></b-skeleton-img>
          </b-col>
          <b-col cols="3" class="mt-3 mw-100">
            <b-skeleton-img no-aspect height="300px"></b-skeleton-img>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <div class="row flex-column flex-sm-row">
          <!-- <div v-if="loading == true">
            <PreLoader></PreLoader>
          </div> -->
          <div class="col-12 col-md-4 col-lg" v-for="(data, index) in cardsData" :key="index">
            <div class="card c-pointer">
              <div class="card-body" @click="taskdata(data)">
                <div class="media">
                  <div class="media-body overflow-hidden">
                    <p class="text-truncate font-size-14 mb-2">{{ data.title }}</p>
                    <h4 class="mb-0">{{ data.value }}</h4>
                  </div>
                  <div class="text-primary">
                    <i :class="`${data.icon} font-size-24`"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-9 mobile-bottom">
            <div class="card h-100">
              <div class="card-body">
                <h4 class="card-title mb-4">My Tasks</h4>
                <div class="row mt-4">
                </div>
                <div class="table-responsive">
                  <b-table small style="border: 1px solid #ced4da;" :items="filterTableData" :fields="fields" v-if="filterTableData?.length>0"
                    :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                    :filter="filter" :filter-included-fields="filterOn" sticky-header>
                    <template #cell(TaskName)="row">
                      <a href="javascript:void(0);" class="link font-weight-bold" @click="viewItem(row.item)"
                        :title="row.value">{{ row.value
                        }}</a>
                    </template>
                    <template #cell(ProjectName)="row">
                      <p class="mb-0">
                        <span :title="row.value">{{ row.value }}</span>
                      </p>
                    </template>
                    <template #cell(AssignedTo)="row">
                      <p class="mb-0">
                        <span :title="row.value">{{ row.value }}</span>
                      </p>
                    </template>
                    <template #cell(Id)="row">
                      <a href="javascript:void(0);" class="link" @click="viewItem(row.item)" :title="row.value">{{ row.value
                      }}</a>
                    </template>
                    <template #cell(CreatedDt)="row">
                      <p class="mb-0">
                        <span :title="row.value">{{ row.value }}</span>
                      </p>
                    </template>
                    <template #cell(Comments)="row">
                      <p class="mb-0 text-truncate mw-150">
                        <span :title="row.value">{{ row.value }}</span>
                      </p>
                    </template>
                    <template #cell(Status)="row">
                      <div class="d-flex align-items-center" :title="row.value">
                            <span
                              :class="{
                                'color-active': row.value === 'New',
                                'bg-primary': row.value === 'Active',
                                'bg-success':
                                  row.value === 'Completed' ||
                                  row.value === 'Closed',
                                'bg-warning': row.value === 'In-progress',
                                'bg-secondary': row.value === 'In-active',
                              }"
                              class="ytmStatusdashboardIndicator"
                            ></span>
                            {{ row.value }}
                          </div>
                    </template>
                    <!-- <template v-slot:cell(action)="row" style="cursor: pointer">
                      <div class="d-flex align-items-center">
                        <span v-if="hasPermission('EditTask') && row.item['Status'] != 'Completed'">
                          <a href="javascript:void(0);" class="mr-3 text-primary" v-b-tooltip.hover data-toggle="tooltip"
                            title="Edit" @click="viewItem(row.item)" variant="primary" size="sm"><i
                              class="mdi mdi-pencil font-size-18 align-middle"></i>
                          </a>
                        </span>
                        <span
                          v-if="hasPermission('DeleteTask') && row.item['Status'] != 'In-progress' && row.item['Status'] != 'Completed'">
                          <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete"
                            @click="cancel(row.item['Id'], row.item)" variant="danger" size="sm"><i
                              class="ri-delete-bin-line mr-1 align-middle"></i>
                          </a>
                        </span>
                      </div>
                    </template> -->
                  </b-table>
                  <div class="fullscreen-height d-flex align-items-center justify-content-center"
                    v-if="this.getpercentageCount?.recentTasksData?.length == 0" v-cloak>
                    <div class="text-center text-muted">
                      <i v-if="filterTableData?.length>=0" class="ri-information-line font-size-36 text-muted"></i>
                      <h5 v-if="filterTableData?.length>=0" class="text-muted">No data found!</h5>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="card h-100">
              <div class="card-body">
                <h4 class="card-title">Tasks overview</h4>
                <template>
                  <div>
                    <div v-if="!zerovalue == 0">
                      <div id="donut-chart" class="apex-charts"></div>
                      <div class="chart-container" id="responsive-chart">
                        <apexchart class="apex-charts" dir="ltr" size="sm" :series="series" :options="chartOptions">
                        </apexchart>
                      </div>
                    </div>
                    <div v-else>
                      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100 100">
                        <circle cx="50" cy="50" r="31" fill="none" stroke="#808080" stroke-width="9" />
                        <text x="50" y="50" text-anchor="middle" alignment-baseline="middle" font-size="6" fill="#808080"
                          class="animated-label">No data found!</text>
                      </svg>
                    </div>
                  </div>
                </template>
                <!-- <template v-else>
                  <div class="fullscreen-height d-flex align-items-center justify-content-center" >
                    <div class="text-center text-muted">
                      <i  class="ri-information-line font-size-36 text-muted"></i>
                      <h5 class="text-muted">No data found!</h5>
                    </div>
                  </div>
                </template>               -->
                <div class="row" v-if="zerovalue != 0">
                  <div class="col-6">
                    <div class="text-center mt-3">
                      <p class="mb-2 text-truncate" title="New tasks">
                        <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i>
                        New tasks
                      </p>
                      <h5>{{ dashboardItems[0]?.TasksOverview }}</h5>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="text-center mt-3">
                      <p class="mb-2 text-truncate" title="Completed tasks">
                        <i class="mdi mdi-circle text-success font-size-10 mr-1"></i>
                        Completed tasks
                      </p>
                      <h5>{{ dashboardItems[1]?.TasksOverview }}</h5>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="text-center mt-3">
                      <p class="mb-2 text-truncate" title="In-Progress tasks">
                        <i class="mdi mdi-circle text-warning font-size-10 mr-1"></i>
                        In-Progress tasks
                      </p>
                      <h5>{{ dashboardItems[2]?.TasksOverview }} </h5>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="text-center mt-3">
                      <p class="mb-2 text-truncate" title="Overdue tasks">
                        <i class="mdi mdi-circle text-danger font-size-10 mr-1"></i>
                        Overdue tasks
                      </p>
                      <h5>{{ dashboardItems[3]?.TasksOverview }}</h5>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="text-center mt-3">
                      <p class="mb-2 text-truncate" title="Active tasks">
                        <i class="mdi mdi-circle text-info font-size-10 mr-1"></i>
                        Active tasks
                      </p>
                      <h5>{{ dashboardItems[4]?.TasksOverview }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



  </Layout>
</template>
<style scoped>
.fullscreen-height {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 260px;
}

.ytmStatusdashboardIndicator{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 3px;
}

.color-new {
  color: gray;
  background-color: lightgray;
}

.color-active {
  color: #007ACC;
  background-color: rgba(0, 122, 204, 0.28);
}

.badge-soft-danger {
  color: #ff3d60;
  background-color: rgba(255, 61, 96, 0.28);
}


.link {
  color: #303030;

  &:hover {
    color: #00435d;
  }
}

.chart-container {
  width: 100%;
  max-width: 400px;
  margin: auto;
  /* margin-top: 20px; */
}

@media (max-width:1199px) {
  .mobile-bottom {
    margin-bottom: 30px
  }
}

.c-pointer {
  cursor: pointer;

  p,
  h4 {
    color: #00435d;
  }
}</style>
