<template>
    <div>
        <!-- <div v-if="loading">
            <PreLoader></PreLoader>
        </div> -->
        <div class="row">
            <div class="col-xl-3 col-md-6 col-sm-8 col-12 mx-auto">
                <div v-if="showShimmers">
                    <div v-for="(user, index ) in [].constructor(2)" :key="index">
                        <b-card no-body img-left>
                            <b-card-body class="d-flex">
                                <div>
                                    <b-skeleton type="avatar" width="40px" height="40px"></b-skeleton>
                                </div>
                                <div class="ml-3 align-self-end">
                                    <b-skeleton width="120px"></b-skeleton>
                                    <b-skeleton width="60px" height="10px"></b-skeleton>
                                    <b-skeleton width="30px" height="10px"></b-skeleton>
                                </div>
                            </b-card-body>
                        </b-card>
                    </div>
                </div>
                <div v-if="!showShimmers">
                    <div class="card mb-0 orgchart-card" v-if="level0" @click="ChangeRepotingGuid(level0)">
                        <div class="card-body position-relative" :class="{ 'main-active': level0?.UserId == UserId }">
                            <div class="d-flex justify-align-center align-items-center">
                                <div class="main-avatar flex-shrink-0">
                                    <img :src="level0?.ImgUrl" alt="avatar" width="50" v-if="level0?.ImgUrl" />
                                    <span class="font-size-20 font-weight-bold" v-else>{{ level0?.Title }}</span>
                                </div>
                                <div class="align-self-center details ml-3 w-100">
                                    <h4 class="font-size-14 mb-2 font-weight-bold text-truncate mw-80"
                                        :title="level0?.name">{{
                                            level0?.name }}
                                    </h4>
                                    <p class="mb-0 text-muted font-size-13" :title="level0?.Designation">{{
                                        level0?.Designation
                                    }}</p>
                                    <p class="mb-0 font-size-13" :title="level0?.DepartmentName">{{ level0?.DepartmentName
                                    }}
                                    </p>
                                </div>
                            </div>
                            <!-- <div class="profile-icon">
                            <i class="ri-arrow-right-s-line align-bottom"></i>
                        </div> -->
                        </div>
                    </div>
                    <div class="card mb-0 mt-4 orgchart-card" v-if="level1" @click="ChangeRepotingGuid(level1)"
                        :class="level2?.length > 0 ? 'hasChild' : ''">
                        <div class="card-body position-relative" :class="{ 'main-active': level1?.UserId == UserId }">
                            <div class="d-flex justify-align-center align-items-center">
                                <div class="main-avatar flex-shrink-0">
                                    <img :src="level1?.ImgUrl" alt="avatar" width="50" v-if="level1?.ImgUrl" />
                                    <span class="font-size-20 font-weight-bold" v-else>{{ level1?.Title }}</span>
                                </div>
                                <div class="align-self-center details ml-3 w-100">
                                    <h4 class="font-size-14 mb-2 font-weight-bold text-truncate mw-80"
                                        :title="level1?.name">{{
                                            level1?.name }}
                                    </h4>
                                    <p class="mb-0 text-muted font-size-13" :title="level1?.Designation">{{
                                        level1?.Designation
                                    }}</p>
                                    <p class="mb-0 font-size-13" :title="level1?.DepartmentName">{{ level1?.DepartmentName
                                    }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="level2 && !showShimmers">
            <div class="col-sm-10 mx-auto mt-5">
                <hr class="hr-line mt-0" v-if="level2?.length > 0" />
                <div class="orgchart-members">
                    <div class="grid-container">
                        <div class="grid-item" :class="{ 'active': childUser?.UserId == UserId }"
                            v-for="childUser of level2" :key="childUser?.UserId" @click="ChangeRepotingGuid(childUser)">
                            <div class="d-flex justify-align-center align-items-center">
                                <div class="avatar flex-shrink-0">
                                    <img :src="childUser?.ImgUrl" alt="avatar" width="40" v-if="childUser?.ImgUrl" />
                                    <span class="font-size-18 font-weight-bold " v-else>{{ childUser?.Title }}</span>
                                </div>
                                <div class="align-self-center details ml-3 w-100">
                                    <h4 class="font-size-14 mb-0 font-weight-bold mw-80 text-truncate"
                                        :title="childUser?.name">
                                        {{ childUser?.name }}
                                    </h4>
                                    <p class="mb-0 text-muted font-size-13" :title="childUser?.Designation">{{
                                        childUser?.Designation }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
// import PreLoader from "@/components/pre-loader";

export default {
    components: {},
    data() {
        return {
            // loading: false,
            showShimmers: false,
            level0: [],
            level1: [],
            level2: [],
            RepotingGuid: '',
            UserId: '',
        }
    },
    computed: {
        ...mapState(['getRepotingGuid', 'getUserId']),
        ...mapGetters('auth', ['getProfileDetails']),
        ...mapGetters('userservice', ['getUserHierarchy']),
    },
    mounted() {
        this.UserId = this.getProfileDetails.UserId || this.getProfileDetails.Id
        this.oninit();
    },
    methods: {
        async oninit() {
            // this.loading = true
            this.showShimmers = true
            const params = {
                UserId: this.RepotingGuid || this.getRepotingGuid || this.getProfileDetails.UserGuid
            }
            await this.$store.dispatch('userservice/GetUserHierarchy', params)
            let getUserHierarchy = await this.getUserHierarchy?.map(user => ({
                ReportingGuid: user?.ReportingGuid, DepartmentId: user?.DepartmentId,
                value: user?.Level, name: user?.FirstName + ' ' + user?.LastName, Title: user?.FirstName.slice(0, 1).toUpperCase() + user?.LastName.slice(0, 1).toUpperCase(),
                Designation: user?.DesignationName, ReportingId: user?.ReportingId, UserId: user?.UserId, ImgUrl: user?.ImgUrl, DepartmentName: user?.DepartmentName,
                UserGuid: user?.UserGuid
            }));
            this.richMediaData = getUserHierarchy
            this.level0 = this.richMediaData.find(user => user.value == 0)
            if ((this.level0.ReportingGuid != '00000000-0000-0000-0000-000000000000' || this.level0.ReportingGuid == this.getProfileDetails.UserGuid) && this.level0.UserGuid == this.getProfileDetails.UserGuid) {
                this.level1 = this.richMediaData.find(user => user.value == 1 && user?.ReportingId == this.level0?.UserId && user.UserId == this.UserId) ?
                    this.richMediaData.find(user => user.value == 1 && user?.ReportingId == this.level0?.UserId && user.UserId == this.UserId) : this.richMediaData.find(user => user.value == 1 && user?.ReportingId == this.level0?.UserId)
            }
            else {
                this.level1 = this.richMediaData.find(user => user.value == 1 && user.UserId == this.UserId)
            }
            this.level2 = this.richMediaData.filter(user => user.value == 2 && user.ReportingId == this.level1?.UserId)
            // console.log(this.level1, this.level0)
            // this.loading = false
            this.showShimmers = false
        },
        ChangeRepotingGuid(data) {
            if (data.UserId != this.UserId && data.UserGuid != data.ReportingGuid) {
                this.RepotingGuid = data.ReportingGuid;
                this.RepotingGuid != '00000000-0000-0000-0000-000000000000' ? this.oninit() : '';
            }
            this.UserId = data.UserId
            // console.log(this.getProfileDetails)
        }
    }
};
</script>

<style lang="scss" scoped>
.orgchart-card {
    cursor: pointer;
}

.orgchart-card.hasChild::after,
.orgchart-card:not(:last-child)::after {
    content: "";
    width: 2px;
    height: 50px;
    position: absolute;
    background-color: #ddd;
    bottom: -50px;
    left: 0;
    right: 0;
    margin: auto;
}

.main-active {
    border-bottom: #00435d 2px solid;
}

.main-avatar {
    width: 50px;
    height: 50px;
    background: #cee0d6;
    border-radius: 30px;
    line-height: 50px;
    text-align: center;


    img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 30px;
        vertical-align: baseline;
    }
}

.mw-80 {
    max-width: 70%;

    @media screen and (min-width:993px) and (max-width:1140px) {

        max-width: 55%;
    }
    @media screen and (min-width:1141px) and (max-width:1200px) {

        max-width: 65%;
    }
}

.profile-icon {
    position: absolute;
    right: 20px;
    top: 30%;
    font-size: 20px;
}

.hr-line {
    width: 100%;
    height: 1px;
    background-color: #ddd;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 10px;
    column-gap: 10px;

    @media screen and (max-width:480px) {
        grid-template-columns: repeat(1, 1fr);
    }

    @media screen and (min-width:481px) and (max-width:991px) {
        grid-template-columns: repeat(2, 1fr);

    }

    @media screen and (min-width:992px) and (max-width:1440px) {
        grid-template-columns: repeat(3, 1fr);
    }


    .grid-item {
        background-color: #fff;
        padding: 20px 15px;
        border-radius: 4px;

        &:hover {
            cursor: pointer;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08)
        }

        .avatar {
            width: 40px;
            height: 40px;
            background: #ebd3e1;
            object-fit: contain;
            border-radius: 30px;
            line-height: 40px;
            text-align: center;

            img {
                width: 40px;
                height: 40px;
                object-fit: cover;
                border-radius: 30px;
                vertical-align: baseline;
            }
        }

        .mw-200px {
            max-width: 200px;
        }
    }

    .active {
        border: 2px solid #00435d;
    }
}</style>
  