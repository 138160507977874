<script>
import { mapGetters, mapMutations } from 'vuex';
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
// import PreLoader from "@/components/pre-loader";
import _ from "lodash";
import moment from 'moment-timezone';

export default {
    components: { Multiselect },
    computed: {
        ...mapGetters("holiday", ["getAllHolidays", "getCustomHolidayTypes", 'getHolidays']),
        ...mapGetters("errors", ["getErrors"]),

        isFormInvalid() {
            return (
                !this.holidayData.holidayName ||
                !this.holidayData.holidayType ||
                !this.holidayData.date ||
                !this.holidayData.comments
            );
        },
    },

    data() {
        return {
            holidayData: {
                holidayName: "",
                holidayType: null,
                date: "",
                comments: "",
                Id: '',
                country:""
            },
            publicHoliday: "Public",
            selectedOption: "Location",
            orderbylist: ["India", "US"],
            TaskList: null,
            rows: 0,
            perPage: 10,
            currentPage: 1,
            pageOptions: [10, 25, 50, 100],
            title: "Holiday Calendar",
            formChanged: false,
            isComments: false,
            isTaskName: false,
            loading1: false,
            timer: null,
            isDate: false,
            anotherVariable: [],
            // loading: true,
            showShimmers: true,
            modalVisible: false,
            currentDate: new Date(),
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            option: "",
            isholidayName: false,
            isHolidayType: false,
            saveDismissibleAlert: false,
            isError: false,
            response: "",
            holidayLists: [],
            options: [
                // "Custom",
                // "Optional"
            ],
            // holidayTypes: [],                     
            countryname: [
                {
                    Id: '1',
                    Name: "India",
                },
                {
                    Id: '2',
                    Name: "Us",
                },
            ],
            debouncedFiltered: _.debounce(this.onFiltered,),
            editform: true,
            fields: [
                { key: "HolidayName", label: "Holiday name", sortable: true, class: ["text-truncate", "mw-200"] },
                { key: "HolidayType", label: "Holiday type", sortable: true, class: ["text-truncate", "mw-200"] },
                { key: "CountryCode", label: "Country name", sortable: true, class: ["text-truncate", "mw-200"] },
                { key: "HolidayDate", label: "Holiday date", sortable: true, class: ["text-truncate", "mw-200"] },
                { key: "day", label: "Day", sortable: true, class: ["text-truncate", "mw-200"] },
                { key: "Comments", label: "Comments", sortable: true, class: ["text-truncate", "mw-200"] },
                { key: "Actions", label: "Actions", class: ["text-truncate", "mw-200"] },
            ],

            items: [
                {
                    text: "Dashboard",
                    href: "/dashboard",
                },
                {
                    text: "Calendar",
                    active: true,
                },
            ],
            toDate: new Date(),
            disabledDates: [],
        };
    },

    validations: {
        holidayData: {
            holidayName: { required },
            holidayType: { required },
            date: { required },
            comments: { required }
        },
    },
    watch: {
        holidayData: {
            deep: true,
            handler() {
                this.editform = true;
            },
        },
    },
    methods: {
        /**
         * disable dates function
         * @param {*} ymd 
         * @param {*} date 
         */
        // :date-disabled-fn="dateDisabled"
        dateDisabled(ymd, date) {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            const formattedDay = day.toString().padStart(2, '0');
            const formattedMonth = month.toString().padStart(2, '0');
            return this.disabledDates.includes(`${formattedDay}-${formattedMonth}-${year}`);
        },
        // :date-info-fn="dateClass" 
        // dateClass(ymd, date) {
        //     const day = date.getDate();
        //     const month = date.getMonth() + 1;
        //     const year = date.getFullYear();

        //     const formattedDay = day.toString().padStart(2, '0');
        //     const formattedMonth = month.toString().padStart(2, '0');
        //     let customHolidays = this.disabledDates.filter(data => data.type == 'Custom').map(data => data.date)
        //     let optionalHolidays = this.disabledDates.filter(data => data.type == 'Optional').map(data => data.date)
        //     let publicHoliday = this.disabledDates.filter(data => data.type == 'Public').map(data => data.date)
        //     return customHolidays.includes(`${formattedDay}-${formattedMonth}-${year}`) ? 'table-info' :
        //         optionalHolidays.includes(`${formattedDay}-${formattedMonth}-${year}`) ? 'table-warning' :
        //             publicHoliday.includes(`${formattedDay}-${formattedMonth}-${year}`) ? 'table-success' : '';
        // },
        /**
         * 
         * @param {Holiday} data 
         */
        async EditHolidays(data) {
            this.$refs['myModal'].show();
            this.holidayData = {
                holidayName: data.HolidayName,
                holidayType: this.options.find(option => option.Id = data.HolidayTypeId),
                date: moment(data.HolidayDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                comments: data.Comments,
                Id: data.Id,
                country: this.countryname.find(country => country.Id == data.CountryCode)
            }
            setTimeout(() => {
                this.editform = false;
                this.formChanged = true;
            }, 300);
        },

        ChangePage() {
            // this.loading = true;
            this.showShimmers = true;
            setTimeout(() => {
                // this.loading = false;
                this.showShimmers = false;
            }, 500);
        },

        ResetData() {
            this.$refs['myModal'].hide();
            this.holidayData.holidayName = "";
            this.holidayData.holidayType = null;
            this.holidayData.date = "";
            this.holidayData.comments = "";
            this.holidayData.Id = "";
            this.holidayData.country = this.countryname.find(country => country.Id == 1)
            this.holidayData.Id = "";

            this.isholidayName = false;
            this.isHolidayTpe = false;
            this.isComments = false;
            this.isDate = false;
            this.isHolidayType = false;
            this.formChanged = true;
        },
        ...mapMutations(['setAlertNotification']),
        async SaveHolidays() {
            // this.loading = true;
            this.showShimmers = true;
            const params = await {
                HolidayDate: this.holidayData?.date,
                HolidayName: this.holidayData?.holidayName,
                HolidayYear: new Date().getFullYear(),
                CountryCode: this.holidayData?.country.Id,
                HolidayTypeId: this.holidayData?.holidayType.Id,
                HolidayType: this.holidayData?.holidayType.HolidayType,
                Comments: this.holidayData?.comments,
                Description: this.holidayData?.holidayType.Description,
                Id: this.holidayData?.Id ? this.holidayData?.Id : undefined
            };

            await this.$store.dispatch("holiday/SaveHolidays", params);
            this.response = await this.getHolidays;
            const message = {
                variant: this.response.status === true ? 'success' : 'danger',
                alertTitle: this.holidayData.Id ? 'Update' : 'Save',
                alertDecription: this.response.msg
            };
            this.setAlertNotification(message)
            // this.loading = false;
            this.showShimmers = false;
            this.GetHolidays()
            if (this.response.msg != "We already have a holiday for this date") {
                await this.ResetData()
            }
        },

        async GetHolidays() {
            // this.loading = true;
            this.showShimmers = true;
            const params = {
                pagenumber: this.currentPage,
                rowcount: this.projectRowCount,
                keyword: this.filter,
                sort: this.Sort,
                orderby: this.Orderby,
            };
            await this.$store.dispatch('holiday/GetHolidays', params);
            this.holidayLists = await this.getAllHolidays.holidayList.map(holiday => ({
                CreatedDt: holiday?.CreatedDt ? new Date(holiday.CreatedDt)?.toLocaleDateString('en-GB')?.replace(/\//g, '-') : '',
                HolidayType: holiday?.HolidayType,
                HolidayName: holiday?.HolidayName,
                day: holiday?.HolidayDate ? new Date(holiday.HolidayDate)?.toLocaleDateString('default', { weekday: 'long' }) : '',
                Id: holiday?.Id,
                HolidayYear: holiday?.HolidayYear,
                CountryCode: holiday?.CountryCode,
                HolidayTypeId: holiday?.HolidayTypeId,
                Comments: holiday?.Comments,
                Description: holiday?.Description,
                UserId: holiday?.UserId,
                HolidayDate: holiday?.HolidayDate ? new Date(holiday.HolidayDate)?.toLocaleDateString('en-GB')?.replace(/\//g, '-') : '',
            }));
            this.holidays=this.holidayLists;
            this.rows = this.holidayLists?.length;
            this.disabledDates = this.holidayLists.map(date => (date.HolidayDate))
            // this.loading = false;
            this.showShimmers = false;
        },

        async GetHolidaysTypes() {
            await this.$store.dispatch('holiday/GetHolidaysTypes');
            const HolidayTypes = this.getCustomHolidayTypes.holidayTypes;
            this.options = HolidayTypes?.filter(element => element.HolidayType !== "Public") || []
        },
        ChangeOrderby(option) {
            this.selectedOption = option;
            this.holidayList=this.holidays;
            this.holidayLists = this.holidayList?.filter(holiday =>  holiday?.CountryCode == Number(this.selectedOption.Id));
            this.selectedOption = option.Name;
        },


        onFiltered() {
            // this.loading = true;
            this.showShimmers = true;
            setTimeout(() => {
                // this.loading = false;
                this.showShimmers = false;
            }, 1000);
            this.rows = this.holidayLists?.length;
            if (this.holidayLists?.length == 0) {
                this.rows = this.holidayLists?.length;
            } else if (this.$refs.myTable.filteredItems?.length != 0) {
                this.rows = this.$refs.myTable.filteredItems?.length;
            } else {
                this.rows = this.$refs.myTable.filteredItems?.length;
            }
        },
        handleFilterInput() {
            if (this.filter !== null) {
                if (this.timer) {
                    clearTimeout(this.timer);
                }
                this.timer = setTimeout(() => {
                    this.timer = null;
                    this.anotherVariable = this.filter;
                }, 800);
            }
        }
    },


    async mounted() {
        this.toDate = moment(new Date(), "YYYY-MM-DD").add(3, 'days').format('DD-MM-YYYY');
        let minDate = new Date();
        minDate.setDate(minDate.getDate() + 3);
        this.currentDate = moment(minDate).format('YYYY-MM-DD')
        await this.GetHolidaysTypes();
        await this.GetHolidays();
    }
}
</script>
<template>
    <div class="home">
        <!-- <div v-if="loading">
            <PreLoader></PreLoader>
        </div> -->
        <div v-if="showShimmers">
            <div class="d-flex justify-content-between mt-2">
                <div>
                    <b-skeleton type="input" width="100px" height="30px"></b-skeleton>
                </div>
                <div class="d-flex justify-content-end">
                    <b-skeleton class="mr-2" type="input" width="100px" height="30px"></b-skeleton>
                    <b-skeleton class="mr-2" type="input" width="100px" height="30px"></b-skeleton>
                    <b-skeleton type="button" width="100px" height="30px"></b-skeleton>
                </div>
            </div>
             <div class="mt-2">
             <b-skeleton-table :rows="5" :columns="6"></b-skeleton-table>
             </div>
        </div>
        <div class="row" v-else>
            <div class="col-12">
                <div class="d-flex justify-content-between ytmFilterRow my-2 flex-column flex-sm-row">
                    <div class="mt-1 mt-sm-0">
                        <div id="tickets-table_length" class="dataTables_length text-center text-sm-left">
                            <label class="d-inline-flex align-items-center mb-2 mb-sm-0">
                                Show
                                <select size="sm" v-model="perPage" class="form-control align-self-start selction-padding"
                                    style="width: 70px;" @change="ChangePage">
                                    <option v-for="option of pageOptions" :key="option">{{ option }}</option>
                                </select>
                            </label>
                        </div>
                    </div>
                    <!-- Search -->
                    <div class="dataTables_filter text-md-right">
                        <div class="d-flex flex-column flex-sm-row justify-content-end align-items-center">
                            <div id="tickets-table_filter" class="dataTables_filter text-md-right w-100">
                                <label class="d-inline-flex align-items-center position-relative mb-2 mb-sm-0">
                                    <b-form-input size="sm" v-model="filter" type="search" @input="handleFilterInput"
                                        placeholder="Search..." class="form-control searchInput"></b-form-input>
                                    <i class="ri-search-line search-icon"></i>
                                </label>
                            </div>
                            <div class="ml-md-2 mb-2 mb-sm-0 w-50">
                                <b-dropdown size="sm" v-model="selectedOption.Name" variant="outline-light" class="w-100">
                                    <template v-slot:button-content>
                                        {{ selectedOption }}
                                        <i class="mdi mdi-chevron-down"></i>
                                    </template>
                                    <b-dropdown-item-button v-for="option of countryname" :key="option.Id" :label="option.Name"
                                        @click="ChangeOrderby(option)">{{ option.Name }}</b-dropdown-item-button>
                                </b-dropdown>
                            </div>
                            <div class="ml-2 flex-shrink-0 mb-2 mb-sm-0">
                                <b-button size="sm" variant="primary" @click="ResetData" v-b-modal.modal-center>
                                    <i class="ri-add-line align-middle"></i>Add holiday
                                </b-button>
                            </div>
                        </div>
                    </div>
                    <!-- End Search -->
                </div>
                <div>
                    <!-- Table -->
                    <div class="table-responsive">
                        <b-table ref="myTable" v-if="this.holidayLists?.length > 0" :items="holidayLists"
                            style="border: 1px solid #ced4da;border-top:0" :fields="fields" responsive="sm"
                            :per-page="perPage" :filter-included-fields="filterOn" @filtered="debouncedFiltered"
                            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="anotherVariable"
                            :current-page="currentPage" class="mb-0" sticky-header>
                            <template #cell(CreatedDt)="row">
                                <a v-if="row.value" class="task-link" :title="row.value">
                                    {{ row.value }}
                                </a>
                                <span v-else>{{ row.value }}</span>

                            </template>
                            <template #cell(day)="row">
                                <a class="link font-weight-bold"  :title="row.value">{{ row.value}}</a>
                            </template>
                            <template #cell(HolidayName)="row">
                                <p class="mb-0">
                                    <span :title="row.value">{{ row.value }}</span>
                                </p>
                            </template>
                            <template #cell(HolidayType)="row">
                                <p class="mb-0">
                                    <span :title="row.value">{{ row.value }}</span>
                                </p>
                            </template>
                            <template #cell(CountryCode)="row">
                                <p class="mb-0">
                                    <span :title="row.value">{{ row.value === 1 ? 'India' : row.value === 2 ? 'US' : 'Other' }}</span>
                                </p>
                            </template>
                            <template #cell(actions)="row">
                                <i v-if="row.item.HolidayType.toLowerCase() === 'custom' && row.item.HolidayDate >= toDate"
                                    @click="EditHolidays(row.item)" class="ri-pencil-fill ml-3 text-primary font-size-18"
                                    size="sm" style="font-size: 1.5rem;margin-right: 10px; cursor: pointer;"
                                    v-b-tooltip.hover.bottom="'Edit'" no-close-on-backdrop></i>
                                <span v-else style="margin-left: 20px;"> - </span>
                            </template>
                            <!-- ...your existing table cell templates... -->
                        </b-table>
                    </div>
                    <!-- No data found message-->
                    <div class="nodata-ui" v-if="!rows && !loading">
                        <div class="fullscreen-height d-flex align-items-center justify-content-center">
                            <div class="py-5 text-muted">
                                <div class="d-flex flex-column justify-content-center gap-1 align-items-center fs-4">
                                    <i class="ri-information-line font-size-36"></i>
                                    <h5>No data found!</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- No data found message end-->
                </div>
            </div>
        </div>
        <!-- pagination -->
        <div class="row">
            <div class="col">
                <div class="paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                        <b-pagination v-model="currentPage" pills v-if="rows != 0" aria-controls="my-table" show-total
                            :total-rows="rows" :per-page="perPage" @input="ChangePage()"></b-pagination>
                    </ul>
                </div>
            </div>
        </div>

        <b-modal ref="myModal" id="modal-center" centered :title="this.holidayData.Id ? 'Edit holiday' : 'Add holiday'"
            title-class="font-18" hide-footer no-close-on-backdrop>
            <form @submit.prevent="SaveHolidays">
                <div class="form-group">
                    <label for="">Country name</label>
                    <multiselect v-model="holidayData.country" :options="countryname" label="Name"></multiselect>
                </div>
                <div class="form-group">
                    <label for="holidayName">Holiday name<span :class="{ 'text-danger': !this.holidayData.holidayName }">
                            *</span></label>
                    <input type="text" @blur="isholidayName = true" class="form-control" placeholder="Enter holiday name"
                        :class="[!this.holidayData.holidayName && isholidayName ? 'border border-danger'
                            : '']" v-model="holidayData.holidayName" />
                    <span class="text-danger" v-if="!this.holidayData.holidayName && isholidayName">Holiday name is
                        required!</span>
                </div>
                <div class="form-group">
                    <label for="holidayType"> Holiday type <span :class="{ 'text-danger': !holidayData.holidayType }">
                            *</span></label>
                    <div @click="isHolidayType = true" :class="[
                        !this.holidayData.holidayType && isHolidayType
                            ? ''
                            : 'multi-selector-container',
                    ]">
                        <multiselect v-model="holidayData.holidayType" @blur="isHolidayType = true"
                            :class="[!this.holidayData.holidayType && isHolidayType ? 'multiSelectError' : 'multi-selector-container',]"
                            :options="options" track-by="Id" label="HolidayType" :disabled="holidayData.Id ? true : false">
                        </multiselect>
                    </div>
                    <p v-if="!this.holidayData.holidayType && isHolidayType" class="text-danger">
                        Holiday type is required!
                    </p>

                </div>
                <div class="form-group">
                    <label for="date">Date <span :class="{ 'text-danger ': !this.holidayData.date }"> *</span></label>
                    <!-- <date-picker v-model="dueDate" :first-day-of-week="1" lang="en" value="25-12-2023"></date-picker> -->
                    <!-- <input type="date" @blur="isDate = true" class="form-control"  v-model="holidayData.date" format="DD-MM-YYYY"> -->
                    <b-form-datepicker v-model="holidayData.date" id="datepicker2" @change="response = ''"
                        selected-variant="success" today-variant="info" nav-button-variant="primary"
                        :date-disabled-fn="dateDisabled" @blur="isDueDate = true" :date-format-options="{ day: 'numeric' }"
                        :hide-header='true' :class="[!this.holidayData.date && isDate ? 'border border-danger' : '']"
                        v-bind:min="currentDate" placeholder="Select to date" locale="en"
                        format="YYYY-MM-DD"></b-form-datepicker>

                    <span class="text-danger" v-if="!this.holidayData.date && isDate">date is required!</span>
                </div>
                <div class="form-group">
                    <label for="comments">Comments<span :class="{ 'text-danger': !holidayData.comments }"> *</span></label>
                    <textarea rows="3" v-model="holidayData.comments" @blur="isComments = true" :class="[!this.holidayData.comments && isComments ? 'border border-danger'
                        : '']" placeholder="Enter comments" class="form-control"></textarea>
                    <span class="text-danger" v-if="!this.holidayData.comments && isComments">Comments is required!</span>
                </div>
                <div v-if="response?.msg == 'We already have a holiday for this date'" class="text-danger">
                    <p>We already have a holiday for this selected date</p>
                </div>
                <div class="text-right mt-4">
                    <b-button class="ml-1" :disabled="!formChanged" type="button" variant="outline-primary"
                        @click="ResetData"><i class="ri-close-line align-bottom"></i> Cancel</b-button>
                    &nbsp;
                    <button type="submit" class="btn btn-primary" :disabled="isFormInvalid || !editform">
                        <i class="ri-save-line align-bottom"></i> {{ this.holidayData.Id ? 'Update' : 'Save' }}
                    </button>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<style lang="scss" scoped></style>