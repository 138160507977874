<script>
import Layout from "../../layouts/vertical.vue";
import PageHeader from "@/components/page-header";
// import PreLoader from "@/components/pre-loader";
// import DatePicker from "vue2-datepicker";
// import Multiselect from "vue-multiselect";
export default {
    name: 'LetterRequests',
    components: { Layout, PageHeader},

    data() {
        return {
            // loading: true,
            showShimmers: true,
            department: '',
            defaultdate: '',
            title: "Income Tax Declaration (FY 2023 - 24)",
            items: [
                {
                    text: "Dashboard",
                    routeName: "Dashboard",
                },
                {
                    text: "Investment Declarations",
                    active: true,
                },
            ],
        }
    },
    mounted() {
        setTimeout(() => {
            // this.loading = false;
            this.showShimmers = false;
        }, 1000);
    },
}
</script>


<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <!-- <div v-if="loading">
            <PreLoader/>
        </div> -->
        <div v-if="showShimmers">
            <div class="card col-8 m-auto">
                <div class="card-body">
                   <div class="row">
                       <div class="col-md-4 col-sm-12 mb-4"  v-for="(user, index ) in [].constructor(9)" :key="index">
                            <div>
                               <b-skeleton width="40%" height="20px"></b-skeleton>
                               <b-skeleton type="input"></b-skeleton>
                            </div>
                       </div>
                    </div>
                </div>               
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-8 m-auto">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mb-3"><b>Personal details</b></h4>
                        </div>
                        <form>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                    <div class="form-group">
                                       <label for="">Employee ID</label>
                                       <b-form-input  placeholder="Enter employee id"
                                            class="form-control">
                                        </b-form-input>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                    <div class="form-group">
                                       <label for="">Date of Birth</label>
                                       <b-form-input  placeholder="Enter date of birth "
                                            class="form-control">
                                        </b-form-input>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                    <div class="form-group">
                                       <label for="">Joining Date</label>
                                       <b-form-input  placeholder="Enter joining date"
                                            class="form-control">
                                        </b-form-input>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                    <div class="form-group">
                                       <label for="">Employee Name</label>
                                       <b-form-input  placeholder="Enter employee name"
                                            class="form-control">
                                        </b-form-input>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                    <div class="form-group">
                                       <label for="">Father Name</label>
                                       <b-form-input  placeholder="Enter father name "
                                            class="form-control">
                                        </b-form-input>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                    <div class="form-group">
                                       <label for="">Department</label>
                                       <b-form-input  placeholder="Enter department "
                                            class="form-control">
                                        </b-form-input>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                    <div class="form-group">
                                       <label for="">PAN Number</label>
                                       <b-form-input  placeholder="Enter pan number  "
                                            class="form-control">
                                        </b-form-input>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                    <div class="form-group">
                                       <label for="">Gender</label>
                                       <b-form-input  placeholder="Enter gender "
                                            class="form-control">
                                        </b-form-input>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-xl-4">
                                    <div class="form-group">
                                       <label for="">Designation</label>
                                       <b-form-input  placeholder="Enter designation "
                                            class="form-control">
                                        </b-form-input>
                                    </div>
                                </div>
                                <div class="col text-right">
                                    <b-button  variant="outline-primary">Continue</b-button>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                    <hr>
                            </div>
                            <div class="d-flex justify-content-between">
                                <h4 class="card-title mb-3"><b>Income</b></h4>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group class="border-bottom pb-2" id="example text" label-cols-sm="12" label-cols-lg="8" label="Gross salary income" label-for="text">
                                            <b-form-input class="ml-auto" style="width: 100px;" for="text"></b-form-input>
                                        </b-form-group>
                                    </form>
                                </div>
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group class="border-bottom pb-2" id="example text" label-cols-sm="12" label-cols-lg="8" label="Annual income from other sources" label-for="text">
                                            <b-form-input class="ml-auto" style="width: 100px;" for="text"></b-form-input>
                                        </b-form-group>
                                    </form>
                                </div>
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group class="border-bottom pb-2" id="example text" label-cols-sm="12" label-cols-lg="8" label="Annual income from interest" label-for="text">
                                            <b-form-input class="ml-auto" style="width: 100px;" for="text"></b-form-input>
                                        </b-form-group>
                                    </form>
                                </div>
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group class="border-bottom pb-2" id="example text" label-cols-sm="12" label-cols-lg="8" label="Annual income from let-out house property (rental income)" label-for="text">
                                            <b-form-input class="ml-auto" style="width: 100px;" for="text"></b-form-input>
                                        </b-form-group>
                                    </form>
                                </div>
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group class="border-bottom pb-2" id="example text" label-cols-sm="12" label-cols-lg="8" label="Annual interest paid on home loan (self-occupied)" label-for="text">
                                            <b-form-input class="ml-auto" style="width: 100px;" for="text"></b-form-input>
                                        </b-form-group>
                                    </form>
                                </div>
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group class="border-bottom pb-2" id="example text" label-cols-sm="12" label-cols-lg="8" label="Annual interest paid on home loan (let-out)" label-for="text">
                                            <b-form-input class="ml-auto" style="width: 100px;" for="text"></b-form-input>
                                        </b-form-group>
                                    </form>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <h4 class="card-title mb-3"><b>Deductions</b></h4>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group class="border-bottom pb-2" id="example text" label-cols-sm="12" label-cols-lg="8" label="Basic deductions u/s 80C" label-for="text">
                                            <b-form-input class="ml-auto" style="width: 100px;" for="text"></b-form-input>
                                        </b-form-group>
                                    </form>
                                </div>
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group class="border-bottom pb-2" id="example text" label-cols-sm="12" label-cols-lg="8" label="Contribution to NPS u/s 80CCD(1B)" label-for="text">
                                            <b-form-input class="ml-auto" style="width: 100px;" for="text"></b-form-input>
                                        </b-form-group>
                                    </form>
                                </div>
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group class="border-bottom pb-2" id="example text" label-cols-sm="12" label-cols-lg="8" label="Medical Insurance Premium u/s 80D" label-for="text">
                                            <b-form-input class="ml-auto" style="width: 100px;" for="text"></b-form-input>
                                        </b-form-group>
                                    </form>
                                </div>
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group class="border-bottom pb-2" id="example text" label-cols-sm="12" label-cols-lg="8" label="Donation to charity u/s 80G" label-for="text">
                                            <b-form-input class="ml-auto" style="width: 100px;" for="text"></b-form-input>
                                        </b-form-group>
                                    </form>
                                </div>
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group class="border-bottom pb-2" id="example text" label-cols-sm="12" label-cols-lg="8" label="Interest on Educational Loan u/s 80E" label-for="text">
                                            <b-form-input class="ml-auto" style="width: 100px;" for="text"></b-form-input>
                                        </b-form-group>
                                    </form>
                                </div>
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group class="border-bottom pb-2" id="example text" label-cols-sm="12" label-cols-lg="8" label="Interest on deposits in saving account u/s 80TTA/TTB" label-for="text">
                                            <b-form-input class="ml-auto" style="width: 100px;" for="text"></b-form-input>
                                        </b-form-group>
                                    </form>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <h4 class="card-title mb-3"><b>HRA Exemption</b></h4>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group class="border-bottom pb-2" id="example text" label-cols-sm="12" label-cols-lg="8" label="Basic salary received per annum" label-for="text">
                                            <b-form-input class="ml-auto" style="width: 100px;" for="text"></b-form-input>
                                        </b-form-group>
                                    </form>
                                </div>
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group class="border-bottom pb-2" id="example text" label-cols-sm="12" label-cols-lg="8" label="Dearness allowance (DA) received per annum" label-for="text">
                                            <b-form-input class="ml-auto" style="width: 100px;" for="text"></b-form-input>
                                        </b-form-group>
                                    </form>
                                </div>
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group class="border-bottom pb-2" id="example text" label-cols-sm="12" label-cols-lg="8" label="HRA received per annum" label-for="text">
                                            <b-form-input class="ml-auto" style="width: 100px;" for="text"></b-form-input>
                                        </b-form-group>
                                    </form>
                                </div>
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group class="border-bottom pb-2" id="example text" label-cols-sm="12" label-cols-lg="8" label="Total rent paid per annum" label-for="text">
                                            <b-form-input class="ml-auto" style="width: 100px;" for="text"></b-form-input>
                                        </b-form-group>
                                    </form>
                                </div>
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group class="border-bottom pb-2" id="example text" label-cols-sm="12" label-cols-lg="8" label="Do you live in a metro city?" label-for="text">
                                            <b-form-input class="ml-auto" style="width: 100px;" for="text"></b-form-input>
                                        </b-form-group>
                                    </form>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<style scoped>


</style>