<script>
import Layout from "../../layouts/vertical.vue";
import PageHeader from "@/components/page-header";
// import PreLoader from "@/components/pre-loader";
// import DatePicker from "vue2-datepicker";
// import Multiselect from "vue-multiselect";
export default {
    name: 'LetterRequests',
    components: { Layout, PageHeader },

    data() {
        return {
            // loading: true,
            showShimmers: true,
            department: '',
            defaultdate: '',
            title: "IT Proof Documents",
            items: [
                {
                    text: "Dashboard",
                    routeName: "Dashboard",
                },
                {
                    text: "It Proof Submission",
                    active: true,
                },
            ],
        }
    },
    mounted() {
        setTimeout(() => {
            // this.loading = false;
            this.showShimmers = false;
        }, 1000);
    },
}
</script>


<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <!-- <div v-if="loading">
            <PreLoader/>
        </div> -->
        <div v-if="showShimmers">
            <div class="d-flex justify-content-end">
                <b-skeleton class="mr-2" type="input" width="180px" height="30px"></b-skeleton>
                <b-skeleton type="button" width="100px" height="30px"></b-skeleton>
            </div>
            <div class="row">
                <div class="col-12 mt-4">
                    <div class="card">
                        <div class="card-body">
                            <b-skeleton class="mb-2" type="input"></b-skeleton>
                            <b-skeleton type="input"></b-skeleton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <div v-else>
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-end">
                        <div class="d-flex">
                            <div class="dataTables_filter text-md-right mb-2">
                                <label class="d-inline-flex align-items-center position-relative mb-0">
                                    <b-form-input size="sm" type="search" placeholder="Search..."
                                        class="form-control  searchInput"></b-form-input>
                                    <i class="ri-search-line search-icon"></i>
                                </label>
                            </div>
                            <div class="ml-2 flex-shrink-0">
                                <b-button size="sm" variant="primary" id="modal-document" v-b-modal.modal-document>
                                    <i class="ri-upload-line align-bottom"></i> Upload
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div class="dropdown-divider"></div>
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between my-2 flex-column flex-sm-row border py-2 mb-3">
                           <div class="col-sm-12 col-md-4 mt-1">
                               <div id="tickets-table_length" class="dataTables_length">
                                  <h6 class="d-inline-flex align-items-center mb-0">
                                     <i class="ri-file-list-2-line mr-2"></i>
                                     House Rent
                                  </h6>
                               </div>
                           </div>
                          <div class="col-sm-12 col-md-8">
                            <div class="d-flex justify-content-end align-items-sm-center flex-column flex-sm-row mt-1">
                               <div class="ml-2">
                                    <div style="cursor: pointer;" class="ml-2 text-primary">
                                        <i class="ri-zoom-in-line align-bottom"></i>
                                         Preview
                                    </div>
                               </div>
                               <div class="ml-2">
                                    <div style="cursor: pointer;" class="ml-2 text-danger">
                                       <i class="ri-delete-bin-line align-bottom"></i>
                                      Delete
                                    </div>
                               </div>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between my-2 flex-column flex-sm-row border py-2 mb-3">
                           <div class="col-sm-12 col-md-4 mt-1">
                               <div id="tickets-table_length" class="dataTables_length">
                                  <h6 class="d-inline-flex align-items-center mb-0">
                                     <i class="ri-file-list-2-line mr-2"></i>
                                     Owner PAN
                                  </h6>
                               </div>
                           </div>
                          <div class="col-sm-12 col-md-8">
                            <div class="d-flex justify-content-end align-items-sm-center flex-column flex-sm-row mt-1">
                               <div class="ml-2">
                                    <div style="cursor: pointer;" class="ml-2 text-primary">
                                        <i class="ri-zoom-in-line align-bottom"></i>
                                         Preview
                                    </div>
                               </div>
                               <div class="ml-2">
                                    <div style="cursor: pointer;" class="ml-2 text-danger">
                                       <i class="ri-delete-bin-line align-bottom"></i>
                                      Delete
                                    </div>
                               </div>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between my-2 flex-column flex-sm-row border py-2 mb-3">
                           <div class="col-sm-12 col-md-4 mt-1">
                               <div id="tickets-table_length" class="dataTables_length">
                                  <h6 class="d-inline-flex align-items-center mb-0">
                                     <i class="ri-file-list-2-line mr-2"></i>
                                     Medical Insurance
                                  </h6>
                               </div>
                           </div>
                          <div class="col-sm-12 col-md-8">
                            <div class="d-flex justify-content-end align-items-sm-center flex-column flex-sm-row mt-1">
                               <div class="ml-2">
                                    <div style="cursor: pointer;" class="ml-2 text-primary">
                                        <i class="ri-zoom-in-line align-bottom"></i>
                                         Preview
                                    </div>
                               </div>
                               <div class="ml-2">
                                    <div style="cursor: pointer;" class="ml-2 text-danger">
                                       <i class="ri-delete-bin-line align-bottom"></i>
                                      Delete
                                    </div>
                               </div>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between my-2 flex-column flex-sm-row border py-2 mb-3">
                           <div class="col-sm-12 col-md-4 mt-1">
                               <div id="tickets-table_length" class="dataTables_length">
                                  <h6 class="d-inline-flex align-items-center mb-0">
                                     <i class="ri-file-list-2-line mr-2"></i>
                                     Medical Insurance - Spouse
                                  </h6>
                               </div>
                           </div>
                          <div class="col-sm-12 col-md-8">
                            <div class="d-flex justify-content-end align-items-sm-center flex-column flex-sm-row mt-1">
                               <div class="ml-2">
                                    <div style="cursor: pointer;" class="ml-2 text-primary">
                                        <i class="ri-zoom-in-line align-bottom"></i>
                                         Preview
                                    </div>
                               </div>
                               <div class="ml-2">
                                    <div style="cursor: pointer;" class="ml-2 text-danger">
                                       <i class="ri-delete-bin-line align-bottom"></i>
                                      Delete
                                    </div>
                               </div>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between my-2 flex-column flex-sm-row border py-2 mb-3">
                           <div class="col-sm-12 col-md-4 mt-1">
                               <div id="tickets-table_length" class="dataTables_length">
                                  <h6 class="d-inline-flex align-items-center mb-0">
                                     <i class="ri-file-list-2-line mr-2"></i>
                                     Child Education Reciepts
                                  </h6>
                               </div>
                           </div>
                          <div class="col-sm-12 col-md-8">
                            <div class="d-flex justify-content-end align-items-sm-center flex-column flex-sm-row mt-1">
                               <div class="ml-2">
                                    <div style="cursor: pointer;" class="ml-2 text-primary">
                                        <i class="ri-zoom-in-line align-bottom"></i>
                                         Preview
                                    </div>
                               </div>
                               <div class="ml-2">
                                    <div style="cursor: pointer;" class="ml-2 text-danger">
                                       <i class="ri-delete-bin-line align-bottom"></i>
                                      Delete
                                    </div>
                               </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
        </div>
<!--b-modal-->
   <div class="col-sm-6 col-md-4 col-xl-3">
      <div class="my-4 text-center">
           <b-modal  no-close-on-backdrop hide-footer id="modal-document"  ref="myModal" title="Upload IT Proof Document">
                <div class="form-group">
                   <label for="">Document Name</label>
                   <b-form-input  placeholder="Enter document name"
                                        class="form-control"></b-form-input>
                </div>
                <div class="form-group">
                    <label for="">Select File</label>
                     <input type="file"  class="form-control" />
                </div>
                <div class="card-footer text-right">
                    <button type="button" class="btn btn-outline-danger mr-2">
                       <i class="ri-close-line align-middle"></i> Cancel
                    </button>
                   <button type="button" class="btn btn-primary">
                       <i class="ri-upload-line align-bottom"></i> Upload
                   </button>
                </div>
           </b-modal>
        </div> 
    </div>
    </Layout>
</template>
<style scoped>


</style>