import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from '../views/pages/accounts/login.vue'
import DashboardPage from '../views/pages/dashboard/dashboard.vue'
// import RegisterPage from '../views/pages/accounts/register.vue'
import ForgotPasswordPage from '../views/pages/accounts/forgot-password.vue'
import TasksList from '../views/pages/tasks/tasks-list.vue'
import ViewTask from '../views/pages/tasks/view-task.vue'
import UsersList from '../views/pages/users/users-list.vue'
import User from '../views/pages/users/user.vue'
import AddUser from '../views/pages/users/add-user.vue'
import UserView from '../views/pages/users/user-view.vue'
import Settings from '../views/pages/settings/settings.vue'
import Projects from '../views/pages/projects/projects-list.vue'
import ProjectTeam from '../views/pages/projects/project-team.vue'
import ProjectDetails from '../views/pages/projects/project-details.vue'
import ManagementTree from '../views/pages/management/management-tree.vue'
import NotVerifed from '../views/pages/accounts/not-verified'
import ResetPassword from '@/views/pages/accounts/Reset-password'
import AuthMiddleware from '../helpers/protectgard'
import Leaves from '../views/pages/leaves/leave-approval.vue'
import Attendance from '../views/pages/attendance/attendance-record.vue'
import Calendar from '../views/pages/calendar/calendar.vue'
import Reports from '../views/pages/Reports/individual.vue'
import Group from '../views/pages/Reports/group.vue'
import LateEarly from '../views/pages/Reports/late-early.vue'
import Absence from '../views/pages/Reports/absence.vue'
import Overtime from '../views/pages/Reports/overtime.vue'
import Holiday from '../views/pages/Reports/holiday.vue'
import LetterRequests from '../views/pages/hrmanagement/letter-requests.vue'
import SelfAppraisal from '../views/pages/hrmanagement/self-appraisal.vue'
import ManagerAppraisalView from '../views/pages/hrmanagement/manager-appraisal-view.vue'
import ManagerAppraisal from '../views/pages/hrmanagement/manager-appraisal.vue'
import ITProofSubmission from '../views/pages/hrmanagement/it-proof.vue'
import InvestmentDeclarations from '../views/pages/hrmanagement/investment-declaration.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    redirect: 'dashboard',
    name: 'dashboard',
  },
  {
    path: '/login',
    component: LoginPage,
    name: 'Login',
  },
  {
    path: '/not-verified',
    component: NotVerifed,
    name: "NotVerified",
    beforeEnter: AuthMiddleware.guest
  },
  {
    path: '/ResetPassword/:guid',
    component: ResetPassword,
    name: 'ResetPassword',
    beforeEnter: AuthMiddleware.guest
  },
  // {
  //   path: '/register',
  //   component: RegisterPage,
  //   name: 'Register',
  //   beforeEnter:AuthMiddleware.guest
  // },
  {
    path: '/forgot-password',
    component: ForgotPasswordPage,
    name: 'ForgotPassoword',
    beforeEnter: AuthMiddleware.guest
  },
  {
    path: '/dashboard',
    component: DashboardPage,
    name: 'Dashboard',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/tasks-list',
    component: TasksList,
    name: 'TaskList',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/view-task/:Id',
    component: ViewTask,
    name: 'ViewTask',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/users-list',
    component: UsersList,
    name: 'UsersList',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/add-user/:guid',
    component: AddUser,
    name: 'AddUser',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/user-view',
    component: UserView,
    name: 'UserView',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/user/:guid',
    component: User,
    name: 'User',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/projects-list',
    component: Projects,
    name: 'Projects',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/project-team/:Id',
    component: ProjectTeam,
    name: 'ProjectTeam',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/project-details/:guid',
    component: ProjectDetails,
    name: 'ProjectDetails',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/settings',
    component: Settings,
    name: 'Settings',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/management-tree',
    component: ManagementTree,
    name: 'Organization',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/leave-approval',
    component: Leaves,
    name: 'Leaves',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/attendance-record',
    component: Attendance,
    name: 'attendance',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/calendar',
    component: Calendar,
    name: 'calendar',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/individual',
    component: Reports,
    name: 'reports',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/group',
    component: Group,
    name: 'group',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/late-early',
    component: LateEarly,
    name: 'lateearly',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/absence',
    component: Absence,
    name: 'Absence',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/overtime',
    component: Overtime,
    name: 'Overtime',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/holiday',
    component: Holiday,
    name: 'Holiday',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/letter-requests',
    component: LetterRequests,
    name: 'letterrequests',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/self-appraisal',
    component: SelfAppraisal,
    name: 'selfappraisal',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/manager-appraisal-view',
    component: ManagerAppraisalView,
    name: 'managerappraisalview',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/manager-appraisal',
    component: ManagerAppraisal,
    name: 'managerappraisal',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/it-proof',
    component: ITProofSubmission,
    name: 'itproof',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: '/investment-declaration',
    component: InvestmentDeclarations,
    name: 'investmentdeclaration',
    beforeEnter: AuthMiddleware.user
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
