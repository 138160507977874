import { taskApiService } from "../../config/tasksaxios"

const initialState=()=>{
    return{
        AllAppraisal:null,
        SelfAppraisal:null,
    };
};
const state=initialState();

export const getters={
GetSaveAppraisalById:(state)=>{
    return state.AllAppraisal
},
GetQuestionById:(state)=>{
    return state.SelfAppraisal
},
};


export const mutations = {
    SetAppraisalById: (state, data) => {
        return state.AllAppraisal = data
    },
    GetQuestionsById:(state,data)=>{
        return state.SelfAppraisal=data
    }, 
};


export const actions = {
    async GetSaveAppraisalById({ commit, dispatch }, params) {
        try {
            commit("SetAppraisalById", null);
            let response = await taskApiService.get(`/appraisal/GetSaveAppraisalById/${params}`);
            commit("SetAppraisalById", response.data)
        }
        catch (err) {
            console.warn(err)
            commit("SetAppraisalById", null);
            await dispatch("errors/errorResponse", err.response, { root: true });
        }
    },

    async GetQuestionById({commit,dispatch}){
        try{
            commit("GetQuestionsById",null);
            let response = await taskApiService.get(`/appraisal/GetQuestionById`);
            commit("GetQuestionsById", response.data)
        }
        catch(err){
            console.warn(err)
            commit("GetQuestionsById", null);
            await dispatch("errors/errorResponse", err.response, { root: true });
        }
    },

};
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
