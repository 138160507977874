// import axios from "axios";
import { axios } from "../../config/axios";
import { taskApiService } from "../../config/tasksaxios";
const initialState = () => {
  return {
    allUsers: [],
    defaults: [],
    userdetails:[],
    result:[],
    Hierarchy:[],
    response:[],
    data:[],
    details:[],
    message:"",
    userdeleted:"",
    ResendResponse:[],
    userdetailsbyid:"",
    UserHierarchy:[],
    UserHierarchyfromMango:[],
    NewRepotingManager:'',
    HierarchyWithOrganization:[],
    updateAssignedName:[],
    Designations:[],
    ActiveUser : [],
    UserLeaves: [],
    saveUserByProject :[],
    DeleteEmployee: [],
    DesignationAndDepartment :[]
  };
};

const state = initialState();

export const getters = {
  getAllUsers: (state) => state.allUsers,
  getUserDefaults:(state)=>state.defaults,
  getUserDetails:(state)=>state.userdetails,
  getUserDetailsById:(state)=>state.userdetailsbyid,
  getUpdatePassword:(state)=> (state.result),
  getUserHierarchy:(state)=>(state.Hierarchy),
  getEmailCheck:(state)=>state.response,
  getSignupUser:(state)=> state.data,
  getUserDeteils:(state)=>state.details,
  getEmailconfirmed:(state)=>state.message,
  getDeleteUser:(state)=>state.userdeleted,
  getResendEmail:(state)=>state.ResendResponse,
  getUserHierarchybyId:(state)=>state.UserHierarchy,
  getUserHierarchyfortree:(state)=>state.UserHierarchyfromMango,
  getNewRepotingManager:(state)=>state.NewRepotingManager,
  getHierarchyWithOrganization:(state)=>state.HierarchyWithOrganization,
  getupdateAssignedName:(state)=>state.updateAssignedName,
  getDesignationValue:(state)=>state.Designations,
  getActiveUser:(state)=>state.ActiveUser,
  getUserLeaves:(state)=>state.UserLeaves,
  getUserDetailsByProject:(state)=>state.saveUserByProject,
  getDeleteEmployee:(state)=>state.DeleteEmployee,
  getDesignationAndDepartment:(state)=>state.DesignationAndDepartment,
};

export const mutations = {
  setAllUsers(state, data) {
    state.allUsers = data;
  },
  SetUserDefaults(state,data){
    state.defaults = data;
  },
  SetUserDetails(state,data){
    state.userdetails = data;
  },
  // set Delete user Response 
  setDeleteUser(state, data){
    state.userdeleted = data;
 },
 setUpdateRepotingManager(state, data){
    state.NewRepotingManager = data;
 },
  SetUserDetailsById(state,data){
   return state.userdetailsbyid = data;
  },
  setUpdatePassword:(state,response)=>{
    state.result=response
  },
  SetUserHierarchy:(state,response)=>{
    state.Hierarchy=response
  },
  SetUserHierarchyWithOrganization:(state,response)=>{
    state.HierarchyWithOrganization=response
  },
  SetUserHierarchybyId:(state,response)=>{
    state.UserHierarchy=response
  },
  SetUserHierarchyfortree:(state,response)=>{
    state.UserHierarchyfromMango=response
  },
  SetEmailCheck:(state,data)=>{
    state.response=data;
  },
  SetSignupUser:(state,data)=>{
    state.data=data;
  },
  SetUserdetails:(state,data)=>{
    state.details=data
  },
  SetEmailconfirmed:(state,data)=>{
    state.message=data
  },
  setResendEmail:(state,data)=>{
    state.ResendResponse=data
  },
  setUpdateAssignedNames:(state,data)=>{
    state.updateAssignedName=data
  },
  SetDesignation:(state,data)=>{
    state.Designations=data
  },
  SetUserLeaves:(state,data)=>{
    state.UserLeaves=data
  },
  SaveUserByProject(state,data){
    state.saveUserByProject = data;
  },
  SetDeleteEmployee:(state,data)=>{
    state.DeleteEmployee=data
  },
  SetDesignationAndDepartment:(state,data)=>{
    state.DesignationAndDepartment=data
  },
  SetDeActiveUser:(state,data)=>{
    state.ActiveUser=data
  },
  reset(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};
export const actions = {
  async fetchUsers  ({ commit, dispatch }, params) {
    try {
      commit("setAllUsers", null);
      // let response = await axios.get("https://ytmauth.fulltanker.com/api/v1/Auth/GetUsers");
      let response =await axios.post("/api/v1/Auth/GetUsers",params)
      commit("setAllUsers", response.data);
    } catch (err) {
      console.log(err)
      await dispatch("errors/errorResponse", err.response, { root: true });
      commit("setAllUsers", null);
    }
  },
  async GetUserDefaults({commit, dispatch},params){
    try{
      commit("SetUserDefaults", null);
      let response = await axios.get(`/api/v1/User/GetUserDefaults?UserGuid=${params}`)
      commit("SetUserDefaults", response.data);
    }
    catch(err){
      console.warn(err)
      commit("SetUserDefaults", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  /**
   * Save User Details
   * @param {*} param 
   * @param {*} params 
   */
  async SaveUserDetails({commit,dispatch} , params){
    try{
      commit("SetUserDetails", null);
        //const response = await axios.post("http://localhost:5001/api/tasks/savetask",  params );
         const response =  await axios.post("/api/v1/User/SaveUserDetails",  params );
       commit("SetUserDetails", response.data);
    }
     catch (err) {
        console.warn("error")
        commit("setSaveTask", null);
        await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
   /**
   * Save User Details
   * @param {*} param 
   * @param {*} params 
   */
   async SaveUserByProject({commit,dispatch} , params){
    try{
      commit("SaveUserByProject", null);
        //const response = await axios.post("http://localhost:5001/api/tasks/savetask",  params );
         const response =  await axios.post("/api/v1/User/AddUserByProject",  params );
       commit("SaveUserByProject", response.data);
    }
     catch (err) {
        console.warn("error")
        commit("setSaveTask", null);
        await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
    /**
   * Save Custom Designation 
   * @param {*} param 
   * @param {*} params 
   */
    async SaveDesignation({commit,dispatch} , params){
      try{
        commit("SetDesignation", null);
           const response =  await axios.post("/api/v1/Roles/SaveDepartmentOrDesigination",  params );
         commit("SetDesignation", response.data);
      }
       catch (err) {
          console.warn("error")
          commit("setSaveTask", null);
          await dispatch("errors/errorResponse", err.response, { root: true });
      }
    },
    /**
   * Save user leaves
   * @param {*} params 
   */
  async SaveUserLeaves({commit,dispatch} , params){
    try{
      commit("SetUserLeaves", null);
         const response =  await taskApiService.post("employee/postEmployee",  params );
       commit("SetUserLeaves", response.data);
    }
     catch (err) {
        console.warn("error")
        commit("SetUserLeaves", null);
        await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  /**
   * Delete employee
   * @param {*} params 
   */
  async DeleteEmployee({commit,dispatch} , params){
    try{
      commit("SetDeleteEmployee", null);
         const response =  await taskApiService.post("employee/deleteEmployee",  params );
       commit("SetDeleteEmployee", response.data);
    }
     catch (err) {
        console.warn("error")
        commit("SetDeleteEmployee", null);
        await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  /**
       * Delete user Details
       * @param {*} param0 
       * @param {*} params 
       */
  async DeleteUser({commit,dispatch} , params){
    try{
      commit("setDeleteUser", null);
      const response =  await axios.post(`/api/v1/User/DeleteUser` , params);
        commit("setDeleteUser", response);
    }
      catch (err) {
        console.warn("error")
        commit("setDeleteUser", null);
        await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  /**
       * Update Repoting Manager when deleting the user
       * @param {*} param0 
       * @param {*} params 
       */
  async UpdateRepotingManager({commit,dispatch} , params){
    try{
      commit("setUpdateRepotingManager", null);
      const response =  await axios.post(`/api/v1/User/UpdateRepotingManager` , params);
        commit("setUpdateRepotingManager", response);
    }
      catch (err) {
        console.warn("error")
        commit("setUpdateRepotingManager", null);
        await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async GetUserDetailsByGuid({commit, dispatch},params){
    try{
      commit("SetUserDetailsById", null);
      let response = await axios.get(`/api/v1/User/GetUserDetailsByGuid?UserGuid=${params}`)
      commit("SetUserDetailsById", response.data);
    }
    catch(err){
      console.warn(err)
      commit("SetUserDetailsById", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  }, 
  /**
  * Update password
  * @param {*} param0 
  * @param {*} params 
  */
 async UpdatePassword({ commit, dispatch }, params = {}) {
   try {
     commit("setUpdatePassword",null)
     let response = await axios.post("/api/v1/User/UpdatePassword", params);
     let data = response.data
     commit("setUpdatePassword", data);
   }
   catch (err) {
    //  commit("setUpdatePassword",err.response.data)
     console.warn("error")
     await dispatch("errors/errorResponse", err.response, { root: true });
   }
 },
 /**
  * Get Hierarchy for management page
  * @param {*} param0 
  * @param {*} params 
  */
 async GetUserHierarchy({commit,dispatch}, params={}){
   try{
     commit("SetUserHierarchy", null);
      const response =  await axios.get(`/api/v1/User/GetUserHierarchy?UserGuid=${params?.UserId}` );
      let data= response.data
      commit("SetUserHierarchy", data);
   }
    catch (err) {
       console.warn("error")
       commit("SetUserHierarchy", null);
       await dispatch("errors/errorResponse", err.response, { root: true });
   }
  },
 /**
  * Get User Hierarchy By User id with Organization
  * @param {*} param0 
  * @param {*} params 
  */
 async GetUserHierarchyWithOrganization({commit,dispatch}, params={}){
   try{
     commit("SetUserHierarchyWithOrganization", null);
      const response =  await axios.get(`/api/v1/User/GetUserHierarchywithOrganization?UserId=${params?.UserId}` );
      let data= response.data
      commit("SetUserHierarchyWithOrganization", data);
   }
    catch (err) {
       console.warn("error")
       commit("SetUserHierarchyWithOrganization", null);
       await dispatch("errors/errorResponse", err.response, { root: true });
   }
  },
 /**
  * Get Hierarchy for management page
  * @param {*} param0 
  * @param {*} params 
  */
 async GetUserHierarchyfromMango({commit,dispatch}){
   try{
     commit("SetUserHierarchyfortree", null);
      const response =  await taskApiService.get(`/employee/user` );
      let data= response.data
      commit("SetUserHierarchyfortree", data);
   }
    catch (err) {
       console.warn("error")
       commit("SetUserHierarchyfortree", null);
       await dispatch("errors/errorResponse", err.response, { root: true });
   }
  },
/**
  * Get Hierarchy for management page based on userid
  * @param {*} param0 
  * @param {*} params 
  */
 async GetUserHierarchybyId({commit,dispatch}, params = {}){
  try{
    commit("SetUserHierarchybyId", null);
     const response =  await taskApiService.get(`/employee/getSubHeirarchy/${params}` );
     let data= response.data
     commit("SetUserHierarchybyId", data);
  }
   catch (err) {
      console.warn("error")
      commit("SetUserHierarchybyId", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
  }
 },
  /**
  * Duplicate email check
  * @param {*} param0 
  * @param {*} params 
  */
  async EmailCheck({ commit, dispatch }, params = {}) {
    try {
      commit("SetEmailCheck", null);
      const response = await axios.post("/api/v1/Auth/DuplicateEmailCheck", params);
      let data = response.data
      commit("SetEmailCheck", data);
    }
    catch (err) {
      console.warn("error")
      commit("SetEmailCheck", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  /**
 * Sign up the new user
 * @param {*} param0 
 * @param {*} params 
 */
  async signup({ commit, dispatch }, params = {}) {
    try {
      commit("SetSignupUser", null);
      const response = await axios.post("/api/v1/Auth/Signup", params);
      let data = response.data
      commit("SetSignupUser", data);
    }
    catch (err) {
      console.warn("error")
      commit("SetSignupUser", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  /**
   * Getting user details by UserGuid
   * @param {*} param0 
   * @param {*} params 
   */
  async GetUserByGuid({commit,dispatch},params={}){
    try{
      commit('SetUserdetails',null);
      const response= await axios.get(`/api/v1/User/GetUserByGuid?userGuid=${params}`);
      let data=response.data
      commit('SetUserdetails',data)
    }
    catch (err) {
      console.warn("error")
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  /**
   * Saving email confirmation
   * @param {*} param0 
   * @param {*} params 
   */
  async UpdateEmailConfirmed({ commit, dispatch }, params = {}) {
    try {
      commit("SetEmailconfirmed", null);
      const response = await axios.post("/api/v1/User/UpdateEmailConfirmed", params);
      let data = response.data
      commit("SetEmailconfirmed", data);
    }
    catch (err) {
      console.warn("error")
      commit("SetEmailconfirmed", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  /**
   * Resend email
   * @param {*} param0 
   * @param {*} params 
   */
  async ResendEmail({ commit, dispatch }, params = {}){
    try{
      commit("setResendEmail",null);
      const response=await axios.post("/api/v1/User/ResendEmailVerification",params);
      let data = response.data
      commit("setResendEmail",data);
    }
    catch(err) {
      commit("setResendEmail",null);
       await dispatch("errors/errorResponse", err.response, { root: true });
     }
  },
  /**
   * Update AssignedBy and AssignedTo in task and comments table
   * @param {*} param0 
   * @param {*} params 
   */
  async UpdateAssignedNames({ commit, dispatch }, params = {}){
    try{
      commit("setUpdateAssignedNames",null);
      const response=await taskApiService.post("/tasks/updateAssinedByOrToName",params);
      let data = response.data
      commit("setUpdateAssignedNames",data);
    }
    catch(err) {
       await dispatch("errors/errorResponse", err.response, { root: true });
     }
  },
  async GetDesignationAndDepartment({commit, dispatch},params){
    try{
      commit("SetDesignationAndDepartment", null);
      let response = await axios.get(`/api/v1/User/GetDesignationAndDepartment?UserGuid=${params}`)
      commit("SetDesignationAndDepartment", response.data);
    }
    catch(err){
      console.warn(err)
      commit("SetDesignationAndDepartment", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  }, 
  async UpdateActiveUser({ commit, dispatch }, params = {}) {
    try {
      commit("SetDeActiveUser", null);
      const response = await axios.post("/api/v1/User/UpdateActiveUser", params);
      let data = response.data
      commit("SetDeActiveUser", data);
    }
    catch (err) {
      console.warn("error")
      commit("SetDeActiveUser", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};