//import axios from 'axios';
import { taskApiService } from "../../config/tasksaxios"
const initialState = () => {
  return {
    getalltasks: null,
    savetasks: [],
    getDeteteTask: null,
    getViewTask: null,
    getAllStatuses: null,
    getUserTasks: null,
    getViewAllTaskstoId: null,
    getAllCommentsDataById: null,
    getSaveCommentData: null,
    countsdata: [],
    tasksDeleted: '',
    getTaskData: null,
    getpercentageCount : null,
    getUpdateComment : null,
    getCommentById : null,
    getDeletedTask : null,
    getAssignedToTaskData :  null,
    getSaveMultiCommentData : null,
    alluserstasks:[],
    alltasks:[],
    getReportingTasks:null,
    getReportingComments : null,
    email:'',
    updateCommentsByTaskId:[],
    commentsDataById:[],
    ViewTask : null,
    CommentedAttachments:[],
    getCommentByIdData : null
  };
};
const state = initialState();
const getters = {


  getDeletedTask : (state) => {
    return state.DeletedTaskList
  },
  getsendEmail : (state) => {
    return state.email
  },

  getReportingComments : (state) => {
    return state.CommentList
  },

  // Get comment by comment Id 
  getCommentById: (state) => {
    return state.getCommentByIdData?.comment
  },

  // getUpdateComment
  getUpdateComment: (state) => {
    return state.getUpdateCommentData
  },
  getalltasks: (state) => {
    return state.tasks
  },
  // get update comment by TaskId
  getupdateCommentsByTaskId:(state)=>{
    return state.updateCommentsByTaskId
  },

  //Get TaskCount List
  getpercentageCount : (state) => {
    return state.countsdata
  },

  // Get Save Task Response 
  getSaveTask: (state) => {
    return state.savetasks;
  },

  // Get Delete Task Response 
  getDeteteTask: (state) => {
    return state.DeteteTask;
  },

  // particular data get 
  getViewTask: (state) => {
    return state.ViewTask;
  },

  // Get taskCount
  gettaskscounts: (state) => {
    return state.countsdata
  },

  // Get all Statuses
  getAllStatuses: (state) => {
    return state.AllStatuses.statusList;
  },
  getUserTasks: (state) => {
    return state.Usertasks
  },

  // Get Task List Based on assigned To Id 
  getViewAllTaskstoId: (state) => {
    return state.ViewAllUsertasks
  },

  // Get all comments By Id
  getAllCommentsDataById(state) {
    return state?.commentsDataById?.data?.comments;
  },

  // Get Comments Data 
  getSaveCommentData(state) {
    return state.SaveCommentByTask?.data?.comments;
  },

  getSaveMultiCommentData(state) {
    return state.SaveMultiComment;
  },
  getdeleteAlltasks: (state) => state.tasksDeleted,
  // get all tasks assigned
  getTaskData:(state) =>{
    return state.alltasks;
  },
  getAssignedToTaskData:(state) =>{
    return state.alluserstasks;
  },
  getReportingTasks:(state)=>{
    return state.TaskList;
  },
  getTaskAttachment:(state)=>{
    return state.CommentedAttachments;
  }

};
const mutations = {

  setDeletedTask(state, data) {
    state.DeletedTaskList = data;
  },
  setsendEmail(state, data) {
    state.email = data;
  },

  //setComment By comment Id
  setCommentById(state, data) {
    state.getCommentByIdData= data;
  },

  // setUpdate Comment By Id
  setUpdateComment(state, data) {
    state.getUpdateCommentData = data;
  },

  // set update comment by TaskId
  setupdateCommentsByTaskId(state, data){
    state.updateCommentsByTaskId =data;
  },

  // set all task response
  setAllTasks(state, data) {
    state.tasks = data;
  },
  // set all comments 
  setAllCommentsDataById(state, data) {
    return state.commentsDataById = data;
  },

  // set all comments data 
  setSaveCommentData(state, data) {
    return state.SaveCommentByTask = data;
  },

  // set Multi comments data 
  setSaveMultiCommentData(state, data) {
    return state.SaveMultiComment = data;
  },

  // set Save  Task Response 
  setSaveTask(state, data) {
    return state.savetasks = data;

  },
  setAssignedToTaskData(state, data) {
    return state.alluserstasks = data;

  },

  // set Delete Task Response 
  setDeteteTask(state, data) {
    state.DeteteTask = data;
  },

  // get particular task data 
  setViewTask(state, data) {
    state.ViewTask = data;
  },

  //Save Task List
  setPercentageCount(state, data) {
    state.countsdata = data;
  },

  // taskCount
  setTasksCount(state, data) {
    state.countsdata = data;
  },

  // set all statuses call 
  setAllStatuses(state, data) {
    state.AllStatuses = data;
  },
  setViewAllTasksbyId(state, data) {
    state.Usertasks = data
  },

  // Get All task list assgined To Id 
  setViewAllTaskstoId(state, data) {
    state.ViewAllUsertasks = data
  },
  // setUpdateTask
  setUpdateTask(state, data) {
    state.Usertasks = data
  },
  setTasksDeteted(state, data) {
    state.tasksDeleted = data
  },

  setReportingTasks(state, data) {
    state.TaskList = data
  },

  setReportingComments(state, data) {
    state.CommentList = data
  },
  setTaskAttachment(state, data) {
    state.CommentedAttachments = data
  },

  // set all comments data 
  setTaskData(state, data) {
    return state.alltasks = data;
  },
  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};
const actions = {

  async fetchalltasks({ commit, dispatch }, params) {
    try {
      commit("setAllTasks", null);
      const response = await taskApiService.get("/tasks/gettasks", { params });
      commit("setAllTasks", response.data.tasks);
    } catch (err) {
      console.warn("error")
      commit("setAllTasks", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },


  /**
   * View particular task details
   * 
   */
  async ViewTaskData({ commit, dispatch }, params) {
    try {
      commit("setViewTask", null);
      const response = await taskApiService.get(`/tasks/gettaskbyid/${params}`);
      commit("setViewTask", response.data.task);
    }
    catch (err) {
      console.warn("error")
      commit("setViewTask", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },


  /**
   * Save Task details
   * @param {*} param0 
   * @param {*} params 
   */
  async SaveTaskData({ commit, dispatch }, params) {
    try {
      commit("setSaveTask", null);
      const response = await taskApiService.post("/tasks/savetask", params);
      commit("setSaveTask", response.data);
    }
    catch (err) {
      console.warn("error")
      commit("setSaveTask", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  /**
   * Tasks assigned to single person
   */
  async GetAllViewTasksAssigned({ commit, dispatch},params){
    try{
      commit("setAssignedToTaskData",null);
      const response = await taskApiService.post("tasks/getAssignedToTasksbyUserId", params);
      commit("setAssignedToTaskData", response.data);
    }
    catch (err) {
      console.warn("error")
      commit("setAssignedToTaskData", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },


  /**
   *  Update Task based on particular Id 
   * @param {*} param0 
   * @param {*} params 
   */
  async UpdateTaskData({ commit, dispatch }, params) {
    try {
      commit("setUpdateTask", null);
      const response = await taskApiService.post(`tasks/updateTask/${params.Id}`, params);
      commit("setUpdateTask", response.data.task);
    }
    catch (err) {
      console.warn("error")
      commit("setUpdateTask", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }

  },

  /**
   * Delete Task based on ID
   * @param {*} param0 
   * @param {*} params 
   */
  async DeleteTaskData({ commit, dispatch }, taskIds) {
    try {
      commit("setDeteteTask", null);
      const response = await taskApiService.post(`/tasks/updateDeleteTask`, taskIds);
      commit("setDeteteTask", response);
    }
    catch (err) {
      console.warn("error")
      commit("setDeteteTask", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  async fetchtaskscount({ commit, dispatch }, params) {
    try {
      commit("setTasksCount", null);
      const response = await taskApiService.get(`/tasks/getdashboardcounts/${params}`);
      commit("setTasksCount", response.data);
    } catch (err) {
      console.warn("error")
      commit("setTasksCount", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },


  /**
   * get all statuses List 
   * @param {*} param0 
   * @param {*} params 
   */
  async GetAllStatusesList({ commit, dispatch }, params) {
    try {
      commit("setAllStatuses", null);
      const response = await taskApiService.get("/status/getAllStatuses", params);
      commit("setAllStatuses", response.data);
    } catch (err) {
      console.warn("error")
      commit("setAllStatuses", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }

  },
  /**
   * Get all tasks by userid
   *
   * @param {*} param0 
   * @param {*} params 
   */
  async Getalltasksbyid({ commit, dispatch }, params) {
    try {
      commit("setViewAllTasksbyId", null);
      const response = await taskApiService.get(`tasks/gettasksbyuserid/${params}`);
      commit("setViewAllTasksbyId", response.data.task);
    }
    catch (err) {
      console.warn("error")
      commit("setViewAllTasksbyId", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },


  /**
   * Get all tasks To userid
   * @param {*} param0 
   * @param {*} params 
   */
  async GetalltasksToid({ commit, dispatch }, params) {
    try {
      commit("setViewAllTaskstoId", null);
      const response = await taskApiService.get(`tasks/gettaskstouserid/${params}`);
      commit("setViewAllTaskstoId", response.data.task);
    }
    catch (err) {
      console.warn("error")
      commit("setViewAllTaskstoId", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },

  /**
   * GetAllCommnets based on TaskId  
   * @param {*} param0 
   * @param {*} params 
   */
  async GetAllCommentsById({ commit, dispatch }, params) {
    try {
      commit("setAllCommentsDataById", null);
      const response = await taskApiService.get(`/comments/gettaskcomments/${params}`,);
      commit("setAllCommentsDataById", response);
    }
    catch (err) {
      commit("setAllCommentsDataById", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  /**
   * get Task Attachment
   * @param {*} param0 
   * @param {*} params 
   */
  async GetTaskAttachment({ commit, dispatch }, params) {
    try {
      commit("setTaskAttachment", null);
      const response = await taskApiService.post('/comments/getTaskAttachment',params);
      commit("setTaskAttachment", response);
    }
    catch (err) {
      commit("setTaskAttachment", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },

  /** 
   * @param {*} param0 
   * @param {Comment details} params 
   */
  async SaveCommentsByTaskId({ commit, dispatch }, params) {
    try {
      commit("setSaveCommentData", null);
     const response = await taskApiService.post(`/comments/savecomment`, params);
      commit("setSaveCommentData", response);
    }
    catch (err) {
      commit("setSaveCommentData", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },

  /** 
   * @param {*} param0 
   * @param {Comment details} params 
   */
  async SaveMultiCommentsByTaskIds({ commit, dispatch }, params) {
    try {
      commit("setSaveMultiCommentData", null);
     const response = await taskApiService.post(`/comments/saveMultiComments`, params);
      commit("setSaveMultiCommentData", response);
    }
    catch (err) {
      commit("setSaveMultiCommentData", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  
  /**
   * Delete tasks by UserId
   * @param {*} param0 
   * @param {*} params 
   */
  async DeleteTasksbyUserId({ commit, dispatch }, params) {
    try {
      commit("setTasksDeteted", null);
      const response = await taskApiService.post(`/tasks/deleteTasksbyUserId/${params.id}`, params);
      commit("setTasksDeteted", response);
    }
    catch (err) {
      console.warn("error")
      commit("setTasksDeteted", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
  /** 
   * @param {*} param0 
   * @param {Comment details} params 
   */
  async GetAllTasksAssigned({ commit, dispatch }, params) {
    try {
      commit("setTaskData", null);
      const response = await taskApiService.post(`/tasks/getAllTasksAssigned`, params);
      commit("setTaskData", response.data);
    }
    catch (err) {
      commit("setTaskData", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },

  async GetDashboardDataCountList({ commit, dispatch }, params){
    try{
      commit('setPercentageCount' , null)
    const response = await taskApiService.post(`/tasks/getDashBoardCountList` , params)
    commit("setPercentageCount", response.data);
    }
    catch(err){
      commit("setPercentageCount", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
    
  },

  /**
   * 
   * @param {*} param0 
   * @param {*} params 
   */
  async UpdateCommentById({ commit, dispatch }, params){
    try{
      commit('setUpdateComment' , null)
    const response = await taskApiService.post(`/comments/updateComment/${params.Id}` , params)
    commit("setUpdateComment", response.data);
    }
    catch(err){
      commit("setUpdateComment", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },

  async UpdateCommentsByTaskId({commit,dispatch},params){
    try{
      commit("setupdateCommentsByTaskId",null)
      const response = await taskApiService.post("/comments/UpdateCommentsByTaskId",params)
      commit("setupdateCommentsByTaskId", response.data);
    }catch(err){
      commit("setupdateCommentsByTaskId",null);
      await dispatch("errors/errorResponse",err.response, { root: true });
    }

  },


async getAllDeletedTasksAssignedType({ commit, dispatch }, params){
  try{
    commit('setDeletedTask' , null)
  const response = await taskApiService.post(`tasks/getAllDeletedTasksAssignedType` , params)
  commit("setDeletedTask", response.data);
  }
  catch(err){
    commit("setDeletedTask", null);
    await dispatch("errors/errorResponse", err.response, { root: true });
  }
},

  /**
   * 
   * @param {*} param0 
   * @param {*} params 
   */
  async GetCommentById({ commit, dispatch }, params){
    try{
      commit('setCommentById' , null)
    const response = await taskApiService.get(`/comments/getspecificcomments/${params}`)
    commit("setCommentById", response.data);
    }
    catch(err){
      commit("setCommentById", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },

  async ReportingTasks({ commit, dispatch }, params){
    try{
      commit('setReportingTasks' , null)
    const response = await taskApiService.post(`/tasks/ReportingTasks`,params)
    commit("setReportingTasks", response.data);
    }
    catch(err){
      commit("setReportingTasks", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  } ,

  /**
   * 
   * @param {*} param0 
   * @param {*} params 
   */
  async ReportingComments({commit , dispatch} , params)
  {
    try{
      commit("setReportingComments", null);
      const response = await taskApiService.post(`comments/AssignedCommentsReporting`,params)
      commit("setReportingComments", response);
    }catch(err){
      commit("setReportingComments", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },

  /**
   * Send email when creating the task
   * @param {*} param0 
   * @param {*} params 
   */
  async sendEmail({commit , dispatch} , params)
  {
    try{
      commit("setsendEmail", null);
      const response = await taskApiService.post('email/sendEmail',params)
      commit("setsendEmail", response);
    }catch(err){
      commit("setsendEmail", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  }
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
