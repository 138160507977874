<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  methods: {
    navigateToRoute(item , event) {
      const Item = item.find(item => item.text === event.target.innerText);
      this.$router.push({ name: Item.routeName });
    }, 
  },
}
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between flex-column flex-sm-row">
        <h4 class="mb-0" style="text-transform: none;font-size:large;" :title="title">{{ title }}</h4>

        <div class="page-title-right">
          <b-breadcrumb :items="items" class="m-0" @click="navigateToRoute(items, $event)"></b-breadcrumb>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
