import { taskApiService } from "../../config/tasksaxios";

const initialState = () => {
    return {
      allHolidays: [],
      holidays: []
    }
   
  };
const state = initialState();
export const getters = {
    getHolidays: (state) => state.allHolidays,
    getAllHolidays: (state) => state.holidays,
    getCustomHolidayTypes: (state) => state.setCustomHolidayTypes
    
  };
  export const mutations = {
    setHolidays(state, data) {
      state.allHolidays = data;
    },
    setcustomHolidays(state, data) {
      state.holidays = data
    },

    setHolidaysTypes(state, data) {
      state.setCustomHolidayTypes = data
    },

    reset(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }
  };

  export const actions = {
    async SaveHolidays({commit,dispatch} , params){
        try{
          commit("setHolidays", null);
             const response =  await taskApiService.post("holiday/Saveholiday",  params );
           commit("setHolidays", response.data);
        }
         catch (err) {
            console.warn("error")
            commit("setHolidays", null);
            await dispatch("errors/errorResponse", err.response, { root: true });
        }
      },

      async GetHolidays({commit,dispatch} , params){
        try{
          commit("setcustomHolidays", null);
             const response =  await taskApiService.get("holiday/getHolidays", params);
           commit("setcustomHolidays", response.data);
        }
         catch (err) {
            console.warn("error")
            commit("setcustomHolidays", null);
            await dispatch("errors/errorResponse", err.response, { root: true });
        }
      },

      async GetHolidaysTypes({commit,dispatch}){
        try{
          commit("setHolidaysTypes", null);
             const response =  await taskApiService.get("holiday/getholidayTypes");
           commit("setHolidaysTypes", response.data);
        }
         catch (err) {
            console.warn("error")
            commit("setHolidaysTypes", null);
            await dispatch("errors/errorResponse", err.response, { root: true });
        }
      },
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };