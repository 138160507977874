<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment-timezone';
import PreLoader from "@/components/pre-loader";


/**
 * Timeline activity component
 */
export default {
  components: { PreLoader },
  computed: {
    ...mapState(["getUserId", "getUserguid", 'getUserIds', 'getRepotingGuid']),
    ...mapGetters('tasks', ['getAllCommentsDataById', 'getSaveCommentData', 'getViewTask', 'getUpdateComment', 'getCommentById']),
    ...mapGetters('auth', ['getProfileDetails', 'getUpload']),
    ...mapGetters("projects", ["getAllUsersByProject"]),
  },
  data() {
    return {
      // placeholderText: "Add a comment and use @ to mention a person.",
      // isCommentFocused: false,
      loading: false,
      mainuser: [],
      parts: [],
      isCompleted: 'Completed',
      isDisable: false,
      UserData: '',
      inputValueChange: '',
      taskId: null,
      activityData: [],
      // icon: "ri-suitcase-line",  icon: "ri-calendar-2-fill",
      inputValue: '',
      UserDetailsList: [],
      databinding: '',
      allUsersListOptions: [],
      showDropdown: false,
      currentPage: 1,
      perPage: 200,
      Orderby: '',
      Sort: 'asc',
      fileType: null,
      filter: null,
      textColor: 'black',
      commentText: '',
      mentions: [],
      emailListOptions: [],
      usersOptionList: [],
      emailList: ['bobby@yopmail.com', 'alice@example.com', 'john@example.com'],
      showSuggestions: false,
      filteredSuggestions: [],
      placeholderHide: false,
      acceptedFormats: ['.pdf', '.doc', '.docx', '.txt', '.png', '.jpg'],
      selectedFile: null,
      ImageUrl: [],
      CommentData: null,
      isCountIndex: null,
      editCommentInputValue: null,
      CommentId: null,
      isCommentChecked: false,
      isTaskEdited: 'TaskEdited',
      isTimeline: 'Timeline',
      isTaskCreated: 'TaskCreated',
      isTaskRestored: 'TaskRestored',
      UserProfileList: [],
      isDisableSave: false,
      taskData: [],
      message: '',
      searchText: null,
      isTaskDeleted: "TaskDeleted",
      UserProfile: null
    };
  },
  methods: {
    containLink(text) {
      const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|(ftp:\/\/[^\s]+)/;
      return urlRegex.test(text);
    },
    /**
     * Save Comment details
     */
    async SaveCommentsData() {
      this.showSuggestions = false
      this.loading = true;
      let emailSend = this.UserDetailsList.filter(user => user.email).map(email => email.email)
      let userName = this.UserDetailsList.filter(user => user.name).map(email => email.name)
      this.showDropdown = false;
      const params = {
        TaskId: this.taskId,
        Users: this.UserDetailsList,
        Comments: this.inputValue,
        Status: this.taskData.Status,
        TaskName: this.taskData.TaskName,
        AssignedById: this.taskData.AssignedById,
        AssignedToId: this.taskData.AssignedToId,
        EndDate: this.taskData?.EndDate,
        AssignedTo: this.taskData.AssignedTo,
        AssignedBy: this.taskData.AssignedBy,
        Commentedby: this.getProfileDetails.FirstName + ' ' + this.getProfileDetails.LastName,
        CommentedbyId: this.getProfileDetails.UserId || this.getProfileDetails.Id,
        FileType: this.fileType,
        ImageUrl: this.ImageUrl,
        CommentType: this.isTimeline,
        ProjectName: this.taskData.ProjectName,
        ProjectId: this.taskData.ProjectId
      }
      await this.$store.dispatch('tasks/SaveCommentsByTaskId', params);

      if (this.UserDetailsList.length != 0) {
        const input = {
          "to": emailSend,
          "emailType": "Mentioned",
          "username": userName,
          "taskName": this.taskId + '(' + this.taskData.TaskName + ')',
          "ProjectName": this.taskData.ProjectName,
          "Assignedby": this.getProfileDetails.FirstName + ' ' + this.getProfileDetails.LastName
        }
        await this.$store.dispatch("tasks/sendEmail", input);
      }
      this.UserDetailsList = [];
      this.inputValue = '';
      this.UserData = '';
      this.ImageUrl = [];
      this.$refs.commentDiv.innerHTML = '';
      this.commentText = '';
      this.isCommentChecked = await false;
      this.isDisableSave = this.commentText != "" || this.ImageUrl != '' ? true : false;
      await this.GetAllCommentsById(this.taskId);
      this.loading = false;
      this.emailListOptions = this.usersOptionList;
    },

    /**
    * GetUsersList
    */
    async GetUsersList() {
      const params =  {
        pagenumber: this.currentPage,
        rowcount: this.perPage,
        keyword: this.filter,
        sort: this.Sort,
        orderby: this.Orderby,
        ProjectId: this.getViewTask?.ProjectId,
      };
      await this.$store.dispatch("projects/GetUsersByProject", params);
      this.usersOptionList = await this.getAllUsersByProject?.filter(Project => Project.userId).map(userName => userName.FullName)
      this.emailListOptions = await this.usersOptionList;
      this.filteredSuggestions = await this.usersOptionList;
      this.allUsersListOptions = await this.getAllUsersByProject?.filter(element => element && element.userId != null)
        .map(user => (
          {
            id: user.userId,
            name: user.FullName,
            email: user.Email
          }
        ));
      this.loading = false;
    },

    /**
   * @param {TaskId} Id 
   * Get user ViewTask details
   */
    async GetViewTask(Id) {
      this.ImageUrl = [];
      await this.$store.dispatch('tasks/ViewTaskData', Id);
      this.taskData = await this.getViewTask;
      await this.GetAllCommentsById(Id);
      await this.GetUsersList();
    },


    /**
     * Edit Comment Toggle
     * @param {CommentId} Id 
     * @param {IndexCount} index 
     */
   async EditToggle(Id, index) {
      this.CommentId = Id;
      if (this.isCountIndex == index && this.isCommentChecked == true) {
        this.isCommentChecked = false;
      } else {
        this.isCountIndex = index;
        this.isCommentChecked = true
       await this.GetCommentById(Id);
      }

    },

    /**
     * Get Comment by Id 
     * @param {CommentId} Id 
     */
    async GetCommentById(Id) {
      this.editCommentInputValue = '';
      await this.$store.dispatch('tasks/GetCommentById', Id);
      this.CommentData = await this.getCommentById
      this.editCommentInputValue = this.CommentData?.Comments;
    },

    /**
     * Update Comment By Id
     */
    async updateCommentById() {
      const params = {
        Id: this.CommentId,
        IsEdited: true,
        Comments: this.editCommentInputValue,
        CreatedDt: new Date()
      }
      await this.$store.dispatch('tasks/UpdateCommentById', params);
      this.loading = true;
      await this.GetViewTask(this.taskId)
      this.isCountIndex = null;
      this.isCommentChecked = await false;
      this.$refs.commentDiv.innerHTML = '';
    },

    /**
     * 
     * @param {TaskId} Id 
     * Get user CommentsList
     */
    async GetAllCommentsById(Id) {
      await this.$store.dispatch('tasks/GetAllCommentsById', Id);
      this.UserProfileList = this.getProfileDetails;
      this.activityData = await this.getAllCommentsDataById.map(user => {
        let utcMoment = moment(user.CreatedDt).tz("UTC");
        let istMoment = utcMoment.clone().tz("Asia/Kolkata");
        let datetime = istMoment.format("DD-MM-yyyy hh:mm:ss a");
        let now = moment().tz("Asia/Kolkata");
        let duration = now.diff(istMoment, 'minutes');
        if (duration < 1) {
          this.TimelineDuration = 'just now';
        } else if (duration < 60) {
          this.TimelineDuration = `${duration} minutes ago`;
        } else if (now.diff(istMoment, 'hours') < 24) {
          this.TimelineDuration = `${now.diff(istMoment, 'hours')} hours ago`;
        } else if (now.diff(istMoment, 'days', true) > 1) {
          this.TimelineDuration = istMoment.format('MMMM D');
        } else {
          this.TimelineDuration = 'yesterday';
        }

        return {
          CreatedDt: this.TimelineDuration,
          Commentedby: user.Commentedby,
          Comments: user.Comments,
          Timetitle: datetime,
          ImageUrl: user.ImageUrl,
          Id: user.Id,
          IsEdited: user.IsEdited,
          currentComments: user.currentComments || '',
          currentAssignedBy: user.currentAssignedBy || '',
          currentAssignedTo: user.currentAssignedTo || '',
          currentEndDate: user?.currentEndDate ? new Date(user.currentEndDate)?.toLocaleDateString('en-GB')?.replace(/\//g, '-') : '' || '',
          currentStatus: user.currentStatus || '',
          currentTaskName: user.currentTaskName || '',
          Status: user.Status,
          TaskName: user.TaskName,
          EndDate: user?.EndDate ? new Date(user.EndDate)?.toLocaleDateString('en-GB')?.replace(/\//g, '-') : '',
          AssignedTo: user.AssignedTo,
          CommentedbyId: user.CommentedbyId,
          CommentType: user.CommentType,
          AssignedToId: user.AssignedToId,
          AssignedBy: user.AssignedBy,
          CommentedbyName: this.allUsersListOptions?.find(users => users.id == user.CommentedbyId)?.name,
          ProjectName: user.ProjectName,
          ProjectId: user.ProjectId
        }
      });
      this.loading = false;
    },



    /**
     * Selected user details
     * @param {User details} data 
     */
    selectItem(data) {
      this.isDisable = true;
      this.showDropdown = false;
      this.UserData = data;
      const isValidUser = this.UserDetailsList.find(element => element.id == this.UserData.id)
      if (isValidUser == undefined) {
        this.inputValue = this.inputValue + this.UserData.name;
        this.UserDetailsList.push(this.UserData)
        this.allUsersListOptions = this.allUsersListOptions?.filter(element => element.id != this.UserData.id)
          .map(user => (
            {
              id: user.id,
              name: user.name,
              email: user.email
            }
          ));
      }
    },
    toggleDropdown(inputValue) {
      const cursorPosition = inputValue.selectionStart;
      const match = inputValue.substring(0, cursorPosition).match(/@(\w*)$/);
      if (match) {
        this.showDropdown = true;
      } else {
        this.showDropdown = false;
      }
    },

    UsersDropdownTogglewithIcon() {
      const commentDiv = this.$refs.commentDiv;
      const currentText = commentDiv.innerHTML;

      if (currentText.endsWith('@')) {
        const newText = currentText.slice(0, -1) + '';
        commentDiv.innerHTML = newText;
      } else {
        commentDiv.innerHTML += "@";
      }

      this.handleInput();
    },

    handlePaste(event) {
      event.preventDefault();
      const clipboardData = event.clipboardData || window.clipboardData;
      const plainText = clipboardData.getData("text/plain");
      const isImageUrl = clipboardData.types.includes('text/uri-list');

      if (!isImageUrl) {
        // Insert the plain text into the contenteditable div
        document.execCommand("insertText", false, plainText);
      } else {
        // Optionally, you can display a message to the user that images are not allowed.
        alert("Images are not allowed in this field.");
      }
    },
    preventDragAndDrop(event) {
      event.preventDefault();
    },

    async handleInput() {
      this.commentText = this.$refs.commentDiv.innerHTML;
      const lastMentionIndex = this.commentText.lastIndexOf('@');
      if (lastMentionIndex !== -1) {
        this.searchText = this.commentText.slice(lastMentionIndex + 1);
        this.filteredSuggestions = await this.emailListOptions?.filter(email =>
          !this.mentions.includes(email) && email.toLowerCase().includes(this.searchText.toLowerCase())
        ).sort((a, b) => a.localeCompare(b));
        this.showSuggestions = this.filteredSuggestions?.length > 0;

      } else {
        this.showSuggestions = false;
        this.message = '';

      }

      this.inputValue = this.$refs.commentDiv.innerText;
      if (this.inputValue.length == 0) {
        this.isDisableSave = this.commentText != "" || this.ImageUrl != '' ? true : false;
        this.emailListOptions = this.usersOptionList;
        this.UserDetailsList = [];
      } else {
        this.isDisableSave = this.commentText != "" || this.ImageUrl != '' ? true : false
      }
      this.isCommentChecked = false;
    },

    async handleFileChange(event) {
      const selectedFiles = event.target.files;
      console.log(selectedFiles)
      const allowed_types = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword', 'application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/rtf', 'text/csv',
        'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      ];
      this.message = '';
      this.ImageUrl = [];
      this.isDisableSave = false;

      if (selectedFiles?.length > 10) {
        this.message = 'The file size must not exceed 10MB, and a maximum of 10 files can be uploaded.'
      }
      else {

        for (const selectedFile of selectedFiles) {
          if (!allowed_types.includes(selectedFile.type)) {
            this.message = "Please upload jpg, png, jpeg, img, doc, pdf, ppt, xls, pptx, xlsx, docx files only!";
          } else if (selectedFile.size > 10485760) { // 10MB in bytes (10 * 1024 * 1024)
            this.message = "File size should not be greater than 10MB.";
          } else {
            try {
              const params = {
                File: selectedFile,
                FileName: selectedFile.name
              };
              await this.$store.dispatch('auth/fileUpload', params);
              this.ImageUrl.push(this.getUpload?.result);
              this.isDisableSave = this.ImageUrl ? true : false;
            } catch (error) {
              // Handle any upload errors here
              console.error("File upload error:", error);
              this.message = "File upload failed.";
            }
          }
        }
      }
    },

    removeImage(data) {
      this.$refs.fileInput.value = '';
      this.ImageUrl = this.ImageUrl.filter(img => img != data);
      this.message = '';
      this.isDisableSave =  this.commentText != "" || this.ImageUrl != '' ? true : false;
    },

    RemoveUser(User) {
      this.UserDetailsList = this.UserDetailsList?.filter(element => element.email != User)
    },

    selectMention(mention) {
      this.UserData = this.allUsersListOptions?.find(element => element.name === mention);
      const isValidUser = this.UserDetailsList?.find(element => element == mention)
      if (isValidUser == undefined) {
        this.showDropdown = false;
        this.inputValue = this.inputValue + this.UserData.name;
        this.UserDetailsList.push(this.UserData)
        this.emailListOptions = this.emailListOptions?.filter(element => element != mention)
      }

      const selectedMention = mention + ' ';
      this.searchText = '@' + this.searchText;
      const uneditableMention = `<span style="color: white; padding:2px 5px; border-radius:20px; background-color:#00435d; pointer-events: none;" contenteditable="false">${selectedMention}</span>`;
      this.commentText = this.commentText.replace(this.searchText, uneditableMention);
      this.$refs.commentDiv.innerHTML = '';
      this.$refs.commentDiv.appendChild(document.createRange().createContextualFragment(this.commentText));
      this.showSuggestions = false;
      this.filteredSuggestions = [];
      this.inputValue = this.$refs.commentDiv.innerText;
    },

    async getformViewTask() {
      this.taskId = await this.$route.params.Id;
      this.isCommentChecked = false;
      await this.GetViewTask(this.taskId);
    }
  },

  async mounted() {
    this.taskId = await this.$route.params.Id;
    await this.GetViewTask(this.taskId);
  }
};
</script>

<template>
  <div class="card h-100">
    <div v-if="loading == true">
      <PreLoader></PreLoader>
    </div>
    <div class="card-body pb-0">
      <h4 class="card-title mb-4">Timeline activity </h4>
      <!-- <img v-if="ImageUrl" :src="ImageUrl" class="attachment-file">{{ ImageUrl }} -->
      <!-- <span v-for="(part, index) in parts" :key="index" :style="part.style">{{ part.text }}</span> -->
      <div style="overflow: auto;"
        :style="this.taskData?.Status == isCompleted ? 'max-height: 400px' : 'max-height: 350px'">
        <ul class="list-unstyled activity-wid mb-0">
          <li class="activity-list" v-for="(data, index) in activityData" :key="index">
            <div class="activity-icon avatar-xs">
              <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                <i
                  :class="data.ImageUrl == '' || data.CommentType != isTimeline ? 'ri-calendar-2-fill' : 'ri-image-line'"></i>
              </span>
            </div>
            <div>
              <div>
                <h5 class="font-size-13">
                  <span class="text-truncate"> {{ data.Commentedby }}</span>
                  <small class="text-muted" :title="data.Timetitle">{{ data.CommentType ==
                    isTaskCreated ? ' created' : data.CommentType == isTaskRestored ? ' restored' : data.CommentType ==
                      isTaskDeleted ? ' deleted' : data.CommentType == isTaskEdited?' updated':' commented' }} {{
      data.CreatedDt }}
                    <span @click="EditToggle(data.Id, index)"
                      v-if="((data.CommentedbyId == UserProfileList?.Id || data.CommentedbyId == UserProfileList?.UserId) && data.Comments != '') && taskData?.IsDeleted == false && data.CommentType == isTimeline "><i
                        class="edit_icon fa fa-solid fa-pen"></i></span><small
                      v-if="data.IsEdited == true">(Edited)</small></small>
                </h5>
              </div>
              <div>
                <div class="d-flex flex-column"
                  v-if="data.CommentType == isTaskEdited || data.CommentType == 'UserDeleted'">
                  <span class="d-flex flex-column gap-2 m-1" v-if="data.currentComments != ''">
                    <p class="mr-1 mb-0 flex-shrink-0">Description :</p>
                    <p class="mb-0 old-comment mr-1  flex-shrink-0" :title="data.Comments"> <del> {{ data.Comments
                    }}</del></p>
                    <p class="mb-0 new-comment text-truncate nc-mw" :title="data.currentComments">{{ data.currentComments }}</p>
                  </span>
                  <span class="d-flex m-1" v-if="data.currentStatus != ''">
                    <p class="mr-1 mb-0 flex-shrink-0">Status :</p>
                    <p class="mb-0 old-comment mr-1 text-truncate mw-150 flex-shrink-0 " :title="data.Status"><del class="ml-1"> {{ data.Status }}</del></p>
                    <p class="mb-0 new-comment text-truncate nc-mw" :title="data.currentStatus">{{ data.currentStatus }}</p>
                  </span>
                  <span class="d-flex m-1" v-if="data.currentAssignedBy != ''">
                    <p class="mr-1 mb-0 flex-shrink-0">Assigned by :</p>
                    <p class="mb-0 old-comment mr-1 text-truncate mw-150 flex-shrink-0" :title="data.AssignedBy"><del class="ml-1"> {{ data.AssignedBy }}</del>
                    </p>
                    <p class="mb-0 new-comment text-truncate nc-mw" :title="data.currentAssignedBy">{{ data.currentAssignedBy }}</p>
                  </span>
                  <span class="d-flex m-1" v-if="data.currentAssignedTo != ''">
                    <p class="mr-1 mb-0 flex-shrink-0">Assigned to :</p>
                    <p class="mb-0 old-comment mr-1 text-truncate mw-150 flex-shrink-0" :title="data.AssignedTo"><del class="ml-1"> {{ data.AssignedTo }}</del>
                    </p>
                    <p class="mb-0 new-comment text-truncate nc-mw" :title="data.currentAssignedTo">{{ data.currentAssignedTo }}</p>
                  </span>
                  <span class="d-flex m-1" v-if="data.currentEndDate != ''">
                    <p class="mr-1 mb-0 flex-shrink-0">End date :</p>
                    <p class="mb-0 old-comment mr-1 text-truncate mw-150 flex-shrink-0" :title="data.EndDate"><del class="ml-1"> {{ data.EndDate.split('T')[0]
                    }}</del></p>
                    <p class="mb-0 new-comment text-truncate nc-mw" :title="data.currentEndDate">{{ data.currentEndDate.split('T')[0] }}</p>
                  </span>
                  <span class="d-flex m-1" v-if="data.currentTaskName != ''">
                    <p class="mr-1 mb-0 flex-shrink-0">Task name :</p>
                    <p class="mb-0 old-comment mr-1 text-truncate mw-150 flex-shrink-0" :title="data.TaskName"><del class="ml-1"> {{ data.TaskName }}</del></p>
                    <p class="mb-0 new-comment text-truncate nc-mw" :title="data.currentTaskName">{{ data.currentTaskName }}</p>
                  </span>
                </div>
                <div class=" d-flex flex-row mr-3" v-if="index == isCountIndex && isCommentChecked == true">
                  <input class="form-control" type="text" v-model="editCommentInputValue">
                  <div>

                    <button type="button" @click="updateCommentById" :disabled="editCommentInputValue == ''"
                      class="btn btn-primary ytm-file-input ml-2">
                      <i type="button" class="ri-send-plane-2-fill align-middle"></i>
                    </button>
                  </div>
                </div>
                <div
                  v-if="data.CommentType == isTaskCreated || data.CommentType == isTaskRestored || data.CommentType == isTaskDeleted">
                  <p class="text-muted mb-0" :title="data.title">{{ data.Comments }} </p>
                </div>
                <p class="text-muted mb-0" :title="data.title"
                  v-if="data.CommentType == isTimeline && isCommentChecked == false">
                  <span v-for="(commentPart, index) in data.Comments.split(/(\s+)/)" :key="index">
                    <template v-if="containLink(commentPart)">
                      <a :href="commentPart" target="_blank">{{ commentPart }} </a>
                    </template>
                    <template v-else>
                      {{ commentPart.trim() }}
                    </template>
                  </span>
                </p>
                <div class="fileIconBlock">
                  <div class="file-icon position-relative" v-for="img in data.ImageUrl" :key="img">
                    <a target="_blank" :href="img">
                      <img v-if="img && (img.endsWith('.doc') || img.endsWith('.docx'))"
                        src="../../../assets/images/doc.svg" width="65">
                      <img v-if="img && (img.endsWith('.pdf'))" src="../../../assets/images/pdf.svg" width="65">
                      <img v-if="img && (img.endsWith('.ppt') || img.endsWith('.pptx'))"
                        src="../../../assets/images/ppt.svg" width="65">
                      <img v-if="img && (img.endsWith('.xls') || img.endsWith('.xlsx')) || img?.endsWith('.csv')"
                        src="../../../assets/images/xls.svg" width="65">
                      <img v-if="img && (img.endsWith('.jpg') ||
                        img.endsWith('.jpeg') ||
                        img.endsWith('.png') ||
                        img.endsWith('.img'))" :src="img" width="65">
                    </a>
                  </div>
                </div>

                <!-- <img v-if="data.ImageUrl" :src="data.ImageUrl" alt="image" class="attachment-file"> -->
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-footer bg-white" v-if="taskData.IsDeleted === false">
      <div class="ytmTaskCommentsBlock" >
        <div class="ytmTaskCommentsArea">
          <div class="ytmTaskCommentsImg">
            <img v-if="!UserProfile" src="../../../assets/images/users/user.png" alt="">
            <img v-if="UserProfile" :src="UserProfile" alt="">
          </div>
          <div class="ytmTaskCommentsContent">
            <div ref="commentDiv" contenteditable="true" class="form-control bg-transparent border-0 placeholderText"
              :class="[commentText.length === 0 ? 'nodata' : '']" autocomplete="off" @input="handleInput"
              @paste="handlePaste" @dragover="preventDragAndDrop" >
            </div>
            <div data-simplebar v-if="showSuggestions" class="taggingdropdown">
              <div>
                <div @click="selectMention(item)" v-for="(item, index) in filteredSuggestions" :key="index">
                  <div class="user-tagging d-flex align-items-center">
                    <img src="../../../assets/images/users/user.png" height="30" width="30" />
                    <div class="ml-2 text-truncate">
                      <p class="mb-0 text-truncate font-weight-bold">{{ item }}</p>
                    </div>
                  </div>
                  <hr class="h-line" />
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="ytmTaskAttachmentsArea" v-if="ImageUrl || this.message">
          <div class="ytmTaskAttachment" :title="data.split('verificationdocuments/')[1]" v-for="data in ImageUrl"
            :key="data">
            <a target="_blank" :href="data">
              <div class="ytmTaskAttachmentIcon">
                <img v-if="data && (data.endsWith('.doc') || data.endsWith('.docx'))" src="../../../assets/images/doc.svg"
                  width="22">
                <img v-if="data && (data.endsWith('.pdf'))" src="../../../assets/images/pdf.svg" width="22">
                <img v-if="data && (data.endsWith('.ppt') || data.endsWith('.pptx'))" src="../../../assets/images/ppt.svg"
                  width="22">
                <img v-if="data && (data.endsWith('.xls') || data.endsWith('.xlsx') || data.endsWith('.csv'))"
                  src="../../../assets/images/xls.svg" width="22">
                <img
                  v-if="data && (data.endsWith('.jpg') || data.endsWith('.jpeg') || data.endsWith('.png') || data.endsWith('.img'))"
                  :src="data" width="22">
              </div>
              <div class="ytmTaskAttachmentName">{{ data.split('verificationdocuments/')[1] }}</div>
            </a>
            <div class="ytmTaskAttachmentName" v-if="data" @click="removeImage(data)"><i
                class="ri-close-line text-danger"></i></div>
          </div>
          <div class="small text-muted" v-if="this.message && !commentText">{{
            this.message }}</div>
        </div>
        <div class="ytmTaskCommentsFooter">
          <div class="ytmTaskCIcons">
            <div class="ytmTaskCIcon attachIcon">
              <button type="button" @change="handleFileChange" class="ytm-file-input mr-2 btn-link btn p-0">
                <input type="file" title="" ref="fileInput"
                  accept=".jpg,.png,.jpeg,.img,.doc,.pdf,.ppt,.xls,.pptx,.xlsx,.docx" multiple>
                <i class="ri-attachment-2"></i>
              </button>

            </div>
            <div type="button" class="ytmTaskCIcon attachIcon">
              <!-- <i @click="UsersDropdownTogglewithIcon" type="button" class="ri-at-line"></i> -->
            </div>

          </div>
          <div class="ytmTaskCommentsSubmit">
            <button type="button" :disabled="!isDisableSave && (ImageUrl && commentText)" @click="SaveCommentsData"
              class="btn btn-primary">
              Submit
            </button>
          </div>

        </div>

      </div>
    </div>
    <!-- <div class="card-footer" >
      <div class="col-lg-12 p-0">
        <div class="newsletterBox">
          <div class="input-group" >
            
            <div class="d-flex flex-row justify-content-end">
              
             
            </div>
          </div>
          
        </div>
      </div>
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.Comment-container {
  //overflow: auto;
  height: max-content;
}

.Edit-Send {
  margin-top: 3px;
  margin-left: 5px;
  color: rgb(14, 67, 80);
  font-size: 22px;
}

.edit_icon {
  color: rgb(14, 67, 80);
  font-size: 12px;
}

.CommentDetails {
  font-weight: 800;
  font-size: 14px;
  color: rgb(14, 67, 80);
}

.page-content {
  min-height: 780px;
}

.ytm-file-input {
  position: relative;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  input[type=file],
  input[type=file]::-webkit-file-upload-button {
    cursor: pointer;
  }
}

.ytm-file-input input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;


}
.gap-2{
  gap: 5px;
}

.newsletterBox {
  position: relative;
  height: max-content;
}

.h-line {
  margin: 0.5rem auto;
  border-top: 1px solid rgb(147 147 147 / 10%)
}

.taggingdropdown {
  position: absolute !important;
  background-color: #f9f9f9;
  width: 280px;
  cursor: pointer;
  padding: 10px 10px 0;
  max-height: 180px;
  bottom: 156px;
  border: 1px solid #efefef;
  border-radius: 8px;
  border-bottom: none;
  overflow: auto;

}

.attachment-file {
  position: relative;
  height: 100px;
  width: 200px;
  object-fit: contain;
  background: #f3f3f3;
  border-radius: 10px;
  padding: 10px;
  border: 4px solid #ededed;
  // box-shadow: 0px 0px 20px 0px #efefef;
}

.old-comment {
  background: #4c4c4c;
  color: #fff;
  padding: 2px 4px;
  border-radius: 0px;
  font-size: 12px;
}

.new-comment {
  background: #00435d;
  color: #fff;
  padding: 2px 4px;
  border-radius: 0px;
  font-size: 12px;
  flex-shrink: 0;
}

.nc-mw{
  max-width: 80%;

  @media screen and (max-width: 767px) {
    max-width: 45%;
  }
}

.w-100 {
  max-width: 100px !important
}


.fileIconBlock {
  width: 380px;

  .file-icon {
    width: 65px;
    padding: 0 10px;
    display: inline-flex;
    background: #f1f5f7;
    height: 65px;
    margin-right: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #ededed;

    a {
      display: flex;
    }

    img {
      width: 45px;
      object-fit: contain;
    }
  }
}

.remove-text {
  cursor: pointer;
  padding: 0 15px;
}

.ytmTaskCommentsBlock {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px;

  .ytmTaskCommentsArea {
    display: flex;

    .ytmTaskCommentsImg {
      height: 40px;
      width: 40px;

      img {
        width: inherit;
        height: inherit;
        border-radius: 50%;
        object-fit: contain;
        background: #f3f3f3;
        border: 1px solid #ccc;
      }
    }

    .ytmTaskCommentsContent {
      width: 100%;
      position: relative;
      word-break: break-all;
      

      .placeholderText {
        height: auto;
        min-height: 150px;
        max-height: 250px;
        overflow: auto;

        &.nodata::before {
          content: "Add a comment and use @ to mention a person.";
          position: absolute;
          top: auto;
          bottom: auto;
          margin: auto;
          left: 15px;
          color: #bdb9b9;
        }
      }
    }

  }

  .ytmTaskAttachmentsArea {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 5px;

    .ytmTaskAttachment {
      text-decoration: none;
      display: flex;
      gap: 5px;
      border-radius: 50px;
      border: 1px solid #ccc;
      padding: 3px 10px;
      margin-bottom: 5px;
      cursor: pointer;

      a {
        display: flex;
        gap: 5px;

        .ytmTaskAttachmentIcon {
          width: 22px;
          height: 22px;
          background: #ddd;
          border-radius: 50%;
          overflow: hidden;
          border: 1px solid #ccc;

          img {
            object-fit: contain;
          }
        }

        .ytmTaskAttachmentName {
          max-width: 135px;
          overflow: hidden;
          text-overflow: ellipsis;
          text-wrap: nowrap;
        }
      }
    }
  }

  .ytmTaskCommentsFooter {
    border-top: 1px solid #ccc;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;

    .ytmTaskCIcons {
      display: flex;
      gap: 15px;

      .ytmTaskCIcon {
        font-size: 18px;
        cursor: pointer;

        i {
          font-size: 18px;
        }
      }
    }
  }
}
</style>