<script>
import Layout from "../../layouts/vertical";
import PageHeader from "@/components/page-header";
// import PreLoader from "@/components/pre-loader";
import ProfileSettings from "@/components/profile-settings";
import AccountSettings from "@/components/account-settings";
export default {
    components: { Layout, PageHeader, ProfileSettings, AccountSettings },
    data() {
        return {
            items: [
                {
                    text: "Dashboard",
                    routeName: "Dashboard",
                },
                {
                    text: "Settings",
                    active: true,
                },
            ],
            // loading: true,
            showShimmers: true,
        };
    },
    methods: {
        resetProfileSettings(tabName) {
            this.showShimmers = true
            this.$refs[tabName].initform();
            setTimeout(() => {
                this.showShimmers = false;
            }, 1000);
        },
    },
    mounted() {
        setTimeout(() => {
            // this.loading = false;
            this.showShimmers = false;
        }, 1000);
    },
};
</script>

<template>
    <Layout>
        <PageHeader :items="items" />
        <!-- <div v-if="loading">
            <PreLoader></PreLoader>
        </div> -->

        <div class="row">
            <div class="col-12 col-sm-8 col-md-6 col-xl-6 mx-auto">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title mb-4">Settings</h4>
                        <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
                            <b-tab active @click="resetProfileSettings('profileSettingsRef')">
                                <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="ri-user-line"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">Profile settings</span>
                                </template>
                                <div v-if="showShimmers">
                                    <div class="row">
                                        <div class="col-sm-12 mx-auto">
                                            <div class="col-lg-12">
                                                <div class="form-group d-flex justify-content-center">
                                                    <b-skeleton-img no-aspect height="80px" width="80px"
                                                        style="border-radius: 80px;"></b-skeleton-img>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <b-skeleton width="50%"></b-skeleton>
                                                    <b-skeleton type="input"></b-skeleton>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <b-skeleton width="50%"></b-skeleton>
                                                    <b-skeleton type="input"></b-skeleton>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <b-skeleton width="50%"></b-skeleton>
                                                    <b-skeleton type="input"></b-skeleton>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <b-skeleton width="25%"></b-skeleton>
                                                    <div class="d-flex justify-content-between">
                                                        <b-skeleton height="37px" width="30%"></b-skeleton>
                                                        <b-skeleton height="37px" width="60%" type="input"></b-skeleton>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col d-flex ml-auto justify-content-end">
                                                <b-skeleton class="mr-2" type="button"></b-skeleton>
                                                <b-skeleton type="button"></b-skeleton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <ProfileSettings ref="profileSettingsRef"></ProfileSettings>
                                </div>
                            </b-tab>
                            <b-tab @click="resetProfileSettings('AccountSettingsRef')">
                                <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="ri-key-2-line"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">Account settings</span>
                                </template>
                                <div v-if="showShimmers">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <b-skeleton width="50%"></b-skeleton>
                                                <b-skeleton type="input"></b-skeleton>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <b-skeleton width="50%"></b-skeleton>
                                                <b-skeleton type="input"></b-skeleton>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <b-skeleton width="50%"></b-skeleton>
                                                <b-skeleton type="input"></b-skeleton>
                                            </div>
                                        </div>
                                        <div class="col d-flex justify-content-end">
                                            <b-skeleton class="mr-2" type="button"></b-skeleton>
                                            <b-skeleton type="button"></b-skeleton>
                                        </div>
                                    </div>
                                </div>


                                <div v-else>
                                    <AccountSettings ref="AccountSettingsRef"></AccountSettings>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<style scoped></style>