import axios from "axios";
import router from "../router/index";
import store from "../store/index";
  const $axios = axios.create({
    baseURL:process.env.VUE_APP_DEV_AUTH_API_URL,
    timeout: 30000,
  });
  $axios.interceptors.request.use(
    (config) => {
      store.commit("errors/reset");
      let authDetails = store.getters["auth/getAuthenticationDetails"];
      if (!config.headers.common) {
        config.headers.common = {};
      }
      if (authDetails && authDetails["access_token"]) {
        config.headers.common["Authorization"] = authDetails["access_token"];
      }
  
      if (authDetails && authDetails["user_id"]) {
        config.headers.common["userid"] = authDetails["user_id"];
      }
  
      if (authDetails && authDetails["password"]) {
        config.headers.common["password"] = authDetails["password"];
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  // function setBaseURL(apiDomain) {
  //   switch (apiDomain) {
  //     case 'Auth':
  //       $axios.defaults.baseURL =process.env.VUE_APP_DEV_AUTH_API_URL;
  //       break;
  //     case 'Task':
  //       $axios.defaults.baseURL = process.env.VUE_APP_DEV_TASK_API_URL;
  //       break;
  //     default:
  //       $axios.defaults.baseURL =process.env.VUE_APP_DEV_AUTH_API_URL;
  //       break;
  //   }
  // }
  $axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      let originalRequest = error.config;
      let errorStatusCode = error.response?.status;
  
      let lastEndpoint = error.config?.url.split("/").pop();
      let allowedEndpoints = [
        "connect/token",
        "verify-reset-password",
        "verify-email",
        "verify-password",
        "/api/v1/User/GetUserDefaults",
        "/api/v1/Auth/GetAllUsers",
      ];
      if (
        errorStatusCode === 401 &&
        !originalRequest._retry &&
        allowedEndpoints.indexOf(lastEndpoint) == -1
      ) {
        originalRequest._retry = true;
        resetStore();
        if (error.config.url !== "signin") {
          router.push("/signin");
        }
      }
  
      return Promise.reject(error);
    }
  );
  
  const resetStore = () => {
    store.dispatch("globalReset");
  };
  export { $axios as axios };