<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>


export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.

  }
};
</script>


<style lang="scss">
.multiselect__select {
  z-index: 1;
}

.mw-200 {
  max-width: 200px;
}

.multiselect {
  min-height: 38px !important;
}

.selction-padding {
  padding: 2px 8px !important;
}

.mx-input {
  height: 38px !important;
  box-shadow: none !important
}

.mx-input:hover,
.mx-input:focus {
  border-color: #ced4da !important;
}

.attendance-record {
  .mx-input {
    height: 28.89px !important;
  }
}

.date-range {
  .mx-input {
    height: 28.89px !important;
    padding-right:22px
  }

  @media screen and (min-width:768px) {
    .mx-input {
      width: 220px;
      height: 28.89px !important;
    }
  }
}
</style>