<script>
import Layout from "../../layouts/vertical.vue";
import PageHeader from "@/components/page-header";
import PreLoader from "@/components/pre-loader";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";

export default {
    name: 'TaskList',
    components: { Layout, PageHeader, PreLoader, DatePicker, Multiselect },

    data() {
        return {
            loading: true,
            department: '',
            defaultdate: '',
            title: "Overtime Report",
            items: [
                {
                    text: "Dashboard",
                    routeName: "Dashboard",
                },
                {
                    text: "Overtime Report",
                    active: true,
                },
            ],
            options: [
                "Praveen Manne",
                "Raji Chava",
                "Bannu Thomas",
                "Shreyas Pangal",
                "Ravi Kumar"
            ],
        }
    },
    mounted() {
        setTimeout(() => {
            this.loading = false;
        }, 1000);
    },
}
</script>


<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div v-if="loading">
            <PreLoader />
        </div>
        <div class="home">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <form>
                                <div class="row">
                                    <div class="col-12  col-md-3 ">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="department">Employee Name:</label>
                                                <multiselect v-model="department" :options="options"></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-3">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="dateFrom">From Date:</label>
                                                <date-picker v-model="defaultdate" :first-day-of-week="1" lang="en"
                                                    confirm></date-picker>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12  col-md-3 ">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="dateTo">To Date:</label>
                                                <date-picker v-model="defaultdate" :first-day-of-week="1" lang="en"
                                                    confirm></date-picker>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12  col-md-3 ">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="department">Report Type:</label>
                                                <select class="department mx-input" id="department" name="department">
                                                    <option value="">Daily Overtime</option>
                                                    <option value="">Weekly Overtime</option>
                                                    <option value="">Monthly Overtime</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="report">
                                <!-- <h1>Attendance and Leave Reports</h1> -->
                                <div class="report-form">

                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="text-right">
                                <button type="submit" class="btn btn-outline-primary">Get Report PDF</button>
                                <button class="btn btn-primary ml-2" type="submit">Get Report Excel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<style scoped>
/* body {
    font-family: Arial, sans-serif;
}

.report {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.report-form {
    border: 1px solid #00435D;
    padding: 20px;
    border-radius: 5px;
} */

.form-group {
    margin-bottom: 10px;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

input[type="text"],
input[type="date"]{
    width: 100%;
    padding: 8px;
    border: 1px solid #00435D;
    border-radius: 3px;
}

/* button {
    background-color: #00435D;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
} */

button:hover {
    background-color: #00435D;
}
</style>