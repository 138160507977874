<script>
/* eslint-disable */
import { required, email } from 'vuelidate/lib/validators';
import { layoutComputed } from "../state/helper";
import { mapGetters, mapMutations } from "vuex";
import { countrycode } from "../countrycode";
import PreLoader from "@/components/pre-loader";

export default {
    components: { PreLoader },
    computed: {
        isFormInvalid() {
            return (
                (!this.Profile.phoneNumber || this.Profile.phoneNumber?.length < 10) ||
                !this.Profile.Email ||
                !this.Profile.firstName ||
                !this.Profile.lastName ||
                !this.Profile.countrycode
            );
        },
        ...layoutComputed,
        ...mapGetters('auth', ['getProfileDetails', 'getUpdateDetailsResponse', 'getUpload']),
        ...mapGetters('userservice', ['getUserDetailsById', "getupdateAssignedName"]),
        ...mapGetters("errors", ["getError", "getErrors"]),
    },
    data() {
        return {
            countrycode: countrycode,
            previewUrl: null,
            Profile: {
                phoneNumber: "",
                Email: "",
                firstName: "",
                lastName: "",
                countrycode: ""
            },
            firstnameBlured: false,
            isError: false,
            errorResponse: null,
            lastnameBlured: false,
            phoneNumberBlured: false,
            showDismissibleAlert: false,
            countrycodeBlured: false,
            formChanged: false,
            value: "",
            firstNamehasSpecialCharacters: false,
            lastNamehasSpecialCharacters: false,
            hasCharacters: false,
            loading: false,
            message: ''
        };
    },
    validations: {
        Profile: {
            firstName: { required },
            lastName: { required },
            phoneNumber: { required, maxlength: 10 },
            Email: { required, email }
        }
    },
    created() {
        this.initform(); // Call the method initially to patch the values
    }, methods: {
        async previewImage(event) {
            this.loading = true;
            this.formChanged = true
            const allowed_types = ['image/jpeg', 'image/jpg', 'image/png'];
            if (event?.target?.files[0]) {
                const selectedFile = event?.target?.files[0];
                this.message = '';
                if (!_.includes(allowed_types, selectedFile.type)) {
                    this.message = "Please upload jpeg, jpg, png file only!";
                } else if (selectedFile.size > 5242880) {
                    this.message = "File size should not be greater than 5Mb.";
                } else {
                    try {
                        const params = {
                            File: selectedFile,
                            FileName: selectedFile.name
                        };
                        await this.$store.dispatch('auth/fileUpload', params);
                        this.previewUrl = this.getUpload?.result;
                    } catch (error) {
                        // Handle any upload errors here
                        console.error("File upload error:", error);
                        this.message = "File upload failed.";
                    }
                }
            }
            this.loading = false;
        },
        removeImage() {
            // this.$refs.fileInput.value = '';
            this.formChanged = true;
            this.previewUrl = '';
            this.message = '';
        },
        uploadImage() {
            this.$refs.fileInput.click();
        },
        /**
         * validating phone number
         */
        validatePhoneNumber() {
            this.hasCharacters = false;
            this.hasCharacters = (/[^0-9]/.test(this.Profile.phoneNumber))
            this.Profile.phoneNumber = this.Profile.phoneNumber?.replace(/[^0-9.]/g, '');
        },
        /**
         * validating first name and last name
         * @param {*} fieldName 
         */
        validateName(fieldName) {
            this[fieldName + 'hasSpecialCharacters'] = false;
            const specialCharPattern = /[^\w\s]/;
            const validCharPattern = /[^a-zA-Z0-9 ]/g;
            this[fieldName + 'hasSpecialCharacters'] = specialCharPattern.test(this.Profile[fieldName]);
            this.Profile[fieldName] = this.Profile[fieldName]?.replace(validCharPattern, '');
        },
        /**
         * patching the values
         */
        async initform() {
            try {
                this.getUserDetailsById.UserGuid.toLowerCase() !== this.getProfileDetails.UserGuid.toLowerCase() ? 
                await this.$store.dispatch('userservice/GetUserDetailsByGuid', this.getProfileDetails.UserGuid) : '';

                this.Profile = {
                    countrycode: this.getUserDetailsById.CountryCode || '',
                    firstName: this.getUserDetailsById.FirstName,
                    lastName: this.getUserDetailsById.LastName,
                    Email: this.getUserDetailsById.Email,
                    phoneNumber: this.getUserDetailsById.PhoneNumber?.replace(/[^0-9.]/g, ''),
                },
                    this.previewUrl = this.getUserDetailsById?.ImgUrl
                this.firstnameBlured = false;
                this.lastnameBlured = false;
                this.phoneNumberBlured = false;
                this.countrycodeBlured = false;
                setTimeout(() => {
                    this.formChanged = false;
                });
                this.hasSpecialCharacters = false;
                this.LastnamehasSpecialCharacters = false;
            } catch (error) {
                if (this.getErrors["critical_error"]) {
                    this.isError = true;
                    this.errorResponse = this.getErrors["critical_error"];
                } else {
                    this.ProjectsList = [];
                    this.isError = true;
                    this.errorResponse = "Something went wrong !";
                }
            }
        },
        /**
         * saving the profile details
         */
        ...mapMutations(['setusername', 'setUpdateHierarchy', 'setAlertNotification']),
        async Submit() {
            this.loading = true
            this.hasSpecialCharacters = false;
            this.LastnamehasSpecialCharacters = false;
            this.formChanged = false;
            // this.$v.$touch();
            if (!this.isFormInvalid) {
                const form = this.Profile;
                const params = {
                    firstName: form.firstName,
                    lastName: form.lastName,
                    phoneNumber: form.phoneNumber,
                    countryCode: form.countrycode,
                    userGuid: this.getProfileDetails.UserGuid,
                    ImgUrl: this.previewUrl
                }
                await this.$store.dispatch('auth/UpdateUserdetails', params);
                await this.$store.dispatch('userservice/GetUserDetailsByGuid', this.getProfileDetails.UserGuid);
                const input = {
                    UserId: this.getProfileDetails?.UserId || this.getProfileDetails?.Id,
                    UserName: this.Profile?.firstName + ' ' + this.Profile?.lastName
                }
                await this.$store.dispatch('userservice/UpdateAssignedNames', input);

                this.value = this.getupdateAssignedName?.msg
                const message = {
                    variant: 'success',
                    alertTitle: 'Profile update!',
                    alertDecription: this.getUpdateDetailsResponse
                }
                this.setUpdateHierarchy('');
                setTimeout(() => {
                    this.loading = false
                    this.setUpdateHierarchy(this.getProfileDetails.UserGuid)
                    this.setAlertNotification(message)
                    this.setusername(this.Profile?.firstName + ' ' + this.Profile?.lastName)
                }, 1200);
            }
        },

    },
    watch: {
        Profile: {
            deep: true,
            handler() {
                this.formChanged = true;
            },
        },
    },
};
</script>
<template>
    <div>
        <div v-if="loading">
            <PreLoader></PreLoader>
        </div>
        <form @submit.prevent="Submit">
            <div class="row">
                <div class="profilepic-upload">
                    <button class="btn btn-primary ytm-file-input" v-if="!previewUrl">
                        <input type="file" ref="fileInput" @change="previewImage" accept=".jpg,.png,.jpeg,.img" title="" />
                        <i class="ri-camera-3-line"></i>
                    </button>
                    <div class="profile-pic-container">
                        <div class="image-container">
                            <img src="@/assets/images/users/user.png" alt="Default Profile Picture" class="profile-pic"
                                v-if="!previewUrl" />
                            <img :src="previewUrl" alt="Preview" v-if="previewUrl" class="profile-pic" />
                            <span class="remove-text font-size-16 font-weight-bold" v-if="previewUrl"
                                @click="removeImage">Remove</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="invalid-feedback d-block text-center" v-if="this.message">{{ this.message }}</div>
            <div class="row">
                <div class="col-lg-12 mt-2">
                    <div class="form-group">
                        <label for="email">Email <span :class="{ 'text-danger': !Profile.Email }">*</span></label>
                        <input type="email" v-model="Profile.Email" id="email" class="form-control"
                            placeholder="Enter email" disabled />
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="form-group">
                        <label for="firstName">First name <span
                                :class="{ 'text-danger': !Profile.firstName }">*</span></label>
                        <input type="text" class="form-control" :maxlength="50" v-model="Profile.firstName"
                            placeholder="Enter first name"
                            v-bind:class="{ 'form-control': true, 'is-invalid': !Profile.firstName && (firstnameBlured) || firstNamehasSpecialCharacters }"
                            v-on:blur="firstnameBlured = true; firstNamehasSpecialCharacters = false"
                            @input="validateName('firstName')" />
                        <div class="invalid-feedback"
                            v-if="!Profile.firstName && (firstnameBlured) && !firstNamehasSpecialCharacters">First name is
                            required!</div>
                        <div class="invalid-feedback" v-if="firstNamehasSpecialCharacters">Special characters are not
                            allowed!</div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="form-group">
                        <label for="lastName">Last name <span :class="{ 'text-danger': !Profile.lastName }">*</span></label>
                        <input type="text" class="form-control" :maxlength="50" v-model="Profile.lastName"
                            placeholder="Enter last name"
                            v-bind:class="{ 'form-control': true, 'is-invalid': !Profile.lastName && (lastnameBlured) || lastNamehasSpecialCharacters }"
                            v-on:blur="lastnameBlured = true; lastNamehasSpecialCharacters = false"
                            @input="validateName('lastName')" />
                        <div class="invalid-feedback"
                            v-if="!Profile.lastName && (lastnameBlured) && !lastNamehasSpecialCharacters">Last name is
                            required!</div>
                        <div class="invalid-feedback" v-if="lastNamehasSpecialCharacters">Special characters are not
                            allowed!</div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="form-group">
                        <label class="label-title">Contact number <span
                                :class="{ 'text-danger': Profile.phoneNumber?.length < 10 || !Profile.countrycode || !Profile.phoneNumber }">*</span></label>
                        <div class="phoneWrapper">
                            <div class="countryCodeWrapper">
                                <select v-model="Profile.countrycode" id="countrycode" name="countrycode"
                                    v-bind:class="{ 'is-invalid': !Profile.countrycode && (countrycodeBlured) }"
                                    class="form-control h-40px auth-input flex-grow-0 countryCodeBox phone-dropdown pl-1"
                                    v-on:blur="countrycodeBlured = true" @change="Profile.phoneNumber = ''">
                                    <option value="" disabled selected>Country code</option>

                                    <option v-for="option of countrycode" :value="option.dialCode">{{ option.dialCode }} -
                                        ({{ option.isoCode }})</option>
                                </select>
                                <div class="invalid-feedback d-block" v-if="countrycodeBlured && !Profile.countrycode">
                                    country code is required!</div>
                            </div>
                            <div class="w-100 phone-input">
                                <input type="tel" placeholder="Enter mobile number " class="form-control auth-input"
                                    pattern="[0-9]+" v-model="Profile.phoneNumber" id="contactNumber" :maxlength="10"
                                    :minlength="10" @input="validatePhoneNumber"
                                    v-bind:class="{ 'form-control': true, 'is-invalid': (Profile.phoneNumber?.length < 10 || !Profile.phoneNumber) && (phoneNumberBlured) || hasCharacters }"
                                    v-on:blur="phoneNumberBlured = true; hasCharacters = false" />
                                <span class="invalid-feedback d-block"
                                    v-if="Profile.phoneNumber?.length < 10 && !hasCharacters && Profile.phoneNumber">Phone
                                    number 10 digits valid</span>
                                <div class="invalid-feedback"
                                    v-if="!Profile.phoneNumber && (phoneNumberBlured) && !hasCharacters">Phone number is
                                    required</div>
                            </div>
                        </div>
                        <div class="invalid-feedback d-block" v-if="hasCharacters">Special characters and characters are not
                            allowed!</div>

                    </div>

                </div>
            </div>
            <div class="text-right mt-4">
                <button type="button" :class="{ 'btn btn-outline-primary': !formChanged, 'btn btn-primary': formChanged }"
                    @click="initform" :disabled="!formChanged">
                    <i class="ri-refresh-line align-middle"></i> Reset
                </button>
                <b-button class="ml-2" variant="primary" type="submit" :disabled="!formChanged || isFormInvalid">
                    <i class="ri-check-double-line align-middle"></i> Update
                </b-button>
            </div>
        </form>
    </div>
</template>

<style lang="scss" scoped>
.profilepic-upload {
    position: relative;
    margin: auto;
}

.profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    -o-object-fit: contain;
    object-fit: contain;
    border: 2px solid #00435d;
    background-color: #00435d;
}

.ytm-file-input {
    position: absolute;
    border-radius: 50% !important;
    left: 75px;
    top: 50px;
    width: 40px;
    height: 40px;
    z-index: 1;
}

.edittask-alert {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 10000;
}

.ytm-file-input input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
}

.iti {
    width: 100% !important;
}

.phoneWrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 480px) {
        flex-direction: row;
    }
}

.phone-dropdown {
    /* margin-right: 0.5rem; */
    width: 127px;
    height: 38px;
    padding-left: 0.25rem;
    padding-right: 0.25rem;

    @media screen and (max-width: 479px) {
        width: 100%;
        margin-bottom: 0.5rem;
    }
}

.phone-input {
    margin-left: 0.5rem;

    @media screen and (max-width: 479px) {
        margin-left: 0rem;
    }

    input {
        height: 38px;
        padding: 8px 12px;
    }
}

.profile-pic-container {
    position: relative;
    display: inline-block;
}

.image-container {
    position: relative;
    display: inline-block;
}

.remove-text {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px;
    cursor: pointer;
    width: 100%;
    top: 0;
    color: #00435d;
}

.image-container:hover .remove-text {
    display: flex;
    justify-content: center;
    align-items: center;

}

/* Additional styling for the remove button */
.remove-text:hover {
    background-color: rgba(255, 255, 255, 0.8);
}

.remove-btn {
    position: absolute;
    bottom: 0;
    border-radius: 30px;
    left: 75px;
    top: 50px;
    width: 40px;
    height: 40px;
    z-index: 2;
    display: flex;
    justify-content: center;
}
</style>

  