<script>
import Layout from "../../layouts/vertical.vue";
import PageHeader from "@/components/page-header";
// import PreLoader from "@/components/pre-loader";
import { mapGetters, mapState, mapMutations } from "vuex";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import moment from "moment-timezone";
import DatePicker from "vue2-datepicker";
import _ from "lodash";
import { TaskFields } from "../../../assets/json/tasks-heading";
// import axios from "axios";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export default {
  name: "TaskList",
  components: { Layout, PageHeader, Multiselect, DatePicker },
  computed: {
    computedItems() {
      return this.title == 'Mentioned me' ? this.items3 : (this.title === 'Assigned to me' ? this.items4 : (this.title === 'Tasks' ? this.items1 : (this.title === 'Over due' ? this.items5 :(this.title === 'Assigned by me')? this.items6 : this.items2)));
    },
    ...mapState(["getUserguid", "getUserId", "getPermissions", 'getUserIds', 'taskFilter', 'getTaskPopup', 'gettaskfilterstate', 'getUserReportingGuid']),
    ...mapGetters("userservice", ["getAllUsers", "getUserHierarchy", "getUserDetailsById", "getUserDefaults"]),
    ...mapGetters("projects", ["getAllProjectsForUser", "getAllUsersByProject"]),
    ...mapGetters("tasks", [
      "getalltasks",
      "getSaveTask",
      "getAllStatuses",
      "getViewAllTaskstoId",
      "GetAllTasksAssigned", 'getTaskAttachment',
      "getTaskData", 'getAssignedToTaskData',
      "getDeletedTask",
      "getDeteteTask",
      "getsendEmail"
    ]),

    ...mapGetters("auth", ["getProfileDetails", "getAuthenticatedUser"]),
    ...mapGetters("errors", ["getErrors"]),
    //Check and filters the permissions user has for tasks page.
    filteredTaskPermisions() {
      let permissions = this.getPermissions?.TaskRoles
      function hasPermission(permission) {
        return permissions?.includes(permission);
      }
      return this.TaskPermisions.filter((TaskPermisions) => {
        return (
          (TaskPermisions.permission === "CreateTask" &&
            hasPermission("CreateTask")) ||
          (TaskPermisions.permission === "DeleteTask" &&
            hasPermission("DeleteTask"))
        );
      });
    },

    filteredOptions() {
      const filteredOptions = this.assignedtolist.filter((option) =>
        option.name
          .toLowerCase()
          .includes(this.searchAssignedToQuery.toLowerCase())
      );
      return filteredOptions
    },
    hasData() {
      return this.getViewAllTaskstoId && this.getViewAllTaskstoId.length > 0;
    },
    isFormInvalid() {
      return (
        !this.taskDataBind.taskName ||
        !this.taskDataBind.dueDate ||
        !this.taskDataBind.comments ||
        !this.seletedProject
      );
    },
    taskIdArray() {
      return [
        this.getUserId ||
        this.getProfileDetails.UserId ||
        this.getProfileDetails.Id,
      ];
    },
    uniqueTasks() {
      const uniqueIds = new Set();
      return this.filterTableData.filter((task) => {
        if (!uniqueIds.has(task.TaskIdNumber)) {
          uniqueIds.add(task.TaskIdNumber);
          return true;
        }
        return false;
      });
    },
  },
  data() {
    return {
      showFilters: false, // Initial state of the filters section
      taskDataBind: {
        taskName: "",
        assignedTo: "",
        dueDate: "",
        comments: "",
        Status: "",
        EndDate: "",
        userEmail: ""
      },
      mainuser: [],
      iSOverDue: "Over due",
      StatusType: "",
      isTabActive: 'Tasks',
      Tabs: { name: "All tasks", value: 'Tasks', class:'ri-list-unordered align-text-bottom' },
      debouncedFiltered: _.debounce(this.onFiltered),
      filterVariable: [],
      isError: false,
      errorResponse: null,
      newtaskname: "",
      assignedToOption: [],
      assignedTo: null,
      projectTitleBlurred: false,
      seletedProject: null,
      assignedBy: null,
      isSearch: true,
      isBusy: false,
      tableData: [],
      ProjectsList: [],
      projectRowCount: 200,
      TaskList: null,
      rows: 0,
      perPage: 15,
      taskname: "",
      currentPage: 1,
      pageOptions: [15, 25, 50, 100],
      title: "Tasks",
      defaultStatus: "New",
      isDisable: false,
      isAssignTo: false,
      formChanged: false,
      isComments: false,
      isCompleted: "Completed",
      isinprogess: "In-progress",
      assignedByType: "AssignedBy",
      assignedToType: "AssignedTo",
      isTaskName: false,
      isDueDate: false,
      // loading: true,
      showShimmers: true,
      modalVisible: false,
      currentDate: new Date().toISOString().split("T")[0],
      filter: null,
      filterBy: null,
      searchQuery: "",
      filterOn: [],
      selectAll: false,
      sortBy: "age",
      Sort: "asc",
      Orderby: "",
      sortDesc: false,
      downloading: false,
      option: "",
      TaskPermisions: [],
      filterTableData: [],
      AssignedToFilterCount: null,
      searchAssignedToQuery: "",
      Variable: [],
      optionArray: [],
      assignedToOptionArray: [],
      deletedTaskList: [],
      isTaskList: false,
      assignedToBlurred: false,
      isTaskId: false,
      RestoreTaskName: '',
      bordered: '',
      daterange: "",
      response: "",
      TodayDate: new Date().toISOString().slice(0, 10),
      fields: null,
      taskHeading: [
        { key: "Selection", label: " " },
        {
          key: "TaskIdNumber",
          label: "ID",
          sortable: true,
          class: ["text-truncate", "mw-150"],
        },
        {
          key: "TaskName",
          label: "Task name",
          sortable: true,
          class: ["text-truncate", "mw-150"],
        },
        {
          key: "ProjectName",
          label: "Project name",
          sortable: true,
          class: ["text-truncate", "mw-150"],
        },
        {
          key: "AssignedTo",
          label: "Assigned to",
          sortable: true,
          class: ["text-truncate", "mw-150"],
        },
        {
          key: "CreatedDt",
          label: "Created date",
          sortable: true,
          class: ["text-truncate", "mw-150"],
        },
        {
          key: "EndDate",
          label: "End date ",
          sortable: true,
          class: ["text-truncate", "mw-150"],
        },
        { key: "Status", label: "Status", sortable: true },
        { key: "Actions", label: "Action" },
      ],
      items1: [
        {
          text: "Dashboard",
          routeName: "Dashboard",
        },
        {
          text: "Tasks",
          href: "",
        },
      ],
      items2: [
        {
          text: "Tasks",
          href: "/tasks-list",
        },
        {
          text: "Deleted tasks",
          href: "",
        },
      ],
      items3: [
        {
          text: "Tasks",
          href: "/tasks-list",
        },
        {
          text: "Mentioned me",
          href: "",
        },
      ],
      items4: [
        {
          text: "Tasks",
          href: "/tasks-list",
        },
        {
          text: "Assigned to me",
          href: "",
        },
      ],
      items5: [
        {
          text: "Tasks",
          href: "/tasks-list",
        },
        {
          text: "Over due",
          href: "",
        },
      ],
      items6: [
        {
          text: "Tasks",
          href: "/tasks-list",
        },
        {
          text: "Assigned by me",
          href: "",
        },
      ],
      ProjectsListOptions: [],
      HeirachyUsers: [],
      selectedStatusOption: "Status",
      selectedAssigneeOption: "Assigned to",
      orderbylist: [],
      newStatus: {},
      assignedtolist: [],
      selectedProjects: 'Projects',
      projects: [],
      TaskOrderBy: "Tasks",
      taskData: [],
      fromDate: '',
      Todate: '',
      // projectTitle: '',
      // projectList: [
      //   "Touchscreen",
      //   "Call Function",
      //   "Notifications",
      //   "Fitness",
      //   "Outdoor"
      // ],
      tabOptions: [
        { name: "All tasks", value: 'Tasks', class:'ri-list-unordered align-text-bottom' },
        { name: "Assigned to me", value: 'Assigned to me', class:'ri-user-add-line align-text-bottom' },
        { name: "Mentioned me", value: 'Mentioned me', class:'ri-at-fill align-text-bottom' },
        { name: "Assigned by me", value: 'Assigned by me',class:'ri-user-follow-line align-text-bottom' },
        { name: "Over due tasks", value: 'Over due',class:'ri-calendar-todo-line align-text-bottom' },
        { name: "Deleted tasks", value: 'Deleted tasks',class:'ri-delete-bin-line align-text-bottom' },
      ],
      saved: false,
      Shimmers: true,
      Projectdata: [],
      updateOverDueData: [],
      date: '',
      myTasksCount:0
    };
  },
  validations: {
    taskDataBind: {
      taskName: { required },
      assignedTo: { required },
      dueDate: { required },
      comments: { required },
    },
  },
  watch: {
    assignedTo: {
      deep: true,
      handler() {
        this.isAssignTo = true;
      },
    },
    'taskDataBind.dueDate': function (newDate) {
      newDate ?
        this.date = moment(newDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : "";
    },

    // assignedBy : {
    //   handler(newVal) {
    //     if (newVal) {
    //       // Access the selectedUser.id to get the corresponding additional data
    //       this.additionalData = this.getAdditionalDataAssinedBy(newVal.Id) //|| this.getAdditionalData(this.taskId);
    //     } else {
    //       this.additionalData = null;
    //     }
    //   },
    //   immediate: true,
    // },
  },
  created() {
    this.initTaskPermisions();
  },
  methods: {
    // selecting all checkbox in tasklist
    toggleSelectAll() {
      this.Shimmers = false
      this.tableData.forEach((row) => {
        this.$set(row, 'selected', this.selectAll)
      });
      this.showFilters = true;
    },
    selectAllToggle() {
      this.Shimmers = false;
      const allSelected = this.tableData.every(row => row.selected);
      this.selectAll = allSelected ? true : false;
    },
    async GetAllStstusesList() {
      await this.$store.dispatch("tasks/GetAllStatusesList");
      this.orderbylist = await this.getAllStatuses
        ?.filter((statusData) => statusData && statusData.Id != null && statusData.StatusName != 'Closed')
        .map((status) => status.StatusName)
        .filter(statusName => statusName !== 'Unassigned')
        .sort();
      this.newStatus = await this.getAllStatuses
        ?.filter((statusData) => statusData && statusData.Id !== null && statusData.StatusName != 'Closed')
        .map((status) => {
          return {
            Id: status.Id,
            StatusName: status.StatusName,
          };
        })
        .find((status) => status.StatusName == "New");
      //this.orderbylist.push('Unassigned');
    },
    //checks the user has specific permission or not.
    hasPermission(permissionToCheck) {
      return this.filteredTaskPermisions.some(
        (permissionObj) => permissionObj.permission === permissionToCheck
      );
    },
    changeStatusOption(option) {
      if (option == "clear") {
        this.optionArray = [];
        this.selectedStatusOption = "Status";
      } else {
        const index = this.optionArray.indexOf(option);
        if (index !== -1) {
          this.optionArray.splice(index, 1);
        } else {
          this.optionArray.push(option);
        }
        if (this.optionArray.length) {
          this.selectedStatusOption =
            this.optionArray.length > 1
              ? `${this.optionArray[0]} (+${this.optionArray.length - 1})`
              : `${this.optionArray[0]}`;
        } else {
          this.selectedStatusOption = "Status";
        }
        //     }
        //   //     if (this.optionArray.includes("Unassigned")) {
        //   //   this.tableData = this.filterTableData.filter(task => {
        //   //   return task.AssignedToId == '0';
        //   // });

        // } else {
      }
      this.applyFilters();
    },
    ...mapMutations(['setFilterData']),
    applyFilters() {
      let filteredData = [];

      if (this.optionArray.length > 0 || this.assignedToOptionArray.length > 0) {

        // status filter
        if (this.optionArray.length > 0) {
          filteredData = this.filterTableData.filter((element) =>
            this.optionArray.includes(element.Status)
          );
        }

        // AssignedTo filter
        if (this.assignedToOptionArray.length > 0) {
          filteredData = this.filterTableData.filter((element) =>
            this.assignedToOptionArray.includes(element.AssignedToId)
          );
        }

        // AssignedTo && status filter
        if (this.optionArray.length > 0 && this.assignedToOptionArray.length > 0) {
          filteredData = this.filterTableData.filter((element) =>
            this.assignedToOptionArray.includes(element.AssignedToId) && this.optionArray.includes(element.Status)
          );
        }
      }
      else {
        filteredData = this.filterTableData;
      }
      this.tableData = filteredData;
      this.rows = this.tableData?.length
      const data = {
        statusFilter: this.optionArray || [],
        assignedToFilter: this.assignedToOptionArray || [],
        projectFilter: this.gettaskfilterstate.projectFilter || [],
        dateFilter: this.gettaskfilterstate.dateFilter || [],
        fromViewTask: '',
        taskOrderBy: this.gettaskfilterstate.taskOrderBy || "Tasks"
      }
      this.setFilterData(data);
    },

    async GetTaskListToggle(taskName) {
      if (taskName !== this.title) {
        this.fromDate = '';
        this.Todate = '';
        this.optionArray = [];
        this.selectedProjects = 'Projects';
        this.Projectdata = [];
        this.projects = [];
        this.daterange = '';
        this.selectedStatusOption = "Status";
        this.assignedToOptionArray = [];
        this.selectedAssigneeOption = "Assigned to";
        // this.loading = true;
        this.showShimmers = true;
        this.currentPage = 1;
        this.perPage = 15
        this.title = taskName;
        this.isTabActive = taskName;
        this.TaskOrderBy = taskName;
        this.Tabs = this.tabOptions.filter(tab => tab.value == taskName);
        this.isTaskList = this.TaskOrderBy == 'Deleted tasks' ? true : false;
        await this.GetAllTasks();
        this.rows = this.tableData.length;
        this.selectAll = false;
        this.searchAssignedToQuery = "",
          this.Variable = "",
          this.filter = null;
      }

      this.$refs.scrollToLeft.scrollLeft = 0;
    },
    async GetDeletedTaskList() {
      const params = {
        assignedByIdValues: this.HeirachyUsers,
        assignedToIdValues: this.HeirachyUsers,
      };
      await this.$store.dispatch(
        "tasks/getAllDeletedTasksAssignedType",
        params
      );
      //this.deletedTaskList = await this.getDeletedTask.tasks;
      this.deletedTaskList =
        (await this.getDeletedTask.tasks) != null
          ? this.getDeletedTask.tasks
            .filter((element) => element.AssignedToId)
            .map((task) => ({
              value: task?.Level1,
              _id: task?._id,
              Id: task?.Id,
              TaskName: task?.TaskName,
              AssignedById: task?.AssignedById,
              AssignedToId: task?.AssignedToId,
              Comments: task?.Comments,
              EndDate: task?.EndDate ? new Date(task.EndDate)?.toLocaleDateString('en-GB')?.replace(/\//g, '-') : '',
              StatusId: task?.StatusId,
              IsDeleted: task?.IsDeleted,
              CreatedBy: task?.CreatedBy,
              ModifiedName: task?.ModifiedName,
              AssignedTo: task?.AssignedTo,
              Status: task?.Status,
              AssignedBy: task?.AssignedBy,
              CreatedDt: task?.CreatedDt ? new Date(task.CreatedDt)?.toLocaleDateString('en-GB')?.replace(/\//g, '-') : '',
              ModifiedDt: task?.ModifiedDt,
              type: task?.type,
              TaskId: task?.TaskId,
              TaskIdNumber:
                task?.type === "Mentioned" ? task?.TaskId : task?.Id,
            }))
          : [];
      this.tableData = this.deletedTaskList;
    },

    /**
     * changeAssignedToOption Filter
     * @param {assignedTo} option
     */
    changeAssignedToOption(option) {
      if (option == "clear") {
        this.assignedToOptionArray = [];
        this.selectedAssigneeOption = "Assigned to";
      } else {
        const List = this.assignedToOptionArray.indexOf(option.Id);
        if (List !== -1) {
          this.assignedToOptionArray.splice(List, 1);
          this.AssignedToFilterCount -= 1;
        } else {
          this.assignedToOptionArray.push(option.Id);
          this.AssignedToFilterCount += 1;
        }
        const unassignedIndex = this.assignedtolist.findIndex(item => item.Id === 0);
        if (unassignedIndex === -1) {
          // this.assignedtolist.unshift({ Id: 0, name: 'Unassigned' });
        }
        if (this.optionArray.includes("Unassigned")) {
          this.tableData = this.filterTableData.filter(task => {
            return task.AssignedToId == '0';
          });
        }

        if (this.assignedToOptionArray.length) {
          let name = this.assignedtolist.find(
            (id) => id.Id == this.assignedToOptionArray[0]
          );
          this.selectedAssigneeOption =
            this.assignedToOptionArray.length > 1
              ? `${name.name.slice(0, 22)}... (+${this.assignedToOptionArray.length - 1})`
              : name.name.slice(0, 25) + (name.name.length > 25 ? '...' : '');

        } else {
          this.selectedAssigneeOption = "Assigned to";
        }
      }
      this.searchAssignedToQuery = "";
      this.Variable = "",
        this.applyFilters();
    },

    /**
     * Display label AssignedTo filer
     * @param {*} name
     */
    displayLabel(name) {
      // Display the full name if it's 10 characters or less, otherwise, display a truncated version
      return name.length <= 8 ? name : name.slice(0, 15) + "...";
    },

    ChangePage() {
      // this.loading = true;
      this.showShimmers = true;
      setTimeout(() => {
        // this.loading = false;
        this.showShimmers = false;
        // this.loadInitData();
      }, 1000);
    },
    resetDataClick() {
      this.taskDataBind.taskName = "";
      this.taskDataBind.assignedTo = "";
      this.taskDataBind.dueDate = "";
      this.taskDataBind.comments = "";
      this.assignedTo = "";
      this.seletedProject = '';

      this.date = '';
      this.isTaskName = false;
      this.isComments = false;
      this.isAssignTo = false;
      this.isDueDate = false;
      this.formChanged = true;
      this.assignedToBlurred = false;
      this.projectTitleBlurred = false;
    },
    // cancel(Id, data) {
    //   let selectedidsString ;
    //   let idArrayString ;
    //   this.taskname=data.TaskName
    //   const selectedids = this.tableData
    // .filter((record) => record.selected)
    // .map((record) => record.Id); 
    // let validSelect = selectedids.find((record) => record == Id)
    // console.log(validSelect);
    // if(!validSelect ){
    //   idArrayString = JSON.stringify([Id]);
    // }
    // else{
    //   selectedidsString = JSON.stringify(selectedids);
    //  idArrayString = JSON.stringify([Id]);
    // }
    //   const swalWithBootstrapButtons = Swal.mixin({
    //     customClass: {
    //       confirmButton: "btn btn-success",
    //       cancelButton: "btn btn-danger ml-2",
    //     },
    //     buttonsStyling: false,
    //   });
    //   let truncatedTaskName = this.taskname.length > 15 ? this.taskname.slice(0, 15) + "..." : this.taskname;
    //   let popUpText = selectedids.length > 1 ? "You want to delete these tasks!" : `You want to delete task <span style="color: black; font-weight: bold;">${truncatedTaskName}</span>?`;

    //   swalWithBootstrapButtons
    //     .fire({
    //       title: "Are you sure?",
    //       html: '<div class="text-truncate">' + popUpText + '</div>',
    //       icon: "warning",
    //       confirmButtonText: "Yes, delete",
    //       cancelButtonText: "No, cancel",
    //       showCancelButton: true,
    //       allowOutsideClick: false
    //     })
    //     .then((result) => {
    //       if (result.value) {
    //         if (selectedids.length == 0 || !validSelect) {
    //           this.deleteItem(idArrayString);
    //         }
    //         else {
    //           this.deleteItem(selectedidsString);
    //         }

    //         this.loading = true;
    //       }
    //     });
    // },
    async Restore(Id, Item) {
      this.RestoreTaskName = Item?.TaskName
      const selectedids = this.tableData
        .filter((record) => record.selected)
        .map((record) => record.Id);
      const selectedidsString = await JSON.stringify(selectedids);
      const idArrayString = await JSON.stringify([Id]);
      const CommentId = await this.tableData.filter((record) => record.Id == Id)
      const CommentIds = await this.tableData.filter((record) => record.selected)
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          cancelButton: "btn btn-danger ml-2",
          confirmButton: "btn btn-success",
        },
        buttonsStyling: false,
      });
      let truncatedTaskname = await this.RestoreTaskName.length > 15 ? this.RestoreTaskName.slice(0, 15) + "..." : this.RestoreTaskName;
      let popUpText = await selectedids.length > 1 ? "You want to restore these tasks!" : `You want to restore this task <span style="color: black; font-weight: bold;">${truncatedTaskname}</span>?`;
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          html: popUpText,
          // icon: "warning",
          confirmButtonText: "Yes, restore",
          cancelButtonText: "No, cancel",
          showCancelButton: true,
          allowOutsideClick: false,
          imageUrl: "/images/emailexpired_icon.png",
          imageHeight: 50, 
          imageWidth: 50,
        })
        .then((result) => {
          if (result.value) {
            if (selectedids.length == 0) {
              this.restoreItem(idArrayString);
              this.SaveMultiCommentsByTaskIds(CommentId)
            }
            else {
              this.restoreItem(selectedidsString);
              this.SaveMultiCommentsByTaskIds(CommentIds)
            }
            // this.loading = true;
            this.showShimmers = true;
          }
        });
    },


    async GetUsersByProject(SelectProject) {
      this.assignedTo = ''
      const params = {
        pagenumber: 1,
        rowcount: 200,
        keyword: this.filter,
        sort: this.Sort,
        orderby: this.Orderby,
        ProjectId: SelectProject.Id,
      };
      await this.$store.dispatch("projects/GetUsersByProject", params);
      if (SelectProject.Id) {
        // this.loading = true
        //this.showShimmers = true;
        if (this.getAllUsersByProject?.length > 0) {
          this.assignedToOption = await this.getAllUsersByProject?.filter(element => element && element.userId != null)
            .map(user => (
              {
                userId: user.userId,
                FullName: user.FullName,
                Email: user.Email
              }
            )).sort((a, b) => a.FullName.localeCompare(b.FullName));
        } else {
          this.assignedToOption = [];
        }
      }
      this.loading = false;
    },




    async getAllProjectsForUserListOptions() {
      this.ProjectsListOptions = [];
      const params = {
        pagenumber: this.currentPage,
        rowcount: this.projectRowCount,
        keyword: this.filter,
        sort: this.Sort,
        orderby: this.Orderby,
        userGuid: this.getProfileDetails.UserGuid,
      };
      await this.$store.dispatch("projects/UserProjects", params);
      if (this.getAllProjectsForUser?.length > 0) {
        this.ProjectsListOptions = await this.getAllProjectsForUser?.filter(projectList => projectList != null)
          .map((project) => ({
            name: project.ProjectName,
            Id: project.Id
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
      } else {
        this.ProjectsListOptions = [];
      }
    },
    /**
     * Add new Task AssignedById AssignedByName and  by profile user Id
     */
    async getAdditionalDataAssinedBy() {
      this.mainuser = [{
        Id: this.getProfileDetails?.UserId || this.getProfileDetails.Id,
        name: this.getProfileDetails?.FirstName + " " + this.getProfileDetails?.LastName,
        email: this.getProfileDetails.Email,
        UserGuid: this.getProfileDetails.UserGuid
      }]

      /**
       * Add new Task status by using default varibale
       */
      const statusData = await this.getAllStatuses?.find(
        (element) => element.StatusName == this.defaultStatus
      );
      if (statusData) {
        this.StatusType = await {
          Id: statusData.Id,
          name: statusData.StatusName,
        };
      }
    },

    ResetData() {
      this.$refs["myModal"]?.hide();
      this.taskDataBind.taskName = "";
      this.taskDataBind.assignedTo = "";
      this.taskDataBind.dueDate = "";
      this.taskDataBind.comments = "";
      this.assignedTo = "";

      this.isTaskName = false;
      this.isComments = false;
      this.isAssignTo = false;
      this.isDueDate = false;
      this.formChanged = true;
      this.assignedToBlurred = false;
    },
    ...mapMutations(['setAlertNotification']),
    // Save Task Data
    async onSubmit() {
      await this.$refs["myModal"]?.hide();
      // this.loading = true;
      this.saved = true
      //this.showShimmers = true;
      const params = await {
        TaskName: this.taskDataBind?.taskName,
        EndDate: this.taskDataBind?.dueDate,
        StatusId: this.StatusType?.Id,
        Comments: this.taskDataBind?.comments,
        AssignedToId: this.assignedTo?.userId?this.assignedTo?.userId:0,
        AssignedById: this.mainuser[0]?.Id,
        AssignedTo: this.assignedTo?.FullName?this.assignedTo?.FullName:null,
        AssignedBy: this.mainuser[0]?.name,
        Status: this.StatusType?.name,
        ProjectName: this.seletedProject.name,
        ProjectId: this.seletedProject.Id,
        UserEmail: this.assignedTo?.Email,
        IsOverdueEmailSent: false,
        OverDueEmailSentDate: ""
      };
      // redirect to the task.js
      await this.$store.dispatch("tasks/SaveTaskData", params);
      await this.saveComment();
      await this.GetAllStatusList();
      if (this.getSaveTask) {
        const input = {
          "to": [this.assignedTo?.Email],
          "emailType": "TaskCreated",
          "username": [this.assignedTo?.FullName],
          "taskName": this.getSaveTask.task.Id + '(' + this.taskDataBind?.taskName + ')',
          "ProjectName": this.seletedProject.name,
          "Assignedby": this.mainuser[0]?.name,
        }
        this.assignedTo?.Email? await this.$store.dispatch("tasks/sendEmail", input):'';
      }
      this.response = await this.getSaveTask;
      const message = {
        variant: this.response.status === true ? 'success' : 'danger',
        alertTitle: this.response.status === true ? 'Saved' : 'Error',
        alertDecription: this.response.msg
      };
      this.setAlertNotification(message)
      await this.ResetData();
      this.saved = false
    },
    /**
     * saving the first comment for the task
     */
    async saveComment() {
      this.taskData = this.getSaveTask?.task;
      const input = {
        TaskId: this.taskData.Id,
        Users: [],
        Comments: "Created the task!",
        Status: this.taskData?.Status,
        TaskName: this.taskData?.TaskName,
        AssignedById: this.taskData?.AssignedById,
        AssignedToId: this.taskData.AssignedToId,
        EndDate: moment(this.taskData?.EndDate, "DD-MM-YYYY").toDate(),
        AssignedTo: this.taskData?.AssignedTo,
        AssignedBy: this.taskData?.AssignedBy,
        Commentedby:
          this.getProfileDetails?.FirstName +
          " " +
          this.getProfileDetails?.LastName,
        CommentedbyId: Number(this.getProfileDetails?.UserId || this.getProfileDetails.Id),
        CommentType: "TaskCreated",
        ProjectId: this.taskData?.ProjectId,
        ProjectName: this.taskData?.ProjectName
      };
      await this.$store.dispatch("tasks/SaveCommentsByTaskId", input);
      // this.GetAllTasks()
    },
    // Delete Task data based on Id
    // async deleteItem(taskIds) {
    //   const ids = JSON.parse(taskIds)
    //   const params = {
    //     taskIds: ids,
    //     IsDeleted: true,
    //   };
    //   await this.$store.dispatch("tasks/DeleteTaskData", params);
    //   await this.GetAllStatusList();
    //   this.loading = false;
    //   Swal.fire({
    //     icon: "success",
    //     title: "Deleted!",
    //     text: "Task has been deleted successfully.",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    // },

    /**
     * Save Restore Comment
     * @param {*} SelectedData 
     */
    async SaveMultiCommentsByTaskIds(SelectedData) {
      let TaskIds;
      if (SelectedData.length === 1) {
        const array = [SelectedData[0]?.Id]
        TaskIds = array;
        const input = {
          TaskId: array,
          Users: [],
          Comments: "Task has been restored.",
          Status: "New",
          TaskName: SelectedData[0]?.TaskName,
          AssignedById: SelectedData[0]?.AssignedById,
          AssignedToId: SelectedData[0]?.AssignedToId,
          EndDate: moment(SelectedData[0]?.EndDate, "DD-MM-YYYY").toDate(),
          AssignedTo: SelectedData[0]?.AssignedTo,
          AssignedBy: SelectedData[0]?.AssignedBy,
          Commentedby: `${this.getProfileDetails?.FirstName} ${this.getProfileDetails?.LastName}`,
          CommentedbyId: this.getProfileDetails?.UserId || this.getProfileDetails.Id,
          CommentType: "TaskRestored",
          ProjectId: SelectedData[0]?.ProjectId,
          ProjectName: SelectedData[0]?.ProjectName
        };
        await this.$store.dispatch("tasks/SaveMultiCommentsByTaskIds", input);
      } else {
        const input = await {
          TaskId: SelectedData.map(item => item.Id),
          Users: [],
          Comments: "Task has been restored.",
          Status: "New",
          TaskName: SelectedData.map(item => item.TaskName),
          AssignedById: SelectedData.map(item => item.AssignedById),
          AssignedToId: SelectedData.map(item => item.AssignedToId),
          EndDate: SelectedData.map(item => moment(item.EndDate, "DD-MM-YYYY").toDate()),
          AssignedTo: SelectedData.map(item => item.AssignedTo),
          AssignedBy: SelectedData.map(item => item.AssignedBy),
          Commentedby: `${this.getProfileDetails?.FirstName} ${this.getProfileDetails?.LastName}`,
          CommentedbyId: this.getProfileDetails?.UserId || this.getProfileDetails.Id,
          CommentType: "TaskRestored",
          ProjectId: SelectedData[0]?.ProjectId,
          ProjectName: SelectedData[0]?.ProjectName
        };
        await this.$store.dispatch("tasks/SaveMultiCommentsByTaskIds", input);
        TaskIds = await input.TaskId;
      }
      await this.UpdateCommentsByTaskId(TaskIds);
    },

    async UpdateCommentsByTaskId(TaskIds) {
      const params = {
        TaskId: TaskIds
      }
      await this.$store.dispatch("tasks/UpdateCommentsByTaskId", params);
    },
    // Restore Task data based on Id
    async restoreItem(Id) {
      const ids = JSON.parse(Id)
      // this.loading = true;
      this.showShimmers = true;
      const params = {
        taskIds: ids,
        IsDeleted: false,
        Status: this.newStatus.StatusName,
        StatusId: this.newStatus.Id,
      };
      await this.$store.dispatch("tasks/DeleteTaskData", params);
      await this.GetAllStatusList();
      // this.loading = false;
      this.showShimmers = false;
      this.response = await this.getDeteteTask;
      const message = {
        variant: this.response.data.status === true ? 'success' : 'danger',
        alertTitle: this.response.data.status === true ? 'Restored' : 'Error',
        alertDecription: this.response.data.msg
      };
      this.setAlertNotification(message)
      this.isTaskList = true;
      this.title = "Deleted tasks";
    },

    /**
     * Get All Statuses List
     */
    ...mapMutations(['setNew']),
    async GetAllStatusList() {
      // await this.$store.dispatch("tasks/GetAllStatusesList");
      this.taskFilter?.ProjectId ? this.setNew(null) : '';
      if (this.taskFilter) {
        this.taskFilter == "Over due" ?
          (this.TaskOrderBy = this.iSOverDue, this.isTabActive = this.taskFilter, this.title = this.taskFilter) :
          (this.TaskOrderBy = "Assigned to me", this.isTabActive = "Assigned to me", this.title = "Assigned to me", await this.changeStatusOption(this.taskFilter), this.toggleFilters());
      }
      await this.GetAllTasks();
      this.taskFilter == "Over due" ? this.tableData = this.tableData.filter(item => item.AssignedToId == (this.getProfileDetails.UserId || this.getProfileDetails.Id)) : null
      this.rows = this.tableData?.length
      this.setNew(null)

    },
    //src/components/management-tree.vue
    //user?.LastName

    // Get All Task Details
    ...mapMutations(['setTaskPopup', 'setFilterData',]),
    async GetAllTasks() {
      this.isTaskList = this.TaskOrderBy == 'Deleted tasks' ? true : false;
      let body = {};
      body.fromDate = this.fromdate ? this.fromdate + "T00:00:00.001Z" : undefined;
      body.toDate = this.Todate ? this.Todate + "T23:59:59.999Z" : undefined;
      body.ProjectId = this.projects?.length != 0 ? this.projects : this.getUserDetailsById?.Projects.match(/[^,]+/g)?.map(project => Number(project));
      body.UserId= this.TaskOrderBy === "Assigned to me"?Number(this.getProfileDetails.UserId || this.getProfileDetails.Id) : Number(this.getUserId || this.getProfileDetails.UserId || this.getProfileDetails.Id)
      if (this.TaskOrderBy === "Mentioned me") {
        this.fields = TaskFields;
        body.CommentedUserId = Number(this.getUserId || this.getProfileDetails.UserId || this.getProfileDetails.Id);
        body.isDeleted = this.isTaskList;
      }
      else if (this.TaskOrderBy === "Assigned to me") {
        this.filterVariable = '';
        this.fields = this.taskHeading;
        body.assignedToIdValues = [Number(this.getUserId || this.getProfileDetails.UserId || this.getProfileDetails.Id)];
        body.isDeleted = this.isTaskList;
      }
      else if (this.TaskOrderBy === this.iSOverDue) {
        this.filterVariable = '';
        this.fields = this.taskHeading;
        body.overdue = this.TaskOrderBy;
      }
      else if (this.TaskOrderBy === "Assigned by me") {
        this.fields = this.taskHeading;
        body.assignedByIdValues = [Number(this.getUserId || this.getProfileDetails.UserId || this.getProfileDetails.Id)];
        body.isDeleted = this.isTaskList;
      }
      else {
        this.filterVariable = '';
        this.fields = this.taskHeading;
        body.isDeleted = this.isTaskList;
      }
      // const UserId = await this.getUserId;
      //this.date = this.taskDataBind.EndDate.split("T")[0];
      this.title == 'Tasks' ? body = {
        "AssignedTo": '',
        "ProjectId": this.projects?.length != 0 ? this.projects : this.getUserDetailsById?.Projects.match(/[^,]+/g)?.map(project => Number(project)),
        "overdue": '',
        "fromDate": this.fromdate ? this.fromdate + "T00:00:00.001Z" : undefined,
        "toDate": this.Todate ? this.Todate + "T23:59:59.999Z" : undefined,
        "UserId": Number(this.getUserId || this.getProfileDetails.UserId || this.getProfileDetails.Id)
      } : null;
      this.title == 'Tasks' || this.title == 'Deleted tasks' || this.title == 'Over due' ?
        await this.$store.dispatch("tasks/GetAllViewTasksAssigned", body) : await this.$store.dispatch("tasks/GetAllTasksAssigned", body);
      const isTaskType = this.title === 'Tasks' || this.title === 'Deleted tasks' || this.title === 'Over due';
      let tasksData = isTaskType ? this.getAssignedToTaskData.task : this.getTaskData.tasks;
      this.myTasksCount = isTaskType ? this.getAssignedToTaskData.assignedToMetaskcount : this.getTaskData.assignedToMetaskcount;

      // fiter assignedBy List Name's
      if (tasksData) {
        this.filterTableData =
          (await tasksData) != null
            ? tasksData
              .filter((element) => element.AssignedToId >= 0 && (this.TaskOrderBy === "Mentioned me" ? element.type == 'Mentioned' : element.type != 'Mentioned'))
              .map((task) => ({
                value: task?.Level1,
                _id: task?._id,
                Id: task?.Id,
                TaskName: task?.TaskName,
                AssignedById: task?.AssignedById,
                AssignedToId: task?.AssignedToId,
                Comments: task?.Comments,
                EndDate: task?.EndDate ? new Date(task.EndDate)?.toLocaleDateString('en-GB')?.replace(/\//g, '-') : '',
                StatusId: task?.StatusId,
                IsDeleted: task?.IsDeleted,
                CreatedBy: task?.CreatedBy,
                ModifiedName: task?.ModifiedName,
                AssignedTo: task?.AssignedTo || '-',
                Status: task?.Status,
                AssignedBy: task?.AssignedBy,
                CreatedDt: task?.CreatedDt ? new Date(task.CreatedDt)?.toLocaleDateString('en-GB')?.replace(/\//g, '-') : '',
                ModifiedDt: task?.ModifiedDt,
                type: task?.type,
                TaskId: task?.TaskId,
                TaskIdNumber: task?.type === "Mentioned" ? task?.TaskId : task?.Id,
                ProjectName: task?.ProjectName,
                ProjectId: task?.ProjectId,
                UserEmail: task?.UserEmail,
                IsOverdueEmailSent: task?.IsOverdueEmailSent,
                OverDueEmailSentDate: task?.OverDueEmailSentDate ? new Date(task?.OverDueEmailSentDate).toISOString().split("T")[0] : '',
                Seen:task?.Seen
                // _rowVariant: moment(task?.CreatedDt).format('YYYY-MM-DD') == moment(new Date()).format('YYYY-MM-DD') ?'success':''
              }))
            : [];
        this.tableData = this.uniqueTasks;
        await this.filterAssignedtolist();
        if (this.optionArray.length || this.assignedToOptionArray.length) {
          this.applyFilters()
          if (this.optionArray.length) {
            this.selectedStatusOption = this.optionArray.length > 1 ? `${this.optionArray[0]} (+${this.optionArray.length - 1})` : `${this.optionArray[0]}`;
          }
          if (this.assignedToOptionArray.length) {
            let name = this.assignedtolist.find(
              (id) => id.Id == this.assignedToOptionArray[0]
            );
            if (this.assignedToOptionArray[0] == 0 && this.assignedToOptionArray.length > 1) { this.selectedAssigneeOption = `Unassingned (+${this.assignedToOptionArray.length - 1})` }
            else if (this.assignedToOptionArray[0] == 0) { this.selectedAssigneeOption = "Unassingned" }
            else { this.selectedAssigneeOption = this.assignedToOptionArray.length > 1 ? `${name?.name.slice(0, 22)}... (+${this.assignedToOptionArray.length - 1})` : name?.name.slice(0, 25) + (name?.name.length > 25 ? '...' : ''); }
          }
        }
      } else {
        this.filterTableData= []
        this.tableData = this.uniqueTasks;
        if (this.getErrors["critical_error"]) {
          this.isError = true;
          this.errorResponse = this.getErrors["critical_error"];
        } else {
          this.isError = false;
          this.errorResponse = "Something went wrong !";
        }
      }

      if (this.tableData.length == 0) {
        this.isSearch = false;
      } else {
        this.isSearch = true;
      }
      // this.loading = false;
      this.showShimmers = false;
      if (this.getTaskPopup == "TaskDeleted") {
        this.setTaskPopup(null)
      }
      // Based on the table's length, the pagination count should be displayed
      this.rows = this.tableData.length;
      const data = {
        statusFilter: this.gettaskfilterstate.statusFilter || [],
        assignedToFilter: this.gettaskfilterstate.assignedToFilter || [],
        projectFilter: this.gettaskfilterstate.projectFilter || [],
        dateFilter: this.gettaskfilterstate.dateFilter || [],
        fromViewTask: this.gettaskfilterstate.fromViewTask || '',
        taskOrderBy: this.TaskOrderBy || "Tasks"
      }
      this.setFilterData(data);
    },
    //filtere to only show Assigned to me names with tasks
    filterAssignedtolist() {
      const uniqueAssignedToList = Array.from(new Set(this.filterTableData.map(task => task.AssignedToId)))
        .map(Id => this.filterTableData.find(task => task.AssignedToId === Id));
      this.assignedtolist = (uniqueAssignedToList.filter(task => task.AssignedToId !== 0 && 
      task.AssignedToId != Number(this.getUserId ||this.getProfileDetails.UserId || this.getProfileDetails.Id)).map((task) => ({
        Id: task.AssignedToId,
        name: task.AssignedTo
      }))).sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      this.assignedtolist.unshift({ Id: 0, name: 'Unassigned' });
      this.assignedtolist.unshift({ Id: Number(this.getUserId ||this.getProfileDetails.UserId || this.getProfileDetails.Id), name: '@Me' });
    },
    // View particular Task Details based on Id
    ...mapMutations(["setTaskId"]),
    async viewItem(Task) {
      let Id = Task.TaskIdNumber;
      await this.setTaskId(Task.TaskIdNumber);
      await this.$router.push({ name: "ViewTask", params: { Id: Id } });
    },

    // search funcation
    onFiltered() {
      if (this.Shimmers) {
        // this.loading = true;
        this.showShimmers = true;
        setTimeout(() => {
          // this.loading = false;
          this.showShimmers = false;
        }, 1000);
        // Based on the search field , the list should be displayed
        if (this.tableData.length == 0) {
          this.rows = this.tableData.length;
        } else if (this.$refs.myTable?.filteredItems.length != 0) {
          this.rows = this.$refs.myTable.filteredItems.length;
        } else {
          this.rows = this.$refs.myTable.filteredItems.length;
        }
      }
      this.Shimmers = true;
    },
    // search filter timeset.
    handleFilterInput() {
      if (this.filter !== null) {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.timer = null;
          this.filterVariable = this.filter;
        }, 800);
      }
    },
    // search filter timeset In Assigned to me Dropdown.
    assignedFilterInput() {
      if (this.Variable !== null) {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.timer = null;
          this.searchAssignedToQuery = this.Variable;
        }, 800);
      }
    },
    isDueDateCrossed(EndDate) {
      const currentDate = new Date();
      const endDateParts = EndDate.split('-');
      const endDate = new Date(
        endDateParts[2],
        endDateParts[1] - 1,
        endDateParts[0]
      );
      return endDate < currentDate && EndDate !== currentDate?.toLocaleDateString('en-GB')?.replace(/\//g, '-');
    },

    async sendOverDueMail(event) {
      this.updateOverDueData = event;
      (event.item?.UserEmail !== undefined)
        ? (async () => {
          const input = {
            "to": [event.item?.UserEmail],
            "emailType": "Overdue",
            "username": [event.item?.AssignedTo],
            "taskName": event.item?.Id + '(' + event.item?.TaskName + ')',
            "ProjectName": event.item?.ProjectName,
            "EndDate": event.item?.EndDate,
            "Status": event.item?.Status,
            "Assignedby": event.item?.AssignedBy,
          };
          event.item?.UserEmail?await this.$store.dispatch("tasks/sendEmail", input):'';
          this.EditTaskData();
        })()
        : null;
    },

    async EditTaskData() {
      // this.loading = true
      this.showShimmers = true
      const params = {
        Id: this.updateOverDueData.item?.Id,
        _id: this.updateOverDueData.item?._id,
        TaskName: this.updateOverDueData.item?.TaskName,
        EndDate: moment(this.updateOverDueData.item?.EndDate, "DD.MM.YYYY").format('YYYY-MM-DD'),
        StatusId: this.updateOverDueData.item?.StatusId,
        Comments: this.updateOverDueData.item?.Comments,
        AssignedToId: this.updateOverDueData.item?.AssignedToId,
        AssignedTo: this.updateOverDueData.item?.AssignedTo,
        Status: this.updateOverDueData.item?.Status,
        IsOverdueEmailSent: true,
        OverDueEmailSentDate: moment(new Date()?.toLocaleDateString('en-GB')?.replace(/\//g, '-'), "DD.MM.YYYY").format('YYYY-MM-DD')
      }
      await this.$store.dispatch('tasks/UpdateTaskData', params);
      this.updateOverDueData = "";
      await this.GetAllStatusList();
    },

    downloadRecords() {
      this.downloading = true;
      const selectedRecords = this.tableData.filter(
        (record) => record.selected
      );
      this.downloadZip(selectedRecords)

      const dataToDownload = selectedRecords.map((record) => ({
        Id: record.Id || "",
        "Task name": record.TaskName ? `"${record.TaskName.replace(/"/g, '""')}"` : "",
        "Created by": record.AssignedBy || "",
        "Assigned to me": record.AssignedTo || "",
        "End date": record.EndDate || "",
        "Created date": record.CreatedDt || "",
        Description: record.Comments ? `"${record.Comments.replace(/"/g, '""')}"` : "",
        Status: record.Status || ""
      }));

      const headers = Object.keys(dataToDownload[0]);
      const headerRow = headers.join(",");
      const dataRows = dataToDownload.map((record) =>
        headers.map((header) => record[header]).join(",")
      );

      const csvData = [headerRow, ...dataRows].join("\n");
      const blob = new Blob(["\ufeff" + csvData], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, "Task_list.csv");
      } else {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Task_list.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      this.downloading = false;
    },


    /**
    * Single task download
    * @param {Recordlist} record
    */
    downloadRecord(record) {
      const detailsToDownload = {
        ID: record.Id || "",
        "Task name": record.TaskName ? `"${record.TaskName.replace(/"/g, '""')}"` : "",
        "Created by": record.AssignedBy || "",
        "Assigned to me": record.AssignedTo || ",",
        "End date": record.EndDate || "",
        "Created date": record.CreatedDt || "",
        Description: record.Comments ? `"${record.Comments.replace(/"/g, '""')}"` : "",
        Status: record.Status || ""

      };

      const headings = Object.keys(detailsToDownload);
      const csvData = [
        headings.join(","),
        Object.values(detailsToDownload).join(","),
      ].join("\n");
      const blob = new Blob(["\ufeff" + csvData], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, "TaskId.csv");
      } else {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `TaskId_${record.Id}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },


    /**
    * Single task download popup
    * @param {Recordlist} record
    */
    RecordDownload(record) {
      this.newtaskname = record.TaskName
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2",
        },
        buttonsStyling: false,
      });
      let truncatedTaskName = this.newtaskname.length > 15 ? this.newtaskname.slice(0, 15) + "..." : this.newtaskname;
      let popUpText = this.downloadRecord.length > 1 ? "You want to delete these tasks!" : `You want to download this task <span style="color: black; font-weight: bold;">${truncatedTaskName}</span>?`;
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          html: '<div class="text-truncate">' + popUpText + '</div>',
          // icon: "warning",
          confirmButtonText: "Yes, download",
          cancelButtonText: "No, cancel",
          showCancelButton: true,
          allowOutsideClick: false,
          imageUrl: "/images/emailexpired_icon.png",
          imageHeight: 50, 
          imageWidth: 50,
        })
        .then((result) => {
          if (result.value) {
            this.downloadZip([record])
            this.downloadRecord(record);
          }
        });
    },
    /**
     * download commented files into a zip
     */
    async downloadZip(data) {
      let selectedtasks = data.map(task => task.Id);
      const params = {
        taskIds: selectedtasks
      };
      await this.$store.dispatch('tasks/GetTaskAttachment', params);
      const arrayOfUrls = await this.getTaskAttachment?.data?.comments?.map((data) => data.ImageUrl);
      const fileURLs = [].concat(...arrayOfUrls);

      if (fileURLs) {
        const zip = new JSZip();

        const getFileExtension = (url) => {
          const match = url.match(/\.([a-z0-9]+)(?:[?#]|$)/i);
          return match ? match[1] : "";
        };

        for (let i = 0; i < fileURLs.length; i++) {
          const response = await fetch(fileURLs[i]);
          if (response.ok) {
            const blob = await response.blob();
            const fileExtension = getFileExtension(fileURLs[i]);
            const fileName = `file${i + 1}.${fileExtension || "txt"}`;
            zip.file(fileName, blob);
          }
        }

        const content = await zip.generateAsync({ type: "blob" });
        saveAs(content, `Task_Attachments_${selectedtasks}.zip`);
      }
    },
    // Toggle Filters funcation
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    /**
     * Order by functionality
     * @param {*} value
     */
    async ChangeOrderby(value) {
      if (this.TaskOrderBy == value) {
        if (this.sortclickcount % 2 == 0) {
          this.Sort = "asc";
          this.sortclickcount = this.sortclickcount + 1;
        } else {
          this.Sort = "desc";
          this.sortclickcount = this.sortclickcount + 1;
        }
      } else {
        this.sortclickcount = 0;
        this.Sort = "asc";
        this.sortclickcount = this.sortclickcount + 1;
        this.TaskOrderBy = value;
      }
      this.orderbylist[0].disabled = value !== "";
      await this.GetAllTasks();
    },
    //Ueser task permissios data
    initTaskPermisions() {
      this.TaskPermisions = [
        {
          key: "createtask",
          permission: "CreateTask",
        },
        {
          key: "deletetask",
          permission: "DeleteTask",
        },
      ];
    },
    SearchByDatediff(data) {
      if (data) {
        if (data == "clear") {
          this.projects = [];
          this.Projectdata = [];
          this.selectedProjects = "Projects";
        } else {
          const index = this.Projectdata.indexOf(data);
          if (index !== -1) {
            this.projects.splice(index, 1);
            this.Projectdata.splice(index, 1);
          } else {
            this.Projectdata.push(data)
          }
          if (this.Projectdata.length) {
            this.projects = this.Projectdata.map(x => x.Id);
            this.selectedProjects =
              this.projects.length > 1
                ? `${this.Projectdata[0].name} (+${this.projects.length - 1})`
                : `${this.Projectdata[0].name}`;
          } else {
            this.selectedProjects = "Projects";
          }
        }
      }
      // this.loading = true;
      this.showShimmers = true;
      this.fromdate = this.daterange !== '' && this.daterange[0] != null ? moment(this.daterange[0]).format('YYYY-MM-DD') : '';
      this.Todate = this.daterange !== '' && this.daterange[1] != null ? moment(this.daterange[1]).format('YYYY-MM-DD') : '';
      this.GetAllTasks()
      const storedata = {
        statusFilter: this.optionArray || [],
        assignedToFilter: this.assignedToOptionArray || [],
        projectFilter: this.Projectdata || [],
        dateFilter: this.daterange || [],
        fromViewTask: '',
        taskOrderBy: this.gettaskfilterstate.taskOrderBy || "Tasks"
      }
      this.setFilterData(storedata);
    },
    //Rememberance fo filters 
    async filtered() {
      if (this.gettaskfilterstate?.fromViewTask) {
        this.optionArray = this.gettaskfilterstate.statusFilter || []
        this.assignedToOptionArray = this.gettaskfilterstate.assignedToFilter || []
        this.Projectdata = this.gettaskfilterstate.projectFilter || [],
          this.daterange = this.gettaskfilterstate.dateFilter || [],
          this.TaskOrderBy = this.gettaskfilterstate.taskOrderBy || "Tasks"
        this.isTabActive = this.gettaskfilterstate.taskOrderBy
        this.title = this.gettaskfilterstate.taskOrderBy
        this.optionArray.length || this.assignedToOptionArray.length ? this.toggleFilters() : null
        this.setFilterData([])
        this.projects = this.Projectdata?.map(x => x.Id);
        this.Projectdata.length || this.daterange.length ?
          (this.showFilters = true, this.Projectdata.length > 0 ? this.selectedProjects =
            this.Projectdata.length > 1
              ? `${this.Projectdata[0]?.name} (+${this.Projectdata.length - 1})`
              : `${this.Projectdata[0]?.name}` : null) : null
      }
      else {
        this.setFilterData([])
      }
    },
  },
  ...mapMutations(['setFilterData']),
  async mounted() {
    await this.getAllProjectsForUserListOptions();
    await this.filtered()
    await this.GetAllStstusesList();
    await this.GetAllStatusList();
    await this.getAdditionalDataAssinedBy();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="computedItems" />
    <!-- <div v-if="loading">
      <PreLoader></PreLoader>
    </div> -->
    <b-alert show variant="danger" v-if="isError == true">
      <i class="ri-close-circle-line mr-2 align-bottom"></i>{{ this.errorResponse }}
    </b-alert>



    <div class="home">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body card-fullscreen-height">
              <!-- Toggle Button -->
              <div class="mb-4">
                <div
                  v-bind:class="{ 'd-flex filter-btn flex-wrap': true, 'justify-content-between': isSearch, 'justify-content-end': !isSearch }">
                  <div v-if="isSearch" id="tickets-table_filter" class="dataTables_filter text-md-right searchWidth">
                    <label class="d-inline-flex align-items-center position-relative mb-2 mb-md-0 w-100">
                      <b-form-input size="sm" v-model="filter" type="search" placeholder="Search..."
                        @input="handleFilterInput" class="form-control searchInput mr-2"></b-form-input>
                      <i class="ri-search-line search-icon"></i>
                    </label>
                  </div>
                  <div class="d-flex">
                    <div class="flex-shrink-0" v-if="this.tableData?.length > 0" @click="downloadRecords">
                      <b-button class="mr-2 ml-sm-4" size="sm" variant="outline-primary" v-if="tableData.filter((record) => record.selected).length > 1 ||
                        tableData.filter((record) => record.selected).length === tableData.length">
                        <div class="d-flex"><i class="ri-download-2-line align-bottom"></i><span
                            class="d-none d-sm-none d-md-block d-lg-block ml-2">Export all</span></div>
                        <div class="spinner-border" role="status" v-if="downloading">
                          <span class="sr-only"></span>
                        </div>
                      </b-button>
                    </div>
                    <div class="ml-0 flex-shrink-0">
                      <b-button size="sm" v-if="isTaskList == false && hasPermission('CreateTask')"
                        variant="outline-primary" @click="resetDataClick" v-b-modal.modal-center>
                        <div class="d-flex"><i class="ri-add-line align-bottom"></i><span
                            class="d-none d-sm-none d-md-block d-lg-block">Add task</span></div>
                      </b-button>
                    </div>
                    <div class="ml-2">
                      <b-button variant="primary" @click="toggleFilters" size="sm" class="" style="top:5px">
                        <i :class="'ri-' + (showFilters == true ? 'filter-line' : 'filter-line')"
                          class="align-bottom"></i>
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Tabs-->

              <div class="tasklist-tabs d-none d-sm-none d-md-block d-lg-block position-relative">
                <b-tabs nav-class="nav-tabs-custom" content-class="text-muted">
                  <b-tab :active="isTabActive == item.value" @click="GetTaskListToggle(item.value)" v-for=" (item,index) of tabOptions" :key="index">
                    <template v-slot:title>
                      <span class="d-inline-block">
                        <i :class=item.class></i>
                      </span>
                      <span class="d-inline-block ml-2">{{item.name}}
                        <span class="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle blink" v-if="item.name=='Assigned to me' && myTasksCount>0">
                        </span>
                      </span>
                    </template>
                  </b-tab>
                </b-tabs> 
              </div>
              <div v-if="showShimmers" class="mt-2">
                <div class="d-flex justify-content-end mb-2">
                  <b-skeleton type="input" height="28px" width="180px"></b-skeleton>
                  <b-skeleton class="ml-2" type="input" height="28px" width="150px"></b-skeleton>
                  <b-skeleton class="ml-2" type="input" height="28px" width="150px"></b-skeleton>
                  <b-skeleton class="ml-2" type="input" height="28px" width="150px"></b-skeleton>
                </div>
                <b-skeleton-table :rows="5" :columns="10"
                  :table-props="{ bordered: false, striped: false }"></b-skeleton-table>
              </div>

              <!-- Filters -->
              <div v-else>
                <transition name="slide-fade" mode="out-in">
                  <div v-if="(showFilters)" class="ytmFilterRow my-2">
                    <div class="d-flex flex-wrap justify-content-start justify-content-sm-end">
                      <div class="date-range ml-0 ml-sm-2 mb-2 mb-lg-0">
                        <date-picker v-model="daterange" range append-to-body lang="en" confirm
                          placeholder="Select date range" format="DD/MM/YYYY" @change="SearchByDatediff()"></date-picker>
                      </div>
                      <div class="mb-2 mb-lg-0 orderby-dropdown">
                        <b-dropdown size="sm" variant="outline-light" class="outline-light w-180" ref="projectsdropdown">
                          <template v-slot:button-content>
                            {{ selectedProjects }}
                            <i class="mdi mdi-chevron-down"></i>
                          </template>
                          <div class="assignedToDropdownData" :class="[selectedProjects != 'Projects' ? 'pb-40' : '']">
                            <b-dropdown-item-button v-for="(option, index) of ProjectsListOptions" :key="index"
                              @click="$refs.projectsdropdown.show(true)">
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" :id="option.name" @click="SearchByDatediff(option)"
                                  :checked="projects?.includes(option.Id)" class="custom-control-input" />
                                <label class="custom-control-label ml-1 mb-0" :for="option.name">{{
                                  displayLabel(option.name)
                                }}</label>
                              </div>
                            </b-dropdown-item-button>
                          </div>
                          <button class="btn clear-btn text-primary font-weight-bold " @click="SearchByDatediff('clear')"
                            v-if="selectedProjects != 'Projects'">
                            x Clear
                          </button>
                        </b-dropdown>
                      </div>
                      <div class="mb-2 ml-md-2 mb-xl-0 orderby-dropdown">
                        <b-dropdown size="sm" @click="filterOption(option)" variant="outline-light"
                          class="outline-light w-100" ref="statusdropdown">
                          <template v-slot:button-content>
                            {{ selectedStatusOption }}
                            <i class="mdi mdi-chevron-down"></i>
                          </template>
                          <div class="assignedToDropdownData" :class="[selectedStatusOption != 'Status' ? 'pb-40' : '']">
                            <b-dropdown-item-button v-for="option of orderbylist" :key="option"
                              @click="$refs.statusdropdown.show(true)">
                              <div class="custom-control custom-checkbox">
                                <input @click="changeStatusOption(option)" type="checkbox" :id="option"
                                  :checked="optionArray?.includes(option)" class="custom-control-input" />
                                <label :for="option" class="custom-control-label ml-1 mb-0">{{ option }}</label>
                              </div>
                            </b-dropdown-item-button>
                          </div>
                          <button class="btn clear-btn text-primary font-weight-bold "
                            @click="changeStatusOption('clear')" v-if="selectedStatusOption != 'Status'">
                            x Clear
                          </button>
                        </b-dropdown>
                      </div>
                      <div class="mb-2 mb-lg-0 orderby-dropdown assignedToButton">
                        <b-dropdown size="sm" v-model="selectedAssigneeOption" variant="outline-light"
                          class="outline-light" ref="assigneddropdown">
                          <template v-slot:button-content>
                            {{ selectedAssigneeOption }}
                            <i class="mdi mdi-chevron-down"></i>
                          </template>
                          <div class="col-12 pb-2" v-if="this.assignedtolist.length > 1">
                            <input size="sm" class="form-control form-control-sm p-0 search-input" type="search" id=""
                              v-model="Variable" placeholder="Search..." @input="assignedFilterInput" />
                          </div>
                          <div class="assignedToDropdownData" style="text-align: left;"
                            :class="[selectedAssigneeOption != 'Assigned to' ? 'pb-40' : '']">
                            <b-dropdown-item-button v-for="(option) of filteredOptions" :key="option.Id"
                              @click="$refs.assigneddropdown.show(true)">
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" :id="option.Id" @click="changeAssignedToOption(option)" :checked="assignedToOptionArray?.includes(option.Id)
                                  " class="custom-control-input" />
                                <label class="custom-control-label ml-1 mb-0" :for="option.Id">{{
                                  displayLabel(option.name)
                                }}</label>
                              </div>
                            </b-dropdown-item-button>
                            <b-dropdown-item-button v-if="filteredOptions == '' || this.assignedtolist == ''">
                              <div class="d-flex flex-column flex-sm-row mt-2 justify-content-center aling-items-center">
                                <!-- <i  class="ri-information-line font-size-15 text-center"></i> -->
                                <p class="mb-0">No results found!</p>
                              </div>
                              <br />
                            </b-dropdown-item-button>
                            <button class="btn clear-btn text-primary font-weight-bold"
                              @click="changeAssignedToOption('clear')" v-if="selectedAssigneeOption != 'Assigned to'">
                              x Clear
                            </button>
                          </div>
                        </b-dropdown>
                      </div>
                      <!-- <div class="ml-0 ml-sm-2 flex-shrink-0" v-if="hasPermission('CreateTask')">
                        <b-button size="sm" v-if="isTaskList == false" variant="primary" @click="resetDataClick"
                          v-b-modal.modal-center>
                          <i class="ri-add-line align-middle"></i> Add task
                        </b-button>
                      </div> -->
                    </div>
                  </div>
                </transition>

                <!-- Table -->
                <div class="taskTableSection" :class="[showFilters ? '' : 'mt-40px']">
                  <!-- Tabs dropdown UI -->
                  <div class="tasklist-dropdown d-lg-none d-md-none">
                    <multiselect v-model="Tabs" :options="tabOptions" label="name" track-by="value"
                      @input="GetTaskListToggle(Tabs.value)"></multiselect>
                  </div>
                  <div class="table-responsive mt-2" ref="scrollToLeft">
                    <div class="d-flex justify-content-end" v-if="!(
                      (this.tableData.length != 0 && isError == false || optionArray != []) ||
                      (this.tableData.length == 0 && isError == true)
                    ) && !showShimmers
                      ">
                      <!-- <b-button size="sm" variant="outline-primary" @click="GetTaskListToggle" class="mr-2">
                        <i class="ri-eye-line align-bottom ml-1"></i>{{
                          isTaskList == true
                          ? "View tasks list"
                          : "Deleted tasks list"
                        }}
                      </b-button> -->
                      <b-button size="sm" v-if="!isTaskList && hasPermission('CreateTask')" variant="primary"
                        @click="resetDataClick" v-b-modal.modal-center>
                        <i class="ri-add-line align-middle"></i> Add task
                      </b-button>
                    </div>
                    <div class="position-relative" v-if="isSearch != false && !showShimmers">
                      <div class="round header-top">
                        <input type="checkbox" :id="'checkbox'" v-model="selectAll" @change="toggleSelectAll" />
                        <label :for="'checkbox'"></label>
                      </div>
                    </div>
                    <div class="tasklist-table">
                      <b-table ref="myTable" class="tasksTable mb-0" style="border: 1px solid #ced4da;"
                        v-if="isSearch != false || (rows == 0 && isError == true)" :items="tableData" :fields="fields"
                        :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                        :filter="filterVariable" :filter-included-fields="filterOn" @filtered="debouncedFiltered" sticky-header>
                        <!-- Custom rendering for TaskName column -->
                        <template #cell(TaskIdNumber)="row" >
                          <a v-if="row.value" @click="viewItem(row.item)" class="task-link" :title="row.value">
                            {{ row.value }}
                          </a>
                          <span v-else>{{ row.value }}</span>
                        </template>
                        <template #cell(TaskName)="row">
                          <a class="link font-weight-bold" @click="viewItem(row.item)" :title="row.value">{{ row.value
                          }}</a>
                        </template>
                        <template #cell(ProjectName)="row">
                          <p class="mb-0">
                            <span :title="row.value">{{ row.value }}</span>
                          </p>
                        </template>
                        <template #cell(AssignedTo)="row">
                          <p class="mb-0">
                            <span :title="row.value">{{ row.value }}</span>
                          </p>
                        </template>
                        <template #cell(CreatedDt)="row">
                          <p class="mb-0">
                            <span :title="row.value">{{ row.value }}</span>
                          </p>
                        </template>
                        <template #cell(EndDate)="row">
                          <p class="mb-0">
                            <span :title="row.value">{{ row.value }}</span> <a herf="#"
                              :title="row.item.OverDueEmailSentDate !== currentDate ? 'Due date crossed' : 'Mail already sent'"
                              class="link font-weight-bold"
                              v-if="isDueDateCrossed(row.value) && row.item.Status != 'Completed' && row.item.IsDeleted != true">
                              <i :class="{ 'ri-notification-line text-danger align-bottom': row.item.OverDueEmailSentDate !== currentDate, 'ri-mail-line text-success align-bottom': row.item.OverDueEmailSentDate == currentDate }"
                                @click="row.item.OverDueEmailSentDate !== currentDate && row.item.UserEmail != undefined ? sendOverDueMail(row) : ''"></i></a>
                          </p>
                        </template>
                        <template #cell(Comments)="row">
                          <p class="mb-0 text-truncate mw-150">
                            <span :title="row.value">{{ row.value }}</span>
                          </p>
                        </template>
                        <!-- Slot for the actions column -->
                        <template #cell(Status)="row">
                          <div class="d-flex align-items-center" :title="row.value">
                            <span
                              :class="{
                                'color-active': row.value === 'New',
                                'bg-primary': row.value === 'Active',
                                'bg-success':
                                  row.value === 'Completed' ||
                                  row.value === 'Closed',
                                'bg-warning': row.value === 'In-progress',
                                'bg-secondary': row.value === 'In-active',
                              }"
                              class="ytmStatusIndicator"
                            ></span>
                            {{ row.value }}
                          </div>
                        </template>
                        <!-- Select options -->
                        <template #cell(Selection)="row">
                          <div class="round" :title="row.value">
                            <input type="checkbox" :id="'checkbox-' + row.item.Id" v-model="row.item.selected"
                              @change="selectAllToggle()" />
                            <label :for="'checkbox-' + row.item.Id"></label>
                          </div>
                        </template>
                        <template #cell(actions)="row">
                          <i @click="RecordDownload(row.item)" class="ri-download-line text-primary font-size-18"
                            size="sm" style="font-size: 1.5rem;margin-right: 10px; cursor: pointer;"
                            v-b-tooltip.hover.bottom="'Download task'"></i>
                          <span v-if="title == 'Deleted tasks'">
                            <i @click="Restore(row.item['Id'], row.item)"
                              class="ri-arrow-go-back-line text-warning font-size-18" size="sm"
                              style="font-size: 1.5rem; cursor: pointer;" v-b-tooltip.hover.bottom="'Restore task'">
                            </i>
                          </span>
                        </template>
                        <!-- <template #cell(actions)="row">
                          <b-dropdown text="Actions" variant="outline-primary" size="sm">
                            <b-dropdown-item @click="RecordDownload(row.item)" variant="primary" size="sm"><i
                                class="ri-download-2-line mr-1 align-bottom"></i>Download </b-dropdown-item>
                            <b-dropdown-item @click="cancel(row.item['Id'], row.item)" variant="danger" size="sm" v-if="hasPermission('DeleteTask') &&
                              (row.item['type'] == assignedByType ||
                                row.item['type'] == assignedToType) &&
                              row.item['Status'] != isCompleted &&
                              row.item['Status'] != isinprogess &&
                              !isTaskList &&
                              (!tableData.filter((record) => record.selected && record.Status == 'Completed').length > 0)
                              && (!tableData.filter((record) => record.selected && record.Status == 'In-progress').length > 0)
                              "><i class="ri-delete-bin-line mr-1 align-bottom"></i>Delete </b-dropdown-item>
                            <b-dropdown-item @click="Restore(row.item['Id'], row.item)" variant="primary" size="sm"
                              v-if="isTaskList && (tableData.length === 1 || tableData.filter((record) => record.selected).length < tableData.length)"><i
                                class="ri-arrow-go-back-line align-bottom mr-1"></i>Restore
                            </b-dropdown-item>
                          </b-dropdown>
                        </template> -->
                      </b-table>
                    </div>
                    <div class="nodata-ui" v-if="!tableData?.length || (!rows && !showShimmers)" v-cloak>
                      <div class="fullscreen-height d-flex align-items-center justify-content-center">
                        <div class="py-5 text-muted">
                          <div class="d-flex flex-column justify-content-center gap-1 align-items-center fs-4">
                            <i v-if="isError == false && !showShimmers"
                              class="ri-information-line font-size-36 text-muted"></i>
                            <h5 v-if="isError == false && !showShimmers" class="text-muted">
                              No data found!
                            </h5>
                            <p v-if="!showShimmers && isTabActive != 'Over due' && isTaskList == false"
                              class="font-size-16 text-center">Get started by creating a new task.</p>
                            <b-button size="sm"
                              v-if="isTaskList == false && !showShimmers && hasPermission('CreateTask') && isTabActive != 'Over due'"
                              variant="primary" @click="resetDataClick" v-b-modal.modal-center>
                              <i class="ri-add-line align-middle"></i> Add task
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- pagination -->
                <div class="d-flex justify-content-between flex-column flex-sm-row">
                  <div class="d-flex" v-if="rows != 0">
                    <div v-if="isSearch" id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center mb-2 mb-lg-0">
                        Show
                        <select size="sm" v-model="perPage" class="form-control align-self-start selction-padding mr-md-2"
                          style="width: 66px; height:28.89px" @change="ChangePage">
                          <option v-for="option of pageOptions" :key="option">{{ option }}</option>
                        </select>
                        <!-- Entries -->
                      </label>
                    </div>
                  </div>
                  <div class="paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-if="isSearch && rows != 0 && isError == false" v-model="currentPage" pills
                        aria-controls="my-table" show-total :total-rows="rows" :per-page="perPage"
                        @input="ChangePage()"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <b-modal ref="myModal" id="modal-center" centered title="Add task" title-class="font-18" hide-footer
      no-close-on-backdrop>
      <form @submit.prevent="onSubmit">
        <!-- <div class="text-left mb-2">
          <h4>Add Task</h4>
          <hr>
        </div> -->
        <div class="form-group">
          <label for="taskName">Task name
            <span :class="{ 'text-danger': !this.taskDataBind.taskName }">
              *</span></label>
          <input type="text" maxlength="50" @blur="isTaskName = true" class="form-control" placeholder="Enter task name"
            :class="[
              !this.taskDataBind.taskName && isTaskName
                ? 'border border-danger'
                : 'form-control',
            ]" v-model="taskDataBind.taskName" />
          <span class="text-danger" v-if="!this.taskDataBind.taskName && isTaskName">Task name is required!</span>
        </div>



        <div class="form-group">
          <label class="" for="seletedProject">Project title
            <span :class="{ 'text-danger': !this.seletedProject }"> *</span></label>
          <div :class="[
            !this.seletedProject && projectTitleBlurred
              ? 'multiSelectError'
              : 'multi-selector-container',
          ]">

            <multiselect @input="GetUsersByProject(seletedProject)" v-model="seletedProject"
              :options="ProjectsListOptions" :searchable="true" track-by="Id" class="projectTitle" label="name"
              @close="projectTitleBlurred = true">
            </multiselect>
          </div>
          <!-- <p v-if="!this.seletedProject && projectTitleBlurred && !assignedTo" class="text-danger">
            Assigned to is required!
          </p> -->
          <p v-if="!this.seletedProject && projectTitleBlurred && !assignedTo" class="text-danger">
            Project title is required!
          </p>
        </div>

        <div class="form-group">
          <label class="" for="assignedTo">Assigned to</label>
            <!-- <span :class="{ 'text-danger': !this.assignedTo }"> *</span> -->
          <div :class="[
            !this.assignedTo && assignedToBlurred
              ? 'multiSelectError'
              : 'multi-selector-container',
          ]">

            <multiselect :disabled="!seletedProject || !assignedToOption.length" v-model="assignedTo" :options="assignedToOption" track-by="userId"
              label="FullName" style="border-radius: 4px;">
              <template slot="option" slot-scope="{ option }">
                {{ option ? option.FullName : "Select Assigned To" }}
              </template>
              <template slot="selected-option" slot-scope="{ option }">
                {{ option.FullName }}
              </template>
            </multiselect>
          </div>
          <!-- <p v-if="!this.assignedTo && assignedToBlurred" class="text-danger">
            Assigned to is required!
          </p> -->
        </div>

        <!-- <div class="form-group">
          <label for="userEmail">Email {{ this.assignedTo?.Email }}</label>
          <input type="text" maxlength="50" v-model="taskDataBind.userEmail" class="form-control"
            />         
        </div> -->
        <div class="form-group">
          <label for="dueDate">Due date
            <span :class="{ 'text-danger ': !this.taskDataBind.dueDate }">
              *</span></label>
          <b-input-group @click="isDueDate = true">
            <b-form-input id="example-input" v-model="date" type="text" placeholder="Select to date" autocomplete="off"
              readonly
              v-bind:class="{ 'form-control': true, 'border border-danger': !taskDataBind.dueDate && isDueDate }"></b-form-input>
            <b-input-group-append @click="isDueDate = true">
              <b-form-datepicker v-model="taskDataBind.dueDate" button-only right locale="en-US"
                aria-controls="example-input" selected-variant="success" today-variant="info" nav-button-variant="primary"
                :hide-header=true v-bind:min="currentDate"></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
          <span class="text-danger" v-if="!this.taskDataBind.dueDate && isDueDate">Due date is required!</span>
        </div>
        <div class="form-group">
          <label for="comments">Description<span :class="{ 'text-danger': !taskDataBind.comments }">
              *</span></label>
          <textarea @blur="isComments = true" rows="3" v-model="taskDataBind.comments" :class="[
            !this.taskDataBind.comments && isComments
              ? 'border border-danger'
              : 'form-control',
          ]" placeholder="Enter description" class="form-control"></textarea>
          <span class="text-danger" v-if="!this.taskDataBind.comments && isComments">Description is required!</span>
        </div>
        <div class="text-right mt-4">
          <b-button class="ml-1 mr-2" :disabled="!formChanged" type="button" variant="outline-primary"
            @click="ResetData"><i class="ri-close-line align-bottom"></i> Cancel</b-button>
          <button type="submit" :disabled="isFormInvalid || saved" class="btn btn-primary">
            <i class="ri-save-line align-bottom"></i> Save
          </button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>

<style lang="scss" scoped>
// .expand-fade-enter-active,
// .expand-fade-leave-active {
//   transition: all 0.5s;
// }

// .expand-fade-enter,
// .expand-fade-leave-to {
//   opacity: 0;
//   transform: translateY(-20px);
// }
// .card-fullscreen-height{
//   height: calc(100vh - 285px);
// }
.spinner-border {
  width: 1rem;
  /* Adjust the width to your desired size */
  height: 1rem;
  /* Adjust the height to your desired size */
}



.fullscreen-height {
  height: 420px;
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  width: 18px;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 0;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 5px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 2px;
  transform: rotate(-45deg);
  width: 10px;
  right: 0;
  margin: auto;
  border: 6px solid #00435d;
  border-radius: 50px;
}
.round label:hover::after {
  opacity: 0.6;
}
.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]{
  background-color: #00435d;
  border-color: #ffffff;
}

.round input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.task-link {
  cursor: pointer;
  font-weight: 600;
  color: #505d69;

  &:hover {
    color: #00435d;
  }

  &:active {
    color: #00435d;
  }
}

.header-top {
  position: absolute;
  top: 10px;
  left: 17px;
  z-index: 3;
}

.dropdown-item {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.clear-btn {
  position: absolute;
  bottom: 0;
  background: #fff;
  width: 100%;
  text-align: right;
  left: 0;
  border-top: 1px solid #ddd;
  border-radius: 0;
  z-index: 1;
}

.color-new {
  color: gray;
  background-color: lightgray;
}

.color-active {
  color: #007acc;
  background-color: rgba(0, 122, 204, 0.28);
}

.badge-soft-danger {
  color: #ff3d60;
  background-color: rgba(255, 61, 96, 0.28);
}

.link {
  color: #303030;
  cursor: pointer;

  &:hover {
    color: #00435d;
    text-decoration: underline !important;
  }
}

.border-right {
  border-right: 1px #d5d2d2 solid;
  padding-right: 10px;
}

.pb-40 {
  padding-bottom: 40px;
}

.search-input {
  border-bottom: 1px solid #ddd !important;
  border: none;
  border-radius: 0;
}

// .filter-btn {
//   position: absolute;
//   right: 0;
// }

.table th {
  padding: 0.25rem 1rem;
}

// Responsive CSS

.w140px {
  @media screen and (max-width:767px) {
    width: 100%;
    max-width: 100%;
  }
}

.ytmFilterRow {
  @media screen and (max-width: 767px) {
    margin-top: 20px !important;
  }
}

.taskTableSection {
  &.mt-40px {
    @media screen and (max-width: 767px) {
      margin-top: 20px;
    }
  }
}


.tasklist-tabs {
  @media screen and (max-width:1023px) {
    margin-top: 40px
  }
}

.search-icon {
  @media screen and (max-width:768px) {
    right: 15px !important;
  }
}

.searchWidth {
  @media screen and (max-width: 768px) {
    width: 220px;
  }
}


.edittask-alert {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 10000;
}

.projectTitle .multiselect__select {
  height: 36px !important;
}

.multiselect--disabled .multiselect__select {
  height: 36px !important;
}

.table th {
  padding-left: 0.45rem;
  padding-right: 0.65rem
}

.orderby-dropdown {
  @media screen and (max-width:444px) {
    width: 100%;
  }

  @media screen and (min-width:445px) and (max-width:518px) {
    margin-right: 0.5rem;
  }

  @media screen and (min-width:519px) {
    margin-left: 0.5rem;
  }
}

.date-range {
  @media screen and (max-width:444px) {
    width: 100%;
  }

  .mx-input {
    @media screen and (max-width:444px) {
      width: 100% !important;
    }
  }
}

.searchInput {
  padding-right: 22px !important;
}
.w-180{
  width: 180px;
}

.ytmStatusIndicator{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 3px;
}
.b-table-sticky-header {
    overflow-y: auto;
    max-height: calc(100vh - 375px);
}

  .blink {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    opacity: .25;
    background-color: red;
    -webkit-animation: blink 5s infinite;
    -moz-animation: blink 5s infinite;
    -o-animation: blink 5s infinite;
    animation: blink 5s infinite;

    &:after{
      border-radius: 50%;
      content: "";
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
    }
  }

  @keyframes blink {
      0% {
        transform: scale(1);
        opacity: .25;
      }
      25%{
        opacity: 1;
      }
      50% {
        opacity: .25;
      }
      75%{
        opacity: 1;
      }
      100% {
        transform: scale(1);
        opacity: .25;
      }
  }
  
</style>