import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'
import "@/assets/scss/app.scss";
import store from './store'
import  '../src/config/axios'
import '../src/config/tasksaxios'
import VueApexCharts from 'vue-apexcharts'
import ElementUI from 'element-ui';
Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(ElementUI)

new Vue({
  router,
  store,

  render: h => h(App)
}).$mount('#app')
