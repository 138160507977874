<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import PreLoader from "@/components/pre-loader";
export default {
  components:{PreLoader},
 data() {
    return {
      email: '',
      emailBlured:false,
      emailNotValid:false,
      loading:false,
      ShowAlert:false,
    }
  },  computed: {
    ...mapState(['getUserguid']),
    ...mapGetters('userservice', ['getEmailCheck']),
  },
  methods: {
    ...mapMutations(['setSharedData']),
    validateEmail() {
      this.ShowAlert=false
      this.emailNotValid = true
      this.emailNotValid = !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.email));
    },
    async tryToReset() {
      this.loading=true
      const params = {
        email: this.email,
      }
      await this.$store.dispatch('userservice/EmailCheck',params)
      if (this.getEmailCheck?.result == false) {
        const input = {
          email: this.email,
          emailType: "ForgotPassword"
        };
        await this.$store.dispatch('userservice/ResendEmail', input)
        this.$router.push({ name: 'NotVerified' })
        this.setSharedData('sent');
        this.loading=false
      }
      else{
        this.loading=false;
        this.ShowAlert=true;
        // alert("Please check your mail")
      }
    }
  }
};
</script>

<template>
  <div>
    <div v-if="loading">
      <PreLoader></PreLoader>
    </div>
    <!-- <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div> -->
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/ytm-dark.svg" height="70" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-5">Forgot password</h4>
                        <!-- <p class="text-muted">Enter your registered email id to reset your password.</p> -->
                      </div>
                      <div class="p-2 mt-2">
                        <b-alert show variant="danger" v-if="ShowAlert">
                            <i class="align-bottom ri-close-circle-line mr-2"></i>Email must be a valid email address!
                        </b-alert>

                        <form class="form-horizontal" @submit.prevent="tryToReset">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="useremail">Email <span :class="{ 'text-danger': !email ||emailNotValid }">*</span></label>
                            <input
                              type="email"
                              class="form-control"
                              id="useremail" :maxlength="150"
                              placeholder="Enter email" @input="validateEmail()"
                              v-model="email" @blur="emailBlured = true"
                              v-bind:class="{'is-invalid': (!email  || emailNotValid) && emailBlured }" 
                            />
                            <!-- <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                              <span v-if="!$v.email.required">Email is required.</span>
                              <span v-if="!$v.email.email">Please enter valid email.</span>
                            </div> -->
                            <div class="invalid-feedback position-absolute" v-if="!email && (emailBlured)">Email is required!</div>
                            <div class="invalid-feedback position-absolute" v-if="email && emailNotValid && (emailBlured)">Email must be a valid email address!</div>
                          </div>

                          <div class="mt-3 text-center">
                            <button
                          class="btn btn-primary w-md waves-effect waves-light"
                      type="submit"
                      :disabled="emailNotValid || !email"
                          >Reset</button>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p><i class="align-bottom ri-arrow-left-s-line"></i>
                          <a
                            href="/login"
                            class="font-weight-bold text-primary"
                          >Back to Login</a>
                        </p>
                        <p>
                          © 2023 Yoda Task Management.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>