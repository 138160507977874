import { decodeJwt } from '../module/util';
import {axios} from '../../config/axios'
import { taskApiService } from '../../config/tasksaxios';
const initialState = () => {
    return {
      user:null,
        authenticationStatus: {},
        authenticationDetails: {},
        profleDetails: {},
        message:{},
        getUpload : null,
        Hierarchy:[],
        EmployeeyLeave:[],
    }}
    const state = initialState();


export const mutations = {

  // set Profile Url 
  setUpload(state, data) {
    state.getUpload = data;
  },

  clearUserData(state) {
    state.user = {};
  },
  
    setAuthenticatedUser: (state, user) => {
        state.user = user;
      },
      setAuthenticationDetails: (state, tokens) => {
        state.authenticationDetails = tokens;
      },
      setAuthenticatedStatus: (state, status) => {
        state.authenticationStatus = status;
      },
      setProfileData(state, data) {
        state.profleDetails = data;
      },
      setUpdatedetailsResponse:(state,response)=>{
        state.message=response
      },
      setUserHierarchyWithOrganization:(state,response)=>{
        state.Hierarchy=response
      },
      setEmployeeyLeave:(state,response)=>{
        state.EmployeeyLeave=response
      },

      reset: (state) => {
        const initial = initialState();
        Object.keys(initial).forEach((key) => {
          state[key] = initial[key];
        });
      },
}

export const getters = {
    getAuthenticatedUser: (state) => state.user,
    getAuthenticationDetails: (state) => state.authenticationDetails,
    getAuthenticationStatus: (state) => state.authenticationStatus,
    getProfileDetails: (state) => state.profleDetails,
    getUpdateDetailsResponse:(state) => state.message,
    getUserHierarchyWithOrganization:(state) => state.Hierarchy,
    getEmployeeyLeave:(state) => state.EmployeeyLeave,
    // Get Upload Image Details
    getUpload :  (state) => {
      return state.getUpload
    },
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars

    // Logs in the current user.
    async  login({ commit,dispatch}, params = {}) {
        try {
          commit("errors/clear", null, { root: true });
            const headers = {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization":""
              };
            commit("setAuthenticatedStatus", false);
            commit("setAuthenticatedUser", null);
            const response = await axios.post("connect/token", params,{ headers });
          if (response) {
            const decodedPayload = decodeJwt(response.data.access_token)
            let userDetails = decodedPayload;
            let tokens = {
              access_token: response.data.access_token,
              refresh_token: response.data.refresh_token,
            };
            commit("setAuthenticatedUser", userDetails);
            commit("setProfileData", userDetails);
            commit("setAuthenticationDetails",tokens);
            commit("setAuthenticatedStatus", true);
          }
          } catch (err) {
            if(err.response){
              commit("setAuthenticatedUser",err.response.data)
            }
            await dispatch("errors/errorResponse", err?.response||err, { root: true });
          }
    },
    /**
     * clearing the data in the getters
     * @param {*} param0 
     */
   async logout({ commit }) {
      commit('clearUserData');
    },
    /**
     * Update user profile details
     * @param {*} param0 
     * @param {*} params 
     */
  async UpdateUserdetails({ commit, dispatch }, params = {}) {
    try {
      commit("setUpdatedetailsResponse",null)
      let response = await axios.post("/api/v1/User/UpdateUserDetails", params);
      commit("setProfileData", null);
      let data = response.data?.result[0]
      commit("setProfileData", data);
      commit("setUpdatedetailsResponse",response.data?.message)
    }
    catch (err) {
      commit("setUpdatedetailsResponse",err.message)
      console.warn("error")
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },
    /**
     * Get User Hierarchy With Organization
     * @param {*} param0 
     * @param {*} params 
     */
  async GetUserHierarchyWithOrganization({ commit, dispatch }, params = {}) {
    try {
      commit("setUserHierarchyWithOrganization",null)
      let response = await axios.get(`/api/v1/User/GetUserHierarchywithOrganization?UserId=${params}`);
      let data = response.data
      commit("setUserHierarchyWithOrganization",data)
    }
    catch (err) {
      commit("setUserHierarchyWithOrganization",err.message)
      console.warn("error")
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
  },

  /**
 * upload image 
 * @param {*} param0 
 * @param {imageFile} params 
 */
  async fileUpload({ commit, dispatch }, params){
    try{
      commit('setUpload' , null)
    const response = await axios.post(`/api/v1/Auth/UploadImage` , params ,  {
      headers: {
        'Content-Type': 'multipart/form-data'
      }})
    commit("setUpload", response.data);
    }
    catch(err){
      commit("setUpload", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
    
  },
  async updateMonthlyLeave({ commit, dispatch }, params){
    try{
      commit('setEmployeeyLeave' , null)
    const response = await taskApiService.post(`/employee/updateMonthlyLeave` , params );
    commit("setEmployeeyLeave", response.data);
    }
    catch(err){
      commit("setEmployeeyLeave", null);
      await dispatch("errors/errorResponse", err.response, { root: true });
    }
    
  }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };