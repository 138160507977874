<script>
import Layout from "../../layouts/vertical.vue";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import { mapGetters, mapMutations } from "vuex";
import moment from 'moment-timezone';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { pdfTempletesList } from '../../../assets/json/LeaveReportHtml'
import html2pdf from 'html2pdf.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
    name: 'Reports',
    components: {
        Layout, PageHeader, Multiselect
    },

    data() {
        return {
            // loading: false,
            showShimmers: false,
            title: "Reports",
            items: [
                {
                    text: "Dashboard",
                    routeName: "Dashboard",
                },
                {
                    text: "Reports",
                    active: true,
                },
            ],
            employees: [],
            reportTypes: [],
            employeeReport: {
                employee: '',
                ReportType: '',
                FromDate: '',
                ToDate: ''

            },
            isUser: false,
            isValue: false,
            oneMonthBack: new Date(),
            pdfTempletes: pdfTempletesList,
            toDate: '',
            fromDate: ''
        }
    },
    computed: {
        ...mapGetters('auth', ['getProfileDetails']),
        ...mapGetters('userservice', ['getUserHierarchy']),
        ...mapGetters("attendance", ["getLeaveType", 'getAttendanceReport', 'getEmployee']),
    },
    watch: {
        'employeeReport.FromDate': function (newDate) {
            newDate ?
                this.fromDate = moment(newDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : "";
        },
        'employeeReport.ToDate': function (newDate) {
            newDate ?
                this.toDate = moment(newDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : "";
        },
    },
    methods: {
        async oninit() {
            this.oneMonthBack.setMonth(new Date().getMonth() - 1);
            // this.loading = true
            this.showShimmers = true
            const params = {
                UserId: this.getProfileDetails.UserGuid
            }
            await this.$store.dispatch('userservice/GetUserHierarchy', params)
            this.employees = this.getUserHierarchy?.map(user => ({
                name: user?.FirstName + ' ' + user?.LastName,
                id: user?.UserId, UserGuid: user?.UserGuid
            }));
            await this.$store.dispatch("attendance/fetchLeaveType");
            this.reportTypes = this.getLeaveType?.map(type => ({ type: type.TypeText, Id: type.Id }))
            this.reportTypes.push(
                { type: 'Attendance report', Id: 'AttendanceReport' }, { type: 'Remaining leave balance', Id: 'RemainingLeaveBalance' }
            )
            // this.loading = false;
            this.showShimmers = false;
        },
        ...mapMutations(['setAlertNotification']),
        async onSubmit(ReportType) {
            // this.loading = true
            this.showShimmers = true
            this.isUser = false;
            this.isValue = false;
            const input = {
                UserId: this.employeeReport.employee.id,
                FromDate: this.employeeReport.FromDate ? moment(this.employeeReport.FromDate).format('YYYY-MM-DD') : this.employeeReport.ToDate ? this.oneMonthBack : undefined,
                ToDate: this.employeeReport.ToDate ? moment(this.employeeReport.ToDate).format('YYYY-MM-DD') : this.employeeReport.FromDate ? new Date() : undefined,
                LeaveTypeId: typeof this.employeeReport?.ReportType?.Id === 'number' ? this.employeeReport.ReportType.Id : undefined
            }
            this.employeeReport?.ReportType?.Id == 'RemainingLeaveBalance' ?
                await this.$store.dispatch("attendance/getEmployee", this.employeeReport.employee.id) :
                await this.$store.dispatch("attendance/getattendanceReport", input);

            this.response = await this.employeeReport?.ReportType?.Id === 'RemainingLeaveBalance' ? await this.getEmployee : await this.getAttendanceReport;
            let response = this.employeeReport?.ReportType?.Id === 'RemainingLeaveBalance' ? [await this.getEmployee?.employee] : await this.getAttendanceReport.getReport;
            // this.loading = false
            this.showShimmers = false
            response?.length > 0 ? this[ReportType](response) : ''
            const message = {
                variant: this.response?.status == true ? 'success' : 'danger',
                alertTitle: 'Reports',
                alertDecription: response?.length > 0 ? this.response?.msg : 'No data found'
            };
            this.setAlertNotification(message)
        },
        handleMultiselectClose() {
            this.isUser = true;
        },
        handleMultiselectClos() {
            this.isValue = true;
        },
        downloadRecords(data) {
            const selectedRecords = data;
            let dataToDownload = []
            this.employeeReport?.ReportType?.Id === 'RemainingLeaveBalance' ?
                dataToDownload = selectedRecords.map((item) => ({
                    "User Id": item.UserId,
                    "User name": item.UserName,
                    "Sick leave": item.SickLeave,
                    "Casual leave": item.CasualLeave,
                    "Work from home": item.WorkFromHome,
                    "Optional holiday": item.OptionalHoliday,
                    "Public holiday": item.PublicHoliday,
                    "Compensatory leave": item.CompensatoryLeave
                }))

                : this.employeeReport?.ReportType?.Id === 'AttendanceReport' ?
                    dataToDownload = selectedRecords.map((item) => ({
                        "UserID": item.UserId,
                        "UserName": item.EmployeeName,
                        "CheckIn": moment(item.CheckIn?.split('T')[0]).format('DD-MM-YYYY'),
                        "CheckOut": moment(item.CheckOut?.split('T')[0]).format('DD-MM-YYYY'),
                        "CheckInLocation": (item.CheckInLocation?.longitude || '') + (item.CheckInLocation?.longitude && item.CheckInLocation?.latitude ? ', ' : '') + (item.CheckInLocation?.latitude || ''),
                        "CheckOutLocation": (item.CheckOutLocation?.longitude || '') + (item.CheckOutLocation?.longitude && item.CheckOutLocation?.latitude ? ', ' : '') + (item.CheckOutLocation?.latitude || ''),
                        "CheckInPic": item.CheckInPic,
                        "CheckOutPic": item.CheckOutPic,
                        "LeaveType": item.LeaveType,
                        "FromDate": moment(item.FromDate?.split('T')[0]).format('DD-MM-YYYY'),
                        "EndDate": moment(item.EndDate?.split('T')[0]).format('DD-MM-YYYY'),
                        "Status": !item.IsApproved && !item?.IsCancelled ? this.isToDateCrossed(item?.EndDate ? moment(item.EndDate).format('DD-MM-YYYY') : '') ? "Expired" : "Pending" : item?.IsApproved ? "Approved" : "Rejected"
                    })) :
                    dataToDownload = selectedRecords.map((item) => ({
                        "Id": item.Id,
                        "User ID": item.UserId,
                        "Leave type": item.LeaveType,
                        "From date": moment(item.FromDate?.split('T')[0]).format('DD-MM-YYYY'),
                        "To date": moment(item.EndDate?.split('T')[0]).format('DD-MM-YYYY'),
                        "Approved": !item.IsApproved && !item?.IsCancelled ? this.isToDateCrossed(item?.EndDate ? moment(item.EndDate).format('DD-MM-YYYY') : '') ? "Expired" : "Pending" : item?.IsApproved ? "Approved" : "Rejected",
                    }))

            const headers = Object.keys(dataToDownload[0]);
            const headerRow = headers.join(",");
            const dataRows = dataToDownload.map((record) =>
                headers.map((header) => record[header]).join(",")
            );

            const csvData = [headerRow, ...dataRows].join("\n");
            const blob = new Blob(["\ufeff" + csvData], { type: "text/csv;charset=utf-8;" });
            if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, "leave_report.csv");
            }
            else {
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${this.employeeReport.employee.name}leave_report.csv`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            this.employeeReport = {
                employee: '',
                FromDate: '',
                ToDate: '',
                ReportType: '',
            }
            this.fromDate = '';
            this.toDate = '';
        },
        async generatePDF(value) {
            try {
                let html = "";
                html = this.employeeReport?.ReportType?.Id === 'RemainingLeaveBalance' ? this.pdfTempletes[0].leavebalance : this.employeeReport?.ReportType?.Id === 'AttendanceReport' ? this.pdfTempletes[0].attendance : this.pdfTempletes[0].leaves;
                const replacement = {
                    '%%UserID%%': this.getEmployee.employee.UserId,
                    '%%UserName%%': this.getEmployee.employee.UserName,
                    '%%SickLeave%%': this.getEmployee.employee.SickLeave,
                    '%%PersonalLeave%%': this.getEmployee.employee.CasualLeave,
                    '%%WorkFromHome%%': this.getEmployee.employee.WorkFromHome,
                    '%%OptionalHoliday%%': this.getEmployee.employee.OptionalHoliday,
                    '%%PublicHoliday%%': this.getEmployee.employee.PublicHoliday,
                    '%%CompensatoryLeave%%': this.getEmployee.employee.CompensatoryLeave,
                }
                const replacements = {
                    '%%FullName%%': this.employeeReport.employee.name,
                    '%%Leavetype%%': ' ' + this.employeeReport.ReportType.type,
                };
                const replacementsToUse = this.employeeReport?.ReportType?.Id === 'RemainingLeaveBalance' ? replacement : replacements;
                for (const key in replacementsToUse) {
                    html = html.replace(key, replacementsToUse[key]);
                }
                let dochtml = '';
                dochtml = new DOMParser().parseFromString(html, 'text/html');
                let leavesReport = dochtml.querySelector('#consumeLeaves');
                let attendanceReport = dochtml.querySelector('#attendances');
                if (leavesReport) {
                    leavesReport.innerHTML = '';
                    for (let i = 0; i < value?.length; i++) {
                        let updatedTemplate = '';
                        updatedTemplate = this.pdfTempletes[0].leaves
                            .replace('%%ID%%', i + 1)
                            .replace('%%UserID%%', value[i].UserId || '')
                            .replace('%%LeaveType%%', value[i].LeaveType || '')
                            .replace('%%LeaveDate%%', moment(value[i].FromDate?.split('T')[0]).format('DD-MM-YYYY') || '')
                            .replace('%%ToDate%%', moment(value[i].EndDate?.split('T')[0]).format('DD-MM-YYYY') || '')
                            .replace('%%Status%%', !value[i]?.IsApproved && !value[i]?.IsCancelled ? this.isToDateCrossed(value[i]?.EndDate ? moment(value[i].EndDate).format('DD-MM-YYYY') : '') ? "Expired" : "Pending" : value[i]?.IsApproved ? "Approved" : "Rejected")
                        let jobElement = '';
                        jobElement = new DOMParser().parseFromString(updatedTemplate, 'text/html').querySelector('#consumeLeaves');
                        leavesReport.appendChild(jobElement);
                    }
                }
                else if (attendanceReport) {
                    attendanceReport.innerHTML = '';
                    for (let i = 0; i < value?.length; i++) {
                        let updatedTemplate = '';
                        updatedTemplate = this.pdfTempletes[0].attendance
                            .replace('%%UserID%%', value[i].UserId || '')
                            .replace('%%UserName%%', value[i].EmployeeName || '')
                            .replace('%%CheckIn%%', moment(value[i].CheckIn?.split('T')[0]).format('DD-MM-YYYY') || '')
                            .replace('%%CheckOut%%', moment(value[i].CheckOut?.split('T')[0]).format('DD-MM-YYYY') || '')
                            .replace('%%CheckInLocation%%', (value[i].CheckInLocation?.longitude || '') + (value[i].CheckInLocation?.longitude && value[i].CheckInLocation?.latitude ? ', ' : '') + (value[i].CheckInLocation?.latitude || ''))
                            .replace('%%CheckOutLocation%%', (value[i].CheckOutLocation?.longitude || '') + (value[i].CheckOutLocation?.longitude && value[i].CheckOutLocation?.latitude ? ', ' : '') + (value[i].CheckOutLocation?.latitude || ''))
                            .replace('%%CheckInPic%%', value[i].CheckInPic || '')
                            .replace('%%CheckOutPic%%', value[i].CheckOutPic || '')
                            .replace('%%LeaveType%%', value[i].LeaveType || '')
                            .replace('%%FromDate%%', moment(value[i].FromDate?.split('T')[0]).format('DD-MM-YYYY') || '')
                            .replace('%%EndDate%%', moment(value[i].EndDate?.split('T')[0]).format('DD-MM-YYYY') || '')
                            .replace('%%Status%%', !value[i]?.IsApproved && !value[i]?.IsCancelled ? this.isToDateCrossed(value[i]?.EndDate ? moment(value[i].EndDate).format('DD-MM-YYYY') : '') ? "Expired" : "Pending" : value[i]?.IsApproved ? "Approved" : "Rejected" || '')
                        let jobElement = '';
                        jobElement = new DOMParser().parseFromString(updatedTemplate, 'text/html').querySelector('#attendances');
                        attendanceReport.appendChild(jobElement);
                    }
                }

                html = dochtml.documentElement.outerHTML;
                const options = {
                    filename: `${this.employeeReport.employee.name}Leaves.pdf`,
                    margin: 0.2,
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { scale: 1 },
                    jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' }
                };
                const element = html;
                html2pdf().from(element).set(options).save();
                this.employeeReport = {
                    employee: '',
                    FromDate: '',
                    ToDate: '',
                    ReportType: '',
                }
            } catch (error) {
                console.error("Error generating PDF:", error);
            }
        },
        isToDateCrossed(EndDate) {
            const currentDate = new Date();
            const endDateParts = EndDate.split('-');
            const endDate = new Date(
                endDateParts[2],
                endDateParts[1] - 1,
                endDateParts[0]
            );
            return endDate < currentDate && EndDate !== currentDate?.toLocaleDateString('en-GB')?.replace(/\//g, '-');
        },
    },

    mounted() {
        this.oninit()
    },
}
</script>


<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <!-- <div v-if="loading">
            <PreLoader />
        </div> -->
        <div v-if="showShimmers">
            <b-card no-body>
                <b-card-body>
                    <div class="row">
                        <div class="col-sm-3">
                            <b-skeleton height="10px" width="100px"></b-skeleton>
                            <b-skeleton type="input"></b-skeleton>
                        </div>
                        <div class="col-sm-3">
                            <b-skeleton height="10px" width="100px"></b-skeleton>
                            <b-skeleton type="input"></b-skeleton>
                        </div>
                        <div class="col-sm-3">
                            <b-skeleton height="10px" width="100px"></b-skeleton>
                            <b-skeleton type="input"></b-skeleton>
                        </div>
                        <div class="col-sm-3">
                            <b-skeleton height="10px" width="100px"></b-skeleton>
                            <b-skeleton type="input"></b-skeleton>
                        </div>
                    </div>
                </b-card-body>
                <div class="card-footer d-flex justify-content-end">
                    <b-skeleton class="mr-4" type="button" height="30px" width="100px" border-radius="8px"></b-skeleton>
                    <b-skeleton type="button" height="30px" width="100px" border-radius="8px"></b-skeleton>
                </div>
            </b-card>
        </div>

        <div class="home" v-else>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <form>
                                <div class="row">
                                    <div class="col-12  col-md-3 ">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="ReportType">Report type <span
                                                        :class="{ 'text-danger': !employeeReport.ReportType }">*</span></label>
                                                <div
                                                    :class="[!employeeReport.ReportType && isUser ? 'multiSelectError' : 'multi-selector-container',]">
                                                    <multiselect v-model="employeeReport.ReportType" :options="reportTypes"
                                                        v-bind:class="{ 'is-invalid': !employeeReport.ReportType?.length && isUser }"
                                                        label="type" track-by="Id" @close="handleMultiselectClose">
                                                    </multiselect>
                                                </div>
                                                <span class="text-danger" v-if="!employeeReport.ReportType && isUser">Report
                                                    type
                                                    is required!</span>
                                                <!-- <select class="department mx-input" id="ReportType" name="ReportType">
                                                    <option value="">Casual/Sick Leave</option>
                                                    <option value="">Maternity Leave</option>
                                                    <option value="">Paternity Leave</option>
                                                    <option value="">Compensatory Leave</option>
                                                    <option value="">Bereavement Leave</option>
                                                    <option value="">Department Attendance Report</option>
                                                    <option value="">Remaining Leave Balance</option>
                                                    <option value="">Remaining Holidays</option>
                                                    <option value="">Daily Attendance Report</option>
                                                    <option value="">Monthly Attendance Report</option>
                                                    <option value="">Yearly Attendance Report</option>
                                                    <option value="">Employees Arriving Late</option>
                                                    <option value="">Employees Arriving Early</option>
                                                    <option value="">Employees Leaving Early</option>
                                                    <option value="">Employees Leaving Late</option>
                                                    <option value="">Daily Overtime</option>
                                                    <option value="">Weekly Overtime</option>
                                                    <option value="">Monthly Overtime</option>
                                                </select> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12  col-md-3 ">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="employee">Employee name <span
                                                        :class="{ 'text-danger': !employeeReport.employee }">*</span></label>
                                                <div
                                                    :class="[!employeeReport.employee && isValue ? 'multiSelectError' : 'multi-selector-container',]">
                                                    <multiselect v-model="employeeReport.employee" :options="employees"
                                                        label="name" track-by="UserGuid" @close="handleMultiselectClos">
                                                    </multiselect>
                                                </div>
                                                <span class="text-danger"
                                                    v-if="!employeeReport.employee && isValue">Employee name
                                                    is required!</span>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-3">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="dateFrom">From date</label>
                                                <b-input-group>
                                                    <b-form-input id="datepicker1" v-model="fromDate" type="text"
                                                        placeholder="Select from date" autocomplete="off"
                                                        readonly></b-form-input>
                                                    <b-input-group-append>
                                                        <b-form-datepicker v-model="employeeReport.FromDate" button-only
                                                            right locale="en-US" @change="employeeReport.ToDate = ''"
                                                            aria-controls="datepicker1" selected-variant="success"
                                                            today-variant="info" nav-button-variant="primary"
                                                            :hide-header=true v-bind:max="employeeReport.ToDate"
                                                            :disabled="employeeReport.ReportType?.Id === 'RemainingLeaveBalance'"></b-form-datepicker>
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12  col-md-3 ">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="dateTo">To date</label>
                                                <b-input-group>
                                                    <b-form-input id="datepicker2" v-model="toDate" type="text"
                                                        placeholder="Select to date" autocomplete="off"
                                                        readonly></b-form-input>
                                                    <b-input-group-append>
                                                        <b-form-datepicker v-model="employeeReport.ToDate" button-only right
                                                            locale="en-US" aria-controls="datepicker2"
                                                            selected-variant="success" today-variant="info"
                                                            nav-button-variant="primary" :hide-header=true
                                                            v-bind:max="employeeReport.ToDate"
                                                            :disabled="employeeReport.ReportType?.Id === 'RemainingLeaveBalance'"></b-form-datepicker>
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="report">
                                <!-- <h1>Attendance and Leave Reports</h1> -->
                                <div class="report-form">

                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="text-right">
                                <button type="button" :class="{
                                    'btn btn-outline-primary mb-2': !employeeReport.ReportType || !employeeReport.employee,
                                    'btn btn-primary mb-2': employeeReport.ReportType || employeeReport.employee
                                }" @click="onSubmit('generatePDF')"
                                    :disabled="((!employeeReport.ReportType || !employeeReport.employee))"> Get PDF report</button>
                                <button class="btn btn-primary ml-2 mb-2" type="button" @click="onSubmit('downloadRecords')"
                                    :disabled="((!employeeReport.ReportType || !employeeReport.employee))">
                                    Get excel report </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<style scoped lang="scss">
/* body {
    font-family: Arial, sans-serif;
}

.report {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.report-form {
    border: 1px solid #00435D;
    padding: 20px;
    border-radius: 5px;
} */
.text-right{
    margin-top: 10px !important;
}
.form-group {
    margin-bottom: 10px;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

// input[type="text"],
// input[type="date"],
// select {
//     width: 100%;
//     padding: 8px;
//     border: 1px solid #00435D;
//     border-radius: 3px;
//     height: 100%;
// }

// button {
//     background-color: #00435D;
//     color: #fff;
//     border: none;
//     padding: 10px 20px;
//     border-radius: 5px;
//     cursor: pointer;
// }

// button:hover {
//     background-color: #00435D;
// }

.department,
.mx-input {
    height: 38px !important;
}

.card-footer {
    background-color: #e2eaed !important;
}
</style>