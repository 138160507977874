<script>
import Layout from "../../layouts/vertical.vue";
import PageHeader from "@/components/page-header";
// import PreLoader from "@/components/pre-loader";
// import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import { mapGetters, mapMutations } from "vuex";
import moment from 'moment-timezone';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
    name: 'Letter Requests',
    components: {
        Layout, PageHeader, Multiselect,
        // DatePicker
    },

    data() {
        return {
            // loading: false,
            showShimmers: false,
            title: "Letter Requests",
            items: [
                {
                    text: "Dashboard",
                    routeName: "Dashboard",
                },
                {
                    text: "Letter Requests",
                    active: true,
                },
            ],
            item: [
          { RequestId: '1',  RequestType: 'Bank Letter', RaisedOn:'12-08-2023', Status: 'Completed', Actions: '' },
          { RequestId: '2',  RequestType: 'Home Loan Letter', RaisedOn:'14-10-2023', Status: 'Pending', Actions: '' },
          { RequestId: '3',  RequestType: 'Perosnal Loan Letter', RaisedOn:'13-10-2023', Status: 'Completed', Actions: '' },
          { RequestId: '4',  RequestType: 'Roles & Responsibilities Letter', RaisedOn:'15-10-2023', Status: 'Pending', Actions: '' },
          { RequestId: '4',  RequestType: 'Hike Letter', RaisedOn:'15-10-2023', Status: 'Pending', Actions: '' },
          { RequestId: '4',  RequestType: 'Form 16 (FY 2023 - 24)', RaisedOn:'15-10-2023', Status: 'Pending', Actions: '' },
        ],
            options: [
            "Bank Letters",
            "Home Loan Letters",
            "Perosnal Loan Letter",
            "Roles & Responsibilities Letter",
            "Hike Letter",
            "Form 16 (FY 2023 - 24)",

      ],
            employees: [],
            reportTypes: [],
            employeeReport: {
                employee: '',
                ReportType: '',
                FromDate: '',
                ToDate: ''

            },
            isUser: false,
            isValue: false,
            oneMonthBack: new Date()
        }
    },
    computed: {
        ...mapGetters('auth', ['getProfileDetails']),
        ...mapGetters('userservice', ['getUserHierarchy']),
        ...mapGetters("attendance", ["getLeaveType", 'getAttendanceReport', 'getEmployee']),
    },
    methods: {
        async oninit() {
            this.oneMonthBack.setMonth(new Date().getMonth() - 1);
            // this.loading = true
            this.showShimmers = true
            const params = {
                UserId: this.getProfileDetails.UserGuid
            }
            await this.$store.dispatch('userservice/GetUserHierarchy', params)
            this.employees = this.getUserHierarchy?.map(user => ({
                name: user?.FirstName + ' ' + user?.LastName,
                id: user?.UserId, UserGuid: user?.UserGuid
            }));
            await this.$store.dispatch("attendance/fetchLeaveType");
            this.reportTypes = this.getLeaveType?.map(type => ({ type: type.TypeText, Id: type.Id }))
            this.reportTypes.push(
                { type: 'Attendance report', Id: 'AttendanceReport' }, { type: 'Remaining leave balance', Id: 'RemainingLeaveBalance' }
            )
            // this.loading = false;
            this.showShimmers = false;
        },
        ...mapMutations(['setAlertNotification']),
        async onSubmit(ReportType) {
            // this.loading = true
            this.showShimmers = true
            this.isUser = false;
            this.isValue = false;
            const input = {
                UserId: this.employeeReport.employee.id,
                FromDate: this.employeeReport.FromDate ? moment(this.employeeReport.FromDate).format('YYYY-MM-DD') : this.employeeReport.ToDate ? this.oneMonthBack : undefined,
                ToDate: this.employeeReport.ToDate ? moment(this.employeeReport.ToDate).format('YYYY-MM-DD') : this.employeeReport.FromDate ? new Date() : undefined,
                LeaveTypeId: typeof this.employeeReport?.ReportType?.Id === 'number' ? this.employeeReport.ReportType.Id : undefined
            }
            this.employeeReport?.ReportType?.Id == 'RemainingLeaveBalance' ?
                await this.$store.dispatch("attendance/getEmployee", this.employeeReport.employee.id) :
                await this.$store.dispatch("attendance/getattendanceReport", input);

            this.response = await this.getAttendanceReport;
            let response = this.employeeReport?.ReportType?.Id === 'RemainingLeaveBalance' ? [await this.getEmployee.employee] : await this.getAttendanceReport.getReport;
            // this.loading = false
            this.showShimmers = false
            response?.length > 0 ? this[ReportType](response) : ''
            const message = {
                variant: response?.length <= 0 ? 'warning' : this.response?.status == true ? 'success' : 'danger',
                alertTitle: 'Reports',
                alertDecription: response?.length > 0 ? this.response?.msg : 'No data found'
            };
            this.setAlertNotification(message)
        },
    },

    mounted() {
        this.oninit()
    },
}
</script>


<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <!-- <div v-if="loading">
            <PreLoader />
        </div> -->
        <div v-if="showShimmers">
            <b-card no-body>
                <b-card-body>
                    <div class="row">
                        <div class="col-sm-3">
                            <b-skeleton height="10px" width="100px"></b-skeleton>
                            <b-skeleton type="input"></b-skeleton>
                        </div>
                    </div>
                </b-card-body>
                <div class="card-footer d-flex justify-content-end"> 
                        <b-skeleton type="button" height="30px" width="100px" border-radius="8px"></b-skeleton>
                </div>
            </b-card> 
            <div v-if="showShimmers" class="mt-2">
                <b-skeleton-table :rows="5" :columns="5"
                  :table-props="{ bordered: false, striped: false }"></b-skeleton-table>
              </div>  
        </div>

        <div class="home" v-else>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <form>
                                <div class="row">
                                    <div class="col-12  col-md-3 ">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="department">Type of Letter</label>
                                                   <multiselect v-model="department" :options="options"></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer">
                            <div class="text-right">
                                <button class="btn btn-primary ml-2 mb-2" type="button">
                                   Raise a Request</button>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive card">
                       <div class="card-body">
                          <b-table hover :items="item" style="border: 1px solid #ced4da; cursor: pointer;">
                              <template #cell(EmployeeName)="row">
                                 <a href="/manager-appraisal-view" class="link font-weight-bold" @click="viewItem(row.item)"
                                  :title="row.value">{{ row.value
                                  }}</a>
                             </template>
                             <template #cell(actions)="">
                        <i class="ri-download-line text-primary font-size-18" size="sm"
                          style="font-size: 1.5rem;margin-right: 10px; cursor: pointer;"
                          v-b-tooltip.hover.bottom="'Download'"></i>
                      </template>
                          </b-table>       
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<style scoped lang="scss">
/* body {
    font-family: Arial, sans-serif;
}

.report {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.report-form {
    border: 1px solid #00435D;
    padding: 20px;
    border-radius: 5px;
} */

.form-group {
    margin-bottom: 10px;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

// input[type="text"],
// input[type="date"],
// select {
//     width: 100%;
//     padding: 8px;
//     border: 1px solid #00435D;
//     border-radius: 3px;
//     height: 100%;
// }

// button {
//     background-color: #00435D;
//     color: #fff;
//     border: none;
//     padding: 10px 20px;
//     border-radius: 5px;
//     cursor: pointer;
// }

// button:hover {
//     background-color: #00435D;
// }

.department,
.mx-input {
    height: 38px !important;
}

.card-footer {
    background-color: #e2eaed !important;
}
</style>