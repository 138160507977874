<script>
import Layout from "../../layouts/vertical.vue";
import PageHeader from "@/components/page-header";
// import PreLoader from "@/components/pre-loader";
import DatePicker from "vue2-datepicker";
import LeaveApproval from "../leaves/leave-approval.vue";
import HolidayCalendar from "../calendar/calendar.vue";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
import Multiselect from "vue-multiselect";
export default {
  components: {
    Layout, PageHeader, DatePicker, LeaveApproval, HolidayCalendar, Multiselect
  },
  data() {
    return {
      showFilters: false,
      tabName: 'Attendance',
      title: "Attendance",
      items: [
        {
          text: "Dashboard",
          routeName: "Dashboard",
        },
        {
          text: "Attendance",
          active: true,
        },
      ],
      showShimmers: false,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: "",
      daterange: "",
      fromdate: "",
      toDate: "",
      userList: [],
      currentDate: new Date().getDate(),
      currentMonth: new Date().getMonth() + 1,
      currentYear: new Date().getFullYear(),
      currentmonthdays: [],
      selectedOption: "All types",
      holidayTypeList: [],
      PublicHolidayList: [],
      changeMonthvalue: '',
      originalUserList: [],
      tabOptions: [
        { name: "Attendance", value: 'Attendance' },
        { name: "Holiday Calendar", value: 'Holidays' },
        { name: "Leave Approval", value: 'Leave' },
      ],
      Tab: '',
      PresentMonth: new Date().getMonth() + 1
    };
  },

  mounted() {
    this.fetchLeaveTypes()
    this.ChangeMonth('current')
  },
  computed: {
    ...mapState(["getUserguid"]),
    ...mapGetters("userservice", [
      "getAllUsers"
    ]),
    ...mapGetters("attendance", ["getAllEmployeesAttendance", "getDays", "getLeaveType"]),
    totalPages() {
      return Math.ceil(this.userList.length / this.perPage);
    },
    displayedUserList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.userList?.slice(start, end);
    },
    currentMonthName() {
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      return monthNames[this.currentMonth - 1];
    }
  },
  methods: {
    ChanageTab(tab) {
      this.Tab = this.tabOptions.find(data => data.value == tab)
      this.tabName = tab;
      tab === 'Attendance' ? this.loadInitData() : ''
    },
    ChangePage() {
      this.showShimmers = true;
      setTimeout(() => {
        this.showShimmers = false;
      }, 1000);
    },
    async fetchLeaveTypes() {
  await this.$store.dispatch("attendance/fetchLeaveType");
  this.holidayTypeList = this.getLeaveType;
  const additionalTypes = [
    { Type: 'Publicholiday', TypeText: 'Public holiday' },
    { Type: 'Present', TypeText: 'Present' },
    { Type: 'Absent', TypeText: 'Absent' },
    { Type: 'Weekend', TypeText: 'Weekend' }
  ];
  this.holidayTypeList = [...this.holidayTypeList, ...additionalTypes];
  this.holidayTypeList.sort((a, b) => a.TypeText.localeCompare(b.TypeText));
},
    async loadInitData() {
      this.showShimmers = true;
      const params = {
        pagenumber: -1,
        rowcount: -1,
        keyword: this.filter,
        sort: "desc",
        orderby: "",
        userGuid: this.getUserguid,
      };
      await this.$store.dispatch("userservice/fetchUsers", params);
      const body = {
        UsersIds: this.getAllUsers.map(user => user.Id),
        searchValue: this.filter,
        fromDate: this.fromdate,
        toDate: this.toDate
      }
      await this.$store.dispatch("attendance/getAllEmployeesAttendances", body)
      if (this.getAllUsers?.length > 0) {
        const userListMap = new Map();
        this.PublicHolidayList = this.getAllEmployeesAttendance?.Leaveslist[0].holidayList
        this.getAllEmployeesAttendance?.Leaveslist[0].leaveattendance?.forEach(attendance => {
          const key = `${attendance.UserId}-${attendance.EmployeeName}`;
          if (userListMap.has(key)) {
            let userObj = userListMap.get(key);
            userObj.someDataArray.push({
              CheckIn: attendance.CheckIn,
              CheckInLocation: attendance.CheckInLocation,
              CheckInPic: attendance.CheckInPic,
              CheckOut: attendance.CheckOut,
              EmployeeId: attendance.EmployeeId,
              Id: attendance.Id,
              IsApproved: attendance.IsApproved,
              IsCancelled: attendance.IsCancelled,
              IsDeleted: attendance.IsDeleted,
              FromDate: attendance.FromDate,
              EndDate: attendance.EndDate,
              LeaveType: attendance.LeaveType
            });
          } else {
            userListMap.set(key, {
              UserId: attendance.UserId,
              EmployeeName: attendance.EmployeeName,
              someDataArray: [{
                CheckIn: attendance.CheckIn,
                CheckInLocation: attendance.CheckInLocation,
                CheckInPic: attendance.CheckInPic,
                CheckOut: attendance.CheckOut,
                EmployeeId: attendance.EmployeeId,
                Id: attendance.Id,
                IsApproved: attendance.IsApproved,
                IsCancelled: attendance.IsCancelled,
                IsDeleted: attendance.IsDeleted,
                FromDate: attendance.FromDate,
                EndDate: attendance.EndDate,
                LeaveType: attendance.LeaveType
              }]
            });
          }
        });
        this.userList = Array.from(userListMap.values());
        this.originalUserList = Array.from(userListMap.values())
      } else {

        this.userList = [];
      }
      this.showShimmers = false;
    },
downloadLeaveEmployees() {
  this.downloading = true;
  const dataToDownload = [];
  const today = new Date();
  this.userList.forEach((user) => {
    if (user.someDataArray && user.someDataArray.length > 0) {
      user.someDataArray.forEach((data) => {
        const dates = this.currentmonthdays
          .filter((dateItem) => new Date(dateItem.dates) <= today)
          .map((dateItem) => dateItem.dates);
        if (
          this.currentMonth == this.PresentMonth &&
          (!data.FromDate?.includes(dates) ||
            data.IsApproved == null ||
            !data.CheckIn?.includes(dates))
        ) {
          dates.forEach((date) => {
            dataToDownload.push({
              "Employee name": user.EmployeeName,
              "Leave date": date,
              "Leave type": "Absent",
            });
          });
        } else if (data.FromDate !== undefined || data.CheckIn !== undefined) {
          const leaveDate = data.FromDate
            ? formatDate(data.FromDate)
            : formatDate(data.CheckIn);

          dataToDownload.push({
            "Employee name": user.EmployeeName ? user.EmployeeName : "",
            "Leave date": leaveDate,
            "Leave type": data.LeaveType ? data.LeaveType : "Present",
          });
        }
      });
    }
  });
  const headers = Object.keys(dataToDownload[0]);
  const headerRow = headers.join(",");
  const dataRows = dataToDownload.map((record) =>
    headers.map((header) => record[header]).join(",")
  );
  const csvData = [headerRow, ...dataRows].join("\n");
  const blob = new Blob(["\ufeff" + csvData], {
    type: "text/csv;charset=utf-8;",
  });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, "selected_records.csv");
  } else {
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Attendance-list.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  this.downloading = false;
  function formatDate(date) {
    if (!date) {
      return "";
    }
    const dateObject = new Date(date);
    return dateObject.toISOString().split("T")[0];
  }
},
    async fetchDataForMonthAndYear() {
      this.showShimmers = true
      const body = {
        currentMonth: this.currentMonth,
        currentYear: this.currentYear
      };
      await this.$store.dispatch("attendance/getDays", body);
      this.currentmonthdays = this.getDays.daysList;
      this.showShimmers = false
      const fromDateObject = this.currentmonthdays[0].dates;
      const toDateObject = this.currentmonthdays[this.currentmonthdays.length - 1].dates;
      this.fromdate = fromDateObject;
      this.toDate = toDateObject;
      this.loadInitData();
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    // search functionality
    changesearch: _.debounce(function () {
      this.loadInitData();
    }, 800),
    //  Date range search functionality 
    SearchByDatedif() {
      if (this.daterange[0] === null && this.daterange[1] === null) {
        const fromDateObject = this.currentmonthdays[0].dates;
        const toDateObject = this.currentmonthdays[this.currentmonthdays.length - 1].dates;
        this.fromdate = fromDateObject;
        this.toDate = toDateObject;
      } else {
        if (this.daterange) {
          const fromDateObject = this.daterange[0];
          const toDateObject = this.daterange[1];
          const formatDateString = (dateObject) => {
            if (dateObject instanceof Date && !isNaN(dateObject)) {
              const year = dateObject.getFullYear();
              const month = String(dateObject.getMonth() + 1).padStart(2, '0');
              const day = String(dateObject.getDate()).padStart(2, '0');
              return `${year}-${month}-${day}`;
            }
            return '';
          };
          this.fromdate = formatDateString(fromDateObject);
          this.toDate = formatDateString(toDateObject);
        }
      }
      this.loadInitData();
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    changePage() {
      this.currentPage = 1;
    },
    hasCheckInOnDate(user, date) {
      return user.someDataArray.some(item => {
        const fromDate = new Date(item.CheckIn);
        return fromDate.getDate() === date && item.CheckInPic;
      });
    },
    hasLeaveOnDate(user, date) {
      return user.someDataArray.some(item => {
        const fromDate = new Date(item.FromDate);
        const endDate =new Date(item.EndDate)
        return (date >= fromDate.getDate() && date <= endDate.getDate()) && item.LeaveType === 'Sick leave' && item.IsApproved;
      });
    },
    isMaternityleave(user, date) {
      return user.someDataArray.some(item => {
        const fromDate = new Date(item.FromDate);
        const endDate =new Date(item.EndDate)
        return (date >= fromDate.getDate() && date <= endDate.getDate()) && item.LeaveType === 'Maternity leave' && item.IsApproved;
      });
    },
    iscasualLeave(user, date) {
      return user.someDataArray.some(item => {
        const fromDate = new Date(item.FromDate);
        const endDate =new Date(item.EndDate)
        return (date >= fromDate.getDate() && date <= endDate.getDate()) && item.LeaveType === 'Personal leave' && item.IsApproved;
      });
    },
    isWorkFromHome(user, date) {
      return user.someDataArray.some(item => {
        const fromDate = new Date(item.FromDate);
        const endDate = new Date(item.EndDate);
        return (date >= fromDate.getDate() && date <= endDate.getDate()) && item.LeaveType === 'Work from home' && item.IsApproved;
      });
    },
    isOptionalleave(user, date) {
      return user.someDataArray.some(item => {
        const fromDate = new Date(item.FromDate);
        const endDate =new Date(item.EndDate)
        return (date >= fromDate.getDate() && date <= endDate.getDate())  && item.LeaveType === 'Optional leave' && item.IsApproved;
      });
    },
    isPaternityleave(user, date) {
      return user.someDataArray.some(item => {
        const fromDate = new Date(item.FromDate);
        const endDate =new Date(item.EndDate)
        return (date >= fromDate.getDate() && date <= endDate.getDate() )&& item.LeaveType === 'Paternity leave' && item.IsApproved;
      });
    },
    isCompensatoryLeave(user, date) {
      return user.someDataArray.some(item => {
        const fromDate = new Date(item.FromDate);
        const endDate =new Date(item.EndDate)
        return (date >= fromDate.getDate() && date <= endDate.getDate())&& item.LeaveType === 'Compensatory leave' && item.IsApproved;
      });
    },
    isBereavementleave(user, date) {
      return user.someDataArray.some(item => {
        const fromDate = new Date(item.FromDate);
        const endDate =new Date(item.EndDate)
        return (date >= fromDate.getDate() && date <= endDate.getDate() )&& item.LeaveType === 'Bereavement leave' && item.IsApproved;
      });
    },
    isPublicHoliday(date) {
      if (this.PresentMonth == this.currentMonth) {
        return this.PublicHolidayList.some(item => {
          const fromDate = new Date(item.HolidayDate);
          return fromDate.getDate() === date && this.currentDate >= date;
        });
      }
      else {
        return this.PublicHolidayList.some(item => {
          const fromDate = new Date(item.HolidayDate);
          return fromDate.getDate() === date;
        });
      }
    },
    isUpcomingDay(date, currentMonth) {
      if (currentMonth == this.PresentMonth) {
        return this.currentDate < date;
      }
      return
    },
    isAbsent(user, dates, currentMonth) {
      return user.someDataArray.some(item => {
        return currentMonth == this.PresentMonth && (!item.FromDate?.includes(dates) || item.IsApproved == null);
      });
    },
    isWeekend(date, day) {
      if (this.PresentMonth == this.currentMonth) {
        return day == 'S' && this.currentDate >= date;
      }
      else {
        return day == 'S';
      }
    },
    ChangeLeaveTypes(option) {
      this.selectedOption = option.TypeText;
      let filteredUserList = [];
      if (option === 'clear') {
        this.$refs["leavesdropdown"]?.hide();
        this.selectedOption = "All types";
        this.userList = [...this.originalUserList];
      }
      else if (this.selectedOption === 'Present') {
        this.$refs["leavesdropdown"]?.hide();
        filteredUserList = this.originalUserList.filter((user) => {
          const filteredSomeDataArray = user.someDataArray.filter((data) => {
            return data.CheckIn && data.CheckInPic;
          });
          return filteredSomeDataArray.length > 0;
        });
        this.userList = filteredUserList;
      }
      else if (this.selectedOption === 'Public holiday' || this.selectedOption === 'Weekend') {
        this.$refs["leavesdropdown"]?.hide();
        filteredUserList = this.originalUserList.map((user) => {
          const filteredSomeDataArray = user.someDataArray.filter((data) => {
            return data;
          });
          return { ...user, someDataArray: filteredSomeDataArray };
        });
        this.userList = filteredUserList;
      }
      else if (this.selectedOption === 'Absent') {
        this.$refs["leavesdropdown"]?.hide();
        filteredUserList = this.originalUserList.map((user) => {
          const filteredSomeDataArray = user.someDataArray.filter((data) => {
            return !data.FromDate || data.IsApproved == null;
          });
          return { ...user, someDataArray: filteredSomeDataArray };
        });
        this.userList = filteredUserList;
      }
      else {
        this.$refs["leavesdropdown"]?.hide();
        filteredUserList = this.originalUserList.filter((user) => {
          const filteredSomeDataArray = user.someDataArray.filter((data) => {
            return data.LeaveType === this.selectedOption && data.IsApproved;
          });
          return filteredSomeDataArray.length > 0;
        });
        this.userList = filteredUserList;
      }
    },
    ChangeMonth(changeMonthBy) {
      this.changeMonthvalue = changeMonthBy
      if (changeMonthBy === 'previous') {
        this.currentMonth--;
        if (this.currentMonth === 0) {
          this.currentMonth = 12;
          this.currentYear--;
        }
        this.fetchDataForMonthAndYear()
      } else if (changeMonthBy === 'next') {
        this.currentMonth++;
        if (this.currentMonth === 13) {
          this.currentMonth = 1;
          this.currentYear++;
        }
        this.fetchDataForMonthAndYear()
      } else if (changeMonthBy === 'current') {
        this.fetchDataForMonthAndYear()
      }
      if (this.currentMonth < 1) {
        this.currentMonth = 12;
        this.currentYear--;
        this.fetchDataForMonthAndYear()
      } else if (this.currentMonth > 12) {
        this.currentMonth = 1;
        this.currentYear++;
        this.fetchDataForMonthAndYear()
      }
    }
  }

};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="home">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <!-- Toggle Button -->
              <div class="d-flex justify-content-end justify-content-md-between align-items-center">
                <b-tabs nav-class="nav-tabs-custom ml-0" content-class="text-muted"
                  class="d-none d-sm-none d-md-block d-lg-block">
                  <b-tab @click="ChanageTab('Attendance')" :active="tabName == 'Attendance'">
                    <template v-slot:title>
                      <!-- <span class="d-inline-block">
                        <i class="ri-calendar-2-line align-bottom"></i>
                      </span>
                      <span class="d-inline-block ml-2"></span> -->
                      <span class="d-inline-block mr-2">
                        <i class="ri-calendar-2-line align-bottom"></i>
                      </span>
                      <span class="d-sm-none d-lg-inline-block">Attendance</span>
                    </template>
                  </b-tab>
                  <b-tab @click="ChanageTab('Holidays')" :active="tabName == 'Holidays'">
                    <template v-slot:title>
                      <span class="d-inline-block mr-2">
                        <i class="ri-calendar-line align-bottom"></i>
                      </span>
                      <span class="d-sm-none d-lg-inline-block">Holidays calendar</span>
                    </template>
                  </b-tab>
                  <b-tab @click="ChanageTab('Leave')" :active="tabName == 'Leave'">
                    <template v-slot:title>
                      <span class="d-inline-block mr-2">
                        <i class="ri-file-list-3-line align-bottom"></i>
                      </span>
                      <span class="d-sm-none d-lg-inline-block">Leave approval</span>
                    </template>
                  </b-tab>
                </b-tabs>
                <div class="align-items-center d-flex flex-wrap justify-content-center mb-2 mb-md-0">
                  <div class="dateArea font-size-14 font-weight-bold align-self-center d-flex"
                    v-if="tabName != 'Holidays' && tabName != 'Leave'">
                    <p class="mb-0">
                      <i class="ri-arrow-left-s-line btn p-0" @click="ChangeMonth('previous')"></i><button
                        class="btn btn-link p-0">{{ currentMonthName }},
                        {{ currentYear }}</button>
                      <i class="ri-arrow-right-s-line btn p-0" @click="ChangeMonth('next')"></i>
                    </p>
                  </div>
                  <div class="ml-2 flex-shrink=0" v-if="tabName != 'Holidays' && tabName != 'Leave'">
                    <b-dropdown size="sm" v-model="selectedOption" variant="outline-light" class="w-100"
                      ref="leavesdropdown">
                      <template v-slot:button-content>
                        {{ selectedOption }}
                        <i class="mdi mdi-chevron-down"></i>
                      </template>
                      <b-dropdown-item-button class="colorIndicatorBlock" v-for="option of holidayTypeList"
                        :key="option.Id">
                        <div class="d-flex align-items-center" @click="ChangeLeaveTypes(option)">
                          <span class="holidayTypeIndicator mr-2" :class="option.Type"></span>
                          <span> {{ option.TypeText }}</span>
                        </div>
                      </b-dropdown-item-button>
                      <button v-if="this.selectedOption !== 'All types'" class="btn clear-btn text-primary font-weight-bold " @click="ChangeLeaveTypes('clear')">
                        x Clear
                      </button>
                    </b-dropdown>
                  </div>
                  <div class="ml-2 flex-shrink=0" @click="downloadLeaveEmployees">
                    <b-button v-if="tabName != 'Holidays' && tabName != 'Leave'" size="sm" variant="outline-primary"
                      v-b-modal.modal-center>
                      <i class="ri-arrow-up-line align-middle mr-2"></i>{{ tabName === 'Leave' ? 'Add Leave' : tabName
                        === 'Holidays' ? 'Add Holiday' : 'Export' }}
                    </b-button>
                  </div>
                  <div class="ml-2">
                    <b-button v-if="tabName != 'Holidays' && tabName != 'Leave'" variant="primary" @click="toggleFilters"
                      size="sm" class="align-bottom" style="top:5px">
                      <i :class="'ri-' + (showFilters == true ? 'filter-line' : 'filter-line')" class="align-bottom"></i>
                    </b-button>
                  </div>
                </div>
              </div>
              <div v-if="showShimmers" class="mt-2">
                  <div class="d-flex justify-content-between mt-2">
                <div>
                    <b-skeleton type="input" width="100px" height="30px"></b-skeleton>
                </div>
                <div class="d-flex justify-content-end mb-2">
                    <b-skeleton class="mr-2" type="input" width="200px" height="30px"></b-skeleton>
                    <b-skeleton class="mr-2" type="input" width="180px" height="30px"></b-skeleton>
                </div>
                </div>
                  <div class="row">
                    <div class="col-12">
                      <table class="table">
                        <tbody>
                          <thead>
                            <tr v-for="(user, index ) in [].constructor(4)" :key="index">
                              <td>
                                <div class="d-flex justify-content-between align-items-center">
                                  <div class="d-flex">
                                    <b-skeleton type="avatar" width="40px" height="40px"></b-skeleton>
                                    <b-skeleton class="align-self-center ml-2 m-0 mr-auto" width="140px"></b-skeleton>
                                  </div>
                                </div>
                              </td>
                              <td class="w-100">
                                <div>
                                  <b-skeleton class="ml-auto m-0" width="95%"></b-skeleton>
                                </div>
                              </td>
                            </tr>
                          </thead>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              <!--Filters-->
              <div v-else>
                <transition name="slide-fade" mode="out-in">
                  <div v-if="this.tabName != 'Holidays' && this.tabName != 'Leave' ? (showFilters) : ''"
                    class="d-flex justify-content-between ytmFilterRow my-2 flex-column flex-sm-row">
                    <div id="tickets-table_length" class="dataTables_length text-center text-sm-left">
                        <label class="d-inline-flex align-items-center mb-2 mb-sm-0">
                          Show
                          <select size="sm" v-model="perPage" class="form-control align-self-start selction-padding"
                            style="width: 70px;" @change="ChangePage">
                            <option v-for="option of pageOptions" :key="option">{{ option }}</option>
                          </select>
                        </label>
                      </div>
                    <!--Search-->
                    <div class="d-flex justify-content-end align-items-sm-center flex-column flex-sm-row">
                      <div class="">
                        <div class="date-range mr-0 mr-sm-2 mb-2 mb-sm-0">
                          <date-picker v-model="daterange" range append-to-body lang="en" confirm
                            placeholder="Select date range" format="DD/MM/YYYY" @change="SearchByDatedif()"></date-picker>
                        </div>
                      </div>
                      <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                        <label class="d-inline-flex align-items-center position-relative w-100 mb-0">
                          <b-form-input size="sm" v-model="filter" type="Search" placeholder="Search..."
                            @input="changesearch" class="form-control searchInput"></b-form-input>
                          <i class="ri-search-line search-icon"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </transition>
                <!-- Tabs dropdown UI -->
                <div class="attendance-dropdown d-lg-none d-md-none">
                  <multiselect v-model="Tab" :options="tabOptions" label="name" track-by="value"
                    @input="ChanageTab(Tab.value)"></multiselect>
                </div>
                <div v-if="tabName == 'Attendance'">
                  <!-- <div class="legend d-flex justify-content-end mb-3 mt-2 flex-column flex-sm-row" >
                    <h5 class="mb-0 mr-2 font-size-16">Info:</h5>
                    <div class="legend-item d-flex justify-content-sm-center align-items-center">
                      <div class="box-present"></div>
                      <span class="ml-1 font-weight-bold">Present</span>
                    </div>
                    <div class="legend-item d-flex justify-content-sm-center align-items-center ml-0 ml-sm-2">
                      <div class="box-sick"></div>
                      <span class="ml-1 font-weight-bold">Sick</span>
                    </div>
                    <div class="legend-item d-flex justify-content-sm-center align-items-center ml-0 ml-sm-2">
                      <div class="box-casual"></div>
                      <span class="ml-1 font-weight-bold">Casual</span>
                    </div>
                    <div class="legend-item d-flex justify-content-sm-center align-items-center ml-0 ml-sm-2">
                      <div class="box-disabled"></div>
                      <span class="ml-1 font-weight-bold">Weekend</span>
                    </div>
                    <div class="legend-item d-flex justify-content-sm-center align-items-center ml-0 ml-sm-2">
                      <div class="box-public-holiday"></div>
                      <span class="ml-1 font-weight-bold">Public Holiday</span>
                    </div>
                    <div class="legend-item d-flex justify-content-sm-center align-items-center ml-0 ml-sm-2">
                      <div class="box-compensatory-leave"></div>
                      <span class="ml-1 font-weight-bold">Compensatory Leave</span>
                    </div>
                  </div> -->
                
                  <div class="table-responsive stickyScrollableTable mt-2">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" width="20%"></th>
                          <th v-for="number in currentmonthdays" :key="number.date" scope="col">{{ number.day }}</th>
                        </tr>
                        <tr>
                          <th scope="col"></th>
                          <th v-for="number in currentmonthdays" :key="number.date" scope="col">{{ number.date }}</th>
                        </tr>
                      </thead>
                      <tbody style="max-height: 400px;overflow: auto;">
                        <tr v-for="user in displayedUserList" :key="user.Id">
                          <td>
                            <div class="d-flex align-items-center">
                              <div><img class="image flex-shrink-0" :src="user.image" alt="Image" width="35" height="35"
                                  v-if="user.image">
                                <img class="image flex-shrink-0" src="@/assets/images/users/user.png" alt="Image" width="35"
                                  height="35" v-else>
                              </div>
                              <div class="mw-200">
                                <p class="text-truncate mb-0 font-weight-bold text-dark" :title="user.EmployeeName">{{
                                  user.EmployeeName }}</p>
                                <!-- <p class="text-truncate mb-0" :title="user.DesignationName"> {{ user.DesignationName }}</p> -->
                              </div>
                            </div>
                          </td>
                          <td class="box-container" v-for="number in currentmonthdays" :key="number.date">
                            <div class="d-flex align-items-center justify-content-center">
                              <div v-if="hasCheckInOnDate(user, number.date)" :title="'Present'"
                                :class="[selectedOption == 'Present' ? 'Present' : selectedOption != 'All types' ? 'highlighted' : 'Present']">
                              </div>
                              <div v-else-if="hasLeaveOnDate(user, number.date)" :title="'Sick leave'"
                                :class="[selectedOption == 'Sick leave' ? 'Sickleave' : selectedOption != 'All types' ? 'highlighted' : 'Sickleave']">
                              </div>
                              <div v-else-if="isWeekend(number.date, number.day)" :title="'Weekend'"
                                :class="[selectedOption == 'Weekend' ? 'Weekend' : selectedOption != 'All types' ? 'highlighted' : 'Weekend']">
                              </div>
                              <div v-else-if="isPublicHoliday(number.date)" :title="'Public holiday'"
                                :class="[selectedOption == 'Public holiday' ? 'PublicHoliday' : selectedOption != 'All types' ? 'highlighted' : 'PublicHoliday']">
                              </div>
                              <div v-else-if="isCompensatoryLeave(user, number.date)" :title="'Compensatory leave'"
                                :class="[selectedOption == 'Compensatory leave' ? 'Compensatoryleave' : selectedOption != 'All types' ? 'highlighted' : 'Compensatoryleave']">
                              </div>
                              <div v-else-if="iscasualLeave(user, number.date)" :title="'Personal leave'"
                                :class="[selectedOption == 'Personal leave' ? 'Personalleave' : selectedOption != 'All types' ? 'highlighted' : 'Personalleave']">
                              </div>
                              <div v-else-if="isWorkFromHome(user, number.date)" :title="'Work from home'"
                                :class="[selectedOption == 'Work from home' ? 'Workfromhome' : selectedOption != 'All types' ? 'highlighted' : 'Workfromhome']">
                              </div>
                              <div v-else-if="isPaternityleave(user, number.date)" :title="'Paternity leave'"
                                :class="[selectedOption == 'Paternity leave' ? 'Paternityleave' : selectedOption != 'All types' ? 'highlighted' : 'Paternityleave']">
                              </div>
                              <div v-else-if="isMaternityleave(user, number.date)" :title="'Maternity leave'"
                                :class="[selectedOption == 'Maternity leave' ? 'Maternityleave' : selectedOption != 'All types' ? 'highlighted' : 'Maternityleave']">
                              </div>
                              <div v-else-if="isOptionalleave(user, number.date)" :title="'Optional leave'"
                                :class="[selectedOption == 'Optional leave' ? 'Optionalleave' : selectedOption != 'All types' ? 'highlighted' : 'Optionalleave']">
                              </div>
                              <div v-else-if="isBereavementleave(user, number.date)" :title="'Bereavement leave'"
                                :class="[selectedOption == 'Bereavement leave' ? 'Bereavementleave' : selectedOption != 'All types' ? 'highlighted' : 'Bereavementleave']">
                              </div>
                              <div v-else-if="isUpcomingDay(number.date, currentMonth)" class="box-upcoming-days"></div>
                              <div v-else-if="isAbsent(user, number.dates, currentMonth)" :title="'Absent'"
                                :class="[selectedOption == 'Absent' ? 'Absent' : selectedOption != 'All types' ? 'highlighted' : 'Absent']">
                              </div>
                              <div v-else>-</div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- No data found message-->
                    <div class="nodata-ui" v-if="!userList?.length && !showShimmers">
                      <div class="fullscreen-height d-flex align-items-center justify-content-center">
                        <div class="py-5 text-muted">
                          <div class="d-flex flex-column justify-content-center gap-1 align-items-center fs-4">
                            <i class="ri-information-line font-size-36"></i>
                            <h5>No data found!</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- No data found message end-->
                  </div>
                </div>
              </div>
              <div v-if="tabName == 'Leave'">
                <LeaveApproval></LeaveApproval>
              </div>
              <div v-if="tabName == 'Holidays'">
                <HolidayCalendar></HolidayCalendar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </Layout>
</template>
<style scoped lang="scss">
.table th {
  text-align: center;
}

.table td {
  vertical-align: middle;
}

.fullscreen-height {
  height: 500px;
}

.image {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.w-200 {
  width: 200px;
}

.mw-200 {
  max-width: 200px;
}

.box-container {
  width: 40px;
  height: 40px;
}

.highlighted {
  background-color: rgb(0 67 93 / 15%);
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.box {
  background-color: #00435D !important;
  width: 100%;
  height: 100%;
}

.Present {
  background-color: #5ae28e;
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 30px;
  /* Set text color for better visibility */
}

.Sickleave {
  background-color: #f89fe9;
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 30px;
}

.Personalleave {
  background-color: #f89fe9;
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 30px;
}


.box-disabled {
  background-color: rgba(209, 209, 209, 0.5);
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 30px;
}

.box-upcoming-days {
  background-color: rgba(151, 151, 151, 0.5);
  color: white;
  width: 5px;
  height: 5px;
  border-radius: 30px;
  margin: auto;
}

.Weekend {
  background-color: #5ccfdf;
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 30px;
}

.PublicHoliday {
  background-color: #5ccfdf;
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 30px;
}

.Compensatoryleave {
  background-color: #f89fe9;
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 30px;
}

.Workfromhome {
  background-color: #5ae28e;
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 30px;
}

.Paternityleave {
  background-color: #f89fe9;
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 30px;
}

.Maternityleave {
  background-color: #f89fe9;
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 30px;
}

.Optionalleave {
  background-color: #f89fe9;
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 30px;
}

.Bereavementleave {
  background-color: #f89fe9;
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 30px;
}

.Absent {
  background-color: #ee5f5f;
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 30px;
}

.holidayTypeIndicator {
  width: 14px;
  height: 14px;
  background-color: #5ccfdf;
  border-radius: 50px;
  display: block;

  &.SickLeave {
    background-color: #f89fe9;
  }

  &.MaternityLeave {
    background-color: #f89fe9;
  }

  &.CasualLeave {
    background-color: #f89fe9;
  }

  &.WorkFromHome {
    background-color: #5ae28e;
  }

  &.PaternityLeave {
    background-color: #f89fe9;
  }

  &.OptionalHoliday {
    background-color: #f89fe9;
  }

  &.CompensatoryLeave {
    background-color: #f89fe9;
  }

  &.BereavementLeave {
    background-color: #f89fe9;  
  }

  &.Weekend {
    background-color: #5ccfdf;
  }

  &.Present {
    background-color: #5ae28e;
  }

  &.Absent {
    background-color: #ee5f5f;
  }

  &.PublicHoliday {
    background-color: #5ccfdf;
  }
}

.dropdown-item {
  padding-left: 10px;
}

.dateArea {
  order: -1;

  button:hover,
  button:active,
  button:focus {
    text-decoration: none;
  }

  @media screen and (max-width:431px) {

    order: 13;
    margin-top: 0.5rem
  }
}

.filter-btn {
  position: absolute;
  right: 0;
  bottom: 8px
}</style>