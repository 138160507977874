<script>
import { mapGetters, mapMutations } from 'vuex';
import PreLoader from "@/components/pre-loader";
// import Swal from "sweetalert2";
export default {
  components: { PreLoader },
  data() {
    return {
      showDismissibleAlert: false,
      userGuid: '',
      Account: {
        NewPassword: '',
        ConfirmPassword: ''
      },
      newpasswordBlured: false,
      showconfirmPassword: false,
      showPassword: false,
      isSubmitted: false,
      showpopover: false,
      ConfirmPasswordBlured: false,
      isPasswordValid: true,
      isPasswordMatch: true,
      PasswordSame: false,
      isUppercaseValid: false,
      isLowercaseValid: false,
      isNumberValid: false,
      isSpecialCharacterValid: false,
      isResend: true,
      loading: true,
      response:''
    }
  },
  methods: {
    ...mapMutations(['setSharedData', 'setUserGuid']),

    async checkVerificationTime() {
      await this.$store.dispatch('userservice/GetUserByGuid', this.userGuid);
      if (!this.getUserDeteils) {
        this.$router.push({ name: 'Login' });
      }
      this.updatedDate = this.getUserDeteils?.UpdatedDt ? this.getUserDeteils?.UpdatedDt:'';
      const now = new Date();
      const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
      const timeDifference = utc.getTime() - new Date(this.updatedDate).getTime();
      const minutesElapsed = Math.round(timeDifference / 60000);
      this.isResend = Math.abs(minutesElapsed) < 15 ? true : false;
      if (this.isResend == false && this.updatedDate) {
        this.setSharedData('expired');
        this.setUserGuid(this.userGuid);
        this.$router.push({ name: 'NotVerified' });
      }
      this.loading = false
    },
    validatePassword() {
      this.response = '';
      this.invalidform = false
      this.isPasswordValid = false;
      this.showpopover = true;
      this.isUppercaseValid = /[A-Z]/.test(this.Account.NewPassword);
      this.isLowercaseValid = /[a-z]/.test(this.Account.NewPassword);
      this.isNumberValid = /[0-9]/.test(this.Account.NewPassword);
      this.isSpecialCharacterValid = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/\-=]/.test(this.Account.NewPassword);
      const isLengthValid = this.Account.NewPassword?.length >= 8;

      if (this.isUppercaseValid && this.isLowercaseValid && this.isSpecialCharacterValid && this.isNumberValid && isLengthValid) {
        this.showpopover = false;
        this.isPasswordValid = true;
        this.PasswordSame = this.Account.CurrentPassword === this.Account.NewPassword
      }
      this.validateConfirmPassword();
    },
    validateConfirmPassword() {
      this.response = '';
      this.isPasswordMatch = this.Account.NewPassword === this.Account.ConfirmPassword;
    },
    validateEmail() {
      this.emailNotValid = true
      this.emailNotValid = !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email));
    },
    async tryToReset() {
      if(this.isFormValid){
        this.loading=true;
        const params = {
          email: this.getUserDeteils?.Email,
          userGuid: this.userGuid
        }
        const input = {
          userId: this.userGuid,
          password: this.Account.NewPassword,
          confirmPassword: this.Account.ConfirmPassword,
        }
        await this.$store.dispatch('userservice/UpdatePassword', input);
        this.response=this.getErrors.critical_error;
        this.loading=false;
        if (this.getUpdatePassword) {
          this.showDismissibleAlert = true;
          await this.$store.dispatch('userservice/UpdateEmailConfirmed', params);         
          this.showDismissibleAlert = false;
          this.$router.push({ name: 'Login' });
        }
      }
      else{
        this.newpasswordBlured=true;
        this.ConfirmPasswordBlured=true;
      }
    }
  },
  created() {
    this.userGuid = this.$route.params.guid;
  },
  mounted() {
    this.checkVerificationTime()
  },
  computed: {
    ...mapGetters('userservice', ['getUserDeteils', 'getEmailconfirmed', 'getUpdatePassword']),
    ...mapGetters('errors',['getErrors']),
    isFormValid() {
      return (
        this.Account.NewPassword &&
        this.isPasswordValid &&
        this.isPasswordMatch &&
        this.Account.ConfirmPassword &&
        !this.PasswordSame
      );
    },
  },
}

</script>

<template>
  <div>
    <div v-if="loading">
      <PreLoader></PreLoader>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/ytm-dark.svg" height="70" alt="logo" />
                          </a>
                          <h4 class="font-size-18 my-4">Reset password</h4>
                        </div>
                      </div>
                      <form class="form-horizontal" @submit.prevent="tryToReset">
                        <b-alert show variant="danger" v-if="this.response">
                          <i class="align-bottom ri-close-circle-line mr-2"></i>{{this.response}}
                        </b-alert>
                        <div class="position-relative">
                          <div class="form-group auth-form-group-custom mb-4">
                            <div class="password-visibility-block position-relative">
                              <i class="ri-lock-2-line auti-custom-input-icon"></i>
                              <label for="userpassword">New password <span
                                  :class="{ 'text-danger': !isPasswordValid || !Account.NewPassword }">*</span></label>
                              <input :type="showPassword ? 'text' : 'password'" class="form-control" id="userpassword"
                                placeholder="Enter password" v-model="Account.NewPassword" :maxlength="100"
                                v-on:blur="newpasswordBlured = true; showpopover = false" @input="validatePassword"
                                @click="!Account.NewPassword && isPasswordValid?showpopover=true:showpopover=false"
                                v-bind:class="{ 'form-control': true, 'is-invalid': !Account.NewPassword && newpasswordBlured || !isPasswordValid }" />
                              <div class="invalid-feedback position-absolute"
                                v-if="!Account.NewPassword && newpasswordBlured">New password is required!</div>
                              <div class="invalid-feedback position-absolute"
                                v-if="Account.NewPassword && !isPasswordValid">{{Account.NewPassword?.length > 8 ? 'New password is invalid!':'Password must be at least 8 characters!'}}</div>
                              <button class="btn password-visibility-icon" type="button"
                                @click="showPassword = !showPassword">
                                <template v-if="showPassword">
                                  <i class="ri-eye-line font-size-20" variant="primary"></i>
                                </template>
                                <template v-else>
                                  <i class="ri-eye-off-line font-size-20" variant="primary"></i>
                                </template>
                              </button>
                            </div>
                          </div>
                          <div v-if="showpopover" class="password-popup">
                            <div class="password-popup_Header">Passwords must be at least eight characters long and
                              contain at least one lowercase letter, one uppercase letter, one number, and one special
                              character.</div>
                            <ul class="mt-3 align-center">
                              <li :class="{ 'active': isLowercaseValid }"><span></span> Lowercase letter</li>
                              <li :class="{ 'active': isUppercaseValid }"><span></span> Uppercase letter</li>
                              <li :class="{ 'active': isNumberValid }"><span></span> Number</li>
                              <li :class="{ 'active': isSpecialCharacterValid }"><span></span> Special character</li>
                              <li :class="{ 'active': this.Account.NewPassword?.length >= 8 }"><span></span> # of
                                characters</li>
                            </ul>
                          </div>
                        </div>
                        <div class="form-group auth-form-group-custom mb-4 mt-35">
                          <div class="password-visibility-block position-relative">
                            <i class="ri-lock-password-line auti-custom-input-icon"></i>
                            <label for="confirmpassword">Confirm password <span
                                :class="{ 'text-danger': !Account.ConfirmPassword || !isPasswordMatch }">*</span></label>
                            <input :type="showconfirmPassword ? 'text' : 'password'" class="form-control" :maxlength="100"
                              v-model="Account.ConfirmPassword" placeholder="Re-enter password"
                              v-bind:class="{ 'form-control': true, 'is-invalid': (!Account.ConfirmPassword && ConfirmPasswordBlured) || (Account.ConfirmPassword && !isPasswordMatch) }"
                              v-on:blur="ConfirmPasswordBlured = true" @input="validateConfirmPassword()" />
                            <div class="invalid-feedback d-block position-absolute"
                              v-if="!Account.ConfirmPassword && (ConfirmPasswordBlured)">Confirm password is
                              required!</div>
                              <div v-if="isPasswordMatch && Account.ConfirmPassword">
                                        <i class="ri-check-fill tick-icon" style="color: #28a945; font-size: 24px;"></i>
                                    </div>  
                            <button class="btn password-visibility-icon" type="button"
                              @click="showconfirmPassword = !showconfirmPassword">
                              <template v-if="showconfirmPassword">
                                <i class="ri-eye-line font-size-20" variant="primary"></i>
                              </template>
                              <template v-else>
                                <i class="ri-eye-off-line font-size-20" variant="primary"></i>
                              </template>
                            </button>
                            <div class="invalid-feedback d-block position-absolute m-0"
                              v-if="!isPasswordMatch && Account.ConfirmPassword">
                              New password and confirm password must match!</div>
                          </div>
                        </div>
                        <div class="mt-5 text-center">
                          <button class="btn btn-primary w-md waves-effect waves-light" type="submit" :disabled="!isFormValid">Reset</button>
                        </div>
                        <b-alert v-model="showDismissibleAlert" variant="success" dismissible class="edittask-alert">
                      <p class="font-size-16 font-weight-bold mb-0"><i class="ri-checkbox-circle-line align-bottom"></i> Updated!</p>
                      <p class="mb-0">Password has been successfully updated.</p>
                       </b-alert>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
input::-ms-reveal{
        display: none;
      }
.password-visibility-block {
  .password-visibility-icon {
    position: absolute;
    top: 14px;
    right: 10px;
    width: 25px;
    padding: 0;
    box-shadow: none;
  }
}

.password-popup {
  background: #fff;
  position: absolute;
  top: 75px;
  right: 0;
  z-index: 1;
  height: 260px;
  width: 300px;
  border-radius: 11px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);

  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #00435d;
    border-width: 10px;
    margin-left: -10px;
  }

  &_Header {
    background-color: #00435d;
    color: #fff;
    width: 100%;
    padding: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    line-height: 18px;
    letter-spacing: 0.1px;
  }

  ul {
    list-style-type: none;
    padding: auto;
  }

  li {
    display: block;
    margin-bottom: 4px;

    span {
      display: inline-block;
      width: 15px;
      height: 15px;
      border: 2px solid #ccc;
      background-color: #fff;
      border-radius: 10px;
      vertical-align: middle;
    }

    &.active {
      span {
        border: 2px solid #28a745;
        background-color: #28a745;
      }
    }
  }
}
.tick-icon{
  position: absolute;
  top: 14px;
  right: 35px;
  width: 25px;
  padding: 0;
  box-shadow: none;
}
.mt-35 {
  margin-top: 35px
}
.edittask-alert{
  position: fixed;
  top:10px;
  right: 10px;
  z-index: 10000;
}
</style>